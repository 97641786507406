import { useMemo } from "react";
import { MenuItem } from "@mui/material";

import {
  DataGrid,
  // EmptyState,
  Icons,
  Typography,
  Pagination,
  Menu,
  PillButton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { dispatchToast, pageSizeOptions, renderHtmlText } from "@utils";

import {
  CellArea,
  ItensPerPageArea,
  Option,
  PaginationArea,
  PaginationContainer,
  StyledSelectField,
  StyledText,
  StyledTypography,
  TooltipText,
} from "./styled";

import type { TableGridCommonProps } from "@components/PaginationTable/types";

export const TableGrid = ({
  data,
  pagination,
  loading,
  onPaginationChanged,
}: TableGridCommonProps) => {
  const utils = trpc.useContext();

  const { mutate: duplicateMutate } =
    trpc.performance.criteria.duplicateCriteria.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Critério avaliativo duplicado com sucesso!",
        });

        utils.performance.criteria.getAllCriterials.refetch();
      },
      onError: () => {
        const message =
          "Erro ao duplicar o critério avaliativo, tente novamente em breve.";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: "Critério",
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description = "" } = original;
          return (
            <CellArea>
              <TooltipText variant="body3">
                {name}
                <Tooltip
                  title={renderHtmlText(description)}
                  style={{ marginLeft: "4px", cursor: "pointer" }}
                >
                  <div>
                    <Icons name="IconInfoCircle" fill="transparent" size={16} />
                  </div>
                </Tooltip>
              </TooltipText>
              <Typography variant="body4">
                {renderHtmlText(description)}
              </Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () => {
                    duplicateMutate({
                      params: {
                        _id: row?.original?._id,
                      },
                    });
                  },
                  children: (
                    <Option>
                      <Icons name="IconCopy" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Duplicar critério
                      </StyledText>
                    </Option>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                variant="default"
                size="small"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    [data]
  );

  const { page, pageSize, totalCount } = pagination;

  // if (!loading && !isFiltered && totalCount === 0) {
  //   return (
  //     <EmptyState
  //       buttonText={
  //         <>
  //           Criar critério <Icons name="IconPlus" />
  //         </>
  //       }
  //       description={"Você ainda não criou critérios avaliativos"}
  //       buttonProps={{
  //         size: "medium",
  //         onClick: () => {},
  //       }}
  //     />
  //   );
  // }

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        loading={loading}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: loading ? "" : "Nenhum modelo de critério foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
        customPagination={({ setPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 10}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                  onPaginationChanged(page, newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <MenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </MenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > totalCount ? totalCount : page * pageSize}
                &nbsp;de&nbsp;{totalCount || 0}&nbsp;
                {totalCount === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                onChange={(_, value) => {
                  onPaginationChanged(value, pageSize);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
    </>
  );
};
