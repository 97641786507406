import { useState } from "react";
import { Grid } from "@mui/material";

import { EvaluationForm } from "./Tabs/EvaluationForm";
import { BasicInfos } from "./Tabs/BasicInfos";
import { Settings } from "./Tabs/Settings";

import { StyledIcon, StyledText } from "@utils";

import { TabButton, StyledLine } from "./styled";

import type { Evaluation } from "server/src/services/evaluation/types";

interface IDetailsProps {
  selectedEvaluation?: Evaluation;
}

export const Details = ({ selectedEvaluation }: IDetailsProps) => {
  const [tab, setTab] = useState<"basic-info" | "settings" | "form" | null>(
    "basic-info"
  );

  const tabs = {
    ["basic-info"]: <BasicInfos data={selectedEvaluation} />,
    ["settings"]: <Settings data={selectedEvaluation} />,
    ["form"]: <EvaluationForm data={selectedEvaluation} />,
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={2.5} width={"100%"} marginBottom={"24px"}>
        <TabButton
          active={tab === "basic-info"}
          onClick={() => setTab("basic-info")}
          style={{ marginBottom: "16px" }}
        >
          <StyledLine>
            <StyledIcon name="IconFileDescription" size={15} />
            <StyledText
              variant="body4"
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              Informações básicas
            </StyledText>
          </StyledLine>
        </TabButton>

        <TabButton
          active={tab === "settings"}
          onClick={() => setTab("settings")}
          style={{ marginBottom: "16px" }}
        >
          <StyledLine>
            <StyledIcon name="IconFileDescription" size={15} />
            <StyledText
              variant="body4"
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              Configurações
            </StyledText>
          </StyledLine>
        </TabButton>
        <TabButton
          active={tab === "form"}
          onClick={() => setTab("form")}
          style={{ marginBottom: "16px" }}
        >
          <StyledLine>
            <StyledIcon name="IconFileDescription" size={15} />
            <StyledText
              variant="body4"
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              Formulário de avaliação
            </StyledText>
          </StyledLine>
        </TabButton>
      </Grid>

      <Grid item sm={12} md={9.5} width={"100%"}>
        {tabs[tab || ""] || <></>}
      </Grid>
    </Grid>
  );
};
