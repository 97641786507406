import { useMemo } from "react";

import { SelectField, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle } from "@utils";

import { HeaderArea, TitleArea, SelectArea } from "./styled";

import type { Cycle } from "server/src/services/cycle/types";

interface HeaderProps {
  onCycleSelected: (cycleId: Cycle) => void;
  selectedCycle: Cycle | undefined;
  cycles: Cycle[];
  isLoading?: boolean;
}

export const Header = ({
  cycles,
  selectedCycle,
  isLoading,
  onCycleSelected,
}: HeaderProps) => {
  const options = useMemo(
    () =>
      (cycles || []).map(({ _id, name }) => ({
        label: name,
        value: _id,
      })),
    [cycles]
  );

  return (
    <HeaderArea>
      <TitleArea>
        <StyledTitle variant="headline6" setColor="neutral20">
          Ciclo de Desempenho
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral40">
          Acesse e responda às avaliações de desempenho, confira seus feedbacks
          e PDIs criados.
        </StyledText>
      </TitleArea>
      <SelectArea>
        {isLoading && <Skeleton width={"290px"} height={"60px"} />}

        {!isLoading && (
          <SelectField
            label={"Selecione o ciclo"}
            options={options}
            value={selectedCycle?._id || ""}
            onSelectChange={(_, option) => {
              if (!option?.value) return;

              const cycle = cycles.find((cycle) => cycle._id == option?.value);

              if (!cycle) return;

              onCycleSelected(cycle);
            }}
            fullWidth
          />
        )}
      </SelectArea>
    </HeaderArea>
  );
};
