import { useEffect, useState } from "react";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { CreateCriteriaModal } from "@components/Modals";

import { Header, Tabs } from "./components";
import { track } from "@utils";

export const PageManageCriterials = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    track({
      name: "people_strategic_hr_performance_company_view_ratingcriteia",
    });
  }, []);

  return (
    <PageContainer>
      <Header handleOpenModal={() => setOpenModal(true)} />
      <Tabs onCreateClick={() => setOpenModal(true)} />

      <CreateCriteriaModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </PageContainer>
  );
};
