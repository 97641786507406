import { useNavigate, useParams } from "react-router-dom";

import { trpc } from "@api/client";

import { Lantern } from "@assets/index";

import { StyledText, dispatchToast } from "@utils";

import { routes } from "@routes";

import Banner from "@components/Banner";
import { Table } from "@components/Table";

import { TableGrid } from "./components/TableGrid";

import { EmptyStateArea, StyledLinkButton } from "./styled";

const EmptyState = () => (
  <EmptyStateArea>
    <Lantern />

    <StyledText
      variant="body3"
      weight={600}
      setColor="neutral30"
      style={{ textAlign: "center" }}
    >
      Aguarde o período de PDI para
      <br />
      criar e visualizar seus planos.
    </StyledText>
  </EmptyStateArea>
);

export const Idps = () => {
  const navigate = useNavigate();

  const { id = "" } = useParams();

  const { data, isLoading } =
    trpc.performance.idp.getManagerIdpPeopleByCycle.useQuery(
      { cycleId: id },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os PDIs, tente novamente em breve!",
          });
        },
      }
    );

  const isEmpty = !data?.items?.length && !isLoading;

  return (
    <>
      {!isEmpty ? (
        <Table
          data={data?.items || []}
          filters={data?.filters || []}
          loading={isLoading}
          searchable={true}
          searchableField="name"
          searchPlaceholder={"Buscar por nome"}
          tableGridRender={(props) => <TableGrid {...props} />}
        />
      ) : (
        <>
          <Banner
            type="info"
            icon="IconInfoCircle"
            title="Apenas os planos associados a esse ciclo são listados aqui."
            subTitle="Para ver o restante dos seus planos, clique na opção abaixo ou acesse a área de PDI no menu principal."
            hasHideBanner={false}
            style={{ marginBottom: "24px" }}
          >
            <StyledLinkButton
              variant="neutral"
              onClick={() => navigate(routes.PageMyIdps)}
            >
              Ver todos os meus planos
            </StyledLinkButton>
          </Banner>
          <EmptyState />
        </>
      )}
    </>
  );
};
