import { useEffect, useMemo, useState } from "react";
import { EmptyState, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { BigNumbers } from "./BigNumbers";
import { Answers } from "./Answers";
import { Header } from "./Header";

type SelectedEvaluator = {
  type: "self" | "byLeader" | "byLed" | "";
  _id: string;
  name: string;
};

interface IAnswersTabProps {
  evaluationId: string;
  evaluated?: { name: string; _id: string };
}

export const AnswersTab = ({ evaluationId, evaluated }: IAnswersTabProps) => {
  const [selectedEvaluator, setSelectedEvaluator] = useState<SelectedEvaluator>(
    {
      _id: "",
      type: "",
      name: "",
    }
  );

  useEffect(() => {
    setSelectedEvaluator({ _id: "", type: "", name: "" });
  }, [evaluationId]);

  const { data: evaluators, isFetching: loadingEvaluators } =
    trpc.performance.evaluation.getEvaluatorsNamesByEvaluated.useQuery(
      {
        evaluatedId: evaluated?._id || "",
        evaluationId: evaluationId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!evaluationId && !!evaluated?._id,
      }
    );

  const { data: employeeResults, isFetching: loadingResults } =
    trpc.performance.evaluation.getEmployeeResultsByEvaluator.useQuery(
      {
        evaluatedId: evaluated?._id || "",
        evaluationId: evaluationId,
        evaluatorId: selectedEvaluator?._id,
        type: selectedEvaluator?.type || "self",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled:
          !!selectedEvaluator?._id &&
          !!selectedEvaluator?.type &&
          !!evaluationId,
      }
    );

  const evaluatorsOptions = useMemo(() => {
    if (!evaluators?.length) return [];

    const parsedEvaluatorsList = evaluators?.map((evaluator) => ({
      label: evaluator.label,
      value: evaluator._id,
    }));

    return parsedEvaluatorsList;
  }, [evaluators]);

  if (!evaluationId)
    return (
      <EmptyState
        description={"Nenhuma avaliação selecionada"}
        buttonProps={{ hidden: true, size: "small" }}
        buttonText=""
      />
    );

  return (
    <>
      <Header
        evaluated={evaluated}
        onSelectedEvaluator={(selected) => {
          const evaluator = (evaluators || []).find(
            (evaluator) => evaluator._id === selected
          );

          if (!evaluator) return;

          setSelectedEvaluator({
            _id: selected,
            type: evaluator.type,
            name: evaluator?.name || "",
          });
        }}
        evaluatorsOptions={evaluatorsOptions}
        evaluator={selectedEvaluator}
        loading={loadingEvaluators}
      />

      {selectedEvaluator && !loadingResults && (
        <>
          {employeeResults?.insights && (
            <BigNumbers data={employeeResults?.insights} />
          )}

          <Answers
            sections={employeeResults?.sections}
            scale={employeeResults?.scale}
          />
        </>
      )}

      {selectedEvaluator && loadingResults && (
        <Skeleton variant="rounded" width={"100%"} height="400px" />
      )}

      {!selectedEvaluator && (
        <EmptyState
          description={"Nenhum avaliador selecionado"}
          buttonProps={{ hidden: true, size: "small" }}
          buttonText=""
        />
      )}
    </>
  );
};
