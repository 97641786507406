import styled from "styled-components";
import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";

export const Search = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }
  margin-bottom: 32px;
  width: 100%;
  height: 60px;
`;
