import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

interface IDeleteCriteriaModalProps extends CommonModalProps {
  _id: string;
}

type DeleteCriteriaModalProps = Omit<IDeleteCriteriaModalProps, "onConfirm">;

export const DeleteCriteriaModal = ({
  open,
  _id,
  onClose,
}: DeleteCriteriaModalProps) => {
  const utils = trpc.useContext();

  const { mutate, isLoading } =
    trpc.performance.criteria.deleteCriteria.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Critério avaliativo excluído com sucesso!",
        });

        utils.performance.criteria.getAllCriterials.invalidate();
        onClose?.(new Event("click"));
      },
      onError: () =>
        dispatchToast({
          type: "error",
          content:
            "Erro ao deletar o critério avaliativo, tente novamente em breve.",
        }),
    });

  return (
    <ConfirmationModal
      open={open}
      showWarning
      icon="IconAlertCircle"
      title="Tem certeza que deseja excluir o critério avaliativo?"
      subTitle={
        <div>
          Essa ação não poderá ser desfeita.
          <br />
          <br />
          <b>
            A exclusão do critério NÃO afetará avaliações já criadas ou seus
            resultados.
          </b>
        </div>
      }
      subTitleStyle={{ marginBottom: "0" }}
      confirm={{ title: "Excluir critério", icon: "IconTrash" }}
      onClose={onClose}
      onConfirm={() => {
        mutate({ params: { _id: _id } });
      }}
      isLoading={isLoading}
    />
  );
};
