import styled from "styled-components";

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;

  width: 100%;
  margin-top: 16px;
  background: ${({ theme }) => theme.colors.secondary[99]};

  border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
  border-radius: 12px;
`;

export const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;

  width: 100%;
  margin-top: 16px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 12px;
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 4px;
`;
