import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle } from "@utils";

import {
  CloseIcon,
  Header,
  IconArea,
  InfoBannerArea,
  TextContainerArea,
  DescriptionArea,
} from "./styled";

interface IInfoBannerProps {
  onClose?: React.MouseEventHandler<SVGSVGElement>;
}

export const InfoBanner = ({ onClose }: IInfoBannerProps) => {
  return (
    <InfoBannerArea>
      <Header>
        <IconArea>
          <Icons name="IconSparkles" size={24} />
        </IconArea>
        <CloseIcon name="IconX" size={16} onClick={onClose} />
      </Header>
      <TextContainerArea>
        <StyledTitle variant="body3" weight={600} setColor="secondary40">
          Dica da Flash!
        </StyledTitle>
        <DescriptionArea>
          <StyledText variant="body4" weight={400} setColor="neutral30">
            Seu ciclo pode <b>conter uma ou mais avaliações</b>, adaptando-se ao
            contexto da sua empresa.
          </StyledText>
          <StyledText variant="body4" weight={400} setColor="neutral30">
            Caso queira <b>avaliar todos da mesma forma</b>, uma única avaliação
            é suficiente. Para <b>avaliações personalizadas</b>, crie{" "}
            <b>uma para cada área ou cargo</b> dentro do mesmo ciclo.
          </StyledText>
        </DescriptionArea>
      </TextContainerArea>
    </InfoBannerArea>
  );
};
