import styled from "styled-components";

const MainContainer = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.feedback.error[50] : theme.colors.neutral[90]};
  border-radius: 12px;
  gap: 32px;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  gap: ${(props) => props.theme.spacings.xs};
  margin: 0;
`;

const TitleContainer = styled.div<{ disabled?: boolean }>`
  align-items: flex-start;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral[60] : "inherit"};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
  width: 100%;

  .tag-custom-theme-disabled {
    color: ${(props) =>
      props.disabled ? "inherit" : props.theme.colors.neutral[10]};
  }
`;

const ActionsContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-self: start;

  svg {
    stroke: ${({ theme, disabled }) =>
      disabled ? theme.colors.neutral[80] : theme.colors.neutral[40]};
  }
`;

const ContentContainer = styled.div``;

export {
  MainContainer,
  HeaderContainer,
  TitleContainer,
  ActionsContainer,
  ContentContainer,
};
