import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

import { PageTemplate } from "@components/PageTemplate";

import {
  EvaluationWithSaveModal,
  EvaluationWithoutSaveModal,
} from "@components/Modals";

import Banner from "@components/Banner";

import { AddEvaluateds } from "./components/AddEvaluateds";

import { trpc } from "@api/client";

import { routes } from "@routes";

import {
  StyledTitle,
  StyledText,
  dispatchToast,
  track,
  getObjDiffs,
} from "@utils";

import { Header, LeftContainer } from "../../Steps/styled";

import type { BasicStepProps } from "../../Steps/types";
import type { EmployeeWithLeaderType } from "server/src/services/employee/types";

type EmployeeTransferType = EmployeeWithLeaderType & {
  checked?: boolean;
};

export const EvaluatedsStep = ({
  evaluationId,
  isEdit,
  isLoading,
  error,
  steps,
  breadcrumbs,
  evaluation,
  refetch,
  disabledEdit,
  onNavigateByStep,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [selectedBase, setSelectedBase] = useState<EmployeeTransferType[]>([]);
  const [availableBase, setAvailableBase] = useState<EmployeeTransferType[]>(
    []
  );
  const [selectedParticipants, setSelectedParticipants] = useState<
    EmployeeTransferType[]
  >([]);

  const [exitAndSaveModal, setExitAndSaveModal] = useState(false);
  const [withoutSaveModal, setWithoutSaveModal] = useState(false);

  const typeExists = useCallback(
    (
      types: {
        type: "self" | "byLeader" | "byLed";
      }[],
      type: string
    ): boolean => (types || []).some((item) => item.type === type),
    []
  );

  const hasLedType = typeExists(evaluation?.types || [], "byLed");
  const hasLeaderType = typeExists(evaluation?.types || [], "byLeader");

  const isTypesEnabled = useCallback(() => {
    const hasSomeoneWithoutLed = !!selectedParticipants?.find(
      (p) => !p.isLeader
    );

    const hasSomeoneWithoutLeader = !!selectedParticipants?.find(
      (p) => !p.hasLeader
    );

    if (hasLedType && hasSomeoneWithoutLed) {
      dispatchToast({
        type: "error",
        content:
          "Alguns participantes não têm time associados, impedindo a realização das avaliações de liderados.",
      });

      return false;
    }

    if (hasLeaderType && hasSomeoneWithoutLeader) {
      dispatchToast({
        type: "error",
        content:
          "Alguns participantes não estão associados a um líder, impedindo a realização das avaliações do líderes.",
      });

      return false;
    }

    return true;
  }, [hasLedType, hasLeaderType, evaluation, selectedParticipants]);

  const { data: peopleToAdd, isFetching: isFetchingPeople } =
    trpc.employee.findEmployeesWithLeaders.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar pessoas, favor tentar novamente mais tarde!",
        });
      },
    });

  const [
    { data: departments = [] },
    { data: groups = [] },
    { data: roles = [] },
  ] = trpc.useQueries((t) => [
    t.company.getDepartmentsByCompanyId(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Erro ao buscar departamentos, favor tentar novamente mais tarde!",
        });
      },
    }),
    t.company.getGroupsByCompanyIdProcedure(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar grupos, favor tentar novamente mais tarde!",
        });
      },
    }),
    t.company.getRolesByCompanyId(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar cargos, favor tentar novamente mais tarde!",
        });
      },
    }),
  ]);

  const { mutate: updateEvaluationParticipants, isLoading: isMutating } =
    trpc.performance.evaluation.updateEvaluationParticipants.useMutation({
      onSuccess: (_, variables) => {
        refetch?.();

        if (variables?.nextStep === "manageEvaluations") {
          return onNavigateByStep("manageEvaluations");
        }

        onNavigateByStep("form");
      },
      onError: (e: any) => {
        e?.data?.error === "ADD_EMPLOYEE_EVAL_ERROR" && refetch?.();

        dispatchToast({
          type: "error",
          content:
            "Erro ao vincular participantes na avaliação. Tente novamente em breve",
        });
      },
    });

  useEffect(() => {
    if (evaluation && peopleToAdd?.length) {
      const result = (evaluation?.evaluateds || [])
        ?.map((d) => {
          const findParticipant = peopleToAdd?.find((m) => m._id === d._id);
          return findParticipant ? { ...findParticipant } : undefined;
        })
        .filter((evaluated) => !!evaluated);

      setSelectedBase(result as any);
    }
  }, [evaluation, peopleToAdd]);

  useEffect(() => {
    if (selectedBase.length && peopleToAdd?.length) {
      const filteredAlreadySelected =
        peopleToAdd.filter(
          (m) => !!!selectedBase.find((p) => m._id === p._id)
        ) || [];

      setAvailableBase(filteredAlreadySelected);
      return;
    }

    setAvailableBase(peopleToAdd || []);
  }, [selectedBase, peopleToAdd]);

  const showBanner = useMemo(() => {
    const hasSomeoneWithoutLed = !!peopleToAdd?.find((p) => !p.isLeader);

    const hasSomeoneWithoutLeader = !!peopleToAdd?.find((p) => !p.hasLeader);

    if (
      (hasLedType && hasSomeoneWithoutLed) ||
      (hasLeaderType && hasSomeoneWithoutLeader)
    )
      return true;
    return false;
  }, [peopleToAdd, hasLedType, hasLeaderType]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 3,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModal(true),
        },
        ...(evaluation?.evaluationStatus === "draft" && {
          draftProps: {
            disabled: isFetchingPeople || isLoading || isMutating || error,
            title: "Sair e salvar rascunho",
            callback: () => setExitAndSaveModal(true),
          },
        }),
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => onNavigateByStep("configurations"),
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: isMutating,
          disabled:
            error ||
            isFetchingPeople ||
            isLoading ||
            !selectedParticipants.length,
          callback: () => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_settings_continue_clicked",
            });

            const typesEnabled = isTypesEnabled();

            if (!typesEnabled) return;

            const updatedValues = selectedParticipants.map((participants) => ({
              _id: participants._id,
            }));

            const baseValues = evaluation?.evaluateds || [];

            const hasChanges = getObjDiffs(
              { evaluateds: updatedValues },
              { evaluateds: baseValues }
            );

            const isEditDisabled = disabledEdit.all;

            if (!hasChanges || isEditDisabled) return onNavigateByStep("form");

            updateEvaluationParticipants({
              evaluationId: evaluationId || "",
              participants: selectedParticipants,
            });
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid item sm={12} md={12} lg={12} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Avaliados"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={"Selecione os participantes que serão avaliados."}
            />

            {showBanner && (
              <Banner
                type="error"
                icon="IconX"
                title="Participantes sem líder e liderados"
                subTitle="Alguns participantes não estão associados a um líder ou liderado, impedindo a realização das avaliações."
                hasHideBanner={false}
                children={
                  <LinkButton
                    style={{ alignSelf: "flex-start" }}
                    variant="error"
                    onClick={() => window.open(`/employees`, "_blank")}
                  >
                    Associar líderes e liderados{" "}
                    <Icons name="IconExternalLink" />
                  </LinkButton>
                }
              />
            )}
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={12} lg={12} style={{ width: "100%" }}>
          <AddEvaluateds
            availableBase={availableBase}
            selectedBase={selectedBase}
            filtersBase={{
              departments: (departments || []).map((department) => ({
                id: department._id,
                key: department._id,
                label: department.name,
                checked: false,
                hidden: false,
              })),
              groups: (groups || []).map((group) => ({
                id: group._id,
                key: group._id,
                label: group.name,
                checked: false,
                hidden: false,
              })),
              roles: (roles || []).map((role) => ({
                id: role._id,
                key: role._id,
                label: role.name,
                checked: false,
                hidden: false,
              })),
            }}
            loading={isFetchingPeople || isLoading}
            onSelectedChanged={setSelectedParticipants}
            hasLedType={hasLedType}
            hasLeaderType={hasLeaderType}
            disabledEdit={disabledEdit}
          />
        </Grid>
      </Grid>

      <EvaluationWithoutSaveModal
        open={withoutSaveModal}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() => {
          utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
          navigate(routes.PageManageEvaluations);
        }}
      />

      <EvaluationWithSaveModal
        open={exitAndSaveModal}
        isLoading={isMutating}
        onConfirm={() => {
          if (!selectedParticipants?.length)
            onNavigateByStep("manageEvaluations");

          const typesEnabled = isTypesEnabled();

          const updatedValues = selectedParticipants.map((participants) => ({
            _id: participants._id,
          }));

          const baseValues = evaluation?.evaluateds || [];

          const hasChanges = getObjDiffs(
            { evaluateds: updatedValues },
            { evaluateds: baseValues }
          );

          if (!hasChanges || disabledEdit.all || !typesEnabled) {
            return onNavigateByStep("manageEvaluations");
          }

          updateEvaluationParticipants({
            nextStep: "manageEvaluations",
            evaluationId: evaluationId || "",
            participants: selectedParticipants,
          });
        }}
        onClose={() => setExitAndSaveModal(false)}
      />
    </PageTemplate>
  );
};
