import styled from "styled-components";
import { CycleEmptyState } from "@assets/index";

export const TitleArea = styled.div`
  margin-bottom: 24px;
`;

export const ItemArea = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondary[99]};
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const IconArea = styled.div`
  background: ${({ theme }) => theme.colors.secondary[95]};
  padding: 16px;
  border-radius: 100%;
  margin-right: 20px;
  svg {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

export const CardsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BannerArea = styled.div`
  display: flex;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  background-image: url(${() => CycleEmptyState});
  background-size: cover;
`;

export const ContentArea = styled.div`
  padding: 50px 32px;
  width: 60%;
  border-radius: 12px 0 0 12px;
`;
