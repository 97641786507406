import { LinkButton, Button } from "@flash-tecnologia/hros-web-ui-v2";

import { FooterContainer } from "../styled";

interface FooterProps {
  isLoading?: boolean;
  onClose: React.EventHandler<any> | undefined;
  onSubmit: React.MouseEventHandler<HTMLButtonElement> | undefined;
  submitTitle: string;
}

export const Footer = ({
  onClose,
  onSubmit,
  isLoading,
  submitTitle,
}: FooterProps) => (
  <FooterContainer>
    <LinkButton
      variant="neutral"
      children="Cancelar"
      style={{ alignSelf: "center" }}
      onClick={onClose}
    />
    <Button
      size="large"
      variant="primary"
      style={{ alignSelf: "center" }}
      onClick={onSubmit}
      loading={isLoading}
    >
      {submitTitle}
    </Button>
  </FooterContainer>
);
