import { useMemo } from "react";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { cloneDeep } from "lodash-es";

import { TextEditor } from "@components/TextEditor";

import { StyledText, StyledTitle } from "@utils";
import { CompanyValues } from "./CompanyValues";

import { EDITOR_MAX_LENGTH } from "../types";

import {
  CardContainer,
  MainContainer,
  Row,
  SelectContainer,
  Span,
  StyledSelect,
} from "./styled";

export const SolicitationForm = ({ formik, options, isLoading, params }) => {
  const receiverOptions = useMemo(() => {
    if (!options?.receiverOptions) return [];

    const clonedOptions = cloneDeep(options?.receiverOptions || []);

    const indexToRemove = clonedOptions.findIndex((obj) => {
      return obj.value === formik?.values?.from;
    });

    if (indexToRemove !== -1) clonedOptions.splice(indexToRemove, 1);

    return clonedOptions;
  }, [options, formik.values.from]);
  return (
    <MainContainer>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          height={"220px"}
          style={{ marginBottom: "38px" }}
        />
      ) : (
        <CardContainer>
          <Row>
            <StyledTitle
              setColor="neutral30"
              variant="body2"
              children={"Pessoas"}
            />

            <StyledText variant="body3" setColor="neutral30">
              <Span>*</Span> campo obrigatório
            </StyledText>
          </Row>
          <StyledText
            setColor="neutral50"
            variant="body4"
            children={
              params?.filterType === "to"
                ? "Selecione o remetente do feedback."
                : "Selecione o destinatário do feedback."
            }
          />
          <SelectContainer>
            <StyledSelect
              label={
                <StyledText variant="body3" setColor="neutral30">
                  Quem enviará o feedback<Span>*</Span>
                </StyledText>
              }
              searchable={true}
              value={formik.values.from || ""}
              onSelectChange={(_, option) =>
                formik.handleChange({
                  target: { id: "from", value: option?.value },
                })
              }
              options={options.senderOptions || []}
              error={formik.touched.from && Boolean(formik.errors.from)}
              helperText={formik.touched.from && formik.errors.from}
              fullWidth
            />

            <StyledSelect
              label={
                <StyledText variant="body3" setColor="neutral30">
                  Quem receberá o feedback<Span>*</Span>
                </StyledText>
              }
              searchable={true}
              value={formik.values.to || ""}
              onSelectChange={(_, option) =>
                formik.handleChange({
                  target: { id: "to", value: option?.value },
                })
              }
              options={receiverOptions}
              error={formik.touched.to && Boolean(formik.errors.to)}
              helperText={formik.touched.to && formik.errors.to}
              fullWidth
              disabled={true}
            />
          </SelectContainer>
        </CardContainer>
      )}
      {options?.companyValuesExists && (
        <CompanyValues formik={formik} isLoading={false} />
      )}
      <CardContainer>
        <Row>
          <StyledTitle
            setColor="neutral30"
            variant="body2"
            children={"Solicitação"}
          />
          <StyledText variant="body3" setColor="neutral30">
            <Span>*</Span> campo obrigatório
          </StyledText>
        </Row>
        <StyledText
          setColor="neutral50"
          variant="body4"
          children={
            "Especifique sobre o que será o feedback e/ou inclua uma mensagem para o destinatário."
          }
          style={{ marginBottom: "24px" }}
        />
        <TextEditor
          styles={{
            container: { width: "100%" },
          }}
          value={formik.values.message}
          placeholder="Mensagem de solicitação*"
          error={formik?.touched?.message && Boolean(formik?.errors?.message)}
          onChange={(value) => {
            formik.handleChange({
              target: { id: "message", value: value },
            });
          }}
          helperText={formik?.errors?.message}
          maxLength={EDITOR_MAX_LENGTH}
        />
      </CardContainer>
    </MainContainer>
  );
};
