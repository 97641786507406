import styled from "styled-components";
import { Avatar } from "@mui/material";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
`;
export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: flex-end;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  width: 100%;
  max-width: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 10px;

  padding: 24px;
`;
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.secondary[90]};
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
  width: 50px;
  height: 50px;
  font-size: 22px;
`;
export const GradeTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
`;

export const TooltipLabel = styled.div`
  display: flex;
  align-items: center;
`;
