import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";

import { pageSizeOptions } from "@utils";

import { routes } from "@routes";

import { CellArea, DataGridContainer, StyledButton } from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

export const TableGrid = ({ data, rawData, loading }: TableGridCommonProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Pessoa",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name = "-", email = "-" } = original;
          return (
            <CellArea>
              <ProfileTable name={name} email={email} />
            </CellArea>
          );
        },
      },
      // {
      //   Header: "Cargo",
      //   disableSortBy: true,
      //   accessor: "role",
      //   Cell: ({ row: { original } }) => {
      //     const { role = "-" } = original;
      //     return (
      //       <CellArea>
      //         <Typography variant="body3">{role}</Typography>
      //       </CellArea>
      //     );
      //   },
      // },
      // {
      //   Header: "Departamento",
      //   disableSortBy: true,
      //   accessor: "department",
      //   Cell: ({ row: { original } }) => {
      //     const { department = "-" } = original;
      //     return (
      //       <CellArea>
      //         <Typography variant="body3">{department}</Typography>
      //       </CellArea>
      //     );
      //   },
      // },
      {
        Header: "Ação",
        disableSortBy: true,
        accessor: "action",
        sticky: "right",
        Cell: ({ row: { original } }) => {
          const { _id } = original;
          const navigate = useNavigate();

          return (
            <StyledButton
              variant="secondary"
              size={"small"}
              onClick={() => {
                navigate(routes.PagePersonDetails("cycles", _id), {
                  state: { evaluationId: _id },
                });
              }}
              children={
                <>
                  Ver detalhes
                  <Icons name="IconArrowRight" fill="transparent" size={19} />
                </>
              }
            />
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum liderado foi encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhum funcionário foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </DataGridContainer>
  );
};
