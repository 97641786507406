import { DataCard } from "@components/Cards";

import { MainContainer } from "../styled";

import type { Evaluation } from "server/src/services/evaluation/types";

interface IBasicInfosProps {
  data?: Evaluation;
}

export const BasicInfos = ({ data }: IBasicInfosProps) => {
  return (
    <MainContainer>
      <DataCard
        title="Informações privadas"
        subTitle="Confira as informações ficarão visíveis apenas para o RH."
        sections={[
          {
            items: [
              {
                title: "Nome da avaliação",
                description: data?.privateName || "-",
              },
              {
                title: "Descrição",
                description: data?.privateDescription || "-",
              },
              {
                title: "Ciclo",
                description: data?.cycleName || "-",
              },
            ],
          },
        ]}
      />

      <DataCard
        title="Informações públicas"
        subTitle="Confira as instruções para os participantes."
        sections={[
          {
            items: [
              {
                title: "Nome da avaliação",
                description: data?.name || "-",
              },
              {
                title: "Descrição",
                description: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description || "-",
                    }}
                    style={{ listStylePosition: "inside" }}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </MainContainer>
  );
};
