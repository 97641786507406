import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  min-width: 266px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  margin-bottom: 16px;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;
