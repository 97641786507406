import { useNavigate } from "react-router-dom";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { dispatchToast } from "@utils";

import Banner from "@components/Banner";

import { Table } from "@components/Table";

import { TableGrid } from "./TableGrid";

import { StyledLinkButton } from "./styled";

interface IdpProps {
  cycleId: string;
}

export const Idp = ({ cycleId }: IdpProps) => {
  const navigate = useNavigate();

  const { data, isLoading } =
    trpc.performance.idp.getEmployeeIdpsByCycle.useQuery(
      { cycleId: cycleId },
      {
        enabled: !!cycleId,
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar as informações do usuário. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  return (
    <div>
      <Banner
        type="info"
        icon="IconInfoCircle"
        title="Apenas os planos associados a esse ciclo são listados aqui."
        subTitle="Para ver o restante dos seus planos, clique na opção abaixo ou acesse a área de PDI no menu principal."
        children={
          <StyledLinkButton
            onClick={() => navigate(routes.PageMyIdps)}
            variant="neutral"
          >
            Ver todos os meus planos
          </StyledLinkButton>
        }
        hasHideBanner={false}
        style={{ marginBottom: "24px" }}
      />
      <Table
        data={data?.items || []}
        filters={data?.filters || []}
        loading={isLoading}
        searchable={true}
        searchableField="name"
        searchPlaceholder={"Buscar por PDIs"}
        tableGridRender={(props) => <TableGrid {...props} />}
      />
    </div>
  );
};
