import styled from "styled-components";

export const MainContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 24px;
  padding: 40px 0px;
`;

export const BigNumbers = styled.div``;
