import { useState } from "react";
import { useParams } from "react-router-dom";
import { StyledPLine, TabButton } from "./styled";

import { Grid } from "@mui/material";

import { StyledText } from "@utils";
import { Received } from "./Tabs/Received";
import { Sent } from "./Tabs/Sent";
import { ReceivedRequest } from "./Tabs/ReceivedRequests";
import { SentRequests } from "./Tabs/SentRequests";

export const Feedbacks = () => {
  const { _id = "" } = useParams();

  const [tab, setTab] = useState<
    | "feedbacksReceived"
    | "feedbacksSent"
    | "solicitationsReceived"
    | "solicitationsSent"
    | null
  >("feedbacksReceived");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} md={2} width={"100%"} marginBottom={"24px"}>
          <TabButton
            active={tab === "feedbacksReceived"}
            onClick={() => setTab("feedbacksReceived")}
            style={{ marginBottom: "16px" }}
          >
            <StyledPLine numberOfLines={1}>
              <StyledText
                variant="body4"
                style={{ fontWeight: "700", textAlign: "left" }}
              >
                Feedbacks recebidos
              </StyledText>
            </StyledPLine>
          </TabButton>

          <TabButton
            active={tab === "feedbacksSent"}
            onClick={() => setTab("feedbacksSent")}
            style={{ marginBottom: "16px" }}
          >
            <StyledPLine numberOfLines={1}>
              <StyledText
                variant="body4"
                style={{ fontWeight: "700", textAlign: "left" }}
              >
                Feedbacks enviados
              </StyledText>
            </StyledPLine>
          </TabButton>
          <TabButton
            active={tab === "solicitationsReceived"}
            onClick={() => setTab("solicitationsReceived")}
            style={{ marginBottom: "16px" }}
          >
            <StyledPLine numberOfLines={1}>
              <StyledText
                variant="body4"
                style={{ fontWeight: "700", textAlign: "left" }}
              >
                Solicitações recebidas
              </StyledText>
            </StyledPLine>
          </TabButton>
          <TabButton
            active={tab === "solicitationsSent"}
            onClick={() => setTab("solicitationsSent")}
            style={{ marginBottom: "16px" }}
          >
            <StyledPLine numberOfLines={1}>
              <StyledText
                variant="body4"
                style={{ fontWeight: "700", textAlign: "left" }}
              >
                Solicitações enviadas
              </StyledText>
            </StyledPLine>
          </TabButton>
        </Grid>
        <Grid item sm={12} md={10} width={"100%"}>
          {tab === "feedbacksReceived" && <Received employeeId={_id} />}
          {tab === "feedbacksSent" && <Sent employeeId={_id} />}
          {tab === "solicitationsReceived" && (
            <ReceivedRequest employeeId={_id} />
          )}
          {tab === "solicitationsSent" && <SentRequests employeeId={_id} />}
        </Grid>
      </Grid>
    </>
  );
};
