import { ProgressArea, ProgressWrapper, Bar } from "./styled";

type ProgressBarProps = {
  progress: number;
};
export const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
      <ProgressArea>
        <ProgressWrapper>
          <Bar totalDone={progress}>
            <span />
          </Bar>
        </ProgressWrapper>
        <div>{progress}%</div>
      </ProgressArea>
    </div>
  );
};
