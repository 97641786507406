import { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { Spinner, TransferList } from "@flash-tecnologia/hros-web-ui-v2";
import _ from "lodash-es";

import { FormCard } from "@components/Cards";

import type { DisabledEditType } from "../../../types";

import type { EmployeeWithLeaderType } from "server/src/services/employee/types";

import {
  TransferListArea,
  AlertIcon,
  EmployeeName,
  NoLeaderText,
  StyledAvatar,
  TableData,
  TableDataArea,
} from "./styled";

type EmployeeTransferType = EmployeeWithLeaderType & {
  checked?: boolean;
};

type FilterType = {
  id: string;
  key: string;
  label: string;
  checked: boolean;
  hidden: boolean;
};

interface AddEvaluatedsProps {
  selectedBase: EmployeeTransferType[];
  availableBase: EmployeeTransferType[];
  filtersBase: {
    departments: FilterType[];
    groups: FilterType[];
    roles: FilterType[];
  };
  loading?: boolean;
  hasLeaderType?: boolean;
  hasLedType?: boolean;
  onSelectedChanged: (selected: EmployeeTransferType[]) => void;
  disabledEdit: DisabledEditType;
}

let leftTimer = null as any;
let rightTimer = null as any;

let leftFiltersTimer = null as any;
let rightFiltersTimer = null as any;

export const AddEvaluateds = ({
  selectedBase,
  availableBase,
  filtersBase,
  loading,
  hasLeaderType,
  hasLedType,
  onSelectedChanged,
  disabledEdit,
}: AddEvaluatedsProps) => {
  const [leftList, setLeftList] = useState(availableBase);
  const [rightList, setRightList] = useState(selectedBase);
  const [leftSearch, setLeftSearch] = useState("");
  const [rightSearch, setRightSearch] = useState("");

  const [leftFiltersSearch, setLeftFiltersSearch] = useState({
    groups: "",
    departments: "",
    roles: "",
  });

  const [leftFilters, setLeftFilters] = useState<
    AddEvaluatedsProps["filtersBase"]
  >({
    departments: [],
    groups: [],
    roles: [],
  });

  const [leftActiveFilters, setLeftActiveFilters] = useState<any>({
    departments: [],
    groups: [],
    roles: [],
  });

  const [rightFiltersSearch, setRightFiltersSearch] = useState({
    groups: "",
    departments: "",
    roles: "",
  });

  const [rightFilters, setRightFilters] = useState<
    AddEvaluatedsProps["filtersBase"]
  >({
    departments: [],
    groups: [],
    roles: [],
  });

  const [rightActiveFilters, setRightActiveFilters] = useState<any>({
    departments: [],
    groups: [],
    roles: [],
  });

  useEffect(() => {
    if (!selectedBase.length && rightList.length) return;
    setRightList(
      selectedBase.map((item) => {
        return {
          ...item,
          checked: false,
          hidden: false,
        };
      })
    );
  }, [selectedBase]);

  useEffect(() => {
    setLeftList(
      availableBase.map((item) => {
        return {
          ...item,
          checked: false,
          hidden: false,
        };
      })
    );
  }, [availableBase]);

  useEffect(() => {
    if (filtersBase) {
      setLeftFilters(filtersBase);
      setRightFilters(filtersBase);
    }
  }, [
    filtersBase?.departments?.length,
    filtersBase?.groups?.length,
    filtersBase?.roles?.length,
  ]);

  useLayoutEffect(() => {
    const transferListContainer: any = document.getElementsByClassName(
      "transfer-list-filter-search-field-container"
    );

    if (!transferListContainer.length) return;

    Array.from(transferListContainer).forEach((item: any) => {
      if (!item?.childNodes?.length) return;
      item.childNodes[0].style.width = "100%";
    });
  }, []);

  useEffect(() => {
    const listCloned = _.cloneDeep(rightList);
    onSelectedChanged(listCloned);
  }, [rightList.length]);

  const filteredLeftList = useMemo(() => {
    let leftListCloned = _.cloneDeep(leftList);

    const activeFilters = (Object.keys(leftActiveFilters) || []).filter(
      (key) => !!leftActiveFilters[key]?.length
    );

    const hasActiveFilters = !!activeFilters.length;

    if (leftSearch) {
      leftListCloned = leftListCloned.filter((employee) =>
        (employee?.name || "")
          .toLocaleLowerCase()
          .includes(leftSearch.toLocaleLowerCase())
      );
    }

    if (hasActiveFilters) {
      activeFilters.forEach((key) => {
        if (key === "departments") {
          leftListCloned = leftListCloned.filter(({ departments = [] }) => {
            return departments?.some((department) =>
              leftActiveFilters?.departments?.includes(department)
            );
          });
        }

        if (key === "groups") {
          leftListCloned = leftListCloned.filter(({ groups = [] }) => {
            return groups?.some((group) =>
              leftActiveFilters?.groups?.includes(group)
            );
          });
        }

        if (key === "roles") {
          leftListCloned = leftListCloned.filter(({ roles = [] }) => {
            return roles?.some((role) =>
              leftActiveFilters?.roles?.includes(role)
            );
          });
        }
      });
    }

    return leftListCloned;
  }, [leftList, leftSearch, leftActiveFilters]) as any[];

  const filteredRightList = useMemo(() => {
    let rightListCloned = _.cloneDeep(rightList);

    const activeFilters = (Object.keys(rightActiveFilters) || []).filter(
      (key) => !!rightActiveFilters[key]?.length
    );

    const hasActiveFilters = !!activeFilters.length;

    if (rightSearch) {
      rightListCloned = rightListCloned.filter((employee) =>
        (employee?.name || "")
          .toLocaleLowerCase()
          .includes(rightSearch.toLocaleLowerCase())
      );
    }

    if (hasActiveFilters) {
      activeFilters.forEach((key) => {
        if (key === "departments") {
          rightListCloned = rightListCloned.filter(({ departments = [] }) => {
            return departments?.some((department) =>
              rightActiveFilters?.departments?.includes(department)
            );
          });
        }

        if (key === "groups") {
          rightListCloned = rightListCloned.filter(({ groups = [] }) => {
            return groups?.some((group) =>
              rightActiveFilters?.groups?.includes(group)
            );
          });
        }

        if (key === "roles") {
          rightListCloned = rightListCloned.filter(({ roles = [] }) => {
            return roles?.some((role) =>
              rightActiveFilters?.roles?.includes(role)
            );
          });
        }
      });
    }

    return rightListCloned;
  }, [rightList, rightSearch, rightActiveFilters]) as any[];

  const leftSections = useMemo(() => {
    const options: any = [];

    if (leftFilters?.departments?.length) {
      options.push({
        id: "departments_left",
        title: `Departamento (${leftFilters.departments.length || 0})`,
        options: !leftFiltersSearch?.departments
          ? leftFilters.departments
          : leftFilters.departments.filter(({ label = "" }) => {
              const regex = new RegExp(leftFiltersSearch?.departments, "gi");
              return label?.match(regex);
            }),
      });
    }

    if (leftFilters?.groups?.length) {
      options.push({
        id: "groups_left",
        title: `Grupo (${leftFilters.groups.length || 0})`,
        options: !leftFiltersSearch?.groups
          ? leftFilters.groups
          : leftFilters.groups.filter(({ label = "" }) => {
              const regex = new RegExp(leftFiltersSearch?.groups, "gi");
              return label?.match(regex);
            }),
      });
    }

    if (leftFilters?.roles?.length) {
      options.push({
        id: "roles_left",
        title: `Cargo (${leftFilters.roles.length || 0})`,
        options: !leftFiltersSearch?.roles
          ? leftFilters.roles
          : leftFilters.roles.filter(({ label = "" }) => {
              const regex = new RegExp(leftFiltersSearch?.roles, "gi");
              return label?.match(regex);
            }),
      });
    }

    return options;
  }, [leftFilters, leftFiltersSearch]);

  const rightSections = useMemo(() => {
    const options: any = [];

    if (rightFilters?.departments?.length) {
      options.push({
        id: "departments_right",
        title: `Departamento (${rightFilters.departments.length || 0})`,
        options: !rightFiltersSearch?.departments
          ? rightFilters.departments
          : rightFilters.departments.filter(({ label = "" }) => {
              const regex = new RegExp(rightFiltersSearch?.departments, "gi");
              return label?.match(regex);
            }),
      });
    }

    if (rightFilters?.groups?.length) {
      options.push({
        id: "groups_right",
        title: `Grupo (${rightFilters.groups.length || 0})`,
        options: !rightFiltersSearch?.groups
          ? rightFilters.groups
          : rightFilters.groups.filter(({ label = "" }) => {
              const regex = new RegExp(rightFiltersSearch?.groups, "gi");
              return label?.match(regex);
            }),
      });
    }

    if (rightFilters?.roles?.length) {
      options.push({
        id: "roles_right",
        title: `Grupo (${rightFilters.roles.length || 0})`,
        options: !rightFiltersSearch?.roles
          ? rightFilters.roles
          : rightFilters.roles.filter(({ label = "" }) => {
              const regex = new RegExp(rightFiltersSearch?.roles, "gi");
              return label?.match(regex);
            }),
      });
    }

    return options;
  }, [rightFilters, rightFiltersSearch]);

  const columns = [
    {
      Header: `Todos (${filteredLeftList.length || leftList.length})`,
      accessor: "name",
      Cell: ({
        row: {
          original: { name = "", hasLeader = false, isLeader = false },
        },
      }) => {
        const missingTypes: string[] = [];
        if (hasLeaderType && !hasLeader) missingTypes.push("líder");
        if (hasLedType && !isLeader) missingTypes.push("liderado");

        const errorMessage = missingTypes.reduce((acc: string, value) => {
          if (acc) return `${acc} e ${value}`;
          return value;
        }, "");

        return (
          <TableData>
            <StyledAvatar>{name?.charAt(0) ?? ""}</StyledAvatar>
            <TableDataArea>
              <EmployeeName variant="body3">{name ?? ""}</EmployeeName>

              {errorMessage && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AlertIcon name="IconAlertTriangle" size={16} />
                  <NoLeaderText variant="body4">
                    {errorMessage +
                      ` não associado${missingTypes.length > 1 ? "s" : ""}`}
                  </NoLeaderText>
                </div>
              )}
            </TableDataArea>
          </TableData>
        );
      },
    },
  ];

  const rightColumns = [
    {
      Header: `Selecionados (${rightList.filter((l) => l.checked).length}/${
        filteredRightList.length || rightList.length
      })`,
      accessor: "name",
      Cell: ({
        row: {
          original: { name = "", hasLeader = false, isLeader = false },
        },
      }) => {
        const missingTypes: string[] = [];

        if (hasLeaderType && !hasLeader) missingTypes.push("líder");
        if (hasLedType && !isLeader) missingTypes.push("liderado");

        const errorMessage = missingTypes.reduce((acc: string, value) => {
          if (acc) return `${acc} e ${value}`;
          return value;
        }, "");

        return (
          <TableData>
            <StyledAvatar>{name?.charAt(0) ?? ""}</StyledAvatar>
            <TableDataArea>
              <EmployeeName variant="body3">{name ?? ""}</EmployeeName>

              {errorMessage && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AlertIcon name="IconAlertTriangle" size={16} />
                  <NoLeaderText variant="body4">
                    {errorMessage +
                      ` não associado${missingTypes.length > 1 ? "s" : ""}`}
                  </NoLeaderText>
                </div>
              )}
            </TableDataArea>
          </TableData>
        );
      },
    },
  ];

  return (
    <FormCard
      title="Seleção de avaliados"
      description="Quais pessoas serão avaliadas?"
    >
      <TransferListArea>
        <TransferList
          customTransferLabel="Selecione 1 ou mais pessoas para movimentar"
          customRightColumns={rightColumns}
          columns={columns}
          disabled={disabledEdit.all}
          onTransfer={({
            leftList: transferLeft,
            rightList: transferRight,
          }) => {
            const leftBase = _.cloneDeep(leftList);
            const rightBase = _.cloneDeep(rightList);

            const hasAdded = leftBase
              .filter((l) => l.checked)
              .some((l) =>
                (transferRight?.data || []).find((tr) => tr._id === l._id)
              );

            const hasRemoved = rightBase
              .filter((l) => l.checked)
              .some((l) =>
                (transferLeft?.data || []).find((tl) => tl._id === l._id)
              );

            if (hasAdded) {
              const added = leftBase
                .filter((lb) => lb.checked)
                .map((lb) => ({ ...lb, checked: false }));

              rightBase.push(...added);

              const removed = leftBase
                .filter((lb) => !lb.checked)
                .map((lb) => ({ ...lb, checked: false }));

              setRightList(rightBase);
              setLeftList(removed);

              return;
            }

            if (hasRemoved) {
              const added = rightBase
                .filter((rb) => rb.checked)
                .map((rb) => ({ ...rb, checked: false }));

              leftBase.push(...added);

              const removed = rightBase
                .filter((rb) => !rb.checked)
                .map((rb) => ({ ...rb, checked: false }));

              setRightList(removed);
              setLeftList(leftBase);
            }
          }}
          leftList={{
            data: filteredLeftList,
            total: 0,
            title: `Todas as pessoas (${leftList?.length || 0})`,
            loading: false,
            onCheck: ({ allChecked, data }) => {
              const base = _.cloneDeep(leftList);

              setLeftList(
                base.map((row) => {
                  const updated = data?.find((d) => d._id === row._id);
                  const filtered = filteredLeftList?.find(
                    (f) => f._id === row._id
                  );
                  return {
                    ...row,
                    checked:
                      allChecked !== undefined && filtered
                        ? allChecked
                        : updated
                        ? updated.checked
                        : row.checked,
                  };
                })
              );
            },
            onSearch: (leftSearch: string) => {
              if (leftTimer) clearTimeout(leftTimer);

              leftTimer = setTimeout(() => {
                setLeftSearch(leftSearch);
              }, 600);
            },
            ...(leftSections?.length && {
              customFilters: {
                title: "Filtros",
                sections: leftSections,
                onCheck: ({ sectionId, options }) => {
                  const clonedFilters = _.cloneDeep(leftFilters);

                  if (sectionId === "groups_left") {
                    clonedFilters.groups = options as any;
                  }

                  if (sectionId === "departments_left") {
                    clonedFilters.departments = options as any;
                  }

                  if (sectionId === "roles_left") {
                    clonedFilters.roles = options as any;
                  }

                  setLeftFilters(clonedFilters);
                },
                onClear: () => {
                  const clonedFilters = _.cloneDeep(leftFilters);

                  clonedFilters.groups = clonedFilters.groups.map((item) => ({
                    ...item,
                    checked: false,
                  }));

                  clonedFilters.departments = clonedFilters.departments.map(
                    (item) => ({ ...item, checked: false })
                  );

                  clonedFilters.roles = clonedFilters.roles.map((item) => ({
                    ...item,
                    checked: false,
                  }));

                  setLeftFilters(clonedFilters);
                  setLeftActiveFilters({
                    groups: [],
                    departments: [],
                    roles: [],
                  });
                  setLeftFiltersSearch({
                    groups: "",
                    departments: "",
                    roles: "",
                  });
                },
                onFilter: (onFilterResult) => {
                  const clonedActiveFilters = _.cloneDeep(leftActiveFilters);

                  const departmentIndex = onFilterResult.findIndex(
                    (filter) => filter.id === "departments_left"
                  );

                  const groupsIndex = onFilterResult.findIndex(
                    (filter) => filter.id === "groups_left"
                  );

                  const rolesIndex = onFilterResult.findIndex(
                    (filter) => filter.id === "roles_left"
                  );

                  const filteredDepartments =
                    onFilterResult[departmentIndex]?.options?.filter(
                      (option) => option.checked
                    ) || [];

                  const filteredGroups =
                    onFilterResult[groupsIndex]?.options?.filter(
                      (option) => option.checked
                    ) || [];

                  const filteredRoles =
                    onFilterResult[rolesIndex]?.options?.filter(
                      (option) => option.checked
                    ) || [];

                  clonedActiveFilters.departments = filteredDepartments.map(
                    (option: any) => option.id
                  );

                  clonedActiveFilters.groups = filteredGroups.map(
                    (option: any) => option.id
                  );

                  clonedActiveFilters.roles = filteredRoles.map(
                    (option: any) => option.id
                  );

                  setLeftActiveFilters(clonedActiveFilters);
                },
                onSearch: (item) => {
                  const clonedSearchFilters = _.cloneDeep(leftFiltersSearch);

                  const { text, sectionId } = item;
                  const cleanValue = text.replace(
                    /[-[/\]{}()*+?.,\\^$|#]/g,
                    ""
                  );

                  if (sectionId === "groups_left") {
                    clonedSearchFilters.groups = cleanValue;
                  }

                  if (sectionId === "departments_left") {
                    clonedSearchFilters.departments = cleanValue;
                  }

                  if (sectionId === "roles_left") {
                    clonedSearchFilters.roles = cleanValue;
                  }

                  if (leftFiltersTimer) clearTimeout(leftFiltersTimer);

                  leftFiltersTimer = setTimeout(() => {
                    setLeftFiltersSearch(clonedSearchFilters);
                  }, 600);
                },
              },
            }),
            emptyStageMessage: (loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <>Buscando Pessoas... </>
                <Spinner size={24} variant="primary" />
              </div>
            ) : (
              <>Nenhuma pessoa encontrada</>
            )) as any,
          }}
          rightList={{
            data: filteredRightList,
            total: rightList.length || 0,
            title: `Selecionados para serem avaliados (${
              rightList.length || 0
            })`,
            loading: false,
            onCheck: ({ allChecked, data }) => {
              const base = _.cloneDeep(rightList);

              setRightList(
                base.map((row) => {
                  const updated = data?.find((d) => d._id === row._id);
                  const filtered = filteredRightList?.find(
                    (f) => f._id === row._id
                  );

                  return {
                    ...row,
                    checked:
                      allChecked !== undefined && filtered
                        ? allChecked
                        : updated
                        ? updated.checked
                        : row.checked,
                  };
                })
              );
            },
            onSearch: (rightSearch: string) => {
              if (rightTimer) clearTimeout(rightTimer);

              rightTimer = setTimeout(() => {
                setRightSearch(rightSearch);
              }, 600);
            },
            ...(rightSections?.length && {
              customFilters: {
                title: "Filtros",
                sections: rightSections,
                onCheck: ({ sectionId, options }) => {
                  const clonedFilters = _.cloneDeep(rightFilters);

                  if (sectionId === "groups_right") {
                    clonedFilters.groups = options as any;
                  }

                  if (sectionId === "departments_right") {
                    clonedFilters.departments = options as any;
                  }

                  if (sectionId === "roles_right") {
                    clonedFilters.roles = options as any;
                  }

                  setRightFilters(clonedFilters);
                },
                onClear: () => {
                  const clonedFilters = _.cloneDeep(rightFilters);

                  clonedFilters.groups = clonedFilters.groups.map((item) => ({
                    ...item,
                    checked: false,
                  }));

                  clonedFilters.departments = clonedFilters.departments.map(
                    (item) => ({ ...item, checked: false })
                  );

                  clonedFilters.roles = clonedFilters.roles.map((item) => ({
                    ...item,
                    checked: false,
                  }));

                  setRightFilters(clonedFilters);
                  setRightActiveFilters({
                    groups: [],
                    departments: [],
                    roles: [],
                  });

                  setRightFiltersSearch({
                    groups: "",
                    departments: "",
                    roles: "",
                  });
                },
                onFilter: (onFilterResult) => {
                  const clonedActiveFilters = _.cloneDeep(rightActiveFilters);

                  const departmentIndex = onFilterResult.findIndex(
                    (filter) => filter.id === "departments_right"
                  );

                  const groupsIndex = onFilterResult.findIndex(
                    (filter) => filter.id === "groups_right"
                  );

                  const rolesIndex = onFilterResult.findIndex(
                    (filter) => filter.id === "roles_right"
                  );

                  const filteredDepartments =
                    onFilterResult[departmentIndex]?.options?.filter(
                      (option) => option.checked
                    ) || [];

                  const filteredGroups =
                    onFilterResult[groupsIndex]?.options?.filter(
                      (option) => option.checked
                    ) || [];

                  const filteredRoles =
                    onFilterResult[rolesIndex]?.options?.filter(
                      (option) => option.checked
                    ) || [];

                  clonedActiveFilters.departments = filteredDepartments.map(
                    (option: any) => option.id
                  );

                  clonedActiveFilters.groups = filteredGroups.map(
                    (option: any) => option.id
                  );

                  clonedActiveFilters.roles = filteredRoles.map(
                    (option: any) => option.id
                  );

                  setRightActiveFilters(clonedActiveFilters);
                },
                onSearch: (item) => {
                  const clonedSearchFilters = _.cloneDeep(rightFiltersSearch);

                  const { text, sectionId } = item;
                  const cleanValue = text.replace(
                    /[-[/\]{}()*+?.,\\^$|#]/g,
                    ""
                  );

                  if (sectionId === "groups_right") {
                    clonedSearchFilters.groups = cleanValue;
                  }

                  if (sectionId === "departments_right") {
                    clonedSearchFilters.departments = cleanValue;
                  }

                  if (sectionId === "roles_right") {
                    clonedSearchFilters.roles = cleanValue;
                  }

                  if (rightFiltersTimer) clearTimeout(rightFiltersTimer);

                  rightFiltersTimer = setTimeout(() => {
                    setRightFiltersSearch(clonedSearchFilters);
                  }, 600);
                },
              },
            }),
            emptyStageMessage: (loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <>Buscando pessoas selecionadas... </>
                <Spinner size={24} variant="primary" />
              </div>
            ) : (
              <>Pessoas selecionadas para a avaliação aparecerão aqui.</>
            )) as any,
          }}
        />
      </TransferListArea>
    </FormCard>
  );
};
