import styled from "styled-components";

import { Select } from "@mui/material";

import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const DataGridContainer = styled.div`
  .data-grid-pagination-container {
    margin: 16px 0px 0px 0px !important;
  }

  .data-grid-custom-select div.MuiSelect-select {
    padding-right: 36px !important;
  }

  .select-icon-custom svg {
    top: calc(50% - 0.7em);
    right: 5px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const StyledText = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const TooltipText = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const Description = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 24ch;
`;

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const ItensPerPageArea = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const PaginationContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const CellArea = styled.div``;

export const ProfileArea = styled.div`
  width: 200px;
  max-width: 200px;
`;

export const TagArea = styled.div`
  min-width: 170px;
`;

export const StyledButton = styled(Button)`
  width: 165px;
  align-self: center; !important
`;

export const OptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
