import { ReactNode, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Breadcrumbs,
  PillButton,
  Icons,
  Menu,
  MenuOptionType,
  LinkButton,
  SelectField,
  Skeleton,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CloseEvaluationModal,
  ExportEvaluationModal,
} from "@components/Modals";

import { RenderTag } from "./RenderTag";

import { dispatchToast, StyledIcon, StyledTitle } from "@utils";

import { routes } from "@routes";

import { trpc } from "@api/client";

import type {
  Evaluation,
  EvaluationsByCycle,
} from "server/src/services/evaluation/types";

import {
  MainContainer,
  FlexRow,
  LeftColumn,
  TitleRow,
  RightColumn,
  Option,
  StyledButton,
} from "./styled";

interface TOption extends MenuOptionType {
  key: "delete" | "edit" | "duplicate" | "export";
  children: ReactNode;
  disabled?: boolean;
}

interface IHeaderProps {
  isLoading?: boolean;
  cycle?: EvaluationsByCycle["cycle"];
  evaluations: Evaluation[];
  selectedEvaluation: Evaluation | undefined;
  onEvaluationSelected: (value?: Evaluation) => void;
  onChangeTab: (tab: number) => void;
}

export const Header = ({
  isLoading,
  cycle,
  evaluations,
  selectedEvaluation,
  onEvaluationSelected,
  onChangeTab,
}: IHeaderProps) => {
  const { evaluationId = "" } = useParams();
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [closeEvaluationModal, setCloseEvaluationModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  const breadCrumbs = [
    <LinkButton
      variant="neutral"
      style={{ alignSelf: "flex-start" }}
      onClick={() => navigate(routes.PageManageEvaluations)}
    >
      Avaliações
    </LinkButton>,
    <LinkButton style={{ alignSelf: "flex-start" }} variant="neutral">
      Detalhe da avaliação
    </LinkButton>,
  ];

  const evaluationOptions = useMemo(() => {
    const options = (evaluations || []).map((evaluation) => ({
      label: evaluation.name,
      value: evaluation._id,
    }));

    return options;
  }, [evaluations]);

  const menuOptions: TOption[] = useMemo(() => {
    if (!selectedEvaluation) return [];

    const options: TOption[] = [
      {
        key: "edit",
        children: (
          <Option
            onClick={() =>
              navigate(
                routes.PageEditEvaluation("basic-info", selectedEvaluation._id)
              )
            }
          >
            <StyledIcon
              name="IconPencil"
              fill="transparent"
              setColor="neutral30"
            />
            <StyledTitle variant="body3" setColor="neutral30">
              Editar Avaliação
            </StyledTitle>
          </Option>
        ),
      },
      {
        key: "export",
        children: (
          <Option onClick={() => setExportModal(true)}>
            <StyledIcon
              name="IconDownload"
              fill="transparent"
              setColor="neutral30"
            />
            <StyledTitle variant="body3" setColor="neutral30">
              Exportar relatório
            </StyledTitle>
          </Option>
        ),
      },
    ];
    return options;
  }, [selectedEvaluation]);

  const status = {
    in_calibration: {
      label: "Calibrar notas",
      variant: "default",
    },
    calibration_finished: {
      label: "Calibração encerrada",
      variant: "error",
      disabled: true,
    },
    active: {
      label: "Encerrar avaliação",
      variant: "error",
      icon: <StyledIcon name="IconX" setColor={"error50"} fill="none" />,
    },
    scheduled: {
      label: "Encerrar avaliação",
      variant: "error",
      icon: <StyledIcon name="IconX" setColor={"error50"} fill="none" />,
    },
  };

  const daysToExpire = dayjs(selectedEvaluation?.endDate).diff(dayjs(), "day");

  const { mutate: closeMutate, isLoading: isClosing } =
    trpc.performance.evaluation.closeEvaluation.useMutation({
      onSuccess: () => {
        utils.performance.evaluation.getEvaluationsByEvaluationCycle.reset();
        utils.performance.evaluation.getDetailsOverviewEvaluateds.reset();
        utils.performance.evaluation.getDetailsOverviewEvaluatedsInsights.reset();

        setCloseEvaluationModal(false);
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao encerrar a avaliação, tente novamente mais tarde!",
        });
      },
    });

  return (
    <MainContainer>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
      />

      <FlexRow>
        <LeftColumn>
          <TitleRow>
            <StyledTitle
              variant="headline6"
              children={selectedEvaluation?.name || ""}
            />
            <RenderTag status={selectedEvaluation?.evaluationStatus} />
          </TitleRow>

          {cycle?.name && (
            <LinkButton
              variant="neutral"
              style={{ alignSelf: "flex-start" }}
              onClick={() => navigate(routes.PageCycleDetail(cycle._id))}
            >
              {cycle?.name || ""}
              <StyledIcon
                name="IconChevronRight"
                setColor="neutral40"
                size={16}
              />
            </LinkButton>
          )}
        </LeftColumn>

        <RightColumn>
          <div style={{ width: "100%", maxWidth: "290px" }}>
            {isLoading ? (
              <Skeleton variant="rounded" width={"100%"} height={60} />
            ) : (
              <SelectField
                label="Selecione a avaliação do ciclo"
                fullWidth
                value={selectedEvaluation?._id || ""}
                disabled={!evaluationOptions?.length}
                options={evaluationOptions}
                onSelectChange={(_, option) => {
                  const evaluation = evaluations.find(
                    (evaluation) => evaluation._id === option?.value
                  );

                  onEvaluationSelected(evaluation);
                }}
              />
            )}
          </div>

          {selectedEvaluation?.evaluationStatus && (
            <StyledButton
              buttonType={"secondary"}
              variantType={
                status?.[selectedEvaluation?.evaluationStatus]?.variant ||
                "default"
              }
              size="large"
              onClick={() => {
                switch (selectedEvaluation.evaluationStatus) {
                  case "active":
                  case "scheduled":
                    return setCloseEvaluationModal(true);
                  case "in_calibration":
                    return onChangeTab(1);
                  default:
                    break;
                }
              }}
              disabled={
                status?.[selectedEvaluation?.evaluationStatus]?.disabled
              }
              hidden={!status?.[selectedEvaluation?.evaluationStatus]}
              style={{ whiteSpace: "nowrap" }}
            >
              {status?.[selectedEvaluation?.evaluationStatus]?.label || ""}
              {status?.[selectedEvaluation?.evaluationStatus]?.icon || <></>}
            </StyledButton>
          )}

          <Menu
            type={"select"}
            options={menuOptions}
            disabled={!selectedEvaluation}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <PillButton
              size="medium"
              variant="default"
              type="secondary"
              icon="IconDotsVertical"
            />
          </Menu>
        </RightColumn>
      </FlexRow>

      <CloseEvaluationModal
        open={closeEvaluationModal}
        onClose={() => setCloseEvaluationModal(false)}
        isLoading={isClosing}
        daysToExpire={daysToExpire}
        onConfirm={() => {
          if (selectedEvaluation?._id)
            closeMutate({
              evaluationId: selectedEvaluation?._id,
            });
        }}
      />
      <ExportEvaluationModal
        open={exportModal}
        onClose={() => setExportModal(false)}
        evaluationId={evaluationId}
      />
    </MainContainer>
  );
};
