import { useMemo } from "react";

import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

import { Timeline, TimelineProps } from "@components/Timeline";

import { TimelineArea } from "./styled";

import type { Cycle } from "server/src/services/cycle/types";

interface IMyCycleTimeLineProps {
  cycle: Cycle;
}

export const MyCycleTimeline = ({ cycle }: IMyCycleTimeLineProps) => {
  const timelineItems = useMemo(() => {
    if (!cycle?.steps?.length) return [];

    const label = {
      evaluation: { label: "Avaliações", icon: "IconFileDescription" },
      calibration: { label: "Calibração", icon: "IconBrandSpeedtest" },
      feedback: { label: "Feedback", icon: "IconMail" },
      idp: { label: "PDI", icon: "IconPlaneTilt" },
    } as { [key: string]: { label: string; icon: IconTypes } };

    const mappedData: TimelineProps["items"] = cycle?.steps.map((step) => ({
      label: label[step?.type].label,
      icon: label[step?.type].icon,
      start: step?.startDate,
      end: step?.endDate,
    }));

    return mappedData;
  }, [cycle]);

  if (!timelineItems.length) return <></>;

  return (
    <TimelineArea>
      <Timeline items={timelineItems || []} />
    </TimelineArea>
  );
};
