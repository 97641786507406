import styled from "styled-components";
import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";

export const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
`;

export const SelectCard = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;

  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  width: 100%;

  padding: 32px 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledSelect = styled(SelectField)`
  width: 100%;
  max-width: 298px;

  @media screen and (max-width: 650px) {
    max-width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 430px;
  display: flex;

  justify-content: end;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 10px 8px;
`;

export const SelectButton = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 10px;
  padding: 10px 16px;

  background-color: ${({ theme, selected }) => {
    return selected ? theme.colors.secondary[90] : theme.colors.neutral[100];
  }};

  color: ${({ theme, selected }) => {
    return selected ? theme.colors.secondary : theme.colors.neutral[40];
  }};

  transition: 0.5s;
`;
