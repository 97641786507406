import { StyledText, StyledTitle } from "@utils";
import { CompetenceContainer, CompetencesContainer, Tag } from "./styled";

import { Dot } from "@flash-tecnologia/hros-web-ui-v2";

export const Competences = ({ competences }) => {
  return (
    <>
      <StyledTitle
        variant="body2"
        children="Critérios Avaliativos"
        setColor="neutral30"
      />
      <CompetencesContainer>
        {competences.map((competence: any, index: number) => {
          const info = {
            doing_well: { label: "Mandando bem", variant: "green" },
            could_improve: { label: "Pode melhorar", variant: "yellow" },
          };

          return (
            <CompetenceContainer
              key={competence._id}
              lastChildren={index + 1 === competences.length}
            >
              <div style={{ display: "flex" }}>
                <Tag setColor={info[competence.value]?.variant || "gray"}>
                  <Dot variant={info[competence.value]?.variant || "gray"} />
                  <StyledText variant="caption" style={{ fontWeight: 600 }}>
                    {info[competence?.value]?.label || ""}
                  </StyledText>
                </Tag>
              </div>

              <StyledTitle variant="body3" children={competence.name} />
              <StyledText variant="body4" children={competence.description} />
            </CompetenceContainer>
          );
        })}
      </CompetencesContainer>
    </>
  );
};
