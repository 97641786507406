import styled from "styled-components";

export const Option = styled.button<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const MenuContainer = styled.div`
  > div.menu-base-container-custom-theme {
    display: flex;
  }
`;
