import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText } from "@utils";

import { DataCard } from "@components/Cards";

import { Container, DateArea, DateItem } from "./styled";

import type { Cycle } from "server/src/services/cycle/types";

interface ICycleDetailProps {
  cycle: Cycle | undefined;
}

export const CycleDetail = ({ cycle }: ICycleDetailProps) => {
  const renderDate = ({ steps, type, dateType }) => {
    const hasDate = steps?.filter((step) => step?.type === type);

    if (hasDate?.length) {
      return dayjs(hasDate[0]?.[dateType]).format("DD/MM/YYYY");
    }

    return "-";
  };

  const renderItens = () => {
    const itens = [] as any;

    if (cycle?.steps.find(({ type }) => type === "evaluation")) {
      itens?.push({
        title: "Período das avaliações",
        items: [
          {
            description: (
              <DateArea>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Início das avaliações
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "evaluation",
                      dateType: "startDate",
                    })}
                  </StyledText>
                </DateItem>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Encerramento das avaliações
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "evaluation",
                      dateType: "endDate",
                    })}
                  </StyledText>
                </DateItem>
              </DateArea>
            ),
          },
        ],
      });
    }

    if (cycle?.steps.find(({ type }) => type === "calibration")) {
      itens?.push({
        title: "Período da calibração de notas",
        items: [
          {
            description: (
              <DateArea>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Início da calibração
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "calibration",
                      dateType: "startDate",
                    })}
                  </StyledText>
                </DateItem>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Encerramento da calibração
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "calibration",
                      dateType: "endDate",
                    })}
                  </StyledText>
                </DateItem>
              </DateArea>
            ),
          },
        ],
      });
    }

    if (cycle?.steps.find(({ type }) => type === "feedback")) {
      itens?.push({
        title: "Período de feedbacks",
        items: [
          {
            description: (
              <DateArea>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Início dos feedbacks
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "feedback",
                      dateType: "startDate",
                    })}
                  </StyledText>
                </DateItem>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Encerramento dos feedbacks
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "feedback",
                      dateType: "endDate",
                    })}
                  </StyledText>
                </DateItem>
              </DateArea>
            ),
          },
        ],
      });
    }

    if (cycle?.steps.find(({ type }) => type === "idp")) {
      itens?.push({
        title: "Período do PDI",
        items: [
          {
            description: (
              <DateArea>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Início do PDI
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "idp",
                      dateType: "startDate",
                    })}
                  </StyledText>
                </DateItem>
                <DateItem>
                  <StyledText
                    variant="caption"
                    weight={700}
                    setColor="neutral50"
                  >
                    Encerramento do PDI
                  </StyledText>
                  <StyledText variant="body3" weight={600} setColor="neutral40">
                    {renderDate({
                      steps: cycle?.steps,
                      type: "idp",
                      dateType: "endDate",
                    })}
                  </StyledText>
                </DateItem>
              </DateArea>
            ),
          },
        ],
      });
    }

    return itens;
  };

  return (
    <Container>
      <DataCard
        title="Informações básicas"
        subTitle="Confira o nome e descrição do ciclo de desempenho."
        sections={[
          {
            items: [
              {
                title: "Nome do ciclo",
                description: cycle?.name ?? "-",
              },
              {
                title: "Descrição",
                description: cycle?.description ?? "-",
              },
            ],
          },
        ]}
      />

      <DataCard
        title="Etapas do ciclo"
        subTitle="Confira as etapas e prazos do ciclo."
        sections={renderItens()}
      />
    </Container>
  );
};
