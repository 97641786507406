import styled from "styled-components";

export const TableLine = styled.div<{ hasLine?: boolean }>`
  display: flex;
  padding: 16px 0px;
  border-bottom: ${({ theme, hasLine }) =>
    hasLine ? `1px solid ${theme.colors.neutral[90]}` : ""};
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1;

  width: 100%;
`;
export const RightColumn = styled.div`
  display: flex;
  flex: 1;

  width: 100%;
`;

export const OrderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 10px;
`;
