import { useState, useEffect } from "react";

import { trpc } from "@api/client";

import { PaginationTable } from "@components/PaginationTable";

import { TableGrid } from "./TableGrid";

import { dispatchToast } from "@utils";

import { PageContainer } from "./styled";

import { onSelectedChangedType, CriteriaType } from "../../types";

interface CriteriaModelsProps {
  onSelectedChanged: onSelectedChangedType;
  evaluationCriterials: string[];
  selectedCriterials: CriteriaType[];
}

export const CriteriaModels = ({
  onSelectedChanged,
  selectedCriterials,
  evaluationCriterials,
}: CriteriaModelsProps) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
    totalCount: 0,
  });

  const [search, setSearch] = useState("");

  const { data: criterials, isFetching } =
    trpc.performance.criteria.getAllCriterials.useQuery(
      {
        params: {
          type: "default",
          page: pagination?.page ?? 1,
          pageSize: pagination?.pageSize ?? 20,
          sortField: "createdAt",
          sortType: "desc",
          search: search ? search : undefined,
        },
      },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar os modelos de critérios avaliativos, tente novamente em breve!",
          });
        },
        onSuccess: (info) => {
          setPagination({
            page: info?.page,
            pageSize: info?.pageSize,
            totalCount: info?.total,
          });
        },
      }
    );

  useEffect(() => {
    if (!criterials) return;

    if (criterials.total === pagination.totalCount) return;

    setPagination({
      page: criterials.page,
      pageSize: criterials.pageSize,
      totalCount: criterials.total,
    });
  }, [criterials?.total]);

  return (
    <PageContainer>
      <PaginationTable
        loading={isFetching}
        data={criterials?.items || []}
        pagination={pagination}
        isFiltered={!!search}
        searchPlaceholder="Buscar por modelos de critérios"
        tableGridRender={(props) => (
          <TableGrid
            {...props}
            selectedCriterials={selectedCriterials}
            evaluationCriterials={evaluationCriterials}
            onSelectedChanged={onSelectedChanged}
          />
        )}
        onSearch={setSearch}
        onPaginationChanged={(page, pageSize) => {
          setPagination((prev) => ({ ...prev, page, pageSize }));
        }}
      />
    </PageContainer>
  );
};
