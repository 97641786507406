import { useTheme } from "styled-components";

import { FloatingCard } from "@components/Cards";
import { Timeline } from "@components/Timeline";

import { Description, TextBox } from "./styled";

import { StyledText, StyledTitle } from "@utils";
import { IconTypes, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo } from "react";

interface IContentHeaderProps {
  name: string;
  cycleName?: string;
  cycleSteps?: {
    type: "evaluation" | "calibration" | "feedback" | "idp";
    startDate: string;
    endDate: string;
  }[];
  description: string;
  isLoading: boolean;
}

type StepShape = { [key: string]: { label: string; icon: IconTypes } };

export const ContentHeader = ({
  name,
  cycleName,
  cycleSteps,
  description,
  isLoading,
}: IContentHeaderProps) => {
  const theme = useTheme();

  const stepShape: StepShape = {
    evaluation: {
      label: "Avaliações",
      icon: "IconFileDescription",
    },
    calibration: { label: "Calibração", icon: "IconBrandSpeedtest" },
    feedback: { label: "Feedback", icon: "IconMailOpened" },
    idp: { label: "PDI", icon: "IconPlaneTilt" },
  };

  const steps = useMemo(() => {
    return (cycleSteps || []).map((step) => {
      const item = stepShape[step?.type] || {};

      return {
        label: item.label || "",
        icon: item.icon || "",
        start: step.startDate || "",
        end: step.endDate || "",
      };
    });
  }, [cycleSteps]);

  if (isLoading) return <Skeleton variant="rectangular" height={"400px"} />;

  return (
    <FloatingCard gap={theme.spacings.xs}>
      <TextBox style={{ justifyContent: "space-between" }}>
        {cycleName && (
          <StyledText variant="body4" setColor="secondary50" weight={600}>
            {cycleName}
          </StyledText>
        )}

        <StyledTitle variant="headline7" setColor="neutral20">
          {name}
        </StyledTitle>
      </TextBox>

      <Timeline items={steps} />

      <Description
        dangerouslySetInnerHTML={{
          __html: description || "",
        }}
        style={{ listStylePosition: "inside" }}
      />
    </FloatingCard>
  );
};
