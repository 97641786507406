import styled from "styled-components";

export const EmptyStateArea = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 24px;
  button {
    align-self: center !important;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
