import { useEffect, useMemo } from "react";

import { isInteger } from "lodash-es";

import { trpc } from "@api/client";
import { routes } from "@routes";
import { dispatchToast } from "@utils";
import { useNavigate } from "react-router-dom";

import type { UseEvaluationParams } from "./types";

const useEvaluation = ({ evaluationId, step }: UseEvaluationParams) => {
  const navigate = useNavigate();

  const {
    data: employeeEvaluation,
    isFetching,
    error: errorFetching,
  } = trpc.performance.evaluation.getPreviewEmployeeEvaluation.useQuery(
    {
      evaluationId,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!evaluationId,
      onError: (e: any) => {
        const notExists = e?.data?.error === "EVALUATION_NOT_EXISTS_ERROR";

        if (notExists) {
          navigate(routes.PageEditEvaluation("review", evaluationId));
        }

        dispatchToast({
          content:
            "Erro ao carregar os dados da avaliação. Tente novamente em breve.",
          type: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (employeeEvaluation) {
      const steps = employeeEvaluation?.steps || [];
      const activeStep = isInteger(Number(step)) ? Number(step) : 0;

      if (activeStep <= 0) {
        navigate(routes.PagePreviewEvaluation(evaluationId, "1"));

        return;
      }

      if (activeStep > steps.length) {
        const lastStep = steps.length;

        navigate(
          routes.PagePreviewEvaluation(evaluationId, lastStep?.toString())
        );

        return;
      }
    }
  }, [employeeEvaluation, step]);

  const activeStep = useMemo(
    () => (isInteger(Number(step)) ? Number(step) : undefined),
    [step]
  );

  const currentStep = useMemo(() => {
    const steps = employeeEvaluation?.steps || [];

    if (!steps.length || !activeStep) return;

    return steps?.[activeStep - 1];
  }, [employeeEvaluation, activeStep]);

  const progress = useMemo(() => {
    const steps = employeeEvaluation?.steps || [];
    if (!steps.length || !activeStep) return;

    const stepTotalQty = steps.length;
    return (activeStep / stepTotalQty) * 100;
  }, [employeeEvaluation, activeStep]);

  return {
    isFetching,
    errorFetching,
    employeeEvaluation,
    progress,
    activeStep,
    totalSteps: employeeEvaluation?.steps?.length || 0,
    currentStep,
  };
};

export { useEvaluation };
