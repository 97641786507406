import { ReactNode } from "react";
import { NotificationCard as Card } from "@flash-tecnologia/hros-web-ui-v2";

type TypeList = "info" | "error" | "success" | "gray";
type VariantList = "contained" | "outlined";
type NotificationCardProps = {
  type: TypeList;
  variant: VariantList;
  title?: string;
  subtitle?: string | ReactNode;
  iconName: "IconAlertCircle" | "IconAlertTriangle";
  children?: any;
  closeButton?: boolean;
  autoClose?: boolean;
};

export const NotificationCard = ({
  type,
  title,
  variant,
  subtitle,
  iconName,
  children,
  closeButton = false,
  autoClose = false,
}: NotificationCardProps) => {
  return (
    <Card.Root
      type={type}
      variant={variant}
      children={
        <Card.WrapperIconWithText>
          {iconName && <Card.Icon iconName={iconName} />}
          {children ? (
            children
          ) : (
            <Card.WrapperTexts>
              {title && <Card.Title children={title} />}
              {subtitle && <Card.Subtitle children={subtitle} />}
            </Card.WrapperTexts>
          )}
        </Card.WrapperIconWithText>
      }
      showCloseButton={closeButton}
      autoClose={autoClose}
    />
  );
};
