export const pageSizeOptions = [
  { label: "5 itens", value: 5 },
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "25 itens",
    value: 25,
  },
  {
    label: "50 itens",
    value: 50,
  },
  {
    label: "100 itens",
    value: 100,
  },
  {
    label: "250 itens",
    value: 250,
  },
];
