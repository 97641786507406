import { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { Header } from "./components/Header";
import { InfoArea } from "./components/InfoArea";
import { EvaluationTab } from "./components/EvaluationTab";

import { StyledPLine, StyledText, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { TabButton } from "./styled";

const PageSkeleton = () => {
  return (
    <PageContainer style={{ gap: "40px" }}>
      <Skeleton
        height="38px"
        width="40%"
        variant="text"
        style={{ marginTop: "38px" }}
      />
      <Skeleton height="82px" width="100%" variant="rounded" />
      <Skeleton height="400px" width="100%" variant="rectangular" />
    </PageContainer>
  );
};

export const PagePersonEvaluationDetails = () => {
  const { _id = "" } = useParams();

  const [tab, setTab] = useState<number>(1);

  const { data, isFetching } =
    trpc.performance.evaluation.getEmployeeEvaluationDetails.useQuery(
      { _id: _id },
      {
        enabled: !!_id,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: (data: any) => {
          const evaluationNotExistError =
            data?.data?.error === "EVALUATION_NOT_EXISTS_ERROR";

          const message = evaluationNotExistError
            ? "Erro ao buscar dados, Avaliação não encontrada."
            : "Erro ao carregar a avaliação, tente novamente mais tarde.";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );

  if (isFetching) return <PageSkeleton />;

  return (
    <PageContainer>
      <Header evaluationName={data?.name} evaluationStatus={data?.status} />

      <InfoArea
        evaluator={data?.evaluator}
        evaluated={data?.evaluated}
        insights={data?.insights}
      />

      <Grid container spacing={3}>
        <Grid item sm={12} md={3} lg={2} width={"100%"} marginBottom={"24px"}>
          {data?.sections?.map(({ name, _id, order }) => (
            <TabButton
              key={_id}
              active={tab === order}
              onClick={() => setTab(order)}
              style={{ marginBottom: "16px" }}
            >
              <StyledPLine numberOfLines={1}>
                <StyledText
                  variant="body4"
                  style={{ fontWeight: "700", textAlign: "left" }}
                >
                  {name}
                </StyledText>
              </StyledPLine>
            </TabButton>
          ))}
        </Grid>

        <Grid item sm={12} md={9} lg={10} width={"100%"}>
          {data?.sections?.map(
            ({ _id, name, description, criterials, order }) => (
              <>
                {tab === order && (
                  <EvaluationTab
                    key={_id}
                    name={name}
                    description={description || ""}
                    criterials={criterials}
                    scale={data?.scale}
                  />
                )}
              </>
            )
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};
