export const mockData = [
  {
    led: {
      _id: "123456",
      name: "Ricardo Silva",
      email: "ricardo.silva@flashapp.com.br",
    },
    lastLeader: {
      _id: "75asd24",
      name: "Erick Banks",
      email: "erick.banks@flashapp.com.br",
    },
    currentLeader: {
      _id: "865trt54ryh",
      name: "Vini Boy",
      email: "vini.boy@flashapp.com.br",
    },
    evaluation: {
      _id: "5890gh85q",
      name: "Avaliação Q1 2024",
      status: "answered",
    },
  },
  {
    led: {
      _id: "789012",
      name: "Charmander Alegre",
      email: "charmander.alegre@flashapp.com.br",
    },
    lastLeader: {
      _id: "98gh54",
      name: "Bulbasaur Forte",
      email: "bulbasaur.forte@flashapp.com.br",
    },
    currentLeader: {
      _id: "453kjh98",
      name: "Squirtle Ágil",
      email: "squirtle.agil@flashapp.com.br",
    },
    evaluation: {
      _id: "1209jf84",
      name: "Avaliação Q2 2024",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "654321",
      name: "Eevee Criativo",
      email: "eevee.criativo@flashapp.com.br",
    },
    lastLeader: {
      _id: "76hfg23",
      name: "Snorlax Rápido",
      email: "snorlax.rapido@flashapp.com.br",
    },
    currentLeader: {
      _id: "98uh43h",
      name: "Jigglypuff Sábio",
      email: "jigglypuff.sabio@flashapp.com.br",
    },
    evaluation: {
      _id: "5678lkj98",
      name: "Avaliação Q3 2024",
      status: "answered",
    },
  },
  {
    led: {
      _id: "987654",
      name: "Meowth Curioso",
      email: "meowth.curioso@flashapp.com.br",
    },
    lastLeader: {
      _id: "54gh32",
      name: "Psyduck Inteligente",
      email: "psyduck.inteligente@flashapp.com.br",
    },
    currentLeader: {
      _id: "32jkh54",
      name: "Gengar Valente",
      email: "gengar.valente@flashapp.com.br",
    },
    evaluation: {
      _id: "2345uhj23",
      name: "Avaliação Q4 2024",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "112233",
      name: "Mewtwo Poderoso",
      email: "mewtwo.poderoso@flashapp.com.br",
    },
    lastLeader: {
      _id: "65fgh98",
      name: "Pikachu Determinado",
      email: "pikachu.determinado@flashapp.com.br",
    },
    currentLeader: {
      _id: "76yhn98",
      name: "Lucario Corajoso",
      email: "lucario.corajoso@flashapp.com.br",
    },
    evaluation: {
      _id: "3456klm45",
      name: "Avaliação Q1 2025",
      status: "answered",
    },
  },
  {
    led: {
      _id: "998877",
      name: "Lapras Generoso",
      email: "lapras.generoso@flashapp.com.br",
    },
    lastLeader: {
      _id: "54kjh23",
      name: "Machamp Engraçado",
      email: "machamp.engracado@flashapp.com.br",
    },
    currentLeader: {
      _id: "32mnb54",
      name: "Gyarados Determinado",
      email: "gyarados.determinado@flashapp.com.br",
    },
    evaluation: {
      _id: "4567nml56",
      name: "Avaliação Q2 2025",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "556677",
      name: "Dragonite Pacífico",
      email: "dragonite.pacifico@flashapp.com.br",
    },
    lastLeader: {
      _id: "21nbg54",
      name: "Alakazam Inteligente",
      email: "alakazam.inteligente@flashapp.com.br",
    },
    currentLeader: {
      _id: "76lop98",
      name: "Ditto Flexível",
      email: "ditto.flexivel@flashapp.com.br",
    },
    evaluation: {
      _id: "5678opq67",
      name: "Avaliação Q3 2025",
      status: "answered",
    },
  },
  {
    led: {
      _id: "443322",
      name: "Blastoise Forte",
      email: "blastoise.forte@flashapp.com.br",
    },
    lastLeader: {
      _id: "98mbn65",
      name: "Venusaur Leal",
      email: "venusaur.leal@flashapp.com.br",
    },
    currentLeader: {
      _id: "54jkl21",
      name: "Charizard Confiante",
      email: "charizard.confiante@flashapp.com.br",
    },
    evaluation: {
      _id: "6789rst78",
      name: "Avaliação Q4 2025",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "221100",
      name: "Jolteon Ágil",
      email: "jolteon.agil@flashapp.com.br",
    },
    lastLeader: {
      _id: "54vbn32",
      name: "Flareon Persistente",
      email: "flareon.persistente@flashapp.com.br",
    },
    currentLeader: {
      _id: "98jkl76",
      name: "Vaporeon Amigável",
      email: "vaporeon.amigavel@flashapp.com.br",
    },
    evaluation: {
      _id: "7890stu89",
      name: "Avaliação Q1 2026",
      status: "answered",
    },
  },
  {
    led: {
      _id: "334455",
      name: "Umbreon Silencioso",
      email: "umbreon.silencioso@flashapp.com.br",
    },
    lastLeader: {
      _id: "65mnb32",
      name: "Espeon Perspicaz",
      email: "espeon.perspicaz@flashapp.com.br",
    },
    currentLeader: {
      _id: "21qwe43",
      name: "Leafeon Serene",
      email: "leafeon.serene@flashapp.com.br",
    },
    evaluation: {
      _id: "8901uvw90",
      name: "Avaliação Q2 2026",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "667788",
      name: "Sylveon Gentil",
      email: "sylveon.gentil@flashapp.com.br",
    },
    lastLeader: {
      _id: "32lkj54",
      name: "Glaceon Sereno",
      email: "glaceon.sereno@flashapp.com.br",
    },
    currentLeader: {
      _id: "43qwe65",
      name: "Flareon Ardente",
      email: "flareon.ardente@flashapp.com.br",
    },
    evaluation: {
      _id: "9012xyz01",
      name: "Avaliação Q3 2026",
      status: "answered",
    },
  },
  {
    led: {
      _id: "889900",
      name: "Zapdos Veloz",
      email: "zapdos.veloz@flashapp.com.br",
    },
    lastLeader: {
      _id: "87yhn43",
      name: "Moltres Implacável",
      email: "moltres.implacavel@flashapp.com.br",
    },
    currentLeader: {
      _id: "98uhy65",
      name: "Articuno Serene",
      email: "articuno.serene@flashapp.com.br",
    },
    evaluation: {
      _id: "3456qwe67",
      name: "Avaliação Q4 2026",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "112244",
      name: "Mew Brilhante",
      email: "mew.brilhante@flashapp.com.br",
    },
    lastLeader: {
      _id: "54hgf32",
      name: "Mewtwo Poderoso",
      email: "mewtwo.poderoso@flashapp.com.br",
    },
    currentLeader: {
      _id: "32jkl76",
      name: "Lugia Calmo",
      email: "lugia.calmo@flashapp.com.br",
    },
    evaluation: {
      _id: "7890wer12",
      name: "Avaliação Q1 2027",
      status: "answered",
    },
  },
  {
    led: {
      _id: "223311",
      name: "Ho-Oh Majestoso",
      email: "hooh.majestoso@flashapp.com.br",
    },
    lastLeader: {
      _id: "65tre43",
      name: "Celebi Místico",
      email: "celebi.mistico@flashapp.com.br",
    },
    currentLeader: {
      _id: "98uhb54",
      name: "Jirachi Sereno",
      email: "jirachi.sereno@flashapp.com.br",
    },
    evaluation: {
      _id: "3456poi78",
      name: "Avaliação Q2 2027",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "556688",
      name: "Groudon Poderoso",
      email: "groudon.poderoso@flashapp.com.br",
    },
    lastLeader: {
      _id: "21yhn98",
      name: "Kyogre Imponente",
      email: "kyogre.imponente@flashapp.com.br",
    },
    currentLeader: {
      _id: "43jkl21",
      name: "Rayquaza Ágil",
      email: "rayquaza.agil@flashapp.com.br",
    },
    evaluation: {
      _id: "7890qaz34",
      name: "Avaliação Q3 2027",
      status: "answered",
    },
  },
  {
    led: {
      _id: "667799",
      name: "Deoxys Adaptável",
      email: "deoxys.adaptavel@flashapp.com.br",
    },
    lastLeader: {
      _id: "32tre65",
      name: "Dialga Poderoso",
      email: "dialga.poderoso@flashapp.com.br",
    },
    currentLeader: {
      _id: "54jkl87",
      name: "Palkia Versátil",
      email: "palkia.versatil@flashapp.com.br",
    },
    evaluation: {
      _id: "8901sdf56",
      name: "Avaliação Q4 2027",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "778800",
      name: "Giratina Misterioso",
      email: "giratina.misterioso@flashapp.com.br",
    },
    lastLeader: {
      _id: "87yhn65",
      name: "Arceus Supremo",
      email: "arceus.supremo@flashapp.com.br",
    },
    currentLeader: {
      _id: "98uhb76",
      name: "Darkrai Enigmático",
      email: "darkrai.enigmatico@flashapp.com.br",
    },
    evaluation: {
      _id: "3456asd78",
      name: "Avaliação Q1 2028",
      status: "answered",
    },
  },
  {
    led: {
      _id: "889911",
      name: "Cresselia Sereno",
      email: "cresselia.sereno@flashapp.com.br",
    },
    lastLeader: {
      _id: "54tre76",
      name: "Heatran Impassível",
      email: "heatran.impassivel@flashapp.com.br",
    },
    currentLeader: {
      _id: "32jkl98",
      name: "Regigigas Poderoso",
      email: "regigigas.poderoso@flashapp.com.br",
    },
    evaluation: {
      _id: "7890wer56",
      name: "Avaliação Q2 2028",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "990022",
      name: "Manaphy Curioso",
      email: "manaphy.curioso@flashapp.com.br",
    },
    lastLeader: {
      _id: "65tre98",
      name: "Phione Gracioso",
      email: "phione.gracioso@flashapp.com.br",
    },
    currentLeader: {
      _id: "98uhy87",
      name: "Shaymin Brilhante",
      email: "shaymin.brilhante@flashapp.com.br",
    },
    evaluation: {
      _id: "3456asd90",
      name: "Avaliação Q3 2028",
      status: "answered",
    },
  },
  {
    led: {
      _id: "112255",
      name: "Victini Sortudo",
      email: "victini.sortudo@flashapp.com.br",
    },
    lastLeader: {
      _id: "54hgf98",
      name: "Zekrom Poderoso",
      email: "zekrom.poderoso@flashapp.com.br",
    },
    currentLeader: {
      _id: "32jkl76",
      name: "Reshiram Lendário",
      email: "reshiram.lendario@flashapp.com.br",
    },
    evaluation: {
      _id: "7890wer89",
      name: "Avaliação Q4 2028",
      status: "answered",
    },
  },
  {
    led: {
      _id: "223322",
      name: "Keldeo Bravo",
      email: "keldeo.bravo@flashapp.com.br",
    },
    lastLeader: {
      _id: "65tre21",
      name: "Meloetta Melodioso",
      email: "meloetta.melodioso@flashapp.com.br",
    },
    currentLeader: {
      _id: "98uhy54",
      name: "Genesect Implacável",
      email: "genesect.implacavel@flashapp.com.br",
    },
    evaluation: {
      _id: "3456asd12",
      name: "Avaliação Q1 2029",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "334466",
      name: "Xerneas Lendário",
      email: "xerneas.lendario@flashapp.com.br",
    },
    lastLeader: {
      _id: "32tre43",
      name: "Yveltal Poderoso",
      email: "yveltal.poderoso@flashapp.com.br",
    },
    currentLeader: {
      _id: "54jkl98",
      name: "Zygarde Guardião",
      email: "zygarde.guardiao@flashapp.com.br",
    },
    evaluation: {
      _id: "8901wer34",
      name: "Avaliação Q2 2029",
      status: "unanswered",
    },
  },
  {
    led: {
      _id: "556699",
      name: "Diancie Brilhante",
      email: "diancie.brilhante@flashapp.com.br",
    },
    lastLeader: {
      _id: "21tre98",
      name: "Hoopa Misterioso",
      email: "hoopa.misterioso@flashapp.com.br",
    },
    currentLeader: {
      _id: "43jkl54",
      name: "Volcanion Feroz",
      email: "volcanion.feroz@flashapp.com.br",
    },
    evaluation: {
      _id: "7890wer67",
      name: "Avaliação Q3 2029",
      status: "answered",
    },
  },
];
