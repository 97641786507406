import { useEffect, useMemo, useState } from "react";

import {
  DataGrid,
  Skeleton,
  Checkbox,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StyledTitle, pageSizeOptions } from "@utils";
import { OverflowCheck } from "@components/OverflowCheck";

interface TableGridProps {
  data: any[];
  loading: boolean;
  selectedValues: any[];
  onValuesSelected: (values: any) => void;
  refetch?: () => void;
}

export const TableGrid = ({
  data,
  loading,
  selectedValues,
  onValuesSelected,
}: TableGridProps) => {
  const [handleValues, setHandleValues] = useState<any[]>(selectedValues);

  useEffect(() => {
    onValuesSelected(handleValues);
  }, [handleValues]);

  const columns = useMemo(
    () => [
      {
        disableSortBy: true,
        Cell: (info: any) => {
          const _id = info?.row?.original?._id;

          return (
            <Checkbox
              onChange={(_, value) => {
                if (value) {
                  setHandleValues((prevValue) => [
                    ...prevValue,
                    info?.row?.original,
                  ]);
                } else {
                  setHandleValues((prevValue) => {
                    const newValues = prevValue.filter((e) => e._id !== _id);
                    return newValues;
                  });
                }
              }}
            />
          );
        },
        accessor: "checkBoxColumn",
      },
      {
        Header: "Nome do critério",
        accessor: "name",
        Cell: (info) => {
          const name = info?.row?.original.name || "";
          const description = info?.row?.original.description || "";
          return (
            <div style={{ width: "100%", paddingRight: "10px" }}>
              <StyledTitle
                variant="body3"
                children={name}
                setColor="neutral40"
              />
              <StyledTitle
                variant="body4"
                children={OverflowCheck({ text: description, width: "400px" })}
                setColor="neutral60"
              />
            </div>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!data.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum critério disponível."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        hasPagination={data.length !== 0}
        columns={columns}
        data={data || []}
        initialState={10}
        emptyState={{
          isFiltered: true,
          message: "Nenhum critério encontrado",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};
