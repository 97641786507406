import { useTheme } from "styled-components";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { FloatingCard } from "@components/Cards";

import { EvaluationCard } from "../EvaluationCard";

import { StyledTitle } from "@utils";

import type { EmployeeEvaluationIndex } from "server/src/services/evaluation/types";

interface IContentHeaderProps {
  types: EmployeeEvaluationIndex["typesToAnswer"];
  status: EmployeeEvaluationIndex["status"];
  isLoading: boolean;
}

export const TypesToAnswer = ({
  types = [],
  status,
  isLoading,
}: IContentHeaderProps) => {
  const theme = useTheme();

  if (isLoading) return <Skeleton variant="rectangular" height={"300px"} />;

  return (
    <FloatingCard gap={theme.spacings.xs}>
      <StyledTitle variant="headline8" setColor={"neutral20"}>
        Avaliação a responder
      </StyledTitle>

      {types.map((data) => (
        <EvaluationCard
          type={data.type}
          evaluatedName={data.evaluatedName}
          status={data.status}
          statusMessage={data.statusMessage}
          statusDate={data.statusDate}
          disabled={status !== "available"}
        />
      ))}
    </FloatingCard>
  );
};
