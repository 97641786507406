import { useMemo, useState } from "react";

import {
  PillButton,
  Icons,
  Menu,
  Typography,
  IconTypes,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ConfirmationModal, RenameSectionModal } from "@components/Modals";
import { StyledIcon, dispatchToast } from "@utils";

import { Option, MenuContainer } from "./styled";

import type { onOptionsClickedType } from "../../types";
import type { SectionType } from "../../../types";

interface ISectionOptions {
  type: "menu" | "buttons";
  section: SectionType;
  selectedSectionIndex: number;
  sectionTitles?: string[];
  sectionsLimit?: boolean;
  disabledEdit?: boolean;
  onOptionsClicked: onOptionsClickedType;
  hasByLeader: boolean;
}

type renameStateProps = {
  open: boolean;
  data?: SectionType;
};

type itemsProps = {
  key: string;
  title: string;
  icon: IconTypes;
  iconColor?: string;
  disabled?: boolean;
  onClick: () => void;
};

export const SectionsOptions = ({
  type,
  section,
  sectionTitles = [],
  sectionsLimit = true,
  selectedSectionIndex,
  disabledEdit,
  onOptionsClicked,
  hasByLeader,
}: ISectionOptions) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [renameModal, setRenameModal] = useState<renameStateProps>({
    open: false,
    data: undefined,
  });
  const items = useMemo<itemsProps[]>(
    () => [
      {
        key: "rename",
        title: "Renomear seção",
        icon:
          section.fromModel || disabledEdit ? "IconPencilOff" : "IconPencil",
        disabled: !!section.fromModel || disabledEdit,
        onClick: () => {
          if (section.fromModel || disabledEdit) return;
          setRenameModal({
            open: true,
            data: section,
          });
        },
      },
      {
        key: "duplicate",
        title: "Duplicar seção",
        icon: disabledEdit ? "IconCopyOff" : "IconCopy",
        disabled: disabledEdit,
        onClick: () => {
          if (disabledEdit) return;

          onOptionsClicked("duplicate", selectedSectionIndex);
        },
      },
      {
        key: "delete",
        title: "Excluir seção",
        icon: section.fromModel || disabledEdit ? "IconTrashOff" : "IconTrash",
        iconColor: sectionsLimit ? "#D1C7CE" : "#C96C01",
        disabled: sectionsLimit || !!section.fromModel || disabledEdit,
        onClick: () => {
          if (section.fromModel || disabledEdit) return;

          if (!sectionsLimit) return setDeleteModal(true);

          dispatchToast({
            type: "error",
            content: hasByLeader
              ? "A avaliação deve conter pelo menos duas seções para que você possa calibrar as notas dos participantes."
              : "A avaliação deve conter pelo menos uma seção.",
          });
        },
      },
    ],
    [section, selectedSectionIndex, sectionsLimit, disabledEdit, hasByLeader]
  );

  const menuOptions = useMemo(
    () =>
      items.map((item) => ({
        onClick: item.onClick,
        children: (
          <Option>
            <StyledIcon
              name={item.icon}
              fill="transparent"
              color={item.iconColor}
            />
            <Typography
              variant="body3"
              style={{ fontWeight: 600, color: item.iconColor }}
            >
              {item.title}
            </Typography>
          </Option>
        ),
      })),
    [items]
  );

  const renderMenu = () => {
    return (
      <MenuContainer>
        <Menu
          type="select"
          key={`${sectionTitles.length}${section.name}`}
          options={menuOptions}
          disableAutoFocusItem={true}
          disabled={disabledEdit}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Icons name="IconDotsVertical" style={{ display: "flex" }} />
        </Menu>
      </MenuContainer>
    );
  };

  const renderButtons = () => {
    return (
      <div style={{ display: "flex", gap: "16px" }}>
        {items.map((item) => (
          <Tooltip key={item.key} title={item.title}>
            <div>
              <PillButton
                size="medium"
                variant="default"
                type="secondary"
                icon={item.icon}
                disabled={item.disabled}
                onClick={item.onClick}
              />
            </div>
          </Tooltip>
        ))}
      </div>
    );
  };

  return (
    <div onClick={(e) => e.stopPropagation()} role="presentation">
      {type === "menu" && renderMenu()}
      {type === "buttons" && renderButtons()}

      <ConfirmationModal
        open={deleteModal}
        showWarning
        icon="IconAlertCircle"
        title="Tem certeza que deseja excluir a seção?"
        subTitle="Todos os critérios serão removidos e essa ação não poderá ser desfeita."
        confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
        onClose={() => setDeleteModal(false)}
        onConfirm={() => {
          onOptionsClicked("delete", selectedSectionIndex);
          setDeleteModal(false);
        }}
      />

      <RenameSectionModal
        variant="update"
        open={renameModal.open}
        data={renameModal.data}
        sectionTitles={sectionTitles}
        onClose={() => {
          setRenameModal({ open: false, data: undefined });
        }}
        onCompleted={(updatedSection) => {
          onOptionsClicked("rename", selectedSectionIndex, updatedSection);
        }}
      />
    </div>
  );
};
