import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 12px 0 8px 0;
`;

export const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 32px;
`;

export const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const QuestionCounter = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-right: 15px;
`;

export const QuestionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const QuestionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid #ebe6e9;
  margin: 32px 0;
`;
