import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
`;

export const MainContainer = styled.div``;

export const Header = styled.div`
  padding: 36px 0px;
`;
