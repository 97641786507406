import { useEffect, useMemo, useState } from "react";
import { dayjs, Tab, TabProps } from "@flash-tecnologia/hros-web-ui-v2";

import { CycleDetail } from "./CycleDetail";
import { Evaluations } from "./Evaluations";
import { Feedbacks } from "./Feedbacks";
import { Idps } from "./Idps";

import type { Cycle } from "server/src/services/cycle/types";

interface ITabsProps {
  cycle: Cycle | undefined;
}

export const Tabs = ({ cycle }: ITabsProps) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (
      cycle?.steps?.find(
        ({ type, startDate }) =>
          type === "feedback" && dayjs().isSameOrAfter(startDate)
      )
    ) {
      setTab(1);
    }

    if (
      cycle?.steps?.find(
        ({ type, startDate }) =>
          type === "idp" && dayjs().isSameOrAfter(startDate)
      )
    ) {
      setTab(2);
    }
  }, [cycle]);

  const tabItens = useMemo(() => {
    const items: TabProps["tabItens"] = [];
    const steps = cycle?.steps || [];

    items?.push({
      label: "Avaliações",
      component: <Evaluations />,
    });

    if (steps.find(({ type }) => type === "feedback")) {
      items?.push({
        label: "Feedbacks",
        component: <Feedbacks />,
      });
    }

    if (steps.find(({ type }) => type === "idp")) {
      items?.push({
        label: "PDI",
        component: <Idps />,
      });
    }

    items?.push({
      label: "Detalhes do ciclo",
      component: <CycleDetail cycle={cycle} />,
    });

    return items;
  }, [cycle]);

  return (
    <Tab
      selected={tab}
      tabItens={tabItens}
      onTabChanged={(tab) => setTab(tab)}
    />
  );
};
