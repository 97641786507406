import type { Feedback } from "server/src/services/feedback/types";

export type SendFeedbackFormProps = {
  to: string;
  type?: Feedback["type"] | "";
  requestMessage?: string;
  message: string;
  evaluationId?: string;
  companyValues: Feedback["companyValues"];
  evaluationCriterials: Feedback["evaluationCriterials"];
};

export const EDITOR_MAX_LENGTH = 3000;
