import { track } from "@utils";
import { useMemo } from "react";
import { MyCriteria, CriteriaModels } from "./components";

import { Container, StyledTab } from "./styled";

interface ITabsProps {
  onCreateClick: () => void;
}

export const Tabs = ({ onCreateClick }: ITabsProps) => {
  const tabItens = useMemo(
    () => [
      {
        label: "Meus critérios",
        component: <MyCriteria onCreateClick={onCreateClick} />,
      },
      {
        label: "Modelos de critérios",
        component: <CriteriaModels />,
      },
    ],
    []
  );

  return (
    <Container>
      <StyledTab
        tabItens={tabItens}
        onTabChanged={(tab) => {
          if (tab === 1) {
            track({
              name: "people_strategic_hr_performance_company_ratingcriteria_createcriteria_cancel_clicked",
            });
          }
        }}
      />
    </Container>
  );
};
