import { useState, useMemo } from "react";

import {
  Button,
  PillButton,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { Table } from "./Table";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import type { CommonModalProps } from "@components/Modals/types";
import type { Feedback } from "server/src/services/feedback/types";

import {
  FooterContainer,
  HeaderContainer,
  TitleContainer,
  StyledModal,
} from "./styled";

interface IAddEvaluationCriterialsModal extends CommonModalProps {
  selectedValues: Required<Feedback>["evaluationCriterials"];
  evaluation: {
    _id: string;
    name: string;
  };
  onCriterialsChanged: (
    values: Required<Feedback>["evaluationCriterials"]
  ) => void;
}

type AddEvaluationCriterialsModalProps = Omit<
  IAddEvaluationCriterialsModal,
  "onConfirm"
>;

export const AddEvaluationCriterialsModal = ({
  open,
  onClose,
  selectedValues,
  evaluation,
  onCriterialsChanged,
}: AddEvaluationCriterialsModalProps) => {
  const [selectedCriterialsUpdate, setSelectedCriterialsUpdate] = useState([]);

  const { data = [], isLoading } =
    trpc.performance.evaluation.getCriterialsByEvaluationProcedure.useQuery(
      {
        evaluationId: evaluation?._id,
      },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: open && !!evaluation?._id,
      }
    );

  const filteredData = useMemo(
    () =>
      data.filter(
        (criterial) =>
          !selectedValues.some((value) => value._id === criterial._id)
      ),
    [data, selectedValues]
  );

  const Header = () => {
    return (
      <HeaderContainer>
        <TitleContainer>
          <StyledTitle variant="headline6">
            Selecione os critérios da avaliação
          </StyledTitle>
          <StyledText variant="body3" setColor="neutral50">
            Personalize o feedback incluindo critérios da avaliação escolhida.
          </StyledText>
        </TitleContainer>
        <div style={{ marginTop: "-20px" }}>
          <PillButton
            variant="default"
            size="small"
            type="secondary"
            icon="IconX"
            onClick={onClose}
          />
        </div>
      </HeaderContainer>
    );
  };

  const Footer = () => (
    <FooterContainer>
      <LinkButton
        variant="neutral"
        children="Cancelar"
        style={{ alignSelf: "center" }}
        onClick={onClose}
      />
      <Button
        size="large"
        variant="primary"
        disabled={!filteredData.length}
        style={{ alignSelf: "center" }}
        onClick={() => {
          onCriterialsChanged(selectedCriterialsUpdate);
          onClose?.(new Event("click"));
        }}
      >
        Adicionar critérios
        <StyledIcon name="IconArrowRight" fill="none" size={40} />
      </Button>
    </FooterContainer>
  );

  return (
    <StyledModal
      onClose={() => onClose?.(new Event("click"))}
      open={open}
      header={<Header />}
      footer={<Footer />}
    >
      <Table
        data={filteredData || []}
        evaluationName={evaluation.name || ""}
        selectedValues={selectedValues}
        onValuesSelected={setSelectedCriterialsUpdate}
        loading={isLoading}
      />
    </StyledModal>
  );
};
