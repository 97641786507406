import React, { useState, useEffect } from "react";

import { Search } from "./styled";

export interface SearchFieldProps {
  onChange: (search: string) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const SearchField = ({
  onChange,
  disabled,
  placeholder,
  style,
}: SearchFieldProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inputValue === undefined) return;

    const timer = setTimeout(() => {
      onChange(inputValue);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [inputValue]);

  const handleOnChange = (value: string | undefined) => {
    setInputValue(value || "");
  };

  const handleOnBlur = (value) => {
    const target = value?.target?.value;
    setInputValue(target || "");
  };

  const handleOnInput = (value) => {
    const target = value?.target?.value;
    setInputValue(target || "");
  };

  return (
    <Search
      onChange={(e) => handleOnChange(e.target.value)}
      onSearchChange={handleOnBlur}
      onInputChange={handleOnInput}
      label={placeholder || "Buscar"}
      disabled={disabled}
      style={style}
    />
  );
};
