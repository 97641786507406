import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle } from "@utils";
import { Table } from "./Table";

import { CardContainer } from "./styled";

export const CompanyValues = ({ formik, isLoading }) => {
  return (
    <CardContainer>
      <Tag
        variant={"gray"}
        children={"Não obrigatório"}
        style={{ marginBottom: "16px" }}
      />
      <StyledTitle
        setColor="neutral30"
        variant="body2"
        children={"Valores da empresa"}
        style={{ marginBottom: "4px" }}
      />
      <StyledText
        setColor="neutral50"
        variant="body4"
        children={
          "Insira valores empresariais que orientarão a realização dos feedbacks."
        }
        style={{ marginBottom: "24px" }}
      />

      <Table
        data={formik.values.companyValues || []}
        onValuesChanged={(values) => {
          formik.handleChange({
            target: { id: "companyValues", value: values },
          });
        }}
        loading={isLoading}
      />
    </CardContainer>
  );
};
