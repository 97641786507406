import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  EmptyState,
  PageContainer,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { track, getAccessTokenSync, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { Header, Tabs } from "./components";

import { MyCycleTimeline } from "./components/MyCycleTimeline";

import type { Cycle } from "server/src/services/cycle/types";

export const PageMyCycles = () => {
  const navigate = useNavigate();

  const { cycleId = "" } = useParams();

  const { employeeId = "" } = getAccessTokenSync();

  const [selectedCycle, setSelectedCycle] = useState<Cycle | undefined>();

  const { data: cycles = [], isFetching } =
    trpc.performance.cycle.getCyclesByEvaluatorId.useQuery(
      { evaluatorId: employeeId },
      {
        enabled: !!employeeId,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os ciclos, tente novamente em breve.",
          });
        },
      }
    );

  useEffect(() => {
    track({ name: "people_strategic_hr_performance_employee_view_cycles" });
  }, []);

  useEffect(() => {
    if (!cycles?.length) return;

    const urlCycle = cycleId
      ? cycles.find((cycle) => cycle._id == cycleId)
      : undefined;

    const foundCycle = urlCycle ? urlCycle : cycles[0];

    navigate(routes.PageMyCycles(foundCycle._id), {
      replace: true,
    });

    setSelectedCycle(foundCycle);
  }, [cycles]);

  return (
    <>
      <PageContainer>
        <Header
          cycles={cycles || []}
          selectedCycle={selectedCycle}
          isLoading={isFetching}
          onCycleSelected={(cycle) => {
            navigate(routes.PageMyCycles(cycle._id), {
              replace: true,
            });

            setSelectedCycle(cycle);
          }}
        />

        {!selectedCycle && isFetching && (
          <Skeleton width={"100%"} height={"600px"} variant="rectangular" />
        )}

        {selectedCycle && (
          <>
            <MyCycleTimeline cycle={selectedCycle} />
            <Tabs cycle={selectedCycle} />
          </>
        )}

        {!selectedCycle && !isFetching && (
          <div style={{ marginBottom: "40px" }}>
            <EmptyState
              description={
                "Seu RH ainda não criou nenhuma avaliação para este ciclo."
              }
              buttonProps={{ size: "small", hidden: true }}
              buttonText=""
            />
          </div>
        )}
      </PageContainer>
    </>
  );
};
