import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";

import { routes } from "@routes";

import { Evaluation } from "./components/Evaluation";

import { useEvaluation } from "./hooks";

export const PagePreviewEvaluation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { evaluationId = "", step = "1" } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();

  const routesBread = [
    {
      label: "Avaliações",
      route: routes.PageManageEvaluations,
    },
    {
      label: "Responder avaliação",
    },
  ];

  const {
    isFetching,
    errorFetching,
    progress,
    employeeEvaluation,
    activeStep,
    totalSteps,
    currentStep,
  } = useEvaluation({
    evaluationId,
    step,
  });

  return (
    <PageTemplate
      containerStyle={{
        backgroundColor: theme.colors.neutral95,
      }}
      contentStyle={{
        maxWidth: "870px",
        gap: theme.spacings.m,
        padding: `${theme.spacings.m} ${theme.spacings.xs}`,
      }}
      routes={routesBread}
      progressPercentage={progress}
      followUpProgress={{
        questionsLabel: "Critérios respondidos",
        evaluationType: employeeEvaluation?.type,
        loading: isFetching,
        steps: {
          total: totalSteps || 0,
          actualStep: activeStep || 0,
        },
        questions: {
          answered: 0,
          total: employeeEvaluation?.criteriaTotal || 0,
          currentGrade: 0,
        },
      }}
      footer={{
        goBackProps: {
          disabled: !!(isFetching || errorFetching),
          hasToShow: activeStep !== 1,
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => {
            if (activeStep === 1) return;

            containerRef?.current?.scrollIntoView();

            const previousStep = (activeStep || 2) - 1;

            navigate(
              routes.PagePreviewEvaluation(
                evaluationId,
                previousStep?.toString()
              )
            );
          },
        },
        confirmProps: {
          disabled: !!(isFetching || errorFetching),
          hasToShow: activeStep !== totalSteps,
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          callback: () => {
            if (!activeStep) return;
            if (activeStep === totalSteps) return;

            containerRef?.current?.scrollIntoView();

            const nextStep = activeStep + 1;

            navigate(
              routes.PagePreviewEvaluation(evaluationId, nextStep?.toString())
            );
          },
        },
      }}
    >
      <div ref={containerRef}>
        <Evaluation
          step={currentStep}
          scale={employeeEvaluation?.scale || []}
          isLoading={isFetching}
        />
      </div>
    </PageTemplate>
  );
};
