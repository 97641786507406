import { useMemo, useState } from "react";

import { trpc } from "@api/client";

import { dispatchToast, track } from "@utils";

import { Evaluations } from "./Evaluations";
import { Feedbacks } from "./Feedbacks";
import { Idp } from "./Idp";
import { MyTeam } from "./MyTeam";

import { Container, StyledTab } from "./styled";

import type { Cycle } from "server/src/services/cycle/types";

interface ITabsProps {
  cycle: Cycle;
}

export const Tabs = ({ cycle }: ITabsProps) => {
  const [tab, setTab] = useState(0);

  const { data: employeeHierarchy } =
    trpc.employee.getEmployeeHierarchy.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar as informações do usuário. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  const tabItens = useMemo(() => {
    const hasFeedbackTab = !!cycle?.steps?.find(
      ({ type }) => type === "feedback"
    );

    const hasIdpTab = !!cycle?.steps?.find(({ type }) => type === "idp");

    const items = [
      {
        label: "Avaliações",
        component: <Evaluations cycleId={cycle?._id} />,
      },
    ];

    if (hasFeedbackTab)
      items.push({
        label: "Feedbacks",
        component: <Feedbacks cycleId={cycle?._id} />,
      });

    if (hasIdpTab)
      items.push({
        label: "PDI",
        component: <Idp cycleId={cycle?._id} />,
      });

    if (employeeHierarchy?.isLeader)
      items.push({ label: "Meus liderados", component: <MyTeam /> });

    return items;
  }, [cycle, employeeHierarchy]);

  return (
    <Container>
      <StyledTab
        selected={tab}
        tabItens={tabItens}
        onTabChanged={(tab) => {
          if (tab === 0)
            track({
              name: "people_strategic_hr_performance_employee_cycles_evaluations_clicked",
            });
          if (tab === 1)
            track({
              name: "people_strategic_hr_performance_employee_cycles_feedbacks_clicked",
            });
          if (tab === 2)
            track({
              name: "people_strategic_hr_performance_employee_cycles_idps_clicked",
            });
          if (tab === 3)
            track({
              name: "people_strategic_hr_performance_employee_cycles_mysubordinates_clicked",
            });
          setTab(tab);
        }}
      />
    </Container>
  );
};
