import { Outlet, Navigate, matchPath, useLocation } from "react-router-dom";

import {
  useSelectedCompany,
  getFromLS,
  usePermissions,
} from "@flash-tecnologia/hros-web-utility";

import { useAuth } from "@flash-hros/auth-helper";

import { flatMap } from "lodash";

import { slugRoutes } from "@routes";

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation();

  const isLoggedIn = getFromLS("hrosAccessToken");

  const { selectedCompany } = useSelectedCompany();

  const allPermissions = usePermissions();

  const contractStatus = useAuth.getState().contractStatus || null;

  const companyPermissions = allPermissions?.companies?.find(
    (company) => company.id === selectedCompany.id
  );

  const permissions = companyPermissions?.permissions || [];

  const routesBySlug = slugRoutes.filter((route) =>
    permissions.some(
      (permission) => permission === "*" || permission === route.slug
    )
  );

  const routes = flatMap(routesBySlug, "routes");

  const isRouteAllowed = routes.some((c) =>
    matchPath({ path: c, end: true }, location.pathname)
  );

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (!isRouteAllowed) {
    return <Navigate to={"/home"} />;
  }

  if (contractStatus?.url) {
    return <Navigate to={contractStatus?.url} state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};
