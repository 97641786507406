import { useState } from "react";
import { LinkButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { Card, ContentContainer, MainContainer, StyledAvatar } from "./styled";
interface IBigNumbersProps {
  onFilterStatus: (status: boolean | undefined) => void;
  evaluationId: string;
}

export const BigNumbers = ({
  onFilterStatus,
  evaluationId,
}: IBigNumbersProps) => {
  const [filter, setFilter] = useState<
    "evaluated" | "not_evaluated" | undefined
  >(undefined);

  const { data, isLoading } =
    trpc.performance.evaluation.getDetailsOverviewEvaluatedsInsights.useQuery(
      { evaluationId: evaluationId },
      {
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Não foi possível buscar a lista de avaliações do ciclo. Tente novamente em breve.",
          });
        },
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!evaluationId,
      }
    );

  if (isLoading)
    return (
      <MainContainer>
        <Skeleton variant="rounded" width={"100%"} height={166} />
        <Skeleton variant="rounded" width={"100%"} height={166} />
        <Skeleton variant="rounded" width={"100%"} height={166} />
      </MainContainer>
    );

  return (
    <MainContainer>
      <Card style={{ opacity: filter !== undefined ? 0.5 : 1 }}>
        <StyledAvatar setColor="secondary">
          <StyledIcon name="IconUser" setColor="secondary50" size={24} />
        </StyledAvatar>

        <div>
          <StyledText
            variant="body3"
            children="Participantes"
            setColor="neutral50"
          />
          <StyledTitle
            variant="headline5"
            setColor="neutral40"
            children={data?.total}
          />
        </div>
      </Card>
      <Card style={{ opacity: filter === "not_evaluated" ? 0.5 : 1 }}>
        <StyledAvatar setColor="success">
          <StyledIcon name="IconCheck" setColor="success40" size={24} />
        </StyledAvatar>
        <ContentContainer>
          <div>
            <StyledText
              variant="body3"
              children="Participantes que já foram avaliados"
              setColor="neutral50"
              style={{ maxWidth: 200 }}
            />
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.alreadyEvaluated}
            />
          </div>

          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            children={filter === "evaluated" ? "Remover filtro" : "Filtrar"}
            onClick={() => {
              const status = filter === "evaluated" ? undefined : "evaluated";

              setFilter(status);
              onFilterStatus(status ? true : undefined);
            }}
          />
        </ContentContainer>
      </Card>
      <Card style={{ opacity: filter === "evaluated" ? 0.5 : 1 }}>
        <StyledAvatar setColor="error">
          <StyledIcon name="IconAlertTriangle" setColor="error40" size={24} />
        </StyledAvatar>
        <ContentContainer>
          <div>
            <StyledText
              variant="body3"
              children="Participantes que não foram avaliados"
              setColor="neutral50"
              style={{ maxWidth: 200 }}
            />
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.toEvaluate}
            />
          </div>
          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            children={filter === "not_evaluated" ? "Remover filtro" : "Filtrar"}
            onClick={() => {
              const status =
                filter === "not_evaluated" ? undefined : "not_evaluated";

              setFilter(status);
              onFilterStatus(status ? false : undefined);
            }}
          />
        </ContentContainer>
      </Card>
    </MainContainer>
  );
};
