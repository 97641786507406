import { useNavigate } from "react-router-dom";

import {
  Button,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { StyledText, StyledTitle, track } from "@utils";

import { HeaderArea, TitleArea, MenuItem } from "./styled";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <HeaderArea>
      <TitleArea>
        <StyledTitle variant="headline6" setColor="neutral20">
          Avaliações
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral40">
          Crie e gerencie as avaliações de desempenho da empresa.
        </StyledText>
      </TitleArea>

      <Menu
        children={
          <Button
            size="large"
            variant="primary"
            onClick={() =>
              track({
                name: "people_strategic_hr_performance_company_evaluations_createevaluation_clicked",
              })
            }
          >
            Criar avaliação
            <Icons name="IconPlus" />
          </Button>
        }
        type="default"
        options={[
          {
            children: (
              <MenuItem>
                <Icons name="IconLayoutGrid" />
                <Typography variant="body3">Com base em um modelo</Typography>
              </MenuItem>
            ),
            onClick: () => {
              track({
                name: "people_strategic_hr_performance_company_evaluations_createevaluation_model_clicked",
              });
              navigate(routes.PageCreateEvaluation("models"));
            },
          },
          {
            children: (
              <MenuItem>
                <Icons name="IconEdit" />
                <Typography variant="body3">
                  Criar avaliação em branco
                </Typography>
              </MenuItem>
            ),
            onClick: () => {
              track({
                name: "people_strategic_hr_performance_company_evaluations_createevaluation_inblank_clicked",
              });
              navigate(routes.PageCreateEvaluation("basic-info"));
            },
          },
        ]}
      />
    </HeaderArea>
  );
};
