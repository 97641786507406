import { useEffect } from "react";

import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTitle, dispatchToast, track } from "@utils";

import {
  LastCycle,
  EmptyState,
  Header,
  OtherCycles,
  PageSkeleton,
} from "./components";

import { trpc } from "@api/client";

export const PageManageCycles = () => {
  const { data, isLoading } = trpc.performance.cycle.getLastCycle.useQuery(
    undefined,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar último ciclo ,tente novamente em breve.",
        });
      },
    }
  );

  useEffect(() => {
    track({ name: "people_strategic_hr_performance_company_view_cycles" });
  }, []);

  const PageRenderer = () => {
    if (isLoading) return <PageSkeleton />;

    if (!data) return <EmptyState />;

    return (
      <>
        <StyledTitle
          variant="headline7"
          setColor="neutral30"
          weight={700}
          style={{ marginBottom: "16px" }}
        >
          Último ciclo de desempenho
        </StyledTitle>

        <LastCycle cycle={data} />

        <OtherCycles />
      </>
    );
  };

  return (
    <PageContainer>
      <Header />
      <PageRenderer />
    </PageContainer>
  );
};
