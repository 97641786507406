import { trpc } from "@api/client";
import { Table } from "@components/Table";
import { dispatchToast } from "@utils";
import { TableGrid } from "./TableGrid";

export const MyTeam = () => {
  const { data, isLoading } = trpc.employee.getEmployeeTeam.useQuery(
    undefined,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar pessoas, favor tentar novamente mais tarde!",
        });
      },
    }
  );

  return (
    <Table
      data={data?.items || []}
      filters={data?.filters || []}
      loading={isLoading}
      searchable={true}
      searchableField="name"
      searchPlaceholder={"Buscar por pessoa"}
      tableGridRender={(props) => <TableGrid {...props} />}
    />
  );
};
