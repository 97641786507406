import { useMemo } from "react";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import { DataCard } from "@components/Cards";
import { StyledTitle, StyledText, RequiredAsterisk } from "@utils";

import { CardTable } from "../CardTable";

import { CardsContainer } from "./styled";

import { EvaluationType } from "../../../types";
interface ConfigurationsTabProps {
  data?: Partial<EvaluationType>;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
}

export const ConfigurationsTab = ({
  data,
  onNavigate,
  disabled,
}: ConfigurationsTabProps) => {
  const evaluationTypes = [
    {
      type: "byLeader",
      title: "Líder avalia liderado",
      description: (
        <>
          <p>
            Os participantes selecionados serão avaliados por seus líderes
            diretos.
          </p>
          <p>
            <RequiredAsterisk />
            Apenas a <b>nota do líder</b> (líder avalia liderado) será
            considerada no cálculo da <b>nota final</b> e poderá ser calibrada.
          </p>
        </>
      ),
    },
    {
      type: "self",
      title: "Participante se autoavalia",
      description: (
        <p>
          Os participantes selecionados realizam a avaliação de sua própria
          performance.
        </p>
      ),
    },
    {
      type: "byLed",
      title: "Liderado avalia líder",
      description: (
        <p>
          Os participantes selecionados serão avaliados por seus liderados
          diretos.
        </p>
      ),
    },
  ];

  const selectedTypes = useMemo(() => {
    const typeNames = data?.types?.map((type: any) => type?.type);

    return evaluationTypes.filter((evaluationType) =>
      typeNames?.includes(evaluationType.type)
    );
  }, [data?.types, evaluationTypes]);

  return (
    <CardsContainer>
      <DataCard
        title="Tipo de avaliação"
        subTitle="Confira os tipos de avaliação selecionados."
        editable={!disabled}
        onEditClick={onNavigate}
      >
        {selectedTypes.map((types) => {
          return (
            <div key={types.type}>
              <StyledTitle
                variant="headline9"
                setColor="neutral30"
                children={types.title}
              />
              <StyledText
                variant="caption"
                setColor="neutral50"
                fontWeight={600}
                children={types.description}
              />
            </div>
          );
        })}
      </DataCard>

      <DataCard
        title="Cronograma"
        subTitle="Confira o cronograma da avaliação."
        editable={!disabled}
        onEditClick={onNavigate}
        sections={[
          {
            title: "Período das avaliações",
            items: [
              {
                title: "Início das avaliações",
                description:
                  data?.startDate && dayjs(data?.startDate).isValid()
                    ? dayjs(data.startDate).format("DD/MM/YYYY")
                    : "-",
              },
              {
                title: "Encerramento das avaliações",
                description:
                  data?.endDate && dayjs(data?.endDate).isValid()
                    ? dayjs(data.endDate).format("DD/MM/YYYY")
                    : "-",
              },
            ],
          },
        ]}
      />

      <DataCard
        title="Escala"
        subTitle="Confira a escala configurada para todos os critérios da avaliação."
        editable={!disabled}
        onEditClick={onNavigate}
        children={
          <CardTable
            leftColumnTitle="Valores da escala"
            rightColumnTitle="Legenda"
            sections={[{ data: data?.scale || [] }]}
          />
        }
      />
    </CardsContainer>
  );
};
