import { useEffect } from "react";
import { useFormik } from "formik";

import { trpc } from "@api/client";

import { Header, Footer, CompanyValueForm } from "./components";

import { dispatchToast, track } from "@utils";

import { CompanyValueFormProps, validationSchema } from "./types";

import type { CommonModalProps } from "@components/Modals/types";

import { StyledModal, ContentContainer } from "./styled";

interface ICreateCompanyValueModalProps extends CommonModalProps {
  refetch?: () => void;
}

type CreateCompanyValueModalProps = Omit<
  ICreateCompanyValueModalProps,
  "onConfirm"
>;

export const CreateCompanyValueModal = ({
  open,
  onClose,
  refetch,
}: CreateCompanyValueModalProps) => {
  const { mutate, isLoading } =
    trpc.performance.feedback.createCompanyValue.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Valor criado com sucesso.",
        });

        refetch?.();
        setTimeout(() => onClose?.(new Event("click")), 1000);
      },
      onError: (e: any) => {
        const valueExists = e?.data?.error === "COMPANY_VALUE_EXISTS_ERROR";

        let message = "Erro ao criar o valor, tente novamente em breve.";

        if (valueExists) message = "Erro ao criar valor, nome já existe!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const formik = useFormik<CompanyValueFormProps>({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      track({
        name: "performance_feedbacks_settings_createcompanyvalue_createvalue_clicked",
      });

      mutate({
        params: { name: values.name, description: values.description },
      });
    },
  });

  useEffect(() => {
    if (!open) formik.resetForm();
  }, [open]);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <Header
          title="Criar valor empresarial"
          subTitle=" Insira valores empresariais que orientarão a realização dos feedbacks."
          onClose={onClose}
        />
      }
      footer={
        <Footer
          submitTitle="Criar valor"
          isLoading={isLoading}
          onSubmit={() => formik.handleSubmit()}
          onClose={onClose}
        />
      }
    >
      <ContentContainer>
        <CompanyValueForm formik={formik} />
      </ContentContainer>
    </StyledModal>
  );
};
