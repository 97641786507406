import { Avatar, Drawer } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 600px;
  }
`;

export const Header = styled.div`
  padding: 39px;
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

export const Body = styled.div`
  padding: 39px 40px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
  width: 57px;
  height: 57px;
  font-size: 32px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 18px;
`;

export const NameContainer = styled.div``;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  align-items: top;

  margin-bottom: 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 24px;
`;

export const ButtonContaier = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 29px;

  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;
