import styled from "styled-components";

import { Modal } from "@components/Modal";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      width: 100%;
      max-width: 872px !important;
      max-height: 95vh;
    }

    .modal-content-area {
      padding: 40px 112px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 44px 24px 40px 112px;
  border-style: hidden hidden solid hidden;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CharCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export {
  StyledModal,
  Footer,
  Header,
  FlexColumn,
  CharCountContainer,
  FormContainer,
};
