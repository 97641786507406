import { PillButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledSpan, StyledTitle, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import {
  Body,
  FlexRow,
  Header,
  MainContainer,
  NameContainer,
  FlexContainer,
  StyledAvatar,
  StyledDrawer,
  HeaderButtons,
  EvaluationContainer,
  FlexBetween,
} from "./styled";

import { HistoryTimeline } from "./history-timeline";
type Props = {
  isOpen: boolean;
  data: {
    average: undefined | number;
    evaluatedName: string;
    evaluationName: string;
    reportsToName: string;
    evaluationId: string;
    evaluatedId: string;
  };
  onClose: () => void;
};

export const CalibrationDetailsDrawer = ({ isOpen, onClose, data }: Props) => {
  const {
    evaluationId,
    evaluatedName,
    reportsToName,
    evaluatedId,
    evaluationName,
    average,
  } = data;

  const { data: calibrationHistory = [], isLoading } =
    trpc.performance.evaluation.getCalibrationHistory.useQuery(
      {
        evaluationId: evaluationId,
        evaluatedId: evaluatedId,
      },
      {
        onError: () =>
          dispatchToast({
            content: "Erro ao buscar histórico. Tente novamente em breve.",
            type: "error",
          }),
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!evaluationId && !!evaluatedId,
      }
    );

  const historyData = calibrationHistory.map((history) => {
    return {
      previousGrade: history?.previous,
      currentGrade: history?.updatedTo,
      justification: history?.reason || "Nenhuma justificativa adicionada.",
    };
  });

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexRow>
            <StyledTitle variant="body1" children={"Detalhes"} />
            <HeaderButtons>
              <PillButton
                variant="default"
                size="small"
                icon="IconX"
                type="secondary"
                onClick={() => onClose()}
              />
            </HeaderButtons>
          </FlexRow>

          <FlexContainer>
            <StyledAvatar children={evaluatedName?.charAt(0)} />
            <NameContainer>
              <StyledTitle
                variant="body1"
                setColor="neutral30"
                children={evaluatedName}
              />

              {!!reportsToName && (
                <StyledTitle variant="body3" setColor="primary">
                  <StyledSpan setColor="neutral30">Reporta para </StyledSpan>
                  {reportsToName}
                </StyledTitle>
              )}
            </NameContainer>
          </FlexContainer>
          <FlexBetween>
            <EvaluationContainer>
              <StyledTitle
                variant="body4"
                setColor="neutral40"
                children="Avaliação"
              />
              <StyledTitle
                variant="body3"
                setColor="neutral40"
                children={evaluationName || ""}
              />
            </EvaluationContainer>
            <EvaluationContainer>
              <StyledTitle
                variant="body4"
                setColor="neutral40"
                children="Nota final da avaliação"
              />
              <StyledTitle
                variant="body3"
                setColor="neutral40"
                children={average !== undefined ? average : "-"}
              />
            </EvaluationContainer>
          </FlexBetween>
        </Header>
        <Body>
          <StyledTitle
            variant="body2"
            setColor="neutral30"
            children="Histórico"
          />
          {isLoading ? (
            <div>
              <Skeleton width={"80%"} height={"300px"} />
            </div>
          ) : (
            <HistoryTimeline data={historyData} />
          )}
        </Body>
      </MainContainer>
    </StyledDrawer>
  );
};
