import { Modal } from "@components/Modal";

import styled from "styled-components";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      width: 90%;
      max-width: 978px !important;
    }
  }
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterContainer = styled(FlexBetween)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`;

const HeaderContainer = styled(FlexBetween)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 40px 24px 40px 112px;
`;

const TitleContainer = styled.div`
  max-width: 546px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContentContainer = styled.div`
  padding: 0px 40px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const Row = styled(FlexBetween)`
  width: 100%;
  margin-bottom: 25px;
`;

const Span = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export {
  StyledModal,
  FooterContainer,
  HeaderContainer,
  TitleContainer,
  ContentContainer,
  Span,
  Row,
  FormContainer,
};
