import styled from "styled-components";
import { Modal } from "@components/Modal";

export const StyledModal = styled(Modal)`
  && {
    .modal-container {
      width: 90%;
      max-width: 978px !important;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 0px 40px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Row = styled(FlexBetween)`
  width: 100%;
  margin-bottom: 25px;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;
