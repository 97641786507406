import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { TableGrid } from "./TableGrid";

import { PageContainer } from "./styled";

import { Table } from "@components/Table";

import type { Filter } from "@components/TableFilters/types";

interface EvaluationsProps {
  cycleId: string;
}

export const Evaluations = ({ cycleId }: EvaluationsProps) => {
  const { data, isLoading } =
    trpc.performance.evaluation.getEmployeeEvaluationByCycleId.useQuery(
      { cycleId: cycleId },
      {
        enabled: !!cycleId,
        retry: false,
        retryDelay: 3000,
        staleTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar as informações do usuário. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  return (
    <PageContainer>
      <Table
        data={data?.evaluations || []}
        filters={(data?.filters as Filter[]) || []}
        loading={isLoading}
        searchable={true}
        searchableField="name"
        searchPlaceholder={"Buscar por avaliações"}
        tableGridRender={(props) => <TableGrid {...props} />}
      />
    </PageContainer>
  );
};
