import { useMemo } from "react";
import {
  Dot,
  PillButton,
  Icons,
  Menu,
  Tab,
  dayjs,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { Values } from "./Tabs/Values";
import { Feedback } from "./Tabs/Feedback";
import { Competences } from "./Tabs/Competences";

import {
  Body,
  FlexRow,
  Header,
  MainContainer,
  NameContainer,
  FlexContainer,
  Span,
  StyledAvatar,
  StyledDrawer,
  Column,
  Tag,
  HeaderButtons,
} from "./styled";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  feedbackData: any;
  permission: string;
  refetch?: () => void;
};
export const FeedbackDrawer = ({
  isOpen,
  onClose,
  feedbackData,
  permission,
  refetch,
}: Props) => {
  const { data: feedback, isFetching: loading } =
    trpc.performance.feedback.getFeedbackFull.useQuery(
      {
        _id: feedbackData._id || "",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!feedbackData._id,
      }
    );

  const { mutate, isLoading } =
    trpc.performance.feedback.shareFeedback.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: "Feedback compartilhado com sucesso.",
          type: "success",
        });
        refetch && refetch();
      },
      onError: () =>
        dispatchToast({
          content: "Erro ao compartilhar o Feedback. Tente novamente em breve.",
          type: "error",
        }),
    });

  const renderTag = (status: string) => {
    switch (status) {
      case "answered":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"green"}>
              <Dot variant={"success"} />
              <StyledTitle variant="caption">Respondido</StyledTitle>
            </Tag>
          </div>
        );
      case "draft":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"blue"}>
              <Dot variant={"blue"} />
              <StyledTitle variant="caption">Rascunho</StyledTitle>
            </Tag>
          </div>
        );
      case "unanswered":
      default:
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"yellow"}>
              <Dot variant={"yellow"} />
              <StyledTitle variant="caption">Não respondido</StyledTitle>
            </Tag>
          </div>
        );
    }
  };

  const tabItems = useMemo(() => {
    const items = [
      {
        component: (
          <Feedback
            data={feedback?.feedback || feedbackData || {}}
            feedbackRequest={feedback?.request || {}}
            permission={permission}
            onCloseDrawer={onClose}
            refetch={() => refetch?.()}
          />
        ),
        label: "Feedback",
      },
    ];

    if (feedback?.feedback?.evaluationCriterials?.length)
      items.push({
        component: (
          <Competences
            competences={feedback?.feedback?.evaluationCriterials || []}
          />
        ),
        label: "Critérios Avaliativos",
      });

    if (feedback?.feedback?.companyValues?.length)
      items.push({
        component: <Values values={feedback?.feedback?.companyValues || []} />,
        label: "Valores",
      });

    return items;
  }, [feedbackData, feedback]);

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexRow>
            <StyledTitle variant="body1" children={"Feedback"} />
            <HeaderButtons>
              {permission === "answer" && (
                <Menu
                  type={"select"}
                  options={[
                    {
                      onClick: () => {
                        feedbackData.privacy !== "leader" &&
                          mutate({
                            feedbackId: feedbackData._id,
                          });
                      },
                      children:
                        feedbackData.privacy === "leader" ? (
                          <FlexContainer>
                            <Icons name="IconShare" fill="transparent" />
                            <StyledTitle variant="body3">
                              Feedback compartilhado com o líder
                            </StyledTitle>
                          </FlexContainer>
                        ) : (
                          <FlexContainer>
                            <Icons name="IconShare" fill="transparent" />
                            <StyledTitle variant="body3">
                              Compartilhar com líder
                            </StyledTitle>
                          </FlexContainer>
                        ),
                    },
                  ]}
                  disableAutoFocusItem={true}
                  disabled={isLoading}
                  anchorOrigin={{ vertical: "center", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <PillButton
                    variant="default"
                    size="small"
                    type="secondary"
                    icon="IconDotsVertical"
                  />
                </Menu>
              )}

              <PillButton
                variant="default"
                size="small"
                icon="IconX"
                type="secondary"
                onClick={onClose}
              />
            </HeaderButtons>
          </FlexRow>

          <FlexContainer>
            <StyledAvatar children={feedbackData?.to?.name.charAt(0)} />
            <NameContainer>
              <StyledTitle variant="body1" setColor="neutral30">
                {feedbackData?.to?.name} <Span>recebeu um</Span>
              </StyledTitle>

              <StyledTitle variant="body3" setColor="neutral30">
                <Span>feedback de </Span>
                {feedbackData?.from?.name}
              </StyledTitle>
            </NameContainer>
          </FlexContainer>
          <FlexContainer style={{ marginTop: "22px" }}>
            <Column style={{ marginRight: "40px" }}>
              <StyledText
                variant="body4"
                children={"Status do feedback"}
                style={{ fontWeight: "600" }}
              />
              {renderTag(
                feedback?.feedback?.status || feedbackData?.status || ""
              )}
            </Column>
            <Column>
              <StyledTitle variant="body4" children={"Enviado em"} />
              <StyledTitle variant="body3" style={{ marginBottom: "4px" }}>
                {dayjs(feedbackData?.createdAt).isValid()
                  ? dayjs(feedbackData?.createdAt).format("DD/MM/YY")
                  : "-"}
              </StyledTitle>
            </Column>
          </FlexContainer>
        </Header>
        <Body>
          {loading ? (
            <Skeleton variant="rectangular" height={"500px"} />
          ) : (
            <Tab tabItens={tabItems} />
          )}
        </Body>
      </MainContainer>
    </StyledDrawer>
  );
};
