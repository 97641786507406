import { useMemo } from "react";

import { Icons, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

import { ProfileTable } from "@components/ProfileTable";

import {
  ArrowIcon,
  GridDescription,
  GridIcon,
  GridItem,
  GridTitle,
  InfoGrid,
  Label,
  PeopleArea,
  FlexCenter,
} from "./styled";

import type { EmployeeEvaluationDetails } from "server/src/services/evaluation/types";

interface InfoAreaProps {
  evaluator?: EmployeeEvaluationDetails["evaluator"];
  evaluated?: EmployeeEvaluationDetails["evaluated"];
  insights?: EmployeeEvaluationDetails["insights"];
}

export const InfoArea = ({ evaluator, evaluated, insights }: InfoAreaProps) => {
  const labelByType = {
    self: "Nota da autoavaliação",
    byLeader: "Nota da avaliação do líder",
    byLed: "Nota da avaliação do liderado",
  };

  const handleDate = useMemo(() => {
    const dayjsDate = dayjs(insights?.finishedAt);

    if (!insights?.finishedAt) return { date: "", hour: "" };

    const date = dayjsDate.format("DD/MM/YY");
    const hour = dayjsDate.format("HH:mm");

    return { date: date, hour: hour + "h" };
  }, [insights]);

  return (
    <div style={{ marginBottom: "16px" }}>
      <InfoGrid>
        <PeopleArea>
          <FlexCenter>
            <Label variant="body4">Avaliador</Label>
            <ProfileTable
              name={evaluator?.name || " - "}
              email={evaluator?.email || " - "}
            />
          </FlexCenter>

          <ArrowIcon name="IconArrowRight" fill="transparent" />

          <FlexCenter>
            <Label variant="body4">Avaliado</Label>
            <ProfileTable
              name={evaluated?.name || " - "}
              email={evaluator?.email || " - "}
            />
          </FlexCenter>
        </PeopleArea>
      </InfoGrid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <GridItem>
            <GridIcon>
              <Icons name="IconReportAnalytics" fill="transparent" size={25} />
            </GridIcon>
            <div>
              <GridTitle
                variant="body3"
                children={labelByType[insights?.type || ""]}
              />
              <GridDescription variant="headline7" children={insights?.grade} />
            </div>
          </GridItem>
        </Grid>

        <Grid item xs={12} sm={6}>
          <GridItem>
            <GridIcon>
              <Icons name="IconReportAnalytics" fill="transparent" size={25} />
            </GridIcon>
            <div>
              <GridTitle variant="body3">Data de conclusão</GridTitle>
              <GridDescription variant="headline7">
                {handleDate.date}
                <span>{handleDate.hour}</span>
              </GridDescription>
            </div>
          </GridItem>
        </Grid>
      </Grid>
    </div>
  );
};
