import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Tag = styled.div<{ setColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 14px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[10];
      case "yellow":
        return theme.colors.feedback.error[10];
      case "gray":
        return theme.colors.neutral[10];
      case "blue":
        return theme.colors.feedback.info[10];
      case "secondary":
        return theme.colors.secondary[50];
      default:
        return "";
    }
  }};

  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[90];
      case "yellow":
        return theme.colors.feedback.error[90];
      case "gray":
        return theme.colors.neutral[90];
      case "blue":
        return theme.colors.feedback.info[90];
      case "secondary":
        return theme.colors.secondary[95];
      default:
        return "";
    }
  }};
`;

export const AccordionTitle = styled(Typography)`
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  margin-top: 10px;
  line-height: 116%;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const AccordionInfo = styled.div`
  display: flex;
`;

export const TextInfo = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-right: 16px;
  svg {
    margin-right: 6px;
  }
`;

export const AccordionArea = styled.div`
  .MuiAccordion-rounded,
  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    border: none !important;
    padding: 8px;
  }
  .MuiAccordionDetails-root {
    padding: 16px;
  }
`;

export const Label = styled(Typography)`
  font-weight: 600 !important;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const InfoText = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const WarnText = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  margin-bottom: 16px;
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;
