import styled from "styled-components";

const CardWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export { CardWrapper, HeaderContainer, TitleContainer, SectionsContainer };
