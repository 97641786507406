import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { Modal } from "@mui/material";

import styled, { css } from "styled-components";

const StyledModal = styled(Modal)`
  &.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    outline: none;
  }
`;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: #ffffff;
  outline: 0;
  outline: none;
  max-height: 80vh;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: -20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 995;
`;

const ContentArea = styled.div`
  padding: 56px 72px;
  width: 100%;
  height: 100%;
  overflow: auto;
  word-wrap: break-word;
`;

const Footer = styled.div<{ disableCancel?: boolean }>`
  display: flex;
  justify-content: ${({ disableCancel }) =>
    !disableCancel ? "center" : "space-between"};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`;

const errorColor = css`
  color: ${({ theme }) => theme.colors.feedback.error[40]} !important;
  background: ${({ theme }) => theme.colors.feedback.error[90]} !important;
`;

const StyledButton = styled(Button)`
  ${(props) =>
    props.variant === "primary" &&
    props.variantType === "error" &&
    !props.loading &&
    !props.disabled &&
    errorColor}
`;

export {
  StyledModal,
  Container,
  IconContainer,
  ContentArea,
  Footer,
  StyledButton,
};
