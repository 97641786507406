import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Drawer } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div``;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 600px;
  }
`;

export const Header = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

export const Body = styled.div`
  padding: 32px 40px;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const Tag = styled.div<{ setColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 14px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[10];
      case "yellow":
        return theme.colors.feedback.error[10];
      case "gray":
        return theme.colors.neutral[10];
      case "blue":
        return theme.colors.feedback.info[10];
      case "secondary":
        return theme.colors.secondary[50];
      default:
        return "";
    }
  }};

  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[90];
      case "yellow":
        return theme.colors.feedback.error[90];
      case "gray":
        return theme.colors.neutral[90];
      case "blue":
        return theme.colors.feedback.info[90];
      case "secondary":
        return theme.colors.secondary[95];
      default:
        return "";
    }
  }};
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  margin-right: 12px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary[50]};
  font-size: 14px;
  width: 100%;
`;

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const PlanDescription = styled(Typography)`
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const PlanGoal = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DeleteOption = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;
