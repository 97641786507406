import { InfoLabel, InfoText, SectionTitle } from "./styled";

export const MoreInfo = ({ data }) => {
  return (
    <div>
      {/* <SectionTitle variant="headline8">Dados complementares</SectionTitle> */}
      {/* <InfoArea>
        <div style={{ marginRight: "80px" }}>
          <InfoLabel variant="caption">Departamento</InfoLabel>
          <InfoText variant="body3" style={{ marginBottom: 0 }}>
            Martketing
          </InfoText>
        </div>
        <div>
          <InfoLabel variant="caption">Gestor</InfoLabel>
          <InfoText variant="body3" style={{ marginBottom: 0 }}>
            Marcela Lima
          </InfoText>
        </div>
      </InfoArea>
      <Divider /> */}
      <SectionTitle variant="headline8">Dados do plano de ação</SectionTitle>
      <div>
        <InfoLabel variant="caption">Objetivo</InfoLabel>
        <InfoText variant="body3">{data?.goal}</InfoText>
      </div>
      <div>
        <InfoLabel variant="caption">Tipo</InfoLabel>
        <InfoText variant="body3">
          {data?.type === "independent"
            ? "Independente"
            : "Atrelado à avaliação"}
        </InfoText>
      </div>
      <div>
        <InfoLabel variant="caption">Criado por</InfoLabel>
        <InfoText variant="body3">{data?.createdBy?.name}</InfoText>
      </div>
    </div>
  );
};
