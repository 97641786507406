import styled from "styled-components";

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 58px;
  height: 48px;

  background-color: #ffff;
  border-radius: 200px;
  gap: 8px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 200px;
`;

export const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
`;

export const DataGridContainer = styled.div`
  .data-grid-custom-select div.MuiSelect-select {
    padding-right: 36px !important;
  }

  .select-icon-custom svg {
    top: calc(50% - 0.7em);
    right: 5px;
  }
`;
