import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTab = styled(Tab)`
  && {
    &.tabs-container.MuiTabs-root .MuiTabs-fixed {
      margin-left: 0px;
    }
  }
`;
