import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin: 40px 0px 32px 0px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OptionsArea = styled.div`
  width: 100%;
  max-width: 290px;
  display: flex;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: none;
    justify-content: center;
  }
`;
export const CreateButton = styled(Button)`
  width: 200px;
`;
