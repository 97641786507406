import styled, { css } from "styled-components";

import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 80px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral90};
  z-index: 2;
  ${() => css`
    @media screen and (max-width: ${({ theme }) =>
        `${theme.breakpoints.values.md}px`}) {
      flex-direction: column;
      gap: 20px;
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledLinkButton = styled(LinkButton)`
  margin: auto 0;
`;

export const BackButton = styled(Button)`
  && {
    width: 200px;
    margin-left: 20px;
  }
`;
export const ContinueButton = styled(Button)`
  && {
    width: 200px;
  }
`;
