import styled from "styled-components";
import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";

export const Search = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }
  width: 100%;
  height: 60px;
`;

export const Container = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  @media screen and (max-width: 1040px) {
    flex-direction: column-reverse;
    & button {
      align-self: center !important;
    }
  }
`;
