import { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash-es";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";
import { SearchField } from "@components/SearchField";
import { ModelDetailsDrawer } from "@components/Drawers";

import { BlankCard } from "./components/BlankCard";
import { ModelCard } from "./components/ModelCard";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { StyledTitle, StyledText, dispatchToast, track } from "@utils";

import { MainContainer, TextContainer, ModelsContainer } from "./styled";

import { Header } from "../../Steps/styled";

import type { BasicStepProps } from "../../Steps/types";

export const ModelStep = ({ isEdit, steps, breadcrumbs }: BasicStepProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [search, setSearch] = useState("");

  const [detailsDrawer, setDetailsDrawer] = useState({
    open: false,
    modelId: "",
  });

  const { data: evaluationModels = [], isFetching: loadingModels } =
    trpc.performance.evaluation.getEvaluationsModels.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar os modelos! tente novamente mais tarde",
        });
      },
    });

  const filteredModels = useMemo(() => {
    if (!search) return evaluationModels || [];

    const clonedModels = cloneDeep(evaluationModels || []);

    return clonedModels.filter((item) => {
      const byName = item?.name?.toLowerCase().includes(search.toLowerCase());
      return byName;
    });
  }, [search, evaluationModels]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 0,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair",
          callback: () => navigate(routes.PageManageEvaluations),
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <MainContainer>
        <TextContainer>
          <StyledTitle
            setColor="secondary50"
            variant="headline7"
            children={"Modelos de Avaliação de Desempenho"}
          />
          <StyledText
            setColor="neutral50"
            variant="body3"
            children={
              "Tem dúvidas sobre como estruturar uma avaliação de desempenho ou precisa de modelos prontos para agilizar o processo?  Conheça nossos modelos prontos de avaliação de performance, desenvolvidos seguindo as principais recomendações e práticas do mercado. Aproveite para simplificar a jornada de avaliação utilizando nossos modelos pré-definidos e realize avaliações mais rápidas e estruturadas."
            }
          />
        </TextContainer>

        <SearchField onChange={setSearch} disabled={loadingModels} />

        <ModelsContainer>
          <BlankCard
            icon="IconPlus"
            title="Criar avaliação em branco"
            onClick={() =>
              navigate(routes.PageCreateEvaluation("basic-info"), {
                state: { cycleId: state as string },
              })
            }
          />
          {loadingModels ? (
            <>
              <Skeleton height={"282px"} width={"284px"} />
              <Skeleton height={"282px"} width={"284px"} />
            </>
          ) : (
            <>
              {filteredModels.map((model) => (
                <ModelCard
                  key={model._id}
                  icon={
                    model.name === "Habilidades em Foco"
                      ? "IconTarget"
                      : "IconDeviceDesktopAnalytics"
                  }
                  name={model.name}
                  description={model.description}
                  onDetailsClick={() => {
                    track({
                      name:
                        model.name === "Habilidades em foco"
                          ? "people_strategic_hr_performance_company_evaluations_createevaluation_model_skillsinfocus_details_clicked"
                          : "people_strategic_hr_performance_company_evaluations_createevaluation_model_technologytalents_details_clicked",
                    });
                    setDetailsDrawer({ open: true, modelId: model._id || "" });
                  }}
                  onUseClick={() => {
                    track({
                      name:
                        model.name === "Habilidades em foco"
                          ? "people_strategic_hr_performance_company_evaluations_createevaluation_model_skillsinfocus_use_clicked"
                          : "people_strategic_hr_performance_company_evaluations_createevaluation_model_technologytalents_use_clicked",
                    });
                    navigate(routes.PageCreateEvaluation("basic-info"), {
                      state: { modelId: model._id, cycleId: state as string },
                    });
                  }}
                />
              ))}
            </>
          )}
        </ModelsContainer>
      </MainContainer>

      <ModelDetailsDrawer
        isOpen={detailsDrawer.open}
        onClose={() => setDetailsDrawer({ open: false, modelId: "" })}
        modelId={detailsDrawer.modelId}
        onModelSelected={() =>
          navigate(routes.PageCreateEvaluation("basic-info"), {
            state: { modelId: detailsDrawer.modelId },
          })
        }
      />
    </PageTemplate>
  );
};
