import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0px;
`;
export const LeftContainer = styled.div``;
export const RightContainer = styled.div``;
