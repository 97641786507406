import { TableTag, ITableTagProps } from "@components/TableTag";

interface IRenderTag {
  status?:
    | "active"
    | "finished"
    | "in_calibration"
    | "calibration_finished"
    | "scheduled"
    | "draft"
    | "disabled";
}

export const RenderTag = ({ status }: IRenderTag) => {
  if (!status) return <></>;

  let tagInfos = {
    variant: "gray" as ITableTagProps["variant"],
    label: " - ",
  };

  switch (status) {
    case "active":
      tagInfos = { variant: "green", label: "Avaliação ativa" };
      break;
    case "finished":
      tagInfos = { variant: "gray", label: "Avaliação encerrada" };
      break;
    case "in_calibration":
      tagInfos = { variant: "gray", label: "Em calibração" };
      break;
    case "calibration_finished":
      tagInfos = { variant: "gray", label: "Calibração encerrada" };
      break;
    case "scheduled":
      tagInfos = { variant: "blue", label: "Avaliação agendada" };
      break;
    case "draft":
      tagInfos = { variant: "yellow", label: "Avaliação em rascunho" };
      break;
  }

  return (
    <TableTag
      label={tagInfos.label}
      variant={tagInfos.variant}
      hasDot={true}
      style={{ alignSelf: "center", flexShrink: 0 }}
    />
  );
};
