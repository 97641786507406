import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Button,
  PillButton,
  LinkButton,
  Skeleton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ConfirmationModal } from "@components/Modals";
import { TextEditor } from "@components/TextEditor";

import { trpc } from "@api/client";

import {
  StyledSpan,
  StyledText,
  StyledTitle,
  dispatchToast,
  track,
  removeHtmlTags,
} from "@utils";

import {
  Body,
  FlexRow,
  Header,
  MainContainer,
  NameContainer,
  FlexContainer,
  StyledAvatar,
  StyledDrawer,
  HeaderButtons,
  EvaluationContainer,
  FieldsContainer,
  TextContainer,
  ButtonContaier,
} from "./styled";

type ChangedField = {
  _id: string;
  newGrade: number;
  newJustification: string;
};

type CalibrationDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
  onSubmit: ({ _id, newGrade, newJustification }: ChangedField) => void;
};

const EDITOR_MAX_LENGTH = 3000;

const validationSchema = yup.object({
  calibrationAverage: yup
    .number()
    .required("Digite uma nota válida entre 1.0 e 5.0")
    .min(1, "Digite uma nota válida entre 1.0 e 5.0")
    .max(5, "Digite uma nota válida entre 1.0 e 5.0")
    .typeError("Digite uma nota válida entre 1.0 e 5.0"),
  calibrationJustification: yup
    .string()
    .optional()
    .test(
      "len",
      `O texto deve ter até ${EDITOR_MAX_LENGTH} caracteres`,
      (val) => removeHtmlTags(val).length <= EDITOR_MAX_LENGTH
    ),
});

export const CalibrationDrawer = ({
  isOpen,
  onClose,
  data,
  onSubmit,
}: CalibrationDrawerProps) => {
  const [openModal, setOpenModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      calibrationAverage: "",
      calibrationJustification: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newCalibrationAverage = values?.calibrationAverage?.toString();
      const oldCalibrationAverage = data?.calibrationAverage?.toString();

      const average = data?.average?.toString();

      if (!oldCalibrationAverage && newCalibrationAverage === average)
        return formik.setFieldError(
          "calibrationAverage",
          `Digite uma nota diferente da nota final atual`
        );
      if (
        oldCalibrationAverage &&
        newCalibrationAverage === oldCalibrationAverage
      )
        return formik.setFieldError(
          "calibrationAverage",
          `Digite uma nota diferente da nota calibrada atual`
        );

      mutate({
        evaluationId: data.evaluationId,
        evaluatedId: data.evaluatedId,
        params: {
          calibrationAverage: Number(values.calibrationAverage),
          calibrationJustification: values.calibrationJustification || "",
        },
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      calibrationAverage: data?.calibrationAverage || "",
      calibrationJustification: data?.calibrationJustification || "",
    });
  }, [data]);

  const { mutate, isLoading } =
    trpc.performance.evaluation.calibrateEvaluation.useMutation({
      onSuccess: (_, variables) => {
        dispatchToast({
          content: "Nota final calibrada com sucesso.",
          type: "success",
        });

        onSubmit({
          _id: variables?.evaluatedId,
          newGrade: variables?.params?.calibrationAverage,
          newJustification: variables?.params?.calibrationJustification || "",
        });

        setTimeout(() => {
          onClose();
        }, 800);
      },
      onError: () => {
        dispatchToast({
          content:
            "Erro ao salvar nota final calibrada, tente novamente em breve.",
          type: "error",
        });
      },
    });

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexRow>
            <StyledTitle variant="body1" children={"Calibrar"} />
            <HeaderButtons>
              <PillButton
                variant="default"
                size="small"
                icon="IconX"
                type="secondary"
                onClick={() => onClose()}
              />
            </HeaderButtons>
          </FlexRow>

          <FlexContainer>
            <StyledAvatar children={data?.evaluatedName?.charAt(0)} />
            <NameContainer>
              <StyledTitle
                variant="body1"
                setColor="neutral30"
                children={data?.evaluatedName}
              />

              {!!data?.reportsToName && (
                <StyledTitle variant="body3" setColor="primary">
                  <StyledSpan setColor="neutral30">Reporta para </StyledSpan>
                  {data?.reportsToName}
                </StyledTitle>
              )}
            </NameContainer>
          </FlexContainer>
          <EvaluationContainer>
            <StyledTitle
              variant="body4"
              setColor="neutral40"
              children="Avaliação"
            />
            <StyledTitle
              variant="body3"
              setColor="neutral40"
              children={data?.evaluationName || ""}
            />
          </EvaluationContainer>
        </Header>
        <Body>
          {isLoading ? (
            <Skeleton variant="rectangular" height={"500px"} />
          ) : (
            <>
              <TextContainer>
                <StyledTitle
                  variant="body2"
                  setColor="neutral30"
                  children="Calibrar nota"
                />
                <StyledText
                  variant="body3"
                  setColor="neutral50"
                  children="Calibre a média geral do avaliado e insira uma descrição opcional para contextualizar o motivo da calibração da nota."
                />
              </TextContainer>
              <FieldsContainer>
                <TextField
                  label="Nota final atual"
                  fullWidth
                  value={data?.average}
                  disabled={true}
                />
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Nota calibrada"
                    fullWidth
                    value={formik?.values?.calibrationAverage}
                    imaskProps={{
                      mask: Number,
                      scale: 1,
                      radix: ".",
                      mapToRadix: ["."],
                      min: 0,
                      max: 5,
                    }}
                    onChange={(e) => {
                      formik.handleChange({
                        target: {
                          id: "calibrationAverage",
                          value: e.target.value,
                        },
                      });
                    }}
                    error={
                      formik?.touched?.calibrationAverage &&
                      Boolean(formik?.errors?.calibrationAverage)
                    }
                    helperText={
                      formik?.touched?.calibrationAverage &&
                      (formik?.errors?.calibrationAverage as string)
                    }
                  />
                </div>
              </FieldsContainer>
              <TextEditor
                styles={{
                  container: { width: "100%" },
                  editor: { height: "200px" },
                }}
                value={formik.values.calibrationJustification}
                placeholder="Descreva a justificativa (Opcional)"
                error={
                  formik?.touched?.calibrationJustification &&
                  Boolean(formik?.errors?.calibrationJustification)
                }
                onChange={(value) => {
                  formik.handleChange({
                    target: { id: "calibrationJustification", value },
                  });
                }}
                maxLength={EDITOR_MAX_LENGTH}
                helperText={formik?.errors?.calibrationJustification}
              />
            </>
          )}
        </Body>
      </MainContainer>
      <ButtonContaier>
        <LinkButton
          variant="default"
          children="Cancelar"
          style={{ alignSelf: "center" }}
          disabled={isLoading}
          onClick={() => {
            track({
              name: "performance_evaluation_evaluationdetail_results_calibrate_cancelcalibration_clicked",
            });
            setOpenModal(true);
          }}
        />
        <Button
          variant="primary"
          size="large"
          children="Calibrar nota"
          onClick={() => {
            track({
              name: "performance_evaluation_evaluationdetail_results_calibrate_confirmcalibration_clicked",
            });
            formik.handleSubmit();
          }}
          loading={isLoading}
          disabled={isLoading}
        />
      </ButtonContaier>
      <ConfirmationModal
        open={openModal}
        showWarning
        secondaryVariant="default"
        icon="IconAlertCircle"
        title="Deseja sair sem salvar as modificações?"
        subTitle="Você perderá todas as configurações feitas."
        confirm={{
          title: "Sair sem salvar",
          icon: "IconArrowRight",
          iconColor: "neutral100",
        }}
        onClose={() => setOpenModal(false)}
        onConfirm={() => {
          setOpenModal(false);
          onClose();
        }}
      />
    </StyledDrawer>
  );
};
