import { StyledIcon, StyledTitle } from "@utils";
import { MainContainer } from "./styled";

export const RenderReaction = ({ reaction }) => {
  switch (reaction) {
    case "liked":
      return (
        <MainContainer>
          <StyledIcon fill="none" name="IconThumbUp" setColor="info40" />
          <StyledTitle variant="body4" children={"Gostei"} setColor="info40" />
        </MainContainer>
      );
    case "loved":
      return (
        <MainContainer>
          <StyledIcon fill="none" name="IconHeart" setColor="tertiary40" />
          <StyledTitle
            variant="body4"
            children={"Amei"}
            setColor="tertiary40"
          />
        </MainContainer>
      );
    case "support":
      return (
        <MainContainer>
          <StyledIcon
            fill="none"
            name="IconHeartHandshake"
            setColor="success40"
          />
          <StyledTitle
            variant="body4"
            children={"Apoio"}
            setColor="success40"
          />
        </MainContainer>
      );
    case "congratulate":
      return (
        <MainContainer>
          <StyledIcon fill="none" name="IconConfetti" setColor="error40" />
          <StyledTitle
            variant="body4"
            children={"Parabéns"}
            setColor="error40"
          />
        </MainContainer>
      );
    default:
      return (
        <MainContainer>
          <StyledIcon fill="none" name="IconMoodHappy" setColor="neutral40" />
          <StyledTitle
            variant="body4"
            children={"Reagir"}
            setColor="neutral40"
          />
        </MainContainer>
      );
  }
};
