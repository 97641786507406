import { useState, useEffect } from "react";
import { trpc } from "@api/client";

import { PaginationTable } from "@components/PaginationTable";
import { PaginationProps } from "@components/PaginationTable/types";

import { TableGrid } from "../TableGrid";

import { StyledTitle, dispatchToast } from "@utils";

import { filters } from "./utils";

import { TableArea } from "./styled";

type GetAllCyclesFilters =
  | {
      status?: ("closed" | "scheduled" | "available")[];
      steps?: ("evaluation" | "calibration" | "idp" | "feedback")[];
      startDate?: { startDate: string; endDate?: string };
      endDate?: { startDate: string; endDate?: string };
      createdAt?: { startDate: string; endDate?: string };
    }
  | undefined;

export const OtherCycles = () => {
  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    pageSize: 5,
    totalCount: 0,
  });

  const [search, setSearch] = useState("");

  const [filtersInfo, setFiltersInfo] =
    useState<GetAllCyclesFilters>(undefined);

  const { data: cycles, isFetching } =
    trpc.performance.cycle.getAllCycles.useQuery(
      {
        params: {
          page: pagination?.page ?? 1,
          pageSize: pagination?.pageSize ?? 20,
          search: search ? search : undefined,
          order: "desc",
          filters: filtersInfo ? filtersInfo : undefined,
        },
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: (data) => {
          setPagination({
            page: data?.page,
            pageSize: data?.pageSize,
            totalCount: data?.total,
          });
        },
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar ciclos, tente novamente em breve.",
          });
        },
      }
    );

  useEffect(() => {
    if (!cycles) return;

    if (cycles.total === pagination.totalCount) return;

    setPagination({
      page: cycles.page,
      pageSize: cycles.pageSize,
      totalCount: cycles.total,
    });
  }, [cycles?.total]);

  return (
    <TableArea>
      <StyledTitle
        variant="headline7"
        weight={700}
        setColor="neutral30"
        style={{ marginBottom: "16px" }}
      >
        Outros ciclos
      </StyledTitle>
      <PaginationTable
        loading={isFetching}
        data={cycles?.items || []}
        pagination={pagination}
        filters={filters}
        isFiltered={!!search || !!filtersInfo}
        searchPlaceholder="Buscar por ciclos"
        tableGridRender={(props) => <TableGrid {...props} />}
        onSearch={setSearch}
        onPaginationChanged={(page, pageSize) => {
          setPagination((prev) => ({ ...prev, page, pageSize }));
        }}
        onFiltersChanged={({
          status,
          steps,
          startDate,
          endDate,
          createdAt,
        }) => {
          setFiltersInfo({
            status: status ? status : undefined,
            steps: steps ? steps : undefined,
            startDate: startDate ? startDate[0] : undefined,
            endDate: endDate ? endDate[0] : undefined,
            createdAt: createdAt ? createdAt[0] : undefined,
          });
        }}
      />
    </TableArea>
  );
};
