import { getAccessTokenLocal } from "@flash-tecnologia/hros-web-utility";

type AccessTokenPayload = {
  economicGroupId: string;
  employeeId: string;
  exp: number;
  sub: string;
};

function parseJwt(token: string) {
  const base64Url = token.split(".")[1] || "";
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/") || "";
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  if (!jsonPayload) return null;

  return JSON.parse(jsonPayload);
}

export const getAccessTokenSync = () => {
  const accessToken = getAccessTokenLocal();
  return parseJwt(accessToken?.accessToken || "") as AccessTokenPayload;
};
