import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import { DataCard } from "@components/Cards";

import { CardTable } from "../../CardTable";

import { RequiredAsterisk, StyledText, StyledTitle } from "@utils";

import { MainContainer } from "../styled";

import type { Evaluation } from "server/src/services/evaluation/types";
interface ISettingsProps {
  data?: Evaluation;
}

export const Settings = ({ data }: ISettingsProps) => {
  const evaluationTypes = [
    {
      type: "byLeader",
      title: "Líder avalia liderado",
      description: (
        <>
          <p>
            Os participantes selecionados serão avaliados por seus líderes
            diretos.
          </p>
          <p>
            <RequiredAsterisk />
            Apenas a <b>nota do líder</b> (líder avalia liderado) será
            considerada no cálculo da <b>nota final</b> e poderá ser calibrada.
          </p>
        </>
      ),
    },
    {
      type: "self",
      title: "Participante se autoavalia",
      description: (
        <p>
          Os participantes selecionados realizam a avaliação de sua própria
          performance.
        </p>
      ),
    },
    {
      type: "byLed",
      title: "Liderado avalia líder",
      description: (
        <p>
          Os participantes selecionados serão avaliados por seus liderados
          diretos.
        </p>
      ),
    },
  ];

  const filteredEvaluationTypes = evaluationTypes.filter((evaluation) =>
    (data?.types || []).some((type) => type.type === evaluation.type)
  );

  const scalesMapped =
    (data?.scale || []).map((scale) => ({
      name: scale.title || "",
      description: scale.subtitle || "",
      order: scale.order || 0,
    })) || [];

  return (
    <MainContainer>
      <DataCard
        title="Tipo de avaliação"
        subTitle="Confira os tipos de avaliação selecionados."
      >
        {filteredEvaluationTypes.map((types) => (
          <div key={types.type}>
            <StyledTitle
              variant="headline9"
              setColor="neutral30"
              children={types.title}
            />
            <StyledText
              variant="caption"
              setColor="neutral50"
              fontWeight={600}
              children={types.description}
            />
          </div>
        ))}
      </DataCard>

      <DataCard
        title="Cronograma"
        subTitle="Configura o cronograma da avaliação."
        sections={[
          {
            items: [
              {
                title: "Início das avaliações",
                description:
                  (dayjs(data?.startDate).isValid() &&
                    dayjs(data?.startDate).format("DD/MM/YYYY")) ||
                  " - ",
              },
              {
                title: "Encerramento das avaliações",
                description:
                  (dayjs(data?.endDate).isValid() &&
                    dayjs(data?.endDate).format("DD/MM/YYYY")) ||
                  " - ",
              },
            ],
          },
        ]}
      />

      <DataCard
        title="Escala"
        subTitle="Confira a escala configurada para todos os critérios da avaliação."
        children={
          <CardTable
            leftColumnTitle="Valores da escala"
            rightColumnTitle="Legenda"
            sections={[
              {
                data: scalesMapped,
              },
            ]}
          />
        }
      />
    </MainContainer>
  );
};
