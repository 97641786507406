import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, ItemContainer } from "./styled";

export const PageSkeleton = () => {
  return (
    <Container>
      <ItemContainer>
        <Skeleton variant="text" height={"28px"} width={"140px"} />
        <Skeleton variant="rectangular" height="236px" width="100%" />
      </ItemContainer>
      <ItemContainer>
        <Skeleton variant="text" height={"28px"} width={"110px"} />
        <Skeleton variant="rectangular" height="400px" width="100%" />
      </ItemContainer>
    </Container>
  );
};
