import styled from "styled-components";
import { Avatar } from "@mui/material";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

const TableDataArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const TableData = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0px;
  .MuiAvatar-root {
    margin-right: 12px;
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const EmployeeName = styled(Typography)`
  text-transform: capitalize;
`;

const NoLeaderText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-weight: 600 !important;
  :first-letter {
    text-transform: uppercase;
  }
`;

const AlertIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  fill: transparent;
  flex-shrink: 0;

  margin-right: 4px;
`;

const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
`;

const TransferListArea = styled.div`
  width: 100%;
  max-width: 100%;
  height: 800px;

  & .transfer-list-list-filter-container div h6 {
    font-size: 1rem;
    line-height: 22px;
  }
`;

export {
  TransferListArea,
  AlertIcon,
  EmployeeName,
  NoLeaderText,
  StyledAvatar,
  TableDataArea,
  TableData,
};
