import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const InfoBannerArea = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.secondary[70]};
  background: ${({ theme }) => theme.colors.secondary[99]};
  padding: 24px;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseIcon = styled(Icons)`
  cursor: pointer;
  stroke: ${({ theme }) => theme.colors.secondary[50]};
`;

export const TextContainerArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const IconArea = styled.div`
  border-radius: 200px;
  background: ${({ theme }) => theme.colors.secondary[95]};
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;
