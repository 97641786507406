import styled from "styled-components";

export const TimelineArea = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 32px;
  margin-bottom: 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 32px 16px;
  }
`;
