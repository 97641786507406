import styled from "styled-components";
import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px 16px 24px;
  margin-top: 24px;
  width: 100%;
  gap: 20px;

  background: ${({ theme }) => theme.colors.secondary[99]};
  border: 1px solid ${({ theme }) => theme.colors.secondary[80]};
  border-radius: 12px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const LeftColumn = styled.div`
  width: 100%;
  max-width: 300px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;
export const RightColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
`;

export const StyledSelect = styled(SelectField)`
  width: 100%;
  max-width: 298px;
  background-color: white;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
`;
