import { FormikProps } from "formik";

import { TextField } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle } from "@utils";

import { FormContainer, CharCountContainer } from "../styled";

import type { SectionFormProps } from "../types";

interface ISectionFormProps {
  formik: FormikProps<SectionFormProps>;
}

export const SectionForm = ({ formik }: ISectionFormProps) => {
  return (
    <FormContainer onClick={(e) => e.stopPropagation()}>
      <StyledTitle
        variant="body3"
        setColor="neutral30"
        children={"Nome e descrição"}
        style={{ marginBottom: "16px" }}
      />

      <TextField
        label="Nome da seção"
        id={"name"}
        name={"name"}
        value={formik.values.name}
        onChange={formik.handleChange}
        fullWidth
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />

      <TextField
        label="Descrição da seção"
        id={"description"}
        name={"description"}
        multiline={true}
        rows={3}
        inputProps={{ maxLength: 100 }}
        value={formik.values.description}
        onChange={formik.handleChange}
        fullWidth
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        style={{ marginTop: "24px" }}
      />

      <CharCountContainer>
        <StyledText
          variant="caption"
          setColor="neutral50"
          children={"Máximo 100 caracteres"}
          style={{ fontWeight: 600 }}
        />

        <StyledText
          variant="caption"
          setColor="neutral50"
          children={`${formik.values.description?.length || 0}/100`}
          style={{ fontWeight: 600 }}
        />
      </CharCountContainer>
    </FormContainer>
  );
};
