import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin: 38px 0px;
`;

export const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 38px;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RightColumn = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media screen and (max-width: 1000px) {
    justify-content: flex-start;
  }
`;

export const Option = styled.button<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  :hover {
    ${({ disabled }) => {
      if (disabled) return `cursor: none`;
    }}
  }
`;

export const StyledButton = styled(Button)`
  height: 60px;
  align-self: center !important;
`;
