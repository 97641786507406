import {
  ShapeIcon,
  ShapeIconOptions,
  ShapeIconProps,
  LinkButtonProps,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, StyledIcon } from "@utils";

import { DefaultFooter } from "@components/Modal";

import type { CommonModalProps } from "@components/Modals/types";

import { StyledModal, ContentContainer, IconContainer, Survey } from "./styled";

export interface ConfirmationModalProps extends CommonModalProps {
  title: React.ReactNode | string;
  subTitle?: React.ReactNode | string;
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  cancel?: {
    title?: string;
    variant?: LinkButtonProps["variant"];
    hide?: boolean;
  };
  confirm: {
    title: string;
    icon?: ShapeIconProps["name"];
    iconColor?: string;
    variant?: "primary" | "secondary";
    disabled?: boolean;
  };
  icon?: ShapeIconProps["name"];
  showWarning?: boolean;
  warningTitle?: string;
  variantType?: ShapeIconOptions;
  primaryColor?: string;
  secondaryVariant?: ShapeIconOptions;
  children?: React.ReactNode;
  surveyId?: string;
}

export const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  icon,
  title,
  subTitle,
  variantType = "error",
  titleStyle,
  subTitleStyle,
  cancel = { title: "Cancelar" },
  confirm,
  showWarning,
  warningTitle,
  primaryColor = "error40",
  secondaryVariant,
  children,
  surveyId,
}: ConfirmationModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      footer={
        <DefaultFooter
          variantType={variantType}
          secondaryVariant={secondaryVariant}
          isLoading={isLoading}
          cancelButton={{
            title: cancel.title || "Cancelar",
            variant: cancel.variant,
            onClick: onClose,
            hide: cancel.hide,
          }}
          confirmButton={{
            variant: confirm?.variant || "primary",
            content: (
              <>
                {confirm.title}
                {confirm.icon && (
                  <StyledIcon
                    setColor={confirm.iconColor || "error40"}
                    name={confirm.icon}
                    fill="none"
                    size={40}
                  />
                )}
              </>
            ),
            disabled: confirm.disabled,
            onClick: onConfirm,
          }}
        />
      }
    >
      <ContentContainer>
        {icon && (
          <IconContainer>
            <ShapeIcon
              variant={variantType}
              name={icon}
              size={64}
              stroke={variantType}
            />
          </IconContainer>
        )}

        {showWarning && (
          <StyledTitle
            variant="body3"
            children={warningTitle || "Atenção!"}
            setColor={primaryColor}
            style={{ textAlign: "center" }}
          />
        )}

        <StyledTitle
          variant="headline6"
          children={title}
          setColor="neutral20"
          style={{
            textAlign: "center",
            marginBottom: "16px",
            ...(titleStyle || {}),
          }}
        />

        {subTitle && (
          <Survey>
            <StyledText
              variant="body3"
              children={subTitle}
              setColor="neutral50"
              style={{
                textAlign: "center",
                marginBottom: "14px",
                ...(subTitleStyle || {}),
              }}
            />
            {surveyId && <div id={surveyId} />}
          </Survey>
        )}

        {children}
      </ContentContainer>
    </StyledModal>
  );
};
