import {
  Typography,
  Divider,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { useTheme } from "styled-components";

import { DataItem } from "./components/DataItem";

import {
  CardWrapper,
  HeaderContainer,
  TitleContainer,
  SectionsContainer,
} from "./styled";

interface DataCardProps {
  title: string;
  subTitle?: string;
  editable?: boolean;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  sections?: {
    title?: string;
    items: { title?: string; description: string | React.ReactNode }[];
  }[];
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const DataCard = ({
  title,
  subTitle,
  editable,
  onEditClick,
  sections,
  children,
  style,
}: DataCardProps) => {
  const theme = useTheme();

  return (
    <CardWrapper style={style}>
      <HeaderContainer>
        <TitleContainer>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
              {subTitle}
            </Typography>
          )}
        </TitleContainer>
        {editable && (
          <div style={{ marginTop: -20 }}>
            <PillButton
              size="medium"
              variant="default"
              type="secondary"
              icon="IconPencil"
              onClick={onEditClick}
            />
          </div>
        )}
      </HeaderContainer>
      {sections?.map((section, index) => {
        const { title, items } = section;

        const isNotLast = index + 1 !== sections.length;

        return (
          <SectionsContainer key={title || index}>
            {title && (
              <Typography
                variant="headline9"
                children={title}
                style={{ fontWeight: 700 }}
              />
            )}

            {items.map(({ title, description }) => (
              <DataItem key={title} title={title} description={description} />
            ))}

            {isNotLast && <Divider orientation="horizontal" />}
          </SectionsContainer>
        );
      })}
      {children}
    </CardWrapper>
  );
};
