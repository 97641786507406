import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  min-width: 266px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;

export const CircleContainer = styled.div<{ setColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "secondary95":
        return theme.colors.secondary[95];
      case "neutral90":
        return theme.colors.neutral[90];
      case "info90":
        return theme.colors.feedback.info[90];
      case "error90":
        return theme.colors.feedback.error[90];
      case "success90":
        return theme.colors.feedback.success[90];
      default:
        "";
        break;
    }
  }};
  border-radius: 100px;
  margin-bottom: 16px;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;
