import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { EvaluationIndex, AnswerEvaluation } from "./steps";

type StepType = typeof EvaluationIndex | typeof AnswerEvaluation;

export const PageViewEvaluation = () => {
  const { type = "" } = useParams();

  const Step: StepType = useMemo(() => {
    const steps = {
      ["index"]: EvaluationIndex,
      ["evaluation"]: AnswerEvaluation,
      ["review"]: AnswerEvaluation,
    };

    return steps[type] || steps["index"];
  }, [type]);

  return <Step />;
};
