import { useMemo } from "react";

import { DataCard } from "@components/Cards";

import { CardTable } from "../../CardTable";

import { MainContainer } from "../styled";

import type { Evaluation } from "server/src/services/evaluation/types";

interface IEvaluationFormProps {
  data?: Evaluation;
}
export const EvaluationForm = ({ data }: IEvaluationFormProps) => {
  const cardContent = useMemo(() => {
    const sections =
      (data?.sections || []).map((item) => ({
        name: item.name,
        description: item.description || " - ",
        data: item.criterials.map((criterial) => ({
          name: criterial.name || "",
          description: criterial.description || "",
          order: criterial.order || 0,
        })),
      })) || [];

    return sections;
  }, [data?.sections]);

  return (
    <MainContainer>
      <DataCard
        title="Revisar perguntas"
        subTitle="Confira as perguntas que serão enviadas nessa pesquisa."
        children={
          <CardTable
            leftColumnTitle="Critério"
            rightColumnTitle="Descrição"
            sections={cardContent}
          />
        }
      />
    </MainContainer>
  );
};
