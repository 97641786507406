import { StyledText, StyledTitle } from "@utils";
import {
  Column,
  Line,
  Maincontainer,
  ColumnContainer,
  FeedBackText,
} from "./styled";
import { AnswerField } from "./AnswerField";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

export const Feedback = ({
  data,
  permission,
  feedbackRequest,
  onCloseDrawer,
  refetch,
}) => {
  return (
    <Maincontainer>
      {feedbackRequest._id && (
        <>
          <ColumnContainer>
            <StyledTitle setColor="neutral30" variant="body2">
              Solicitação
            </StyledTitle>
            <div style={{ display: "flex" }}>
              <Column>
                <StyledText setColor="neutral50" variant="caption">
                  Solicitante
                </StyledText>
                <StyledTitle setColor="neutral30" variant="body3">
                  {feedbackRequest?.from?.name || ""}
                </StyledTitle>
              </Column>
              <Column style={{ marginLeft: "40px" }}>
                <StyledText setColor="neutral50" variant="caption">
                  Data da solicitação
                </StyledText>
                <StyledTitle setColor="neutral30" variant="body3">
                  {dayjs(feedbackRequest?.createdAt).isValid()
                    ? dayjs(feedbackRequest?.createdAt).format("DD/MM/YY")
                    : "-"}
                </StyledTitle>
              </Column>
            </div>
            <div>
              <StyledText setColor="neutral50" variant="caption">
                Mensagem de solicitação
              </StyledText>
              <StyledTitle setColor="neutral30" variant="body3">
                <div
                  dangerouslySetInnerHTML={{ __html: feedbackRequest.message }}
                  style={{ lineHeight: "normal", listStylePosition: "inside" }}
                ></div>
              </StyledTitle>
            </div>
          </ColumnContainer>
          <Line marginBottom="24px" marginTop="24px" />
        </>
      )}
      <ColumnContainer>
        <StyledTitle setColor="neutral30" variant="body2">
          Feedback
        </StyledTitle>

        <div>
          <StyledText setColor="neutral50" variant="caption">
            Tipo de feedback
          </StyledText>
          <StyledTitle setColor="neutral30" variant="body3">
            {data?.type === "voluntary"
              ? "Feedback espontâneo"
              : "Feedback atrelado à avaliação"}
          </StyledTitle>
        </div>
        {data?.type === "byEvaluation" && (
          <div>
            <StyledText setColor="neutral50" variant="caption">
              Nome da avaliação
            </StyledText>
            <StyledTitle setColor="neutral30" variant="body3">
              {data?.evaluation?.name || ""}
            </StyledTitle>
          </div>
        )}
        <div>
          <StyledText setColor="neutral50" variant="caption">
            Feedback
          </StyledText>

          <FeedBackText variant="body3">
            <div
              dangerouslySetInnerHTML={{ __html: data?.message }}
              style={{ listStylePosition: "inside" }}
            />
          </FeedBackText>
        </div>
      </ColumnContainer>

      <AnswerField
        data={data}
        permission={permission}
        onCloseDrawer={onCloseDrawer}
        refetch={() => refetch()}
      />
    </Maincontainer>
  );
};
