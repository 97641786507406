import styled from "styled-components";

const Description = styled.div`
  > * {
    color: ${({ theme }) => theme.colors.neutral40};
    font-weight: 400;
    font-size: 16px;
    list-style-position: inside;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export { Description, TextBox };
