import { useEffect, useState } from "react";

import { StyledIcon, StyledText, track } from "@utils";
import { TableSearch } from "./TableSearch";

import { HeaderContainer, SelectButton, SelectContainer } from "./styled";

export const Header = ({
  onSearch,
  selectedTab,
}: {
  onSearch: (value: string) => void;
  selectedTab: (value: "evaluateds" | "evaluators") => void;
}) => {
  const [handleSelectedTab, setHandleSelectedTab] = useState<
    "evaluateds" | "evaluators"
  >("evaluateds");

  useEffect(() => {
    selectedTab(handleSelectedTab);
  }, [handleSelectedTab]);

  return (
    <HeaderContainer>
      <SelectContainer>
        <SelectButton
          onClick={() => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_overview_evaluated_clicked",
            });
            setHandleSelectedTab("evaluateds");
          }}
          selected={handleSelectedTab === "evaluateds"}
        >
          <StyledIcon
            name="IconChartBar"
            size={14}
            setColor={
              handleSelectedTab === "evaluateds" ? "secondary50" : "neutral40"
            }
          />
          <StyledText
            variant="body3"
            children="Avaliados"
            setColor={
              handleSelectedTab === "evaluateds" ? "secondary50" : "neutral40"
            }
          />
        </SelectButton>

        <SelectButton
          onClick={() => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_evaluationdetails_overview_evaluators_clicked",
            });
            setHandleSelectedTab("evaluators");
          }}
          selected={handleSelectedTab === "evaluators"}
        >
          <StyledIcon
            name="IconNotes"
            size={14}
            setColor={
              handleSelectedTab === "evaluators" ? "secondary50" : "neutral40"
            }
          />
          <StyledText
            variant="body3"
            children="Avaliadores"
            setColor={
              handleSelectedTab === "evaluators" ? "secondary50" : "neutral40"
            }
          />
        </SelectButton>
      </SelectContainer>

      <TableSearch
        disabled={false}
        onChange={onSearch}
        selectedTab={handleSelectedTab}
      />
    </HeaderContainer>
  );
};
