type EType = "error" | "success" | "warning";

interface dispatchToastParams {
  content: string;
  type: EType;
  title?: string;
  description?: string;
}

export default function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }));
}
