import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { Search, Container } from "./styled";
import { track } from "@utils";

export const TableSearch = ({ onChange, disabled }) => {
  const navigate = useNavigate();

  const { _id = "" } = useParams();

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inputValue === undefined) return;

    const timer = setTimeout(() => {
      onChange(inputValue);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [inputValue]);

  const handleOnChange = (value) => {
    if (onChange) {
      setInputValue(value);
    }
  };

  const handleOnBlur = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };
  const handleOnInput = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };

  return (
    <Container>
      <Search
        onChange={(e) => handleOnChange(e.target.value)}
        onSearchChange={handleOnBlur}
        onInputChange={handleOnInput}
        label="Buscar por plano de ação"
        disabled={disabled}
      />
      <Button
        variant="primary"
        size="large"
        onClick={() => {
          track({
            name: "performance_idp_persondetails_createnewplan_clicked",
          });
          navigate(
            routes.PageCreateIDP.replace(":origin", "manage-idps").replace(
              ":step",
              "1"
            ) + `/to/${_id}`
          );
        }}
      >
        Criar novo plano <Icons name="IconPlus" />
      </Button>
    </Container>
  );
};
