import { Tag, TagProps, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  MainContainer,
  HeaderContainer,
  TitleContainer,
  ActionsContainer,
  ContentContainer,
} from "./styled";

interface ISectionItemProps {
  title: string;
  description?: string;
  tagName?: React.ReactNode | string;
  tagVariant?: TagProps["variant"];
  error?: boolean;
  children: React.ReactNode;
  actionsElement?: React.ReactNode;
}

export const SectionItem = ({
  title,
  description,
  tagName,
  tagVariant = "disabled",
  error,
  actionsElement,
  children,
}: ISectionItemProps) => {
  return (
    <MainContainer error={error}>
      <HeaderContainer>
        <TitleContainer>
          {tagName && (
            <Tag
              variant={tagVariant}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {tagName}
            </Tag>
          )}
          <Typography variant="headline8">{title}</Typography>
          {description && (
            <Typography variant="body4">{description}</Typography>
          )}
        </TitleContainer>
        {actionsElement && (
          <ActionsContainer>{actionsElement}</ActionsContainer>
        )}
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </MainContainer>
  );
};
