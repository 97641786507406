import styled, { CSSProperties } from "styled-components";

type FloatingCardProps = {
  gap?: CSSProperties["gap"];
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
  flexDirection?: CSSProperties["flexDirection"];
};

export const FloatingCard = styled.article<FloatingCardProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  padding: ${({ theme }) => theme.spacings.m};
  background-color: ${({ theme }) => theme.colors.neutral100};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;
