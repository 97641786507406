import * as yup from "yup";

export type CompanyValueFormProps = {
  name: string;
  description: string;
};

export const validationSchema = yup.object({
  name: yup.string().required("Este campo deve ser preenchido"),
  description: yup.string().required("Este campo deve ser preenchido"),
});
