import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  PillButton,
  Menu,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { StyledText, pageSizeOptions } from "@utils";

import { RenderNameField } from "@components/TableNameField";

import { Option, ProgressArea, ProgressBar, ProgressWrapper } from "./styled";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  selectedItem?: string;
  refetch: () => void;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
}: TableGridProps) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Pessoa",
        accessor: "name",
        Cell: ({ row }) => RenderNameField(row.original.name || "-"),
      },
      {
        Header: "Total de planos",
        accessor: "total",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.total}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Planos não iniciados",
        accessor: "created",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.created}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Planos em andamento",
        accessor: "progressing",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.progressing}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Planos atrasados",
        accessor: "delayed",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.delayed}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Planos concluídos",
        accessor: "finished",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.finished}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Taxa de conclusão",
        accessor: "completionRate",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <ProgressArea>
              <ProgressWrapper>
                <ProgressBar totalDone={row?.original?.completionRate}>
                  <span />
                </ProgressBar>
              </ProgressWrapper>
              <div>{row?.original?.completionRate?.toFixed(2)}%</div>
            </ProgressArea>
          </div>
        ),
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () =>
                    navigate(
                      routes.PagePersonDetails("my-idps", row.original._id)
                    ),
                  children: (
                    <Option>
                      <Icons name="IconUser" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Ver detalhes da pessoa
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () =>
                    navigate(
                      routes.PageCreateIDP.replace(
                        ":origin",
                        "my-idps"
                      ).replace(":step", "1") + `/to/${row.original._id}`
                    ),
                  children: (
                    <Option>
                      <Icons name="IconPlus" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Criar novo plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                // {
                //   onClick: () => {
                //     console.log("");
                //   },
                //   children: (
                //     <Option>
                //       <Icons name="IconDownload" fill="transparent" />
                //       <StyledText variant="body3" style={{ fontWeight: 600 }}>
                //         Exportar relatório
                //       </StyledText>
                //     </Option>
                //   ),
                // },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                size="small"
                variant="default"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Ainda não existem PDIs disponíveis."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhuma pessoa foi encontrada.",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};
