import styled from "styled-components";

export const PeopleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnswerStatus = styled.div`
  display: flex;
  align-items: center;
`;
