import { Grades } from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";

import { renderHtmlText } from "@utils";

import {
  Divider,
  PageDescription,
  PageTitle,
  QuestionCounter,
  QuestionDescription,
  QuestionHeader,
  QuestionTitle,
} from "./styled";

import type {
  EvaluationScale,
  EmployeeEvaluationCriterial,
} from "server/src/services/evaluation/types";

const QuestionItem = ({
  number,
  title,
  description,
  value,
  commentary,
  commentaryEnabled,
  scale,
}: {
  number: number;
  title: string;
  description: string;
  value?: string | number;
  commentary?: string;
  commentaryEnabled: boolean;
  scale: EvaluationScale[];
}) => {
  const parsedScale = scale?.map((item) => ({
    label: item?.order?.toString(),
    title: item?.title,
    subtitle: item?.subtitle,
    value: item?.value?.toString(),
    disabled: value !== item.value,
  }));

  return (
    <div>
      <QuestionHeader>
        <QuestionCounter variant="headline8">{number + 1}.</QuestionCounter>
        <QuestionTitle variant="headline8">{title}</QuestionTitle>
      </QuestionHeader>

      <QuestionDescription variant="body3">
        {renderHtmlText(description)}
      </QuestionDescription>

      {scale.length && (
        <Grades
          value={value?.toString() || ""}
          showTooltip={true}
          contentArray={parsedScale}
        />
      )}

      {commentaryEnabled && (
        <TextEditor
          id={number.toString()}
          styles={{
            container: { width: "100%", marginTop: "16px" },
            editor: { height: "120px" },
          }}
          readOnly={true}
          value={commentary}
          placeholder="Comentário (Opcional)"
        />
      )}
      <Divider />
    </div>
  );
};

interface EvaluationTabProps {
  name: string;
  description: string;
  criterials: EmployeeEvaluationCriterial[];
  scale: EvaluationScale[];
}

export const EvaluationTab = ({
  name,
  description,
  criterials,
  scale,
}: EvaluationTabProps) => {
  return (
    <div>
      <PageTitle variant="headline8">{name}</PageTitle>
      <PageDescription variant="body3">{description}</PageDescription>
      {criterials?.map(
        (
          { name, description, value, commentary, commentaryEnabled },
          index
        ) => (
          <QuestionItem
            key={index}
            number={index}
            title={name}
            description={description}
            value={value}
            commentary={commentary}
            commentaryEnabled={commentaryEnabled}
            scale={scale}
          />
        )
      )}
    </div>
  );
};
