import { useMemo } from "react";
import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, track } from "@utils";
import { CompanyValues } from "./Tabs/CompanyValues";
import { OtherSettings } from "./Tabs/OtherSettings";
import { Header } from "./styled";
import { routes } from "@routes";
import { useNavigate } from "react-router-dom";

export const PageFeedbackSettings = () => {
  const navigate = useNavigate();
  const tabItens = useMemo(
    () => [
      { label: "Valores da empresa", component: <CompanyValues /> },
      { label: "Outras configurações", component: <OtherSettings /> },
    ],
    []
  );
  const breadCrumbs = [
    <LinkButton
      variant="neutral"
      style={{ alignSelf: "flex-start" }}
      onClick={() => navigate(routes.PageManageFeedbacks)}
    >
      Gerenciar feedbacks
    </LinkButton>,
    <LinkButton variant="neutral" style={{ alignSelf: "flex-start" }}>
      Configurações de feedback
    </LinkButton>,
  ];
  return (
    <PageContainer>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
        style={{ marginTop: "40px" }}
      />
      <Header>
        <StyledTitle
          variant="headline6"
          children="Configurações de feedback"
          setColor="neutral20"
        />
        <StyledText
          variant="body3"
          setColor="neutral40"
          children="Gerencie as configurações de feedbacks realizados pelos colaboradores."
        />
      </Header>
      <Tab
        tabItens={tabItens}
        onTabChanged={(tab) => {
          if (tab === 1) {
            track({
              name: "performance_feedbacks_settings_othersettings_clicked",
            });
          }
        }}
      />
    </PageContainer>
  );
};
