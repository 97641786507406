import { useState, useEffect } from "react";

import {
  Button,
  PillButton,
  LinkButton,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { MyCriteria, CriteriaModels } from "./Tabs";

import { routes } from "@routes";

import { StyledText, StyledTitle } from "@utils";

import type { CommonModalProps } from "@components/Modals/types";

import type { onSelectedChangedType, CriteriaType } from "./types";

import {
  FooterContainer,
  HeaderContainer,
  TitleContainer,
  StyledModal,
  StyledTab,
} from "./styled";

interface IAddCriteriaModal extends CommonModalProps {
  evaluationCriterials: string[];
  onCriteriaAdded: onSelectedChangedType;
}

type AddCriteriaModalProps = Omit<IAddCriteriaModal, "onConfirm">;

export const AddCriteriaModal = ({
  open,
  onClose,
  evaluationCriterials,
  onCriteriaAdded,
}: AddCriteriaModalProps) => {
  const [selectedCriterialsUpdated, setSelectedCriterialsUpdated] = useState<
    CriteriaType[]
  >([]);

  useEffect(() => {
    if (!open) setSelectedCriterialsUpdated([]);
  }, [open]);

  const Header = () => {
    return (
      <HeaderContainer>
        <TitleContainer>
          <StyledTitle variant="headline6">
            Selecione os critérios para a avaliação
          </StyledTitle>
          <StyledText variant="body3" setColor="neutral50">
            Insira critérios que serão inclusos na avaliação. Para editar os
            critérios existentes acesse o
            <LinkButton
              variant="neutral"
              children={
                <>
                  Banco de critérios <Icons name="IconExternalLink" size={16} />
                </>
              }
              style={{
                alignSelf: "center",
                display: "inline-flex",
                marginLeft: "6px",
              }}
              onClick={() =>
                window.open(routes.PageManageCriterials, "_blank", "noreferrer")
              }
            />
          </StyledText>
        </TitleContainer>
        <div style={{ marginTop: "-20px" }}>
          <PillButton
            variant="default"
            size="small"
            type="secondary"
            icon="IconX"
            onClick={onClose}
          />
        </div>
      </HeaderContainer>
    );
  };

  const Footer = () => (
    <FooterContainer>
      <LinkButton
        variant="neutral"
        children="Cancelar"
        style={{ alignSelf: "center" }}
        onClick={onClose}
      />
      <Button
        size="large"
        variant="primary"
        disabled={!selectedCriterialsUpdated.length}
        style={{ alignSelf: "center" }}
        onClick={() => {
          onCriteriaAdded(selectedCriterialsUpdated);
          onClose?.(new Event("click"));
        }}
      >
        Adicionar critérios
      </Button>
    </FooterContainer>
  );

  return (
    <StyledModal
      onClose={() => onClose?.(new Event("click"))}
      open={open}
      header={<Header />}
      footer={<Footer />}
    >
      <StyledTab
        tabItens={[
          {
            label: "Meus critérios",
            component: (
              <MyCriteria
                onSelectedChanged={setSelectedCriterialsUpdated}
                selectedCriterials={selectedCriterialsUpdated}
                evaluationCriterials={evaluationCriterials}
              />
            ),
          },
          {
            label: "Modelos de critérios",
            component: (
              <CriteriaModels
                onSelectedChanged={setSelectedCriterialsUpdated}
                selectedCriterials={selectedCriterialsUpdated}
                evaluationCriterials={evaluationCriterials}
              />
            ),
          },
        ]}
      />
    </StyledModal>
  );
};
