import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

interface IDeleteIDPModalProps extends CommonModalProps {
  idpId: string;
  refetch?: () => void;
}

type DeleteIDPModalProps = Omit<IDeleteIDPModalProps, "onConfirm">;

export const DeleteIDPModal = ({
  open,
  idpId,
  onClose,
  refetch,
}: DeleteIDPModalProps) => {
  const utils = trpc.useContext();

  const { mutate, isLoading } = trpc.performance.idp.deleteIdp.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: "Plano deletado com sucesso.",
      });

      utils.performance.idp.getIdpInsightsByEmployee.invalidate();

      refetch?.();
      onClose?.(new Event("click"));
    },
    onError: () =>
      dispatchToast({
        type: "error",
        content: "Erro ao deletar o plano, tente novamente em breve.",
      }),
  });

  return (
    <ConfirmationModal
      open={open}
      showWarning
      icon="IconAlertCircle"
      title="Tem certeza que deseja excluir o plano de ação?"
      subTitle="Todas as informações relacionadas serão perdidas e essa ação não poderá ser desfeita."
      subTitleStyle={{ marginBottom: "0" }}
      confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
      onClose={onClose}
      onConfirm={() => {
        mutate({ _id: idpId });
      }}
      isLoading={isLoading}
    />
  );
};
