import { useMemo } from "react";

import { dayjs, Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { RequiredAsterisk, StyledIcon, StyledText, StyledTitle } from "@utils";

import {
  MainContainer,
  DateContainer,
  Card,
  CardContent,
  StyledAvatar,
  GradeTooltip,
  TooltipLabel,
  CardsContainer,
} from "./styled";

import type {
  EvaluationTypes,
  GradesAnalysisType,
} from "server/src/services/evaluation/types";

interface IBigNumbersProps {
  data: GradesAnalysisType["insights"];
  types: EvaluationTypes[];
}
export const BigNumbers = ({ data, types }: IBigNumbersProps) => {
  const renderFinalDate = useMemo(() => {
    const date = data?.finishDate;

    if (!date || !dayjs(date).isValid()) return <> - </>;

    const formatedDate = dayjs(date).format("DD/MM/YY");
    const formatedHour = dayjs(date).format("HH:mm");

    return (
      <DateContainer>
        <StyledTitle
          variant="body1"
          children={formatedDate}
          setColor="neutral40"
        />
        <StyledText
          variant="body2"
          children={formatedHour}
          setColor="neutral50"
        />
      </DateContainer>
    );
  }, [data]);

  const hasByLeader = types?.includes("byLeader");

  return (
    <MainContainer>
      <CardsContainer>
        {hasByLeader && (
          <Card>
            <StyledAvatar
              children={<Icons name="IconReportAnalytics" size={24} />}
            />

            <CardContent>
              <StyledText
                variant="body3"
                children="Nota Final (nota do líder)"
                setColor="neutral40"
              />
              {data?.calibrationAverage ? (
                <Tooltip
                  title={
                    <GradeTooltip>
                      <div>
                        <div>Nota original: {data?.average}</div>
                        <div>Nota calibrada: {data?.calibrationAverage}</div>
                      </div>
                    </GradeTooltip>
                  }
                >
                  <TooltipLabel>
                    <StyledTitle
                      setColor="neutral40"
                      variant="body1"
                      children={data?.calibrationAverage}
                      style={{ fontWeight: 700 }}
                    />
                    <StyledIcon
                      name="IconStar"
                      fill="#F20D7A"
                      size={13}
                      style={{ marginLeft: "6px" }}
                      setColor="primary"
                    />
                  </TooltipLabel>
                </Tooltip>
              ) : (
                <StyledTitle
                  setColor="neutral40"
                  variant="body1"
                  children={data?.average?.toString() || " - "}
                  style={{ fontWeight: 700 }}
                />
              )}
            </CardContent>
          </Card>
        )}

        <Card>
          <StyledAvatar children={<Icons name="IconCalendar" size={24} />} />

          <CardContent>
            <StyledText
              variant="body3"
              children="Data de encerramento"
              setColor="neutral40"
            />
            {renderFinalDate}
          </CardContent>
        </Card>
      </CardsContainer>
      <StyledText hidden={!hasByLeader} variant="body4">
        <RequiredAsterisk /> Apenas a nota do líder (líder avalia liderado) será
        considerada no cálculo da nota final e poderá ser calibrada.
      </StyledText>
    </MainContainer>
  );
};
