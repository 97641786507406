import styled from "styled-components";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";

const StyledTab = styled(Tab)`
  width: 100%;

  > div {
    flex: 1;
  }

  > div button div {
    margin-right: 0px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export { Container, StyledTab };
