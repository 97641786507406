import styled, { css } from "styled-components";

type ItemProps = {
  isSelected?: boolean;
  isDisabled?: boolean;
  isDragging?: boolean;
  error?: boolean;
};

const ItemSelected = css`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

const ItemDisabled = css`
  cursor: none;
  opacity: 0.65;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.secondary[90]};
  border-color: ${({ theme }) => theme.colors.primary};
`;

const ItemDragging = css`
  background-color: ${({ theme }) => theme.colors.secondary[70]};
  border-color: ${({ theme }) => theme.colors.secondary[70]};
  color: ${({ theme }) => theme.colors.neutral[100]};
  rotate: -3.1deg;

  svg {
    stroke: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

const ItemError = css<ItemProps>`
  border-color: ${({ theme }) => theme.colors.feedback.error[50]};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.feedback.error[90] : theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.feedback.error[10]};

  svg {
    stroke: ${({ theme }) => theme.colors.feedback.error[70]};
  }
`;

const CounterSelected = css`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const CounterDragging = css`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

const CounterError = css`
  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemContainer = styled.div<ItemProps>`
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[50]};
  opacity: 1;

  ${({ isSelected }) => isSelected && ItemSelected}
  ${({ isDisabled }) => isDisabled && ItemDisabled}
  ${({ error }) => error && ItemError}
  ${({ isDragging }) => isDragging && ItemDragging}

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary[95]};
    background-color: ${({ theme }) => theme.colors.secondary[95]};
  }
`;

const ItemSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ItemCounter = styled.div<ItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 8px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};

  ${({ isSelected }) => isSelected && CounterSelected}
  ${({ error }) => error && CounterError}
  ${({ isDragging }) => isDragging && CounterDragging}
`;

export { SectionsContainer, ItemContainer, ItemSection, ItemCounter };
