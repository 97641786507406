import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: white;
`;

const ContentWrapper = styled.main`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const PageContainer = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0px  ${theme.spacings.s}`};
`;

type ProgressBarContainerProps = {
  progressValue: number;
};

const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme, progressValue }) =>
      theme.colors.primary + " " + progressValue + "%"},
    ${({ theme, progressValue }) =>
      theme.colors.secondary95 + " " + progressValue + "%"},
    ${({ theme }) => theme.colors.secondary95} 100%
  );
`;

export { Container, ContentWrapper, PageContainer, ProgressBarContainer };
