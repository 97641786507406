import { ShapeIcon, Button, IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTitle, StyledText, StyledPLine } from "@utils";

import {
  CardContainer,
  ContentContainer,
  TextContainer,
  ButtonsContainer,
} from "./styled";

interface IBlankCardProps {
  icon: IconTypes;
  name: string;
  description?: string;
  onDetailsClick?: React.MouseEventHandler<HTMLButtonElement>;
  onUseClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ModelCard = ({
  icon,
  name,
  description,
  onDetailsClick,
  onUseClick,
}: IBlankCardProps) => {
  return (
    <CardContainer>
      <ContentContainer>
        <ShapeIcon name={icon} variant="default" size={48} stroke="default" />

        <TextContainer>
          <StyledPLine numberOfLines={1}>
            <StyledTitle
              variant="headline7"
              children={name}
              setColor="neutral30"
            />
          </StyledPLine>

          {description && (
            <StyledPLine numberOfLines={2} wordBreak="break-word">
              <StyledText
                variant="body3"
                children={description}
                setColor="neutral40"
              />
            </StyledPLine>
          )}
        </TextContainer>
      </ContentContainer>

      <ButtonsContainer>
        <Button
          size={"small"}
          variant="secondary"
          children="Detalhes"
          style={{ width: "100%", maxHeight: "38px" }}
          onClick={onDetailsClick}
        />
        <Button
          size={"small"}
          variant="primary"
          children="Usar"
          style={{ width: "100%", maxHeight: "38px" }}
          onClick={onUseClick}
        />
      </ButtonsContainer>
    </CardContainer>
  );
};
