import { toPath } from "lodash-es";

export function getIn(obj: any, key: string | string[], def?: any, p = 0) {
  const path = toPath(key);

  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }

  if (p !== path.length && !obj) {
    return def;
  }

  return obj === undefined ? def : obj;
}
