import styled from "styled-components";

import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const MainContainer = styled.div`
  margin: 32px 0px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    text-align: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 315px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  margin-bottom: 38px;
`;

export const Row = styled(FlexContainer)`
  width: 100%;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const SelectContainer = styled(FlexContainer)`
  width: 100%;
  gap: 8px;
  padding-top: 32px;
`;

export const StyledSelect = styled(SelectField)`
  width: 100%;
`;
