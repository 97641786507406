import { cloneDeep } from "lodash-es";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import {
  QuestionsContainer,
  ItemContainer,
  ItemGrip,
  ItemQuestionContainer,
  ItemTextContainer,
  ItemTitleContainer,
} from "./styled";

import type { CriterialType } from "../../../../../types";

interface IDraggableCriterialsProps {
  criterials: CriterialType[];
  onReordered: (criterials: CriterialType[]) => void;
  onDeletedCriteria: (criteriaId: string) => void;
  disabledEdit: boolean;
}

type OnReorderItems = (
  list: CriterialType[],
  startIndex: number,
  endIndex: number
) => CriterialType[];

export const DraggableCriterials = ({
  criterials,
  onReordered,
  onDeletedCriteria,
  disabledEdit,
}: IDraggableCriterialsProps) => {
  const reorderItems: OnReorderItems = (list, startIndex, endIndex) => {
    const clonedQuestions = cloneDeep(list);

    const [removed] = clonedQuestions.splice(startIndex, 1);

    clonedQuestions.splice(endIndex, 0, removed);

    return clonedQuestions.map((question, index) => ({
      ...question,
      order: index + 1,
    }));
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;

    const items = reorderItems(
      criterials,
      result.source.index,
      result.destination.index
    );

    onReordered(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="criterials">
        {(provided) => (
          <QuestionsContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {criterials.map((criterial, index) => {
              return (
                <Draggable
                  key={criterial._id}
                  draggableId={criterial._id}
                  index={index}
                  isDragDisabled={disabledEdit}
                >
                  {(provided, snapshot) => (
                    <ItemContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                    >
                      <ItemGrip isDragging={snapshot.isDragging}>
                        <Icons name="IconGripVertical" />
                      </ItemGrip>
                      <ItemQuestionContainer>
                        <ItemTextContainer>
                          <ItemTitleContainer>
                            <StyledText
                              variant="body4"
                              fontWeight={600}
                              setColor="neutral40"
                              children={`Pergunta ${index + 1}`}
                            />
                            <Tooltip
                              title={
                                criterial?.fromModel || disabledEdit
                                  ? "Critérios do modelo de avaliação não podem ser removidos"
                                  : "Remover critério"
                              }
                            >
                              <div
                                role="presentation"
                                onClick={() => {
                                  const fromModel = criterial?.fromModel;

                                  if (disabledEdit || fromModel) return;

                                  onDeletedCriteria(criterial._id);
                                }}
                              >
                                <StyledIcon
                                  name={
                                    disabledEdit || criterial?.fromModel
                                      ? "IconTrashOff"
                                      : "IconTrash"
                                  }
                                  setColor={
                                    disabledEdit || criterial?.fromModel
                                      ? "neutral90"
                                      : "neutral30"
                                  }
                                  disabled={
                                    disabledEdit || criterial?.fromModel
                                  }
                                />
                              </div>
                            </Tooltip>
                          </ItemTitleContainer>

                          <StyledTitle
                            variant="headline8"
                            setColor="neutral30"
                            children={criterial.name}
                          />

                          <StyledText
                            variant="body3"
                            fontWeight={600}
                            setColor="neutral20"
                            children={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: criterial?.description || "",
                                }}
                                style={{
                                  lineHeight: "normal",
                                  listStylePosition: "inside",
                                }}
                              />
                            }
                          />
                        </ItemTextContainer>
                      </ItemQuestionContainer>
                    </ItemContainer>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </QuestionsContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};
