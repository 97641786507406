import { useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  SelectField,
  Skeleton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { dispatchToast, StyledText, StyledTitle, track } from "@utils";
import { FormikProps } from "formik";
import { trpc } from "@api/client";

import { Card, Columns, LeftColumn, RightColumn } from "./styled";

type firstStepFormikProps = {
  basicInfo: {
    name: string;
    goal: string;
    employeeId: string;
    type: "evaluation" | "independent";
    evaluationId: string;
  };
  actions: any[];
};

type FirstStepProps = {
  formik: FormikProps<firstStepFormikProps>;
  fetching?: boolean;
  disabled?: boolean;
};

export const FirstStep = ({ formik, fetching, disabled }: FirstStepProps) => {
  const { employeeId = "" } = useParams();
  const [disabledFields, setDisabledFields] = useState(false);

  const { pathname } = useLocation();

  const isCreating = useMemo(() => !!pathname.match("/create-idp"), [pathname]);

  const ownerId = useMemo(
    () => formik.values.basicInfo.employeeId || employeeId,
    [formik.values.basicInfo.employeeId, employeeId]
  );

  const { data: employeesList, isLoading: loadingEmployeesList } =
    trpc.performance.idp.createIdpBaseData.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    });

  const { data: evaluations = [], isFetching: evaluationsLoading } =
    trpc.performance.evaluation.getAnsweredEvaluationsNameByEvaluated.useQuery(
      { evaluatedId: ownerId },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!ownerId && formik.values.basicInfo.type === "evaluation",
        onSuccess: (data) => {
          if (!data?.length) {
            dispatchToast({
              type: "error",
              content: `Não há nenhuma avaliação associada a essa pessoa`,
            });
          }

          setDisabledFields(!data?.length);
        },
      }
    );

  return (
    <Columns>
      <LeftColumn>
        <StyledTitle
          variant="body1"
          children="Informações Básicas"
          setColor="secondary50"
        />
        <StyledText
          variant="body3"
          children="Adicione as informações básicas do plano, como nome e objetivo."
          setColor="neutral50"
        />
      </LeftColumn>
      <RightColumn>
        {fetching ? (
          <>
            <Card>
              <Skeleton width={"100%"} height={60} variant="rounded" />
              <Skeleton width={"100%"} height={120} variant="rounded" />
            </Card>
            <Card>
              <Skeleton width={"100%"} height={60} variant="rounded" />
              <Skeleton width={"100%"} height={60} variant="rounded" />
            </Card>
          </>
        ) : (
          <>
            <Card>
              <StyledTitle variant="body2" children="Nome e descrição" />
              <div>
                <TextField
                  label="Nome do plano"
                  value={formik.values.basicInfo?.name}
                  disabled={disabled}
                  onChange={({ target }) =>
                    formik.handleChange({
                      target: {
                        id: "basicInfo",
                        value: {
                          ...formik.values.basicInfo,
                          name: target?.value,
                        },
                      },
                    })
                  }
                  error={
                    formik.touched.basicInfo?.name &&
                    Boolean(formik.errors.basicInfo?.name)
                  }
                  helperText={
                    formik.touched.basicInfo?.name &&
                    formik.errors.basicInfo?.name
                  }
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label="Objetivo do plano"
                  multiline={true}
                  rows={3}
                  value={formik.values.basicInfo?.goal}
                  disabled={disabled}
                  onChange={({ target }) =>
                    formik.handleChange({
                      target: {
                        id: "basicInfo",
                        value: {
                          ...formik.values.basicInfo,
                          goal: target?.value,
                        },
                      },
                    })
                  }
                  error={
                    formik.touched.basicInfo?.goal &&
                    Boolean(formik.errors.basicInfo?.goal)
                  }
                  helperText={
                    formik.touched.basicInfo?.goal &&
                    formik.errors.basicInfo?.goal
                  }
                  fullWidth
                />
              </div>
            </Card>
            <Card>
              <StyledTitle variant="body2" children="Dados do plano" />
              {loadingEmployeesList ? (
                <Skeleton width={"100%"} height={60} variant="rounded" />
              ) : (
                <SelectField
                  label="Pessoa"
                  disabled={!!employeeId || !isCreating || disabled}
                  options={employeesList}
                  value={formik.values.basicInfo?.employeeId}
                  searchable={true}
                  onSelectChange={(_, option) => {
                    formik.handleChange({
                      target: {
                        id: "basicInfo",
                        value: {
                          ...formik.values.basicInfo,
                          employeeId: option?.value,
                          evaluationId: "",
                        },
                      },
                    });
                  }}
                  error={
                    formik.touched.basicInfo?.employeeId &&
                    Boolean(formik.errors.basicInfo?.employeeId)
                  }
                  helperText={
                    formik.touched.basicInfo?.employeeId &&
                    formik.errors.basicInfo?.employeeId
                  }
                  fullWidth
                />
              )}
              <SelectField
                label="Tipo de plano"
                options={[
                  { label: "Independente", value: "independent" },
                  {
                    label: "Atrelado à avaliação",
                    value: "evaluation",
                  },
                ]}
                value={formik.values.basicInfo?.type}
                searchable={true}
                disabled={disabled}
                onSelectChange={(_, option) => {
                  if (employeeId) {
                    track({
                      name:
                        option?.value === "independent"
                          ? "performance_myidp_createnewplan_basicinformation_plantype_independent_selected"
                          : "performance_myidp_createnewplan_basicinformation_plantype_linkedtoevaluation_selected",
                    });
                  } else {
                    track({
                      name:
                        option?.value === "independent"
                          ? "performance_idp_createactionplan_basicinformations_plantype_independent_selected"
                          : "performance_idp_createactionplan_basicinformations_plantype_linkedtoevaluation_selected",
                    });
                  }
                  setDisabledFields(false);

                  formik.handleChange({
                    target: {
                      id: "basicInfo",
                      value: {
                        ...formik.values.basicInfo,
                        type: option?.value,
                        evaluationId: "",
                      },
                    },
                  });
                  formik.handleChange({
                    target: {
                      id: "actions",
                      value: formik.values.actions.map((action) => {
                        return {
                          ...action,
                          topic: "",
                        };
                      }),
                    },
                  });
                }}
                error={
                  formik.touched.basicInfo?.type &&
                  Boolean(formik.errors.basicInfo?.type)
                }
                helperText={
                  formik.touched.basicInfo?.type &&
                  formik.errors.basicInfo?.type
                }
                fullWidth
              />

              {formik.values.basicInfo?.type === "evaluation" ? (
                evaluationsLoading ? (
                  <Skeleton width={"100%"} height={60} variant="rounded" />
                ) : (
                  <SelectField
                    label="Selecionar avaliação"
                    options={evaluations}
                    value={formik.values.basicInfo?.evaluationId}
                    searchable={true}
                    disabled={disabled || disabledFields}
                    onSelectChange={(_, option) => {
                      formik.handleChange({
                        target: {
                          id: "basicInfo",
                          value: {
                            ...formik.values.basicInfo,
                            evaluationId: option?.value,
                          },
                        },
                      });
                      formik.handleChange({
                        target: {
                          id: "actions",
                          value: formik.values.actions.map((action) => {
                            return {
                              ...action,
                              topic: undefined,
                            };
                          }),
                        },
                      });
                    }}
                    error={
                      formik.touched.basicInfo?.evaluationId &&
                      Boolean(formik.errors.basicInfo?.evaluationId)
                    }
                    helperText={
                      formik.touched.basicInfo?.evaluationId &&
                      formik.errors.basicInfo?.evaluationId
                    }
                    fullWidth
                  />
                )
              ) : (
                <></>
              )}
            </Card>
          </>
        )}
      </RightColumn>
    </Columns>
  );
};
