import styled from "styled-components";

export const CellText = styled.div<{ width?: string }>`
  width: ${({ width }) => width || "250px"};
  overflow: hidden;
  white-space: nowrap;
`;

export const CellLine = styled.div`
  display: flex;

  align-items: base-line;
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 4px;
`;
