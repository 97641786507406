import { useCallback } from "react";
import { FormikProps } from "formik";
import { cloneDeep } from "lodash-es";
import { useTheme } from "styled-components";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText } from "@utils";

import {
  StyledBoxAdornment,
  TypesContainer,
  StyledCheckBox,
  BoldText,
} from "./styled";

import {
  ConfigurationsFormProps,
  EvaluationTypes as EvalTypes,
} from "../../../../types";

interface EvaluationTypesProps {
  formik: FormikProps<ConfigurationsFormProps>;
  disabledEdit: boolean;
  hasCalibrationStep: boolean;
  isDraft?: boolean;
}

export const EvaluationTypes = ({
  formik,
  disabledEdit,
  hasCalibrationStep,
  isDraft,
}: EvaluationTypesProps) => {
  const theme = useTheme();

  const handleCheckboxChange = useCallback(
    (types: EvalTypes[], type: EvalTypes, checked: boolean) => {
      let clonedTypes = cloneDeep(types || []);

      if (checked) clonedTypes.push(type);
      else clonedTypes = clonedTypes.filter((types) => types !== type);

      return [...new Set(clonedTypes)];
    },
    []
  );

  const typesInfo = [
    {
      title: "Autoavaliação",
      description:
        "Os participantes selecionados realizam a avaliação de sua própria performance.",
      leftAdornment: (
        <StyledCheckBox
          checked={formik.values?.types?.includes("self")}
          onChange={(_, checked) => {
            const typesUpdated = handleCheckboxChange(
              formik.values.types || [],
              "self",
              checked
            );

            formik.handleChange({
              target: {
                id: "types",
                value: typesUpdated,
              },
            });
          }}
          error={formik.touched.types && Boolean(formik.errors.types)}
          disabled={disabledEdit || !isDraft}
        />
      ),
    },
    {
      title: "Líder avalia liderado",
      description: (
        <>
          <p>
            Os participantes selecionados serão avaliados por seus líderes
            diretos.
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icons
              name="IconInfoCircle"
              size={16}
              color={theme.colors.secondary[50]}
              style={{
                flexShrink: 0,
                marginRight: "6px",
              }}
            />
            <div>
              <StyledText variant="body4" setColor="neutral50">
                Apenas a{" "}
                <BoldText
                  children="nota do líder"
                  disabled={disabledEdit || hasCalibrationStep || !isDraft}
                />{" "}
                (líder avalia liderado) será considerada no cálculo da{" "}
                <BoldText
                  children="nota final"
                  disabled={disabledEdit || hasCalibrationStep || !isDraft}
                />{" "}
                e poderá ser calibrada.
              </StyledText>
              <StyledText variant="body4" setColor="neutral50">
                Por isso, caso a{" "}
                <BoldText
                  children="calibração"
                  disabled={disabledEdit || hasCalibrationStep || !isDraft}
                />{" "}
                tenha sido selecionada no ciclo, se torna{" "}
                <BoldText
                  children="obrigatória"
                  disabled={disabledEdit || hasCalibrationStep || !isDraft}
                />
                a seleção dessa modalidade de avaliação.
              </StyledText>
            </div>
          </div>
        </>
      ),

      leftAdornment: (
        <StyledCheckBox
          checked={formik.values?.types?.includes("byLeader")}
          onChange={(_, checked) => {
            const typesUpdated = handleCheckboxChange(
              formik.values.types || [],
              "byLeader",
              checked
            );

            formik.handleChange({
              target: {
                id: "types",
                value: typesUpdated,
              },
            });
          }}
          error={formik.touched.types && Boolean(formik.errors.types)}
          disabled={disabledEdit || hasCalibrationStep || !isDraft}
        />
      ),
    },
    {
      title: "Liderado avalia líder",
      description:
        "Os participantes selecionados serão avaliados por seus liderados diretos.",
      leftAdornment: (
        <StyledCheckBox
          checked={formik.values?.types?.includes("byLed")}
          onChange={(_, checked) => {
            const typesUpdated = handleCheckboxChange(
              formik.values.types || [],
              "byLed",
              checked
            );

            formik.handleChange({
              target: {
                id: "types",
                value: typesUpdated,
              },
            });
          }}
          error={formik.touched.types && Boolean(formik.errors.types)}
          disabled={disabledEdit || !isDraft}
        />
      ),
    },
  ];

  return (
    <TypesContainer>
      {typesInfo.map((i) => (
        <StyledBoxAdornment
          key={i.title}
          title={i.title}
          description={i.description}
          leftAdornment={i.leftAdornment}
          disabled={disabledEdit || !isDraft}
        />
      ))}
    </TypesContainer>
  );
};
