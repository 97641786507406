import { useMemo } from "react";
import { useTheme } from "styled-components";

import {
  Typography,
  GenericProfilePicture,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CardContainer,
  Icon,
  TextContainer,
  StatusContainer,
  EvaluatedsContainer,
} from "./styled";

import type { LabelType, StatusConfigType } from "./types";

interface IEvaluationCardProps {
  type: "self" | "byLed" | "byLeader";
  status: "available" | "done";
  evaluatedName?: string;
  statusMessage?: string;
  statusDate?: Date | dayjs.Dayjs | string;
  disabled?: boolean;
}

export const EvaluationCard = ({
  type,
  evaluatedName,
  status,
  statusMessage,
  statusDate,
  disabled,
}: IEvaluationCardProps) => {
  const theme = useTheme();

  const isLeaderType = type === "byLeader" && evaluatedName;

  const labels: LabelType = {
    self: {
      title: "Se autoavalie",
      subTitle: "Avalie sua própria performance",
    },
    byLed: {
      title: "Avalie seu líder",
      subTitle: "Avalie a performance de seu líder direto",
    },
    byLeader: {
      title: "Avalie seus liderados",
      subTitle: "Avalie a performance de seu(s) liderado(s) direto(s)",
    },
  };

  const statusConfigs: StatusConfigType = {
    available: {
      variant: "default",
      icon: "IconNotes",
      iconColor: theme.colors.primary,
    },
    done: {
      variant: "success",
      icon: "IconCheck",
      iconColor: theme.colors.feedback.success[40],
    },
  };

  const labelsByType = useMemo(() => {
    return labels[type];
  }, [type]);

  const configByStatus = useMemo(() => {
    return statusConfigs[status];
  }, [status]);

  return (
    <CardContainer disabled={disabled}>
      <Icon
        size={48}
        variant={configByStatus.variant}
        name={configByStatus.icon}
        color={configByStatus.iconColor}
        stroke={configByStatus.variant}
      />
      <TextContainer>
        <Typography variant="headline8" variantColor={theme.colors.neutral30}>
          {labelsByType.title}
        </Typography>
        <Typography variant="body4" variantColor={theme.colors.neutral40}>
          {labelsByType.subTitle}
        </Typography>
      </TextContainer>
      {!isLeaderType && (
        <StatusContainer>
          {statusMessage && (
            <Typography
              variant="caption"
              variantColor={theme.colors.neutral[50]}
            >
              {statusMessage}
            </Typography>
          )}

          {statusDate && (
            <Typography
              variant="caption"
              weight={700}
              variantColor={theme.colors.neutral[50]}
            >
              {dayjs(statusDate).isValid()
                ? dayjs(statusDate).format("DD/MM/YYYY")
                : ""}
            </Typography>
          )}
        </StatusContainer>
      )}
      {isLeaderType && (
        <EvaluatedsContainer>
          {evaluatedName && (
            <GenericProfilePicture
              enableEdit={false}
              size={40}
              name={evaluatedName}
            />
          )}

          <TextContainer style={{ flex: 1 }}>
            {evaluatedName && (
              <Typography
                variant="body3"
                weight={600}
                variantColor={theme.colors.neutral[40]}
              >
                {evaluatedName}
              </Typography>
            )}
          </TextContainer>
          <StatusContainer>
            {statusMessage && (
              <Typography
                variant="caption"
                variantColor={theme.colors.neutral[50]}
              >
                {statusMessage}
              </Typography>
            )}

            {statusDate && (
              <Typography
                variant="caption"
                weight={700}
                variantColor={theme.colors.neutral[50]}
              >
                {dayjs(statusDate).isValid()
                  ? dayjs(statusDate).format("DD/MM/YYYY")
                  : ""}
              </Typography>
            )}
          </StatusContainer>
        </EvaluatedsContainer>
      )}
    </CardContainer>
  );
};
