import { Outlet, Navigate } from "react-router-dom";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

export const StagingRoutesGuard = ({
  children,
}: {
  children?: JSX.Element;
}) => {
  const isLoggedIn = getFromLS("hrosAccessToken");

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (process.env.STAGE === "production") {
    return <Navigate to={"/home"} />;
  }

  return children ? children : <Outlet />;
};
