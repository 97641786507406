import { useState } from "react";
import { Icons, IconTypes, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  BannerContainer,
  CloseContainer,
  IconContainer,
  ContentContainer,
} from "./styles";

interface Banner {
  type: "info" | "error";
  icon: IconTypes;
  title: string;
  subTitle?: string;
  hasHideBanner?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Banner = ({
  type,
  icon,
  title,
  subTitle,
  hasHideBanner = true,
  style,
  children,
}: Banner) => {
  const [hideBanner, setHideBanner] = useState<boolean>(false);

  return !hideBanner ? (
    <BannerContainer type={type} style={style}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconContainer type={type}>
          <Icons name={icon} fill="transparent" size={19} />
        </IconContainer>

        <ContentContainer>
          <Typography
            variant="body3"
            style={{ fontWeight: "600", userSelect: "none" }}
          >
            {title}
          </Typography>

          {subTitle && (
            <Typography variant="body4" style={{ userSelect: "none" }}>
              {subTitle}
            </Typography>
          )}

          {children && (
            <div style={{ marginTop: "12px", width: "max-content" }}>
              {children}
            </div>
          )}
        </ContentContainer>
      </div>
      {hasHideBanner && (
        <CloseContainer type={type} onClick={() => setHideBanner(true)}>
          <Icons
            name="IconX"
            size={16}
            style={{ marginTop: "-8px", alignSelf: "flex-start" }}
          />
        </CloseContainer>
      )}
    </BannerContainer>
  ) : null;
};

export default Banner;
