import { useMemo, useState } from "react";

import { trpc } from "@api/client";
import { dispatchToast, getIn } from "@utils";

import { EvaluationType } from "../../../../types";

const useEvaluatedIds = ({
  evaluation,
}: {
  evaluation?: Partial<EvaluationType>;
}) => {
  const [search, setSearch] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { data, isLoading } =
    trpc.performance.evaluation.getParticipantsByType.useQuery(
      { evaluationId: evaluation?._id || "" },
      {
        enabled: !!evaluation?._id,
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            content: "Erro ao carregar os avaliados. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const tabs = useMemo(() => {
    const hasSelfTab = !!evaluation?.types?.find(
      (item) => item?.type === "self"
    );

    const hasByLeaderTab = !!evaluation?.types?.find(
      (item) => item?.type === "byLeader"
    );

    const hasByLedTab = !!evaluation?.types?.find(
      (item) => item?.type === "byLed"
    );

    const items = [] as { label: string; component: React.ReactNode }[];

    let indexes = {} as { [key: string]: "self" | "byLeader" | "byLed" };

    if (hasSelfTab) {
      items.push({
        label: "Autoavaliação",
        component: undefined,
      });
      indexes = { ...indexes, [items.length - 1]: "self" };
    }

    if (hasByLeaderTab) {
      items.push({
        label: "Líder avalia liderado",
        component: undefined,
      });
      indexes = { ...indexes, [items.length - 1]: "byLeader" };
    }

    if (hasByLedTab) {
      items.push({
        label: "Liderado avalia líder",
        component: undefined,
      });
      indexes = { ...indexes, [items.length - 1]: "byLed" };
    }

    return { items, indexes };
  }, [evaluation]);

  const filteredData = useMemo(() => {
    if (!data || !tabs) return [];

    const selectedType = tabs?.indexes?.[selectedTab] || "";

    if (!selectedType) return [];

    const dataToFilter = data?.[selectedType];

    if (!search) return dataToFilter;

    const filtered = dataToFilter?.filter((item) => {
      const searchByEvaluator = getIn(item, "evaluator.name");
      const searchByEvaluated = getIn(item, "evaluated.name");

      const filteredByEvaluator = searchByEvaluator
        ?.toLowerCase()
        .includes(search.toLowerCase());

      const filteredByEvaluated = searchByEvaluated
        ?.toLowerCase()
        .includes(search.toLowerCase());

      return filteredByEvaluated || filteredByEvaluator;
    });

    return filtered;
  }, [data, selectedTab, tabs, search]);

  return {
    tabs,
    filteredData,
    selectedTab,
    setSelectedTab,
    search,
    setSearch,
    isLoading,
  };
};

export { useEvaluatedIds };
