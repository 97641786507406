import { useMemo, useState } from "react";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";
import { ConfirmationModal } from "@components/Modals";
import { Table } from "@components/Table";

import { TableGrid } from "./TableGrid";

import { StyledTitle, StyledText } from "@utils";
import { routes } from "@routes";

import { InfoCard, ItemContainer, TableCard } from "./styled";

import { mockData } from "./mock";

export const PageUpdateParticipants = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const routesBread = [
    {
      label: "Avaliações",
      route: routes.PageManageEvaluations,
    },
    {
      label: "Atualizar participantes",
    },
  ];

  const infoCardData = [
    {
      order: "1.",
      title: "Respostas do líder anterior:",
      description:
        "Caso o líder anterior já tiver respondido a avaliação e o RH optar pelo novo líder, excluiremos a nota antiga.",
    },
    {
      order: "2.",
      title: "Respostas do liderado:",
      description:
        "A atualização de participante afetará todas as modalidades da avaliação (líder escolhido poderá responder sobre seu liderado e ser avaliado pelo mesmo.",
    },
    {
      order: "3.",
      title: "Próximas avaliações:",
      description:
        "A atualização é valida apenas para ciclos em andamento, todas as futuras avaliações serão respondidas pelos novos líderes. Avaliações encerradas ou em calibração permanecerão com o histórico de resposta dos líderes anteriores.",
    },
  ];

  const getEvaluatedsToUpdate = mockData;

  const unchangedLeds = useMemo(
    () => getEvaluatedsToUpdate?.length - selectedIds?.length,
    [selectedIds, getEvaluatedsToUpdate]
  );

  return (
    <PageTemplate
      routes={routesBread}
      footer={{
        confirmProps: {
          width: "238px",
          title: (
            <>
              Atualizar avaliações
              <Icons name="IconCheck" fill="transparent" />
            </>
          ),

          callback: () => setIsOpen(true),
        },
      }}
    >
      <StyledTitle
        children="Atualizar participantes"
        setColor="neutral20"
        variant="headline6"
        style={{ marginTop: 32 }}
      />
      <InfoCard>
        <StyledTitle
          children="Informações importantes:"
          setColor="secondary50"
          variant="headline7"
        />
        {infoCardData.map((data) => (
          <ItemContainer>
            <StyledText
              children={data.order}
              setColor="secondary50"
              variant="body3"
              fontWeight={700}
            />
            <div>
              <StyledText
                children={data.title}
                setColor="secondary50"
                variant="body3"
                fontWeight={700}
              />
              <StyledText
                children={data.description}
                setColor="neutral30"
                variant="body3"
                fontWeight={600}
              />
            </div>
          </ItemContainer>
        ))}
      </InfoCard>
      <TableCard>
        <StyledText
          children={
            "Marque com “check” todas as pessoas que terão sua avaliação respondida pelo novo líder."
          }
          setColor="neutral30"
          variant="body3"
          fontWeight={700}
        />
        <Table
          data={getEvaluatedsToUpdate}
          loading={false}
          searchable={true}
          searchableField="led.name"
          searchPlaceholder={"Buscar por liderado"}
          tableGridRender={(props) => (
            <TableGrid onIdsSelected={setSelectedIds} {...props} />
          )}
        />
      </TableCard>
      <ConfirmationModal
        showWarning={true}
        primaryColor="secondary50"
        icon="IconAlertCircle"
        variantType="default"
        title={`Tem certeza que deseja atualizar ${
          selectedIds.length
        } participante${selectedIds.length > 1 ? "s" : ""}?`}
        subTitle={
          unchangedLeds
            ? `${
                unchangedLeds > 1
                  ? unchangedLeds +
                    ` liderados continuaram sendo avaliados por seus antigos líderes durante os ciclos em andamento.`
                  : unchangedLeds +
                    ` liderado continua sendo avaliado por seu antigo lídere durante os ciclos em andamento.`
              } .`
            : getEvaluatedsToUpdate?.length +
              ` continuaram sendo avaliados por seus antigos líderes durante os ciclos em andamento.`
        }
        confirm={{
          title: "Atualizar participantes",
          icon: "IconAlertCircle",
          iconColor: "transparent",
        }}
        open={isOpen}
        onConfirm={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
      />
    </PageTemplate>
  );
};
