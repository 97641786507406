import { useMemo } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";

import { trpc } from "@api/client";

import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  Skeleton,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";
import { StyledText, StyledTitle, dispatchToast } from "@utils";

import { PerformanceEvaluations } from "./Tabs/PerformanceEvaluations";
import { Feedbacks } from "./Tabs/Feedbacks";
import { Idps } from "./Tabs/Idps";

import {
  FlexCenter,
  FlexColumn,
  MainContainer,
  PersonContainer,
  StyledAvatar,
} from "./styled";

export const PagePersonDetails = () => {
  const { origin = "", _id = "" } = useParams();

  const navigate = useNavigate();

  const availableOrigins = {
    cycles: {
      route: routes.PageMyCycles(),
      label: "Meus Ciclos",
      tab: 0,
    },
    evaluations: {
      route: routes.PageManageEvaluations,
      label: "Gerenciar Avaliações",
      tab: 0,
    },
    "manage-feedbacks": {
      route: routes.PageManageFeedbacks,
      label: "Gerenciar Feedbacks",
      tab: 1,
    },
    "manage-idps": {
      route: routes.PageManageIDP,
      label: "Gerenciar PDIs",
      tab: 2,
    },
    "my-cycles": {
      route: routes.PageMyCycles(),
      label: "Minhas Avaliações",
      tab: 0,
    },
    "my-feedbacks": {
      route: routes.PageMyFeedbacks,
      label: "Meus Feedbacks",
      tab: 1,
    },
    "my-idps": {
      route: routes.PageMyIdps,
      label: "Meu PDI",
      tab: 2,
    },
  };

  const availableOrigin = useMemo<
    { route: string; label: string; tab: number } | undefined
  >(() => {
    return availableOrigins[origin || ""] || undefined;
  }, [origin]);

  if (!availableOrigin) return <Navigate to={`/home`} />;

  const breadCrumbs = [
    <LinkButton
      key="1"
      variant="neutral"
      style={{ alignSelf: "flex-start" }}
      onClick={() => navigate(availableOrigin?.route)}
    >
      {availableOrigin?.label}
    </LinkButton>,
    <LinkButton key="2" variant="neutral">
      Detalhe da pessoa
    </LinkButton>,
  ];

  const { data: evaluated, isFetching: isEmployeeFetching } =
    trpc.employee.getEmployeeById.useQuery(
      {
        employeeId: _id,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!_id,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar os dados do colaborador. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  return (
    <PageContainer>
      <MainContainer>
        <Breadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={breadCrumbs}
        />

        <PersonContainer>
          {isEmployeeFetching ? (
            <Skeleton variant="rectangular" height="62px" width={"100%"} />
          ) : (
            <>
              <StyledAvatar style={{ marginRight: "10px" }}>
                {evaluated?.name?.charAt(0) ?? ""}
              </StyledAvatar>
              <FlexColumn>
                <FlexCenter>
                  <StyledTitle setColor="neutral20" variant="headline6">
                    {evaluated?.name}
                  </StyledTitle>
                </FlexCenter>
                <FlexCenter style={{ marginTop: "4px" }}>
                  <StyledText variant="body4" setColor="neutral50">
                    {evaluated?.email || "Não cadastrado"}
                  </StyledText>
                </FlexCenter>
              </FlexColumn>
            </>
          )}
        </PersonContainer>

        <Tab
          defaultTab={availableOrigin?.tab || 0}
          tabItens={[
            {
              label: "Avaliações de desempenho",
              component: <PerformanceEvaluations evaluated={evaluated} />,
            },
            { label: "Feedbacks", component: <Feedbacks /> },
            { label: "PDI", component: <Idps /> },
          ]}
        />
      </MainContainer>
    </PageContainer>
  );
};
