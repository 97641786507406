import { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Button,
  PillButton,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { SectionForm } from "./components/SectionForm";

import { StyledTitle, dispatchToast } from "@utils";

import { StyledModal, Header, Footer } from "./styled";

import type { CommonModalProps } from "@components/Modals/types";
import type { Section, SectionFormProps, onCompletedType } from "./types";

const validationSchema = yup.object({
  name: yup.string().required("Favor preencher este campo!"),
  description: yup.string().notRequired(),
});

interface IRenameSectionModalProps extends CommonModalProps {
  variant: "create" | "update";
  data?: Section;
  sectionTitles?: string[];
  onCompleted: onCompletedType;
}

type RenameSectionModalProps = Omit<IRenameSectionModalProps, "onConfirm">;

export const RenameSectionModal = ({
  open,
  variant,
  onClose,
  data,
  sectionTitles = [],
  isLoading,
  onCompleted,
}: RenameSectionModalProps) => {
  const formik = useFormik<SectionFormProps>({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onCompleted({ ...values, fromModel: false });
      onClose?.(new Event("click"));
    },
  });

  const validateSectionExists = useCallback(
    (newTitle: string) => {
      if (!sectionTitles?.length) return false;

      const nameExists = sectionTitles.includes(newTitle);
      const isSameUpdating = data?.name === newTitle;

      if (nameExists && !isSameUpdating) return true;

      return false;
    },
    [data, sectionTitles]
  );

  const clearAndClose = () => {
    formik.resetForm();
    onClose?.(new Event("click"));
  };

  useEffect(() => {
    !open && formik.resetForm();
  }, [open]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const section: SectionFormProps = {
        name: data?.name || "",
        description: data?.description || "",
      };

      formik.setValues({
        ...formik.initialValues,
        ...section,
      });
    }
  }, [data]);

  return (
    <StyledModal
      open={open}
      onClose={clearAndClose}
      footer={
        <Footer>
          <LinkButton
            variant="neutral"
            children="Cancelar"
            style={{ alignSelf: "center" }}
            onClick={clearAndClose}
          />
          <Button
            size="large"
            variant="primary"
            children={"Salvar"}
            type="submit"
            style={{ alignSelf: "center" }}
            loading={isLoading}
            onClick={async () => {
              const errors = await formik.validateForm();

              if (!Object.keys(errors).length) {
                const exists = validateSectionExists(formik.values.name);

                if (exists) {
                  const message = "Já existe uma seção com esse nome.";

                  formik.setFieldTouched("name", true, false);
                  formik.setFieldError("name", message);

                  dispatchToast({ type: "error", content: message });

                  return;
                }
              }

              formik.handleSubmit();
            }}
          />
        </Footer>
      }
      header={
        <Header>
          <StyledTitle
            variant="headline6"
            setColor="neutral10"
            children={`${variant === "create" ? "Nomear" : "Renomear"} seção`}
          />

          <div style={{ marginTop: "-20px" }}>
            <PillButton
              variant="default"
              size="small"
              type="secondary"
              icon="IconX"
              onClick={onClose}
            />
          </div>
        </Header>
      }
    >
      <SectionForm formik={formik} />
    </StyledModal>
  );
};
