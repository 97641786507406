import styled, { css } from "styled-components";

import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";

const CardContainer = styled.section<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.neutral100};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: 1px solid ${({ theme }) => theme.colors.neutral80};
  padding: ${({ theme }) => theme.spacings.xs1};
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
  align-items: center;
  user-select: none;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.neutral95};
      border: 1px solid ${theme.colors.neutral90};
      opacity: 0.8;
      pointer-events: none;
    `}
`;

const Icon = styled(ShapeIcon)`
  padding: ${({ theme }) => theme.spacings.xs3};
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
`;

const EvaluatedsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacings.xs2};
  gap: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
`;

export {
  CardContainer,
  Icon,
  TextContainer,
  StatusContainer,
  EvaluatedsContainer,
};
