import { useNavigate } from "react-router-dom";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

import { ConfirmationModal } from "@components/Modals";

import { routes } from "@routes";

interface EvaluationCreatedModalProps {
  open: boolean;
  isEdit?: boolean;
}

export const EvaluationCreatedModal = ({
  open,
  isEdit,
}: EvaluationCreatedModalProps) => {
  const navigate = useNavigate();

  const userAuth = getFromLS("userAuth");

  const name = userAuth?.attributes?.name || "";
  const splittedName = name.split(" ")?.[0] || "";

  return (
    <ConfirmationModal
      open={open}
      showWarning
      surveyId={isEdit ? "" : "modal_create_evaluation_survey"}
      warningTitle={splittedName ? `Parabéns, ${splittedName}!` : "Parabéns"}
      variantType="default"
      primaryColor="primary"
      icon="IconCheck"
      title={
        isEdit
          ? "Você acaba de editar uma avaliação!"
          : "Você acaba de criar uma avaliação!"
      }
      subTitle="Deseja criar mais avaliações dentro desse ciclo?"
      cancel={{ title: "Agora não" }}
      confirm={{
        title: "Criar nova avaliação",
      }}
      onClose={() => navigate(routes.PageManageEvaluations)}
      onConfirm={() => navigate(routes.PageCreateEvaluation("models"))}
    />
  );
};
