import styled from "styled-components";

export const NameContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 220px;
`;

export const ColumnWrapper = styled.div``;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
`;
