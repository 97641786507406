import { useMemo, useState } from "react";
import { MenuItem } from "@mui/material";

import {
  DataGrid,
  Icons,
  dayjs,
  Typography,
  Pagination,
  Menu,
  PillButton,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { DeleteCriteriaModal, EditCriteriaModal } from "@components/Modals";
import { ProfileTable } from "@components/ProfileTable";

import {
  StyledIcon,
  StyledText,
  dispatchToast,
  pageSizeOptions,
  renderHtmlText,
} from "@utils";

import {
  CellArea,
  ItensPerPageArea,
  Option,
  PaginationArea,
  PaginationContainer,
  StyledSelectField,
  StyledTypography,
  TooltipText,
} from "./styled";

import type { TableGridCommonProps } from "@components/PaginationTable/types";
import { OverflowCheck } from "@components/OverflowCheck";

interface ITableGridProps extends TableGridCommonProps {
  onCreateClick: () => void;
}

export const TableGrid = ({
  data,
  pagination,
  loading,
  isFiltered,
  onPaginationChanged,
  onCreateClick,
  refetch,
}: ITableGridProps) => {
  const utils = trpc.useContext();

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [criteriaId, setCriteriaId] = useState("");

  const { mutate: duplicateMutate } =
    trpc.performance.criteria.duplicateCriteria.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Critério avaliativo duplicado com sucesso!",
        });
        refetch?.();
      },
      onError: () => {
        const message =
          "Erro ao duplicar o critério avaliativo, tente novamente em breve.";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: "Critério",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description = "" } = original;
          return (
            <CellArea>
              <div>
                <TooltipText variant="body3">
                  {OverflowCheck({
                    text: name,
                    width: "24ch",
                    customTooltipText: name,
                  })}
                </TooltipText>
              </div>
              <Typography variant="body4">
                {OverflowCheck({
                  text: renderHtmlText(description),
                  width: "40ch",
                  customTooltipText: renderHtmlText(description),
                })}
              </Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Criado por",
        disableSortBy: true,
        accessor: "createdBy",
        Cell: ({ row: { original } }) => {
          const { createdBy } = original;
          return (
            <ProfileTable
              name={createdBy?.name ?? "-"}
              email={createdBy?.email ?? "-"}
            />
          );
        },
      },
      {
        Header: "Criado em",
        disableSortBy: true,
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          const { createdAt } = original;
          return (
            <StyledText variant="body3">
              {dayjs(createdAt).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Ações",
        disableSortBy: true,
        accessor: "action",
        sticky: "right",
        Cell: ({ row }) => {
          return (
            <Menu
              type={"default"}
              options={[
                {
                  onClick: () => {
                    utils.performance.criteria.getCriteriaById.invalidate();
                    setCriteriaId(row?.original?._id);
                    setEditModal(true);
                  },
                  children: (
                    <Option>
                      <Icons name="IconPencil" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Editar critério
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    duplicateMutate({
                      params: {
                        _id: row?.original?._id,
                      },
                    });
                  },
                  children: (
                    <Option>
                      <Icons name="IconCopy" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Duplicar critério
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    setCriteriaId(row?.original?._id);
                    setDeleteModal(true);
                  },
                  children: (
                    <Option>
                      <StyledIcon
                        name="IconTrash"
                        fill="transparent"
                        setColor="error50"
                      />
                      <StyledText
                        variant="body3"
                        style={{ fontWeight: 600 }}
                        setColor="error50"
                      >
                        Excluir critério
                      </StyledText>
                    </Option>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                variant="default"
                size="small"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    [data]
  );

  const { page, pageSize, totalCount } = pagination;

  if (!loading && !isFiltered && totalCount === 0) {
    return (
      <EmptyState
        buttonText={
          <>
            Criar critério <Icons name="IconPlus" />
          </>
        }
        description={"Você ainda não criou critérios avaliativos"}
        buttonProps={{
          size: "medium",
          onClick: onCreateClick,
        }}
      />
    );
  }

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: loading ? "" : "Nenhum resultado encontrado.",
        }}
        pageSizeOptions={pageSizeOptions}
        customPagination={({ setPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 10}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                  onPaginationChanged(page, newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <MenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </MenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > totalCount ? totalCount : page * pageSize}
                &nbsp;de&nbsp;{totalCount || 0}&nbsp;
                {totalCount === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                onChange={(_, value) => {
                  onPaginationChanged(value, pageSize);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />

      <DeleteCriteriaModal
        _id={criteriaId}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
      <EditCriteriaModal
        _id={criteriaId}
        open={editModal}
        onClose={() => setEditModal(false)}
        refetch={() => refetch?.()}
      />
    </>
  );
};
