import { DataCard } from "@components/Cards";

import { CardsContainer } from "./styled";

import { EvaluationType } from "../../../types";
interface BasicInfoTabProps {
  data?: Partial<EvaluationType>;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
}

export const BasicInfoTab = ({
  data,
  onNavigate,
  disabled,
}: BasicInfoTabProps) => {
  return (
    <CardsContainer>
      <DataCard
        title="Informações privadas"
        subTitle="Confira as informações ficarão visíveis apenas para o RH."
        editable={!disabled}
        onEditClick={onNavigate}
        sections={[
          {
            items: [
              {
                title: "Nome da avaliação",
                description: data?.privateName || "-",
              },
              {
                title: "Descrição",
                description: data?.privateDescription || "-",
              },
              {
                title: "Ciclo",
                description: data?.cycleName || "-",
              },
            ],
          },
        ]}
      />

      <DataCard
        title="Informações públicas"
        subTitle="Confira as instruções para os participantes."
        editable={!disabled}
        onEditClick={onNavigate}
        sections={[
          {
            items: [
              {
                title: "Nome da avaliação",
                description: data?.name || "-",
              },
              {
                title: "Descrição",
                description: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description || "-",
                    }}
                    style={{ listStylePosition: "inside" }}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </CardsContainer>
  );
};
