import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 40px 24px 40px 112px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 700px;
`;

export const FooterContainer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`;
