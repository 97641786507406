import { Filter } from "@components/PaginationTable/types";

const filters = [
  {
    accessor: "status",
    label: "Status",
    options: [
      { value: "closed", label: "Ciclo encerrado" },
      { value: "scheduled", label: "Ciclo agendado" },
      { value: "available", label: "Ciclo ativo" },
    ],
    type: "checkbox",
  },
  {
    accessor: "steps",
    label: "Etapas",
    options: [
      { value: "evaluation", label: "Avaliação" },
      { value: "calibration", label: "Calibração" },
      { value: "idp", label: "PDI" },
      { value: "feedback", label: "Feedback" },
    ],
    type: "checkbox",
  },
  {
    accessor: "startDate",
    label: "Início",
    options: [],
    type: "date",
  },
  {
    accessor: "endDate",
    label: "Encerramento",
    options: [],
    type: "date",
  },
  {
    accessor: "createdAt",
    label: "Criado em",
    options: [],
    type: "date",
  },
] as Filter[];

export { filters };
