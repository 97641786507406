import { useMemo } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  Menu,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "@routes";
import { RenderNameField } from "@components/TableNameField";
import { StyledIcon, StyledText, pageSizeOptions, track } from "@utils";
import { useNavigate } from "react-router-dom";

import { Option } from "./styled";
import { ProgressBar } from "@components/ProgressBar";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  refetch?: () => void;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
}: TableGridProps) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Pessoa",
        accessor: "name",
        Cell: ({ row }) => RenderNameField(row?.original?.name || "-"),
      },
      {
        Header: "Líder",
        accessor: "reportsToName",
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <StyledText
              variant="body3"
              children={row?.original?.reportsToName || "-"}
            />
          </div>
        ),
      },
      {
        Header: "Total de planos",
        accessor: "total",
        Cell: ({ row }) => (
          <div style={{ width: "130px" }}>
            <StyledText variant="body3" children={row?.original?.total} />
          </div>
        ),
      },
      {
        Header: "Planos não iniciados",
        accessor: "created",
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <StyledText variant="body3" children={row?.original?.created} />
          </div>
        ),
      },
      {
        Header: "Planos em andamento",
        accessor: "progressing",
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <StyledText variant="body3" children={row?.original?.progressing} />
          </div>
        ),
      },
      {
        Header: "Planos atrasados",
        accessor: "delayed",
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <StyledText variant="body3" children={row?.original?.delayed} />
          </div>
        ),
      },
      {
        Header: "Planos concluídos",
        accessor: "finished",
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <StyledText variant="body3" children={row?.original?.finished} />
          </div>
        ),
      },
      {
        Header: "Taxa de conclusão",
        accessor: "completionRate",
        Cell: ({ row }) => (
          <ProgressBar progress={row?.original?.completionRate?.toFixed(2)} />
        ),
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        Cell: ({ row }) => {
          const employeeId = row?.original?._id || "";
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () => {
                    track({
                      name: "performance_idp_peoplelist_seepersondetails_clicked",
                    });

                    navigate(
                      routes.PagePersonDetails("manage-idps", employeeId)
                    );
                  },
                  children: (
                    <Option>
                      <StyledIcon
                        name="IconUser"
                        fill="transparent"
                        setColor="neutral40"
                        size={25}
                        style={{ marginRight: "12px" }}
                      />
                      <StyledText variant="body3" setColor="neutral40">
                        Ver detalhes da pessoa
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    track({
                      name: "performance_idp_peoplelist_createnewplan_clicked",
                    });
                    navigate(
                      routes.PageCreateIDP.replace(
                        ":origin",
                        "manage-idps"
                      ).replace(":step", "1") + `/to/${row.original._id}`
                    );
                  },
                  children: (
                    <Option>
                      <StyledIcon
                        name="IconPlus"
                        fill="transparent"
                        setColor="neutral40"
                        size={25}
                        style={{ marginRight: "12px" }}
                      />
                      <StyledText variant="body3" setColor="neutral40">
                        Criar Plano de Ação
                      </StyledText>
                    </Option>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                size="small"
                variant="default"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhuma pessoa encontrada."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhuma pessoa foi encontrada.",
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};
