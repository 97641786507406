import { useState, useMemo } from "react";
import { cloneDeep } from "lodash";

import { TableSearch } from "./TableSearch";
import { TableFilters } from "./TableFilters";
import { TableGrid } from "./TableGrid";

import { TableProps } from "./types";

export const Table = ({ loading, data = [], filters }: TableProps) => {
  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const filteredData = useMemo(() => {
    data.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    if (!search && !Object.keys(filterStates)?.length) return data;

    let dataToFilter = cloneDeep(data);

    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const name = item?.name?.toLowerCase().includes(search.toLowerCase());

        return name;
      });
    }

    if (Object.keys(filterStates)?.length) {
      dataToFilter = dataToFilter?.filter((item) => {
        for (const key in filterStates) {
          const itemValue = item[key] || undefined;

          if (itemValue === undefined) return false;

          if (Array.isArray(itemValue)) {
            if (!itemValue.some((el) => filterStates[key].includes(el))) {
              return false;
            }
          } else {
            if (!filterStates[key].includes(item[key])) return false;
          }
        }

        return true;
      });
    }
    return dataToFilter;
  }, [data, search, filterStates]);

  return (
    <div>
      <TableSearch
        disabled={!Boolean((data || [])?.length)}
        onChange={setSearch}
      />
      {filters?.[0] && (
        <TableFilters
          disabled={!Boolean((data || [])?.length)}
          filters={filters}
          onFilter={setFilterStates}
        />
      )}
      <TableGrid
        data={filteredData || []}
        rawData={data || []}
        pageSize={10}
        loading={loading}
      />
    </div>
  );
};
