import styled from "styled-components";

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin: 40px 0px 20px 0px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SelectArea = styled.div`
  width: 100%;
  max-width: 290px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: none;
  }
`;
