import { useMemo } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  dayjs,
  Tag,
  Button,
  Dot,
} from "@flash-tecnologia/hros-web-ui-v2";

import { RenderNameField } from "@components/TableNameField";

import type { TableGridCommonProps } from "@components/Table/types";

import { StyledText, pageSizeOptions } from "@utils";

export const TableGrid = ({ data, rawData, loading }: TableGridCommonProps) => {
  // const [openDrawer, setOpenDrawer] = useState({
  //   open: false,
  //   feedbackData: "",
  //   answering: false,
  // });

  const columns = useMemo(
    () => [
      {
        Header: "Enviado por",
        accessor: "from",
        Cell: ({ row }) => RenderNameField(row.original.from.name || "-"),
      },
      {
        Header: "Enviado para",
        accessor: "to",
        Cell: ({ row }) => RenderNameField(row.original.to.name || "-"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const info = {
            answered: { label: "Respondido", variant: "success" },
            unanswered: { label: "Não respondido", variant: "error" },
            draft: { label: "Rascunho", variant: "info" },
          };

          return (
            <div style={{ display: "flex", gap: "8px", width: "150px" }}>
              <Tag variant={info[value].variant}>
                <Dot variant={info[value].variant} />
                <StyledText variant="caption" style={{ fontWeight: 600 }}>
                  {info[value].label}
                </StyledText>
              </Tag>
            </div>
          );
        },
      },

      {
        Header: "Criado em",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const isValid = dayjs(value).isValid();
          return isValid ? dayjs(value).format("DD/MM/YYYY") : "-";
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: () => {
          return (
            <Button
              variant="secondary"
              size="medium"
              style={{ width: "222px" }}
              onClick={() => {
                // setOpenDrawer({
                //   open: true,
                //   feedbackData: row.original,
                //   answering: false,
                // });
              }}
            >
              Ver feedback
            </Button>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum feedback foi criado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(10) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhum feedback foi encontrado!",
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};
