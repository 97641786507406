import styled from "styled-components";

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataGridContainer = styled.div`
  .data-grid-pagination-container {
    margin: 16px 0px 0px 0px !important;
  }

  .data-grid-custom-select div.MuiSelect-select {
    padding-right: 36px !important;
  }

  .select-icon-custom svg {
    top: calc(50% - 0.7em);
    right: 5px;
  }
`;

export { FlexBetween, FlexRow, FlexColumn, DataGridContainer };
