import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const SectionTitle = styled(Typography)`
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 24px;
`;

export const InfoArea = styled.div`
  display: flex;
`;

export const InfoLabel = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const InfoText = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 24px;
`;

export const Divider = styled.div`
  height: 1px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  width: 100%;
  margin: 24px 0;
`;

export const InputLabel = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
