import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

import { dispatchToast, StyledText, StyledTitle, track } from "@utils";

import { EvaluationCreatedModal } from "@components/Modals";
import { PageTemplate } from "@components/PageTemplate";

import {
  BasicInfoTab,
  ConfigurationsTab,
  EvalFormTab,
  EvaluatedsTab,
} from "./components/Tabs";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { Header, LeftContainer, StyledTab } from "../../Steps/styled";

import type { BasicStepProps } from "../../Steps/types";

const ItemSkeleton = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
      <Skeleton width="100%" height="300px" variant="rounded" />
      <Skeleton width="100%" height="200px" variant="rounded" />
    </div>
  );
};

export const ReviewStep = ({
  evaluationId,
  isEdit,
  isLoading,
  error,
  steps,
  breadcrumbs,
  evaluation,
  disabledEdit,
  onNavigateByStep,
  refetch,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [evaluationCreatedModalOpen, setEvaluationCreatedModalOpen] =
    useState(false);

  const utils = trpc.useContext();

  const tabs = {
    [0]: (
      <BasicInfoTab
        data={evaluation}
        onNavigate={() => onNavigateByStep("basic-info")}
        disabled={disabledEdit.all}
      />
    ),
    [1]: (
      <ConfigurationsTab
        data={evaluation}
        onNavigate={() => onNavigateByStep("configurations")}
        disabled={disabledEdit.all || disabledEdit.partial}
      />
    ),
    [2]: <EvaluatedsTab evaluation={evaluation} />,
    [3]: (
      <EvalFormTab
        data={evaluation?.sections || []}
        onNavigate={() => onNavigateByStep("form")}
        disabled={disabledEdit.all || disabledEdit.partial}
      />
    ),
  };

  const { mutate, isLoading: isMutating } =
    trpc.performance.evaluation.generateEvaluation.useMutation({
      onSuccess: () => {
        setEvaluationCreatedModalOpen(true);
        refetch?.();
      },
      onError: (error: any) => {
        const messageSelect = {
          EVALUATION_NOT_EXISTS_ERROR:
            "Erro ao gerar a avaliação. avaliação não encontrada!.",
          CYCLE_STEP_NOT_AVAILABLE_ERROR:
            "O ciclo desta avaliação está com o prazo expirado. Para continuar, é necessário atualizar a data de encerramento das etapas do ciclo ou selecionar outro ciclo.",
          EVALUATION_WRONG_SETTINGS_ERROR:
            "Um ou mais campos obrigatórios não foram preenchidos. Por favor, revise as etapas e preencha todos os campos necessários.",
          CYCLE_NOT_EXISTS_ERROR:
            "O ciclo selecionado não pode ser encontrado. Por favor, selecione outro ciclo.",
          EVALUATION_MISSING_EMPLOYEES_ERROR:
            "Um ou mais colaboradores atribuidos na avaliação estão sem lider ou liderados associados. Por favor, revise a lista de participantes",
          Default:
            "Não foi possível gerar a avaliação. Por favor, tente novamente em breve.",
        };
        dispatchToast({
          type: "error",
          content:
            messageSelect[error?.data?.error] || messageSelect["Default"],
        });
      },
    });

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 5,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair",
          callback: () => {
            utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
            navigate(routes.PageManageEvaluations);
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => onNavigateByStep("form"),
        },
        confirmProps: {
          title: (
            <>
              {`${isEdit ? "Editar" : "Criar"} avaliação`}
              <Icons name="IconCheck" fill="transparent" />
            </>
          ),
          loading: isMutating,
          disabled: error || isLoading,
          callback: () => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_review_createevaluation_clicked",
            });

            const isDraft = evaluation?.evaluationStatus === "draft";

            if (isDraft) return mutate({ _id: evaluationId || "" });

            setEvaluationCreatedModalOpen(true);
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={4}>
        <Grid item sm={12} md={6} lg={4} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Revisão"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Revise a avaliação e caso necessário edite as informações conforme sua necessidade."
              }
            />

            <Button
              size="medium"
              variant="secondary"
              variantType="default"
              style={{ maxWidth: "386px", minWidth: "100%" }}
              disabled={
                !evaluation?.sections?.some(
                  (section) => section.criterials?.length
                )
              }
              onClick={() => {
                if (!evaluationId) return;
                navigate(routes.PagePreviewEvaluation(evaluationId, "1"));
              }}
            >
              Pré-visualizar avaliação <Icons name="IconFileDescription" />
            </Button>

            <div style={{ marginTop: "16px" }}>
              <StyledTab
                selected={selectedTab}
                onTabChanged={setSelectedTab}
                variant="secondary"
                tabItens={[
                  {
                    label: "Informações básicas",
                    iconLabel: "IconFileDescription",
                    component: undefined,
                  },
                  {
                    label: "Configurações",
                    iconLabel: "IconSettings",
                    component: undefined,
                  },
                  {
                    label: "Avaliados",
                    iconLabel: "IconUsers",
                    component: undefined,
                  },
                  {
                    label: "Formulário de avaliação",
                    iconLabel: "IconListNumbers",
                    component: undefined,
                  },
                ]}
                type="fullWidth"
              />
            </div>
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={6} lg={8} style={{ width: "100%" }}>
          {isLoading ? <ItemSkeleton /> : tabs[selectedTab || 0]}
        </Grid>
      </Grid>

      <EvaluationCreatedModal
        open={evaluationCreatedModalOpen}
        isEdit={isEdit}
      />
    </PageTemplate>
  );
};
