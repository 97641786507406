import { useState } from "react";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";
import {
  ShapeIcon,
  Button,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { StyledText, StyledTitle, StyledIcon, dispatchToast } from "@utils";

import type { CommonModalProps } from "@components/Modals/types";

import { StyledModal, ContentContainer, IconContainer, Footer } from "./styled";

interface IExportEmployeeEvaluationModalProps extends CommonModalProps {
  evaluationId: string;
  employeeId: string;
}

type ExportEmployeeEvaluationModalProps = Omit<
  IExportEmployeeEvaluationModalProps,
  "onConfirm"
>;

export const ExportEmployeeEvaluationModal = ({
  open,
  onClose,
  evaluationId,
  employeeId,
}: ExportEmployeeEvaluationModalProps) => {
  const [hasClicked, setHasClicked] = useState(false);
  const [success, setSuccess] = useState(false);

  const { attributes = null } = getFromLS("userAuth");

  const { isFetching } =
    trpc.performance.report.getEvaluationReportByEmployee.useQuery(
      {
        evaluationId,
        employeeId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 0,
        enabled: !!hasClicked && !!evaluationId && !!employeeId,
        onSuccess: () => {
          setSuccess(true);
          setHasClicked(false);
        },
        onError: () => {
          setSuccess(false);
          setHasClicked(false);

          dispatchToast({
            type: "error",
            content: "Erro ao exportar o relatório, tente novamente em breve.",
          });
        },
      }
    );

  return (
    <StyledModal
      open={open}
      onClose={(e) => {
        onClose?.(e);
        setSuccess(false);
      }}
      footer={
        <Footer success={success}>
          {success ? (
            <Button
              size="large"
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={() => {
                onClose?.(new Event("click"));
                setSuccess(false);
              }}
            >
              Voltar para Detalhe da Avaliação
            </Button>
          ) : (
            <>
              <LinkButton
                variant="neutral"
                children="Cancelar"
                style={{ alignSelf: "center" }}
                onClick={() => {
                  onClose?.(new Event("click"));
                  setSuccess(false);
                }}
              />
              <Button
                size="large"
                variant="primary"
                style={{ alignSelf: "center" }}
                loading={isFetching}
                onClick={() => setHasClicked(true)}
              >
                Exportar relatório
                <StyledIcon
                  setColor="neutral100"
                  name="IconDownload"
                  fill="none"
                  size={40}
                />
              </Button>
            </>
          )}
        </Footer>
      }
    >
      <ContentContainer>
        <IconContainer>
          <ShapeIcon
            variant={"default"}
            name={success ? "IconCheck" : "IconFileSpreadsheet"}
            size={64}
            stroke={"default"}
          />
        </IconContainer>

        {success && (
          <StyledTitle
            variant="body3"
            children="Pronto!"
            setColor="secondary50"
            style={{ textAlign: "center" }}
          />
        )}

        <StyledTitle
          variant="headline6"
          children={
            success
              ? "Seu relatório está sendo exportado!"
              : "Deseja exportar o relatório?"
          }
          setColor="neutral20"
          style={{
            textAlign: "center",
            marginBottom: "16px",
          }}
        />

        <StyledText
          variant="body3"
          setColor="neutral50"
          style={{
            textAlign: "center",
            marginBottom: "14px",
          }}
        >
          {success ? (
            <>
              Para acessá-lo é só conferir o e-mail que será enviado em alguns
              minutos para o endereço: <b>{attributes?.email}</b>
              <br />
              <br />
              Para acessar os dados utilize os{" "}
              <b>4 primeiros dígitos do seu CPF</b> como senha.
            </>
          ) : (
            <>
              O relatório apresentará somente os <b>dados desse colaborador</b>{" "}
              convertidos em um arquivo excel.
              <br /> O arquivo será enviado para o email:
              <br />
              <br />
              <b>{attributes?.email}</b>
            </>
          )}
        </StyledText>
      </ContentContainer>
    </StyledModal>
  );
};
