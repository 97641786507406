import styled from "styled-components";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";

const Header = styled.div`
  display: flex;
  padding: 32px 0px;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;

const RightContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
`;

const StyledTab = styled(Tab)`
  width: 100%;

  > div {
    flex: 1;
  }

  > div button div {
    margin-right: 0px;
  }
`;

export { Header, LeftContainer, RightContainer, StyledTab };
