import styled from "styled-components";

import { Accordion, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const FormSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  height: "500px",
  width: "100%",
}))`
  border-radius: 12px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const StyledAccordion = styled(Accordion)<{
  error?: boolean;
  disableClick?: boolean;
}>`
  width: 100%;

  &.MuiPaper-root {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.colors.feedback.error[50] : theme.colors.neutral[90]};
    border-radius: 8px;
    box-shadow: none;

    &::before {
      background-color: transparent;
    }

    ${({ disableClick }) => (disableClick ? "pointer-events: none" : "")}
  }

  .MuiAccordionSummary-root {
    padding: 24px;
  }

  .MuiAccordionDetails-root {
    padding: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  }
`;

export { FormSkeleton, FormContainer, StyledAccordion };
