import { EmptyState, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledIcon, StyledText, StyledTitle } from "@utils";
import TimelineItem from "@mui/lab/TimelineItem";

import {
  Bullet,
  Connector,
  Content,
  EmptyStateContainer,
  Item,
  LeftContent,
  RightColumn,
  RightContent,
  Separator,
} from "./styled";

export const HistoryTimeline = ({ data }) => {
  if (!data?.length)
    return (
      <EmptyStateContainer>
        <EmptyState
          buttonProps={{ size: "small", hidden: true }}
          buttonText=""
          description="Nenhum histórico encontrado"
        ></EmptyState>
      </EmptyStateContainer>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {data.map((item, index) => {
        const lastItem = index + 1 === data.length;
        const firstItem = index === 0;
        return (
          <Item position="alternate" key={item?.justification}>
            <TimelineItem>
              <LeftContent style={{ flex: 0 }}>
                <StyledText
                  variant="caption"
                  setColor="neutral70"
                  children={
                    dayjs(item?.createdAt).isValid()
                      ? dayjs(item?.createdAt).format("DD/MM/YYYY")
                      : " - "
                  }
                />
              </LeftContent>
              <Separator>
                {!firstItem && <Connector firstItem={true} />}

                <Bullet firstItem={firstItem} />
                {!lastItem && <Connector />}
              </Separator>
              <RightContent>
                <RightColumn>
                  <StyledTitle variant="body3" setColor="neutral30">
                    Nota ajustada
                  </StyledTitle>
                  <Content>
                    <StyledText
                      variant="body4"
                      setColor="neutral50"
                      style={{ textDecoration: "line-through" }}
                      children={item?.previousGrade}
                    />
                    <StyledIcon
                      name="IconArrowRight"
                      setColor="neutral70"
                      size={14}
                    />
                    <StyledText
                      variant="body4"
                      setColor="neutral50"
                      children={item?.currentGrade}
                    />
                  </Content>
                  <StyledText
                    variant="body4"
                    setColor="neutral50"
                    style={{ marginBottom: lastItem ? "2px" : "16px" }}
                  >
                    <b>Justificativa:</b>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.justification }}
                      style={{
                        lineHeight: "normal",
                        listStylePosition: "inside",
                      }}
                    ></div>
                  </StyledText>
                </RightColumn>
              </RightContent>
            </TimelineItem>
          </Item>
        );
      })}
    </div>
  );
};
