import { trpc } from "@api/client";
import { Table } from "./Table";

import { PageContainer } from "./styled";

export const Received = () => {
  const { data, isLoading } =
    trpc.performance.feedback.getManagerReceivedFeedbacks.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    });

  return (
    <PageContainer>
      <Table
        loading={isLoading}
        data={data?.receivedFeedbacks || []}
        filters={data?.filter || []}
      />
    </PageContainer>
  );
};
