import { useState, useEffect } from "react";

import {
  Typography,
  TagCheckboxFilter,
  TagDateFilter,
  TagSliderRangeFilter,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Container } from "./styled";

import type { Filter, Option, onFiltersChangedType } from "./types";

export interface TableFiltersProps {
  disabled?: boolean;
  filters?: Filter[];
  onFilter?: onFiltersChangedType;
}

export const TableFilters = ({
  filters,
  onFilter,
  disabled,
}: TableFiltersProps) => {
  const [filterStates, setFilterStates] = useState<
    | {
        [key: string]: any[];
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (filterStates === undefined) return;

    const timer = setTimeout(() => {
      onFilter && onFilter(filterStates);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [filterStates]);

  const renderFilters = () => {
    return filters?.map((value) => {
      const renderOptions =
        value?.options?.map((e: Option) => {
          return {
            label: e.label || "",
            value: typeof e.value === "boolean" ? e.value : e.value || "",
          };
        }) || [];

      switch (value.type) {
        case "date":
          return (
            <TagDateFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onSubmit={(date) => {
                const filters: any = [];

                const filter: any = {};

                if (date.from) {
                  filter.startDate = dayjs(date?.from).toISOString();
                  filter.endDate = dayjs(date?.from).toISOString();
                }
                if (date.to) filter.endDate = dayjs(date?.to).toISOString();

                if (Object.keys(filter).length) filters.push(filter);

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: filters,
                };

                if (!date.from) delete stateUpdated[value.accessor];

                setFilterStates(stateUpdated);
              }}
              key={(renderOptions || []).length + value.accessor}
            />
          );

        case "slider":
          return (
            <TagSliderRangeFilter
              key={(renderOptions || []).length + value.accessor}
              step={0.5}
              fieldMask={(value: number) => {
                return value.toString();
              }}
              variant={"secondary"}
              filterLabel={value.label}
              min={0}
              max={5}
              onApply={(selectedValues) => {
                if (
                  !selectedValues.length &&
                  !(filterStates?.[value.accessor]?.length || 0)
                )
                  return;

                const filteredOptions = value.options.filter(
                  (option) =>
                    option.value >= selectedValues?.[0] &&
                    option.value <= selectedValues?.[1]
                );

                const parsedValue = filteredOptions.map((e) => e.value);

                selectedValues;

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: parsedValue,
                };

                if (!selectedValues.length) delete stateUpdated[value.accessor];

                setFilterStates(stateUpdated);
              }}
            />
          );
        case "checkbox":
        default:
          return (
            <TagCheckboxFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              showClearButton={true}
              onClick={(e) => {
                if (!e.length && !(filterStates?.[value.accessor]?.length || 0))
                  return;

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: e,
                };

                if (!e.length) delete stateUpdated[value.accessor];

                setFilterStates(stateUpdated);
              }}
              key={(renderOptions || []).length + value.accessor}
            />
          );
      }
    });
  };

  return (
    <Container disabled={disabled}>
      <Typography variant="body3">Filtrar por</Typography>
      {renderFilters()}
    </Container>
  );
};
