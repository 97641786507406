import { useState, useEffect } from "react";

import { trpc } from "@api/client";

import { PaginationTable } from "@components/PaginationTable";

import { TableGrid } from "./TableGrid";

import { dispatchToast } from "@utils";

import { PageContainer } from "./styled";

import type { PaginationProps } from "@components/PaginationTable/types";

export const CriteriaModels = () => {
  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    pageSize: 5,
    totalCount: 0,
  });

  const [search, setSearch] = useState("");

  const [filtersInfo, setFiltersInfo] = useState<
    | {
        hasEvaluation?: "hasEvaluation" | "hasntEvaluation" | undefined;
      }
    | undefined
  >(undefined);

  const filters: any[] = [
    // {
    //   accessor: "hasEvaluation",
    //   label: "Associado à avaliações",
    //   options: [
    //     { value: true, label: "Sim" },
    //     { value: false, label: "Não" },
    //   ],
    //   type: "checkbox",
    // },
  ];

  const {
    data: criterials,
    isFetching,
    refetch,
  } = trpc.performance.criteria.getAllCriterials.useQuery(
    {
      params: {
        type: "default",
        page: pagination?.page,
        pageSize: pagination?.pageSize,
        sortField: "createdAt",
        sortType: "desc",
        search: search ? search : undefined,
        hasEvaluation: filtersInfo?.hasEvaluation || undefined,
      },
    },
    {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Erro ao buscar os modelos de critérios avaliativos, tente novamente em breve!",
        });
      },
      onSuccess: (info) => {
        setPagination({
          page: info?.page,
          pageSize: info?.pageSize,
          totalCount: info?.total,
        });
      },
    }
  );

  useEffect(() => {
    if (!criterials) return;

    if (criterials.total === pagination.totalCount) return;

    setPagination({
      page: criterials.page,
      pageSize: criterials.pageSize,
      totalCount: criterials.total,
    });
  }, [criterials?.total]);

  return (
    <PageContainer>
      <PaginationTable
        loading={isFetching}
        data={criterials?.items || []}
        pagination={pagination}
        filters={filters}
        isFiltered={!!search || !!filtersInfo}
        searchPlaceholder="Buscar por modelos de critérios"
        tableGridRender={(props) => <TableGrid {...props} />}
        onSearch={setSearch}
        onPaginationChanged={(page, pageSize) => {
          setPagination((prev) => ({ ...prev, page, pageSize }));
        }}
        onFiltersChanged={({ hasEvaluation }) => {
          let hasEvaluationFilter: any = undefined;

          if (hasEvaluation?.length === 1) {
            hasEvaluationFilter = hasEvaluation[0]
              ? "hasEvaluation"
              : "hasntEvaluation";
          }

          setFiltersInfo({
            hasEvaluation: hasEvaluationFilter,
          });
        }}
        refetch={refetch}
      />
    </PageContainer>
  );
};
