import { useTheme } from "styled-components";

import { Table } from "@components/Table";
import { FloatingCard } from "@components/Cards";

import { StyledTitle, StyledText } from "@utils";

import { TableGrid } from "./TableGrid";

import { TitleContainer } from "./styled";

import { EvaluationReviewData } from "../../types";

interface IReviewData {
  reviewData: EvaluationReviewData[];
  errorCriterial: { _id: string }[] | undefined;
}

export const ReviewStep = ({
  reviewData = [],
  errorCriterial,
}: IReviewData) => {
  const theme = useTheme();

  return (
    <FloatingCard gap={theme.spacings.s}>
      <TitleContainer>
        <StyledTitle variant="headline8" setColor="secondary50">
          Revise suas respostas antes de enviar:
        </StyledTitle>

        <StyledText variant="body3" setColor="neutral30">
          Você ainda poderá realizar modificações na avaliação enquanto o prazo
          de resposta não expirar.
        </StyledText>
      </TitleContainer>

      <Table
        data={reviewData}
        loading={false}
        tableGridRender={(props) => (
          <TableGrid errorCriterial={errorCriterial} {...props} />
        )}
      />
    </FloatingCard>
  );
};
