import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  Skeleton,
  EmptyState,
  Menu,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { RenderNameField } from "@components/TableNameField";
import { StyledIcon, StyledText, pageSizeOptions } from "@utils";
import { routes } from "@routes";

import { Option } from "./styled";
interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  refetch?: () => void;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
}: TableGridProps) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Pessoa",
        accessor: "name",
        Cell: ({ row }) => RenderNameField(row?.original?.name || "-"),
      },
      {
        Header: "Feedbacks recebidos",
        accessor: "feedbacksReceived",
      },
      {
        Header: "Feedbacks enviados",
        accessor: "feedbacksSent",
      },
      {
        Header: "Solicitações recebidos",
        accessor: "requestsReceived",
      },
      {
        Header: "Solicitações enviadas",
        accessor: "requestsSent",
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        Cell: ({ row }) => {
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () =>
                    navigate(
                      routes.PagePersonDetails(
                        "manage-feedbacks",
                        row?.original?._id
                      )
                    ),
                  children: (
                    <Option>
                      <StyledIcon
                        name="IconUser"
                        fill="transparent"
                        setColor="neutral40"
                        size={25}
                        style={{ marginRight: "12px" }}
                      />
                      <StyledText variant="body3" setColor="neutral40">
                        Ver detalhes da pessoa
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () =>
                    navigate(
                      routes.PageManagerSendFeedback +
                        "/to/" +
                        row?.original?._id
                    ),
                  children: (
                    <Option>
                      <StyledIcon
                        name="IconMailbox"
                        fill="transparent"
                        setColor="neutral40"
                        size={25}
                        style={{ marginRight: "12px" }}
                      />
                      <StyledText variant="body3" setColor="neutral40">
                        Enviar Feedback
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    navigate(
                      routes.PageManagerRequestFeedback.replace(
                        ":filterType",
                        "to"
                      ).replace(":_id", row?.original?._id)
                    );
                  },
                  children: (
                    <Option>
                      <StyledIcon
                        name="IconMailForward"
                        fill="transparent"
                        setColor="neutral40"
                        size={25}
                        style={{ marginRight: "12px" }}
                      />
                      <StyledText variant="body3" setColor="neutral40">
                        Solicitar Feedback
                      </StyledText>
                    </Option>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                size="small"
                variant="default"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhuma pessoa encontrada."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhuma pessoa encontrada.",
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};
