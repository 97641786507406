import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText, StyledTitle, track } from "@utils";
import { routes } from "@routes";
import { useNavigate } from "react-router-dom";

import { HeaderArea, TitleArea, OptionsArea, CreateButton } from "./styled";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <HeaderArea>
      <TitleArea>
        <StyledTitle variant="headline6" setColor="neutral20">
          Ciclos de Desempenho
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral40">
          Crie e personalize todas as etapas dos ciclos de desempenho da
          empresa.
        </StyledText>
      </TitleArea>
      <OptionsArea>
        <CreateButton
          variant="primary"
          size="large"
          onClick={() => {
            navigate(routes.PageCreateCycle());
            track({
              name: "people_strategic_hr_performance_company_cycles_createcycle_clicked",
            });
          }}
        >
          Criar ciclo
          <Icons name="IconPlus" />
        </CreateButton>
      </OptionsArea>
    </HeaderArea>
  );
};
