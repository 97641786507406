import { useCallback, useEffect, useMemo, useState } from "react";

import { cloneDeep } from "lodash-es";

import {
  Button,
  DataGridProps,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";

import { RequiredAsterisk, StyledText } from "@utils";

import { Options } from "./Options";

import { FlexBetween, FlexColumn } from "./styled";

import type {
  EvaluationDetailsResults,
  EvaluationGrades,
  EvaluationStatus,
} from "server/src/services/evaluation/types";

type CalibratedData = {
  _id: string;
  newGrade: number;
  newJustification: string;
};

type CalibrationDrawerProps = {
  open: boolean;
  data: {
    evaluatedId: string;
    evaluationId: string;
    average: string;
    calibrationAverage: string;
    calibrationJustification: string;
    reportsToName: string;
    evaluationName: string;
    evaluatedName: string;
  };
};

type HistoryDrawerProps = {
  open: boolean;
  data: {
    average: undefined | number;
    evaluationId: string;
    evaluationName: string;
    reportsToName: string;
    evaluatedId: string;
    evaluatedName: string;
  };
};

type UseEvaluationDetailsProps = {
  evaluation: {
    _id: string;
    name: string;
    evaluationStatus?: EvaluationStatus;
    types: EvaluationGrades["type"][];
  };
  data: EvaluationDetailsResults["results"];
};

export const useEvaluationDetails = ({
  evaluation,
  data,
}: UseEvaluationDetailsProps) => {
  const [tableData, setTableData] = useState<any>([]);

  const [openCalibrationDrawer, setOpenCalibrationDrawer] =
    useState<CalibrationDrawerProps>({
      open: false,
      data: {
        evaluatedId: "",
        evaluationId: "",
        average: "",
        calibrationAverage: "",
        calibrationJustification: "",
        reportsToName: "",
        evaluationName: "",
        evaluatedName: "",
      },
    });

  const [openHistoryDrawer, setHistoryOpenDrawer] =
    useState<HistoryDrawerProps>({
      open: false,
      data: {
        average: undefined,
        evaluationId: "",
        evaluationName: "",
        reportsToName: "",
        evaluatedId: "",
        evaluatedName: "",
      },
    });

  useEffect(() => {
    if (data?.length) setTableData(data);
  }, [data]);

  const { types, evaluationStatus } = evaluation;

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        Header: "Avaliado",
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            !row?.depth && (
              <FlexColumn style={{ minWidth: "220px" }}>
                <ProfileTable name={value} />
              </FlexColumn>
            )
          );
        },
      },
      {
        Header: "Seções",
        accessor: "sectionsCount",
        Cell: ({ row }) => {
          const { sectionsCount } = row?.original;

          if (row.depth) {
            const { criterialNames, name } = row?.original;

            return (
              <FlexColumn style={{ minWidth: "470px" }}>
                <StyledText
                  variant="body3"
                  setColor="neutral40"
                  fontWeight={600}
                >
                  {name}
                </StyledText>
                <StyledText variant="body4" setColor="neutral60">
                  Critérios avaliados: <strong>{criterialNames}</strong>
                </StyledText>
              </FlexColumn>
            );
          }

          return (
            <StyledText variant="body3">{sectionsCount || "-"}</StyledText>
          );
        },
      },
      {
        Header: () => {
          return (
            <div style={{ width: "150px" }}>
              <StyledText variant="body3">
                <strong>Nota final</strong> (Conta apenas nota do líder)
              </StyledText>
            </div>
          );
        },
        accessor: "average",
        Cell: ({ row, value }) => {
          if (row.depth) {
            return <StyledText variant="body3">{value || "-"}</StyledText>;
          }

          const { calibrationAverage, average } = row.original;
          const parsedCalibration = Number(calibrationAverage) || null;

          const tooltipTitle = parsedCalibration ? (
            <>
              Nota original: {average} <br />
              Nota calibrada: {calibrationAverage}
              <br />
              <br />
              Veja os detalhes para conferir o histórico de alterações.
            </>
          ) : (
            ""
          );

          if (!parsedCalibration)
            return <StyledText variant="body3">{value || "-"}</StyledText>;

          return (
            <Tooltip
              title={tooltipTitle}
              style={{
                marginLeft: "4px",
                cursor: parsedCalibration ? "pointer" : "auto",
              }}
            >
              <FlexColumn>
                <StyledText variant="body3">
                  {parsedCalibration || "-"}
                </StyledText>
                <StyledText variant="body4" setColor="neutral50">
                  Original: {value}
                </StyledText>
              </FlexColumn>
            </Tooltip>
          );
        },
      },
      {
        Header: "Calibrar nota final",
        accessor: "calibrate",
        Cell: ({ row }) => {
          if (row.depth) return <></>;

          const {
            calibrationAverage,
            calibrationJustification = "",
            average,
            name,
            _id,
          } = row.original;

          const isByLeader = !!types?.find((type) => type === "byLeader");
          const hasCalibrationAverage = calibrationAverage !== undefined;

          const disabled = !isByLeader || average === undefined;

          if (evaluationStatus === "calibration_finished")
            return (
              <Button
                size="small"
                variant="secondary"
                disabled={disabled}
                style={{ minWidth: "140px" }}
                onClick={() => {
                  setHistoryOpenDrawer({
                    open: true,
                    data: {
                      average: hasCalibrationAverage
                        ? calibrationAverage
                        : average,
                      evaluationId: evaluation?._id || "",
                      evaluationName: evaluation?.name || "",
                      reportsToName: "",
                      evaluatedId: _id || "",
                      evaluatedName: name || "",
                    },
                  });
                }}
              >
                Ver detalhes
              </Button>
            );

          if (hasCalibrationAverage)
            return (
              <Button
                size="small"
                variant="secondary"
                style={{ minWidth: "120px" }}
                onClick={() => {
                  setOpenCalibrationDrawer({
                    open: true,
                    data: {
                      evaluatedId: _id || "",
                      evaluatedName: name || "",
                      evaluationId: evaluation?._id || "",
                      evaluationName: evaluation?.name || "",
                      average: average?.toString() || "",
                      calibrationAverage: calibrationAverage?.toString() || "",
                      calibrationJustification: calibrationJustification || "",
                      reportsToName: "",
                    },
                  });
                }}
              >
                Editar nota
              </Button>
            );

          return (
            <Button
              size="small"
              variant="primary"
              style={{ minWidth: "110px" }}
              onClick={() => {
                setOpenCalibrationDrawer({
                  open: true,
                  data: {
                    evaluatedId: _id || "",
                    evaluatedName: name || "",
                    evaluationId: evaluation?._id || "",
                    evaluationName: evaluation?.name || "",
                    average: average?.toString() || "",
                    calibrationAverage: calibrationAverage?.toString() || "",
                    calibrationJustification: calibrationJustification || "",
                    reportsToName: "",
                  },
                });
              }}
              disabled={disabled}
            >
              Calibrar
            </Button>
          );
        },
      },
      {
        Header: () => {
          return (
            <StyledText variant="body3" fontWeight={700}>
              Líder avalia liderado <RequiredAsterisk />
            </StyledText>
          );
        },
        accessor: "byLeader",
        Cell: ({ value }) => {
          return <StyledText variant="body3">{value || "-"}</StyledText>;
        },
      },
      {
        Header: "Liderado avalia líder",
        accessor: "byLed",
        Cell: ({ value }) => {
          return <StyledText variant="body3">{value || "-"}</StyledText>;
        },
      },
      {
        Header: "Participante se autoavalia",
        accessor: "self",
        Cell: ({ value }) => {
          return <StyledText variant="body3">{value || "-"}</StyledText>;
        },
      },
      {
        Header: "Ações",
        sticky: "right",
        disableSortBy: true,
        accessor: "action",
        Cell: ({ row }) => {
          if (row.depth) return <></>;

          const { _id } = row.original;

          return (
            <FlexBetween style={{ gap: "15px" }}>
              <Options _id={_id} row={row} />
            </FlexBetween>
          );
        },
      },
    ] as DataGridProps<string>["columns"];

    const hasSelfEvaluation = types?.find((e) => e === "self");
    const hasByLedEvaluation = types?.find((e) => e === "byLed");
    const hasByLeaderEvaluation = types?.find((e) => e === "byLeader");

    let filteredColumns = defaultColumns;

    if (!hasSelfEvaluation)
      filteredColumns = filteredColumns.filter((f) => f.accessor !== "self");

    if (!hasByLedEvaluation)
      filteredColumns = filteredColumns.filter((f) => f.accessor !== "byLed");

    if (!hasByLeaderEvaluation)
      filteredColumns = filteredColumns.filter(
        (f) => f.accessor !== "byLeader"
      );

    if (
      !["in_calibration", "calibration_finished"].includes(
        evaluationStatus || ""
      )
    )
      filteredColumns = filteredColumns.filter(
        (f) => f.accessor !== "calibrate"
      );

    return filteredColumns;
  }, [types, evaluationStatus]);

  const updateCalibratedData = useCallback(
    (calibratedData: CalibratedData) => {
      const { _id, newGrade, newJustification } = calibratedData;

      const clonedTableData = cloneDeep(tableData || []);

      const foundIndex = clonedTableData.findIndex((data) => data._id === _id);

      if (foundIndex >= 0) {
        clonedTableData[foundIndex] = {
          ...clonedTableData[foundIndex],
          calibrationAverage: Number(newGrade),
          calibrationJustification: newJustification,
        };
      }

      return clonedTableData;
    },
    [tableData]
  );

  const onSubmit = useCallback(
    (result) => {
      const updatedData = updateCalibratedData(result);
      setTableData(updatedData);
    },
    [tableData]
  );

  const onCloseHistory = () => {
    setHistoryOpenDrawer({
      open: false,
      data: {
        average: undefined,
        evaluationId: "",
        evaluationName: "",
        reportsToName: "",
        evaluatedId: "",
        evaluatedName: "",
      },
    });
  };

  const onCloseCalibration = () => {
    setOpenCalibrationDrawer({
      open: false,
      data: {
        evaluatedId: "",
        evaluationId: "",
        average: "",
        calibrationAverage: "",
        calibrationJustification: "",
        reportsToName: "",
        evaluationName: "",
        evaluatedName: "",
      },
    });
  };

  return {
    columns,
    tableData,
    openCalibrationDrawer,
    openHistoryDrawer,
    onSubmit,
    onCloseHistory,
    onCloseCalibration,
  };
};
