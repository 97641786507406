import { useState } from "react";
import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { CreateCriteriaModal, AddCriteriaModal } from "@components/Modals";

import { track } from "@utils";

import type { Criteria } from "server/src/services/criteria/types";

type EvaluationCriteria = Criteria & { fromModel?: boolean };
interface ICriterialsOptionsProps {
  hasCriterials?: boolean;
  onAddedCriteria: (criteria: EvaluationCriteria[]) => void;
  evaluationCriterials: string[];
  disabledEdit: boolean;
}

export const CriterialsOptions = ({
  hasCriterials,
  onAddedCriteria,
  evaluationCriterials,
  disabledEdit,
}: ICriterialsOptionsProps) => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [addCriterialModalOpen, setCriterialModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "8px",
          marginTop: hasCriterials ? "24px" : "0px",
        }}
      >
        <Button
          size="small"
          onClick={() => setCriterialModalOpen(true)}
          style={{ width: "100%" }}
          variant="secondary"
          variantType="default"
          disabled={disabledEdit}
        >
          <Icons name="IconStar" style={{ flexShrink: 0 }} />
          Adicionar critério existente
        </Button>
        <Button
          size="small"
          onClick={() => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_evaluationform_createnewcriteria_clicked",
            });
            setCreateModalOpen(true);
          }}
          style={{ width: "100%" }}
          variant="secondary"
          variantType="default"
          disabled={disabledEdit}
        >
          <Icons name="IconPlus" style={{ flexShrink: 0 }} />
          Criar novo critério
        </Button>
      </div>

      <AddCriteriaModal
        open={addCriterialModalOpen}
        evaluationCriterials={evaluationCriterials}
        onCriteriaAdded={onAddedCriteria}
        onClose={() => setCriterialModalOpen(false)}
      />

      <CreateCriteriaModal
        open={createModalOpen}
        showWarning={true}
        onClose={() => setCreateModalOpen(false)}
        onCreated={(criterial) =>
          onAddedCriteria([{ ...criterial, fromModel: false }])
        }
      />
    </>
  );
};
