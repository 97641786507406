import { StyledTitle, StyledText } from "@utils";

import { Container, Asterisk, TitleContainer, Row } from "./styled";

interface FormCardProps {
  title: string;
  description?: string;
  mandatory?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const FormCard = ({
  title,
  description,
  mandatory,
  children,
  style,
}: FormCardProps) => {
  return (
    <Container style={style}>
      <TitleContainer>
        <Row style={{ justifyContent: "space-between", gap: "4px" }}>
          <StyledTitle
            setColor="neutral30"
            variant="headline8"
            children={title}
          />

          {mandatory && (
            <StyledText variant="body4" setColor="neutral30">
              <Asterisk>*</Asterisk> campo obrigatório
            </StyledText>
          )}
        </Row>

        {description && (
          <StyledText
            setColor="neutral50"
            variant="body4"
            children={description}
          />
        )}
      </TitleContainer>
      {children}
    </Container>
  );
};
