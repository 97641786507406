import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Select } from "@mui/material";

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const StyledText = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const ItensPerPageArea = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const PaginationContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ProgressWrapper = styled.div`
  width: 75px;
  margin-right: 12px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary[50]};
  font-size: 14px;
`;

export const CellArea = styled.div`
  width: 280px;
  max-width: 280px;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 270px;
  }
`;

export const CycleCellArea = styled.div`
  width: 200px;
  max-width: 200px;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 24ch;
  }
`;
