import { useState, useMemo } from "react";
import { cloneDeep } from "lodash";

import { TableSearch } from "./TableSearch";
import { TableFilters } from "./TableFilters";
import { TableGrid } from "./TableGrid";

import { TableProps } from "./types";

export const Table = ({
  loading,
  data = [],
  filters,
  selectedItem,
  refetch,
}: TableProps) => {
  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const filteredData = useMemo(() => {
    if (!search && !Object.keys(filterStates)?.length) return data;

    let dataToFilter = cloneDeep(data);

    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const byFrom = item?.from.name
          ?.toLowerCase()
          .includes(search.toLowerCase());
        const byTo = item?.to.name
          ?.toLowerCase()
          .includes(search.toLowerCase());
        return byFrom || byTo;
      });
    }

    if (Object.keys(filterStates)?.length) {
      dataToFilter = dataToFilter?.filter((item) => {
        for (const key in filterStates) {
          const itemValue = item[key] || undefined;
          if (itemValue === undefined) return false;

          if (Array.isArray(itemValue)) {
            if (!itemValue.some((el) => filterStates[key].includes(el))) {
              return false;
            }
          } else {
            const parsedItem =
              typeof item[key] === "object" ? item[key].name : item[key];
            if (!filterStates[key].includes(parsedItem)) return false;
          }
        }
        return true;
      });
    }
    return dataToFilter;
  }, [data, search, filterStates]);

  return (
    <div>
      <TableSearch
        disabled={!Boolean((data || [])?.length)}
        onChange={setSearch}
      />
      {filters?.[0] && (
        <TableFilters
          disabled={!Boolean((data || [])?.length)}
          filters={filters}
          onFilter={setFilterStates}
          data={data || []}
        />
      )}
      <TableGrid
        data={filteredData || []}
        selectedItem={selectedItem}
        rawData={data || []}
        pageSize={10}
        loading={loading}
        refetch={() => refetch()}
      />
    </div>
  );
};
