import styled, { css } from "styled-components";

type ItemProps = {
  isSelected?: boolean;
  isDisabled?: boolean;
  isDragging?: boolean;
};

const ItemDragging = css`
  border-color: ${({ theme }) => theme.colors.primary};
  rotate: -5.08deg;
`;

const ItemGripDragging = css`
  border-right-color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary[70]};
  color: ${({ theme }) => theme.colors.neutral[100]};
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ItemContainer = styled.div<ItemProps>`
  display: flex;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[50]};
  opacity: 1;

  ${({ isDragging }) => isDragging && ItemDragging}

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const ItemGrip = styled.div<ItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 0 0 12px;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  padding: 0px 12px;
  opacity: 1;

  ${({ isDragging }) => isDragging && ItemGripDragging}

  ${ItemContainer}:hover & {
    border-right-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.secondary[95]};
    color: ${({ theme }) => theme.colors.secondary[80]};
  }
`;

const ItemQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0 12px 12px 0;
  padding: 24px 40px;
  gap: 24px;
`;

const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-position: inside;
`;

const ItemTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  QuestionsContainer,
  ItemContainer,
  ItemGrip,
  ItemQuestionContainer,
  ItemTextContainer,
  ItemTitleContainer,
};
