import { useNavigate, useParams } from "react-router-dom";

import { trpc } from "@api/client";

import { Lantern } from "@assets/index";

import { dispatchToast, StyledText } from "@utils";

import { routes } from "@routes";

import Banner from "@components/Banner";
import { Table } from "@components/Table";
import { TableGrid } from "./components/TableGrid";

import { EmptyStateArea, StyledLinkButton } from "./styled";

const EmptyState = () => (
  <EmptyStateArea>
    <Lantern />

    <StyledText
      variant="body3"
      weight={600}
      setColor="neutral30"
      style={{ textAlign: "center" }}
    >
      Aguarde o período de Feedbacks para ver
      <br />
      seus feedbacks recebidos e enviados.
    </StyledText>
  </EmptyStateArea>
);

export const Feedbacks = () => {
  const navigate = useNavigate();

  const { id = "" } = useParams();

  const { data, isFetching: isLoading } =
    trpc.performance.feedback.getManagerSentFeedbacksByCycle.useQuery(
      {
        cycleId: id,
      },
      {
        enabled: !!id,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os Feedbacks, tente novamente em breve!",
          });
        },
      }
    );

  const isEmpty = !data?.items?.length && !isLoading;

  return (
    <>
      {!isEmpty ? (
        <Table
          data={data?.items || []}
          filters={data?.filters || []}
          loading={isLoading}
          searchable={true}
          searchableField="name"
          searchPlaceholder={"Buscar por nome"}
          tableGridRender={(props) => <TableGrid {...props} />}
        />
      ) : (
        <>
          <Banner
            type="info"
            icon="IconInfoCircle"
            title="Apenas os feedbacks associados a esse ciclo são listados aqui."
            subTitle="Para ver o restante dos feedbacks enviados e recebidos, clique na opção abaixo ou acesse a área de Feedbacks no menu principal."
            hasHideBanner={false}
            style={{ marginBottom: "24px" }}
          >
            <StyledLinkButton
              variant="neutral"
              onClick={() => navigate(routes.PageMyFeedbacks)}
            >
              Ver todos os meus feedbacks
            </StyledLinkButton>
          </Banner>
          <EmptyState />
        </>
      )}
    </>
  );
};
