import { useState } from "react";
import { Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { CellLine, CellText, FlexRow } from "./styled";

interface OverflowCheckProps {
  text: string;
  customTooltipText?: string;
  width?: string;
}

export const OverflowCheck = ({
  text,
  width,
  customTooltipText,
}: OverflowCheckProps) => {
  const [overflowing, setOverflowing] = useState(false);
  const result = (divRef) => {
    if (divRef === undefined || divRef === null) return false;

    const curOverflow = divRef.style.overflow;

    if (!curOverflow || curOverflow === "visible")
      divRef.style.overflow = "hidden";
    const isOverflowing = divRef.clientWidth < divRef.scrollWidth;

    divRef.style.overflow = curOverflow;

    setOverflowing(isOverflowing);
  };

  return (
    <CellLine>
      <CellText ref={(el) => result(el)} children={text} width={width} />
      {overflowing && (
        <Tooltip title={customTooltipText || text} arrow={false}>
          <FlexRow>
            ... <Icons name={"IconInfoCircle"} fill="none" size={20} />
          </FlexRow>
        </Tooltip>
      )}
    </CellLine>
  );
};
