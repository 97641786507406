import { useState } from "react";
import { trpc } from "@api/client";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import {
  DeleteCompanyValueModal,
  CreateCompanyValueModal,
  EditCompanyValueModal,
} from "@components/Modals";

import { Table } from "./Table";

import { StyledIcon, StyledText, track } from "@utils";

import { FlexRow, PageContainer } from "./styled";

export const CompanyValues = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [openEditValueModal, setOpenEditValueModal] = useState({
    open: false,
    companyValueId: "",
    params: {
      name: "",
      description: "",
    },
  });

  const [openDeleteValueModal, setOpenDeleteValueModal] = useState({
    open: false,
    companyValueId: "",
  });
  const { data, isLoading, refetch } =
    trpc.performance.feedback.getCompanyValues.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    });

  return (
    <PageContainer>
      <FlexRow>
        <StyledText
          variant="body3"
          setColor="neutral40"
          children="Cadastre os princípios fundamentais que orientarão a realização dos feedbacks da empresa."
        />
        <Button
          size={"large"}
          variant="primary"
          style={{ width: "200px" }}
          onClick={() => {
            track({
              name: "performance_feedbacks_settings_createnewvalue_clicked",
            });
            setIsOpen(true);
          }}
        >
          <StyledIcon name="IconPlus" fill="none" />
          Criar novo valor
        </Button>
      </FlexRow>
      <Table
        loading={isLoading}
        data={data || []}
        filters={[]}
        onDelete={(e) => {
          setOpenDeleteValueModal(e);
        }}
        onEdit={(e) => setOpenEditValueModal(e)}
      />
      <CreateCompanyValueModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        refetch={() => refetch()}
      />
      <EditCompanyValueModal
        open={openEditValueModal.open}
        onClose={() =>
          setOpenEditValueModal({
            open: false,
            companyValueId: "",
            params: { name: "", description: "" },
          })
        }
        companyValueId={openEditValueModal.companyValueId}
        data={openEditValueModal.params}
        refetch={() => refetch()}
      />
      <DeleteCompanyValueModal
        open={openDeleteValueModal.open}
        companyValueId={openDeleteValueModal.companyValueId}
        onClose={() =>
          setOpenDeleteValueModal({ open: false, companyValueId: "" })
        }
        refetch={() => refetch()}
      />
    </PageContainer>
  );
};
