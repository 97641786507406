import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { trpc } from "@api/client";
import { Table } from "./Table";

import { PageContainer } from "./styled";
import { dispatchToast } from "@utils";

export const Received = () => {
  const [searchParams] = useSearchParams();

  const selectedFeedback = useMemo(
    () => searchParams.get("received") || "",
    [searchParams]
  );

  const { data, isLoading, refetch } =
    trpc.performance.feedback.getEmployeeReceivedFeedbacks.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar os feedbacks recebidos. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  return (
    <PageContainer>
      <Table
        loading={isLoading}
        data={data?.receivedFeedbacks || []}
        filters={data?.filter || []}
        selectedItem={selectedFeedback}
        refetch={() => refetch()}
      />
    </PageContainer>
  );
};
