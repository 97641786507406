import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { QueryClientProvider } from "@tanstack/react-query";

import AppRouter from "./routes";
import { GlobalStyles } from "@assets/index";

import { trpc, trpcClient, queryClient } from "./api/client";

import "../src/i18n";

export default function Root() {
  const { selectedCompany } = useSelectedCompany();

  return (
    <trpc.Provider
      key={selectedCompany?.id}
      client={trpcClient({ selectedCompany })}
      queryClient={queryClient}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalStyles />
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
