import { Icons, CircularProgress } from "@flash-tecnologia/hros-web-ui-v2";

import styled, { css } from "styled-components";

export const BannerArea = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme }) => theme.colors.neutral[100]};
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin: 40px 0px 24px 0px;
  align-items: stretch;
  user-select: none;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const StepsArea = styled.div`
  display: flex;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  gap: 24px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    display: flex !important;

    .circle-background {
      color: ${({ theme }) => theme.colors.secondary[95]};
    }
  }
`;

export const ItemBox = styled.div<{ isDone?: boolean }>`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme, isDone }) =>
    isDone ? theme.colors.neutral[95] : theme.colors.neutral[100]};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  min-width: 280px;
  max-width: 325px;
  min-height: 234px;
  justify-content: space-between;

  scroll-snap-align: center;
  scroll-snap-stop: always;

  ${({ isDone }) =>
    isDone
      ? css`
          p,
          h6 {
            text-decoration-line: line-through !important;
            opacity: 0.6;
          }
          button {
            &.MuiButtonBase-root {
              color: ${({ theme }) => theme.colors.neutral[40]} !important;

              &::before {
                border-bottom: 2px solid
                  ${({ theme }) => theme.colors.neutral[40]} !important;
              }
            }
          }
        `
      : null};
`;

export const ItemBoxTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ItemIcon = styled.div<{ isDone?: boolean }>`
  border-radius: 200px;
  background: ${({ theme, isDone }) =>
    isDone ? theme.colors.feedback.success[90] : theme.colors.secondary[95]};
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    stroke: ${({ theme, isDone }) =>
      isDone ? theme.colors.feedback.success[40] : theme.colors.primary};
  }
`;

export const InfoBox = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
  justify-content: center;
`;

export const InfoBoxTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RocketIcon = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
