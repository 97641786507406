import { trpc } from "@api/client";
import { Table } from "./Table";

import { StyledTitle } from "@utils";
import { PageContainer } from "./styled";

export const People = () => {
  const { data, isLoading } = trpc.performance.idp.getManagerIdpPeople.useQuery(
    undefined,
    {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <PageContainer>
      <StyledTitle variant="body2" children="Pessoas" setColor="neutral30" />
      <Table
        loading={isLoading}
        data={data?.idpPeoples || []}
        filters={data?.filters || []}
      />
    </PageContainer>
  );
};
