import { TableGrid } from "./TableGrid";

import { TableProps } from "./types";

export const Table = ({ loading, onValuesChanged, data = [] }: TableProps) => {
  return (
    <div>
      <TableGrid
        data={data || []}
        onValuesChanged={onValuesChanged}
        pageSize={10}
        loading={loading}
      />
    </div>
  );
};
