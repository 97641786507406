import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

export const StyledTab = styled(Tab)`
  &.MuiTabs-root {
    margin-bottom: 32px !important;
  }
`;
