import { TableGrid } from "./TableGrid";
import { Table } from "@components/Table";
import { SearchField } from "@components/SearchField";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTab, Container } from "./styled";

import { StyledTitle } from "@utils";

import { useEvaluatedIds } from "./hooks";

import type { EvaluationType } from "../../../../types";

interface IEvaluatedsTabProps {
  evaluation?: Partial<EvaluationType>;
}

export const EvaluatedsTab = ({ evaluation }: IEvaluatedsTabProps) => {
  const {
    tabs,
    filteredData,
    selectedTab,
    setSelectedTab,
    search,
    setSearch,
    isLoading,
  } = useEvaluatedIds({ evaluation });

  if (isLoading)
    return <Skeleton variant="rectangular" height="400px" width="100%" />;

  return (
    <Container>
      <StyledTitle
        variant="headline8"
        setColor="neutral30"
        children={"Avaliados"}
      />

      <SearchField
        onChange={setSearch}
        placeholder={"Buscar"}
        disabled={isLoading}
      />

      <StyledTab
        selected={selectedTab}
        onTabChanged={(index) => {
          setSelectedTab(index);
          setSearch("");
        }}
        tabItens={tabs?.items || []}
      />

      <Table
        data={filteredData || []}
        loading={isLoading}
        tableGridRender={(props) => (
          <TableGrid {...props} isFiltered={!!search} />
        )}
      />
    </Container>
  );
};
