import { differenceWith, isEqual } from "lodash-es";

interface ObjectLiteral {
  [key: string]: any;
}

export const getObjDiffs = (
  newObj: ObjectLiteral,
  objToCompare: ObjectLiteral
) => {
  const diff: ObjectLiteral = {};

  Object.keys(objToCompare).forEach((key) => {
    if (!newObj.hasOwnProperty(key)) return;

    const isArray = Array.isArray(objToCompare[key]);

    if (isArray) {
      const newObjArr = newObj[key] || [];
      const objComparArr = objToCompare[key] || [];

      const hasDiff = differenceWith(newObjArr, objComparArr, isEqual).length;

      const lengthDiff = newObjArr.length !== objComparArr.length;

      if (!hasDiff && !lengthDiff) return;

      diff[key] = newObj[key];
      return;
    }

    if (objToCompare[key] != newObj[key]) diff[key] = newObj[key];
  });

  return !!Object.keys(diff).length;
};
