import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

export const EvaluationWithoutSaveModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: CommonModalProps) => {
  return (
    <ConfirmationModal
      open={open}
      showWarning
      secondaryVariant="default"
      icon="IconAlertCircle"
      title="Deseja sair sem salvar a avaliação?"
      subTitle="O conteúdo preenchido anteriormente irá permanecer salvo e você poderá editar mais tarde. Entretanto, você perderá todas as novas alterações feitas nessa etapa."
      confirm={{
        title: "Sair sem salvar as novas alterações",
        icon: "IconArrowRight",
        iconColor: "neutral100",
      }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};
