import {
  AvatarContainer,
  Description,
  ProfileArea,
  StyledText,
} from "./styled";

interface ProfileTableProps {
  name: string;
  email?: string | React.ReactNode;
}

export const ProfileTable = ({ name, email }: ProfileTableProps) => {
  const avatarMaker = (name: string) => {
    const matches = name.split(" ");

    if (matches.length > 1) {
      const firstName = matches.shift();

      const firstLetter = firstName?.slice(0, 1);
      const secondLetter = matches?.pop()?.slice(0, 1);

      return `${firstLetter}${secondLetter}`.toLocaleUpperCase();
    } else {
      return matches?.pop()?.slice(0, 2).toLocaleUpperCase();
    }
  };

  const renderDescription = (description: string | React.ReactNode) => {
    const isString = typeof description === "string";

    if (isString) return <Description variant="body4">{email}</Description>;
    return description;
  };

  return (
    <ProfileArea>
      <AvatarContainer children={avatarMaker(name) || "-"} />
      <div>
        <StyledText variant="body3">{name || "-"}</StyledText>
        {email && renderDescription(email)}
      </div>
    </ProfileArea>
  );
};
