import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: 40px;
  padding-left: 24px;
  padding-bottom: 40px;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  width: 100%;
  max-width: 312px;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

export const StyledSelect = styled(SelectField)`
  && {
    &.select-custom {
      width: 100%;
    }
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  height: 1px;
  margin-top: 6px;
  margin-bottom: 8px;
`;

export const StyledPLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;
