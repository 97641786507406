import { trpc } from "@api/client";

import { Table } from "./Table";

import { PageContainer } from "./styled";

interface ReceivedProps {
  employeeId: string;
}

export const Received = ({ employeeId }: ReceivedProps) => {
  const { data, isFetching, refetch } =
    trpc.performance.feedback.getReceivedFeedbacksByEmployee.useQuery(
      {
        employeeId,
      },
      {
        retry: false,
        retryDelay: 3000,
        enabled: !!employeeId,
        refetchOnWindowFocus: false,
      }
    );

  return (
    <PageContainer>
      <Table
        loading={isFetching}
        data={data?.receivedFeedbacks || []}
        filters={data?.filter || []}
        refetch={() => refetch()}
      />
    </PageContainer>
  );
};
