import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ProfileArea = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-right: 12px;
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
`;

export const StyledText = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const Description = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
