import styled from "styled-components";

import { StyledTitle } from "@utils";

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin: 40px 0px 20px 0px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SelectArea = styled.div`
  width: 100%;
  max-width: 290px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: none;
  }
`;

export const Title = styled(StyledTitle)`
  display: flex;
  align-items: center;
  button {
    margin-left: 12px;
    align-self: center;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  align-items: center;
  button:first-child {
    margin-right: 12px;
  }
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DeleteOption = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const DeleteWarning = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;
