import { useNavigate, useParams } from "react-router-dom";

import {
  Icons,
  LinkButton,
  Menu,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, track } from "@utils";
import { routes } from "@routes";

import {
  BannerArea,
  StepsArea,
  StyledCircularProgress,
  ItemBox,
  ItemIcon,
  InfoBox,
  InfoBoxTextArea,
  RocketIcon,
  MenuItem,
  ItemBoxTextArea,
} from "./styled";

import type { CycleSetup } from "server/src/services/cycle/types";

interface IBannerProps {
  data: CycleSetup | undefined;
  isLoading?: boolean;
}

export const Banner = ({ data, isLoading = false }: IBannerProps) => {
  const navigate = useNavigate();

  const { id = "" } = useParams();

  if (isLoading)
    return (
      <Skeleton
        width={"100%"}
        height={"310px"}
        style={{ marginBottom: "24px" }}
      />
    );

  if (!data || data?.done) return <></>;

  return (
    <BannerArea>
      <InfoBox>
        <div>
          <StyledCircularProgress value={data?.progress || 0} size={60}>
            <RocketIcon name="IconRocket" size={32} />
          </StyledCircularProgress>
        </div>

        <InfoBoxTextArea>
          <StyledTitle variant="headline7" weight={700} setColor="secondary50">
            Termine de configurar o ciclo de desempenho
          </StyledTitle>

          <StyledText variant="body3" weight={600} setColor="neutral40">
            Use esse guia para dar seus primeiros passos na ferramenta de
            Desempenho!
          </StyledText>
        </InfoBoxTextArea>
      </InfoBox>

      <StepsArea>
        <ItemBox isDone={data?.leaders}>
          <ItemBoxTextArea>
            <ItemIcon isDone={data?.leaders}>
              <Icons
                name={data?.leaders ? "IconCheck" : "IconUsers"}
                size={16}
              />
            </ItemIcon>
            <StyledTitle variant="headline8" weight={700} setColor="neutral30">
              Líderes e liderados
            </StyledTitle>
            <StyledText variant="body3" weight={400} setColor="neutral50">
              Acesse os perfis da sua equipe e associe os líderes
            </StyledText>
          </ItemBoxTextArea>
          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              navigate(routes.PageEmployees);
              track({
                name: "people_strategic_hr_performance_company_cycles_createcycle_setup_leaders_access_clicked",
              });
            }}
          >
            Acessar
          </LinkButton>
        </ItemBox>

        <ItemBox isDone={data?.criterial}>
          <ItemBoxTextArea>
            <ItemIcon isDone={data?.criterial}>
              <Icons
                name={data?.criterial ? "IconCheck" : "IconStar"}
                size={16}
              />
            </ItemIcon>
            <StyledTitle variant="headline8" weight={700} setColor="neutral30">
              Banco de critérios
            </StyledTitle>
            <StyledText variant="body3" weight={400} setColor="neutral50">
              Inclua competências, valores, perguntas ou metas na avaliação
            </StyledText>
          </ItemBoxTextArea>
          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              navigate(routes.PageManageCriterials);
              track({
                name: "people_strategic_hr_performance_company_cycles_createcycle_setup_ratingcriteria_create_clicked",
              });
            }}
          >
            Criar critérios
          </LinkButton>
        </ItemBox>

        <ItemBox isDone={data?.evaluations}>
          <ItemBoxTextArea>
            <ItemIcon isDone={data?.evaluations}>
              <Icons
                name={data?.evaluations ? "IconCheck" : "IconFileDescription"}
                size={16}
              />
            </ItemIcon>
            <StyledTitle variant="headline8" weight={700} setColor="neutral30">
              Avaliações de desempenho
            </StyledTitle>
            <StyledText variant="body3" weight={400} setColor="neutral50">
              Crie uma ou mais avaliações dentro do ciclo
            </StyledText>
          </ItemBoxTextArea>
          <Menu
            children={
              <Menu
                children={
                  <LinkButton
                    size="large"
                    variant="default"
                    onClick={() =>
                      track({
                        name: "people_strategic_hr_performance_company_evaluations_createevaluation_clicked",
                      })
                    }
                  >
                    Criar avaliação
                    <Icons name="IconPlus" />
                  </LinkButton>
                }
                type="default"
                options={[
                  {
                    children: (
                      <MenuItem>
                        <Icons name="IconLayoutGrid" />
                        <Typography variant="body3">
                          Com base em um modelo
                        </Typography>
                      </MenuItem>
                    ),
                    onClick: () => {
                      track({
                        name: "people_strategic_hr_performance_company_evaluations_createevaluation_model_clicked",
                      });
                      navigate(routes.PageCreateEvaluation("models"), {
                        state: id,
                      });
                    },
                  },
                  {
                    children: (
                      <MenuItem>
                        <Icons name="IconEdit" />
                        <Typography variant="body3">
                          Criar avaliação em branco
                        </Typography>
                      </MenuItem>
                    ),
                    onClick: () => {
                      track({
                        name: "people_strategic_hr_performance_company_evaluations_createevaluation_inblank_clicked",
                      });
                      navigate(routes.PageCreateEvaluation("basic-info"), {
                        state: { cycleId: id },
                      });
                    },
                  },
                ]}
              />
            }
            type="default"
            options={[
              {
                children: (
                  <MenuItem>
                    <Icons name="IconLayoutGrid" />
                    <Typography variant="body3">
                      Com base em um modelo
                    </Typography>
                  </MenuItem>
                ),
                onClick: () => {
                  navigate(routes.PageCreateEvaluation("models"));
                },
              },
              {
                children: (
                  <MenuItem>
                    <Icons name="IconEdit" />
                    <Typography variant="body3">
                      Criar avaliação em branco
                    </Typography>
                  </MenuItem>
                ),
                onClick: () => {
                  navigate(routes.PageCreateEvaluation("basic-info"));
                },
              },
            ]}
          />
        </ItemBox>
      </StepsArea>
    </BannerArea>
  );
};
