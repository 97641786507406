import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { PageContainer, Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";

import { BigNumbers } from "./components/BigNumbers";

import { Header } from "./components/Header";
import { Received } from "./components/Tabs/Received";
import { Sent } from "./components/Tabs/Sent";
import { ReceivedRequest } from "./components/Tabs/ReceivedRequests";
import { SentRequest } from "./components/Tabs/SentRequests";
import { People } from "./components/Tabs/People";
import { dispatchToast } from "@utils";

export const PageMyFeedbacks = () => {
  const [searchParams] = useSearchParams();

  const feedbackReceivedId = useMemo(
    () => searchParams.get("received") || "",
    [searchParams]
  );

  const requestReceivedId = useMemo(
    () => searchParams.get("requestReceived") || "",
    [searchParams]
  );

  const defaultTab = useMemo(() => {
    if (feedbackReceivedId) return 0;
    if (requestReceivedId) return 2;

    return 0;
  }, [feedbackReceivedId, requestReceivedId]);

  const { data } = trpc.employee.getEmployeeHierarchy.useQuery(undefined, {
    retry: false,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    onError: () => {
      dispatchToast({
        content:
          "Erro ao carregar as informações do usuário. Tente novamente em breve.",
        type: "error",
      });
    },
  });

  const { data: employeeInsight, isLoading } =
    trpc.performance.feedback.getEmployeeInsights.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar os detalhes dos feedbacks. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  const tabItens = useMemo(() => {
    const items = [
      { label: "Recebidos", component: <Received /> },
      { label: "Enviados", component: <Sent /> },
      {
        label: "Solicitações recebidas",
        component: <ReceivedRequest />,
      },
      {
        label: "Solicitações enviadas",
        component: <SentRequest />,
      },
    ];

    if (data?.isLeader)
      items.push({ label: "Meus liderados", component: <People /> });

    return items;
  }, [data]);

  return (
    <PageContainer>
      <Header />
      <BigNumbers data={employeeInsight} isLoading={isLoading} />
      <Tab defaultTab={defaultTab} tabItens={tabItens} />
    </PageContainer>
  );
};
