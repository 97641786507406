import { trpc } from "@api/client";
import {
  Accordion,
  dayjs,
  Dot,
  Icons,
  Menu,
} from "@flash-tecnologia/hros-web-ui-v2";

import { dispatchToast, StyledTitle, track } from "@utils";
import { cloneDeep } from "lodash-es";
import { useCallback, useState } from "react";

import { UnfilledField } from "./components/UnfilledField";

import {
  AccordionArea,
  AccordionInfo,
  AccordionTitle,
  Divider,
  InfoArea,
  InfoText,
  Label,
  Tag,
  TextInfo,
  WarnText,
} from "./styled";

export const Actions = ({ data, origin }) => {
  const utils = trpc.useContext();

  const [dataActions, setDataActions] = useState<any[]>(data?.actions);

  const renderTag = (status: string) => {
    switch (status) {
      case "finished":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"green"}>
              <Dot variant={"success"} />
              <StyledTitle variant="caption">Concluído</StyledTitle>
              <Icons name="IconChevronDown" fill="none" size={16} />
            </Tag>
          </div>
        );
      case "progressing":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"blue"}>
              <Dot variant={"blue"} />
              <StyledTitle variant="caption">Em andamento</StyledTitle>
              <Icons name="IconChevronDown" fill="none" size={16} />
            </Tag>
          </div>
        );
      case "created":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"gray"}>
              <Dot variant={"gray"} />
              <StyledTitle variant="caption">Não iniciado</StyledTitle>
              <Icons name="IconChevronDown" fill="none" size={16} />
            </Tag>
          </div>
        );
      case "delayed":
      default:
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"yellow"}>
              <Dot variant={"yellow"} />
              <StyledTitle variant="caption">Atrasado</StyledTitle>
              <Icons name="IconChevronDown" fill="none" size={16} />
            </Tag>
          </div>
        );
    }
  };

  const { mutate: editIDPAction } =
    trpc.performance.idp.updateIdpAction.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Ação editada com sucesso.",
        });

        utils.performance.idp.getIdpByIdFull.invalidate();
        utils.performance.idp.getEmployeeIdps.invalidate();
        utils.performance.idp.getIdpsByEmployee.invalidate();
      },
      onError: () => {
        setDataActions(data?.actions);
        const message = "Erro ao editar o plano, tente novamente em breve.";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const updateAction = useCallback(
    ({ idpId, actionId, status }) => {
      editIDPAction({
        params: {
          status,
        },
        idpId,
        actionId,
      });

      const selectedValues = cloneDeep(dataActions);
      const selectedIndex = selectedValues.findIndex(
        (selectedValue) => selectedValue._id === actionId
      );

      if (selectedIndex >= 0) selectedValues[selectedIndex].status = status;

      setDataActions(selectedValues);
    },
    [data]
  );

  return (
    <>
      {dataActions?.map(
        ({
          _id,
          developmentAction,
          initialDate,
          finalDate,
          status,
          criterial,
          itemsToDevelop = null,
          successIndicators = null,
          type,
          topic,
        }) => {
          return (
            <div key={_id}>
              <AccordionArea>
                <Accordion
                  variant="default"
                  customHeader={
                    <div>
                      <Menu
                        type={"select"}
                        options={[
                          {
                            children: (
                              <>
                                <Dot
                                  variant={"gray"}
                                  style={{ marginRight: "12px" }}
                                />
                                Não iniciado
                              </>
                            ),
                            onClick: () => {
                              track({
                                name:
                                  origin === "my-idps"
                                    ? "performance_myidp_plandetails_status_notstarted_selected"
                                    : "performance_idp_persondetails_plandetails_status_notstarted_selected",
                              });
                              updateAction({
                                status: "created",
                                idpId: data?._id,
                                actionId: _id,
                              });
                            },
                          },
                          {
                            children: (
                              <>
                                <Dot
                                  variant={"blue"}
                                  style={{ marginRight: "12px" }}
                                />
                                Em andamento
                              </>
                            ),
                            onClick: () => {
                              track({
                                name:
                                  origin === "my-idps"
                                    ? "performance_myidp_plandetails_status_inprogress_selected"
                                    : "performance_idp_persondetails_plandetails_status_inprogress_selected",
                              });
                              updateAction({
                                status: "progressing",
                                idpId: data?._id,
                                actionId: _id,
                              });
                            },
                          },
                          {
                            children: (
                              <>
                                <Dot
                                  variant={"yellow"}
                                  style={{ marginRight: "12px" }}
                                />
                                Atrasado
                              </>
                            ),
                            onClick: () => {
                              track({
                                name:
                                  origin === "my-idps"
                                    ? "performance_myidp_plandetails_status_late_selected"
                                    : "performance_idp_persondetails_plandetails_status_late_selected",
                              });
                              updateAction({
                                status: "delayed",
                                idpId: data?._id,
                                actionId: _id,
                              });
                            },
                          },
                          {
                            children: (
                              <>
                                <Dot
                                  variant={"success"}
                                  style={{ marginRight: "12px" }}
                                />
                                Concluído
                              </>
                            ),
                            onClick: () => {
                              track({
                                name:
                                  origin === "my-idps"
                                    ? "performance_myidp_plandetails_status_completed_selected"
                                    : "performance_idp_persondetails_plandetails_status_completed_selected",
                              });
                              updateAction({
                                status: "finished",
                                idpId: data?._id,
                                actionId: _id,
                              });
                            },
                          },
                        ]}
                        children={
                          <div
                            role="presentation"
                            onClick={() => {
                              track({
                                name:
                                  origin === "my-idps"
                                    ? "performance_myidp_plandetails_status_clicked"
                                    : "performance_idp_persondetails_plandetails_status_clicked",
                              });
                            }}
                          >
                            {renderTag(status)}
                          </div>
                        }
                      />
                      <AccordionTitle variant="headline8">
                        {developmentAction}
                      </AccordionTitle>
                      <AccordionInfo>
                        <TextInfo variant="body3" tag="span">
                          <Icons
                            name="IconCalendar"
                            fill="transparent"
                            size={18}
                          />
                          {dayjs(initialDate).format("DD/MM/YY")} -{" "}
                          {dayjs(finalDate).format("DD/MM/YY")}
                        </TextInfo>
                      </AccordionInfo>
                    </div>
                  }
                >
                  <div>
                    <InfoArea>
                      {data?.type === "independent" ? (
                        <div style={{ marginRight: "40px" }}>
                          <Label variant="caption">Tema</Label>
                          <InfoText variant="body3">
                            {topic ?? "Não preenchido"}
                          </InfoText>
                        </div>
                      ) : (
                        <div style={{ marginRight: "40px" }}>
                          <Label variant="caption">Critério Avaliativo</Label>
                          <InfoText variant="body3">
                            {criterial?.name ?? "Não preenchido"}
                          </InfoText>
                        </div>
                      )}

                      <div>
                        <Label variant="caption">Tipo de ação</Label>
                        <InfoText variant="body3">
                          {type === "teorical"
                            ? "Teórica (curso, treinamento)"
                            : type === "practical"
                            ? "Prática"
                            : "Social"}
                        </InfoText>
                      </div>
                    </InfoArea>
                    <div>
                      <Label variant="caption">Indicadores de sucesso</Label>
                      <WarnText variant="body3">
                        <UnfilledField value={successIndicators} />
                      </WarnText>
                    </div>
                    <div>
                      <Label variant="caption">Pontos a desenvolver</Label>
                      <WarnText variant="body3">
                        <UnfilledField value={itemsToDevelop} />
                      </WarnText>
                    </div>
                  </div>
                </Accordion>
              </AccordionArea>
              <Divider />
            </div>
          );
        }
      )}
    </>
  );
};
