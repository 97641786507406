import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { Header } from "./Header";
import { Tabs, TabsHandleProps } from "./Tabs";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import type { Evaluation } from "server/src/services/evaluation/types";

export const PageEvaluationDetails = () => {
  const tabsRef = useRef<TabsHandleProps>(null);

  const { evaluationId = "" } = useParams();

  const [selectedEvaluation, setSelectedEvaluation] = useState<
    Evaluation | undefined
  >();

  const { data: evaluationsByCycle, isFetching } =
    trpc.performance.evaluation.getEvaluationsByEvaluationCycle.useQuery(
      { _id: evaluationId },
      {
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Não foi possível buscar a lista de avaliações do ciclo. Tente novamente em breve.",
          });
        },
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!evaluationId,
      }
    );

  useEffect(() => {
    if (!evaluationsByCycle) return;

    const foundEvaluation = (evaluationsByCycle?.evaluations || []).find(
      (e) => e._id === evaluationId
    );

    return setSelectedEvaluation(foundEvaluation);
  }, [evaluationsByCycle]);

  return (
    <PageContainer>
      <Header
        isLoading={isFetching}
        evaluations={evaluationsByCycle?.evaluations || []}
        cycle={evaluationsByCycle?.cycle}
        selectedEvaluation={selectedEvaluation}
        onEvaluationSelected={setSelectedEvaluation}
        onChangeTab={(tab) => tabsRef?.current?.setActiveTab(tab)}
      />
      <Tabs
        ref={tabsRef}
        isLoading={isFetching}
        selectedEvaluation={selectedEvaluation}
      />
    </PageContainer>
  );
};
