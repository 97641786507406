import { Skeleton } from "@mui/material";

export const FormLoading = () => {
  return (
    <>
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"200px"}
        style={{ marginBottom: "24px" }}
      />
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"200px"}
        style={{ marginBottom: "24px" }}
      />

      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"400px"}
        style={{ marginBottom: "24px" }}
      />
    </>
  );
};
