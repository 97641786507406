import { FormikProps } from "formik";

import { Skeleton, TextField } from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";

import { StyledText, StyledTitle } from "@utils";

import { Row, Span, FormContainer } from "../styled";

import type { EditCriteriaFormProps } from "../types";

interface IEditCriteriaFormProps {
  formik: FormikProps<EditCriteriaFormProps>;
  loadingData: boolean;
}

export const EditCriteriaForm = ({
  formik,
  loadingData,
}: IEditCriteriaFormProps) => (
  <FormContainer>
    <Row>
      <StyledTitle
        setColor="neutral30"
        variant="headline9"
        children={"Nome e descrição"}
        style={{ fontWeight: 700 }}
      />
      <StyledText variant="body3" setColor="neutral30">
        <Span>*</Span> campo obrigatório
      </StyledText>
    </Row>
    {loadingData ? (
      <>
        <Skeleton width={"100%"} height={60} variant="rounded" />
        <Skeleton
          width={"100%"}
          height={267}
          style={{ marginTop: "24px" }}
          variant="rounded"
        />
      </>
    ) : (
      <>
        <TextField
          label={
            <div>
              Nome do critério<Span>*</Span>
            </div>
          }
          value={formik.values.name}
          onChange={(e) =>
            formik.handleChange({
              target: { id: "name", value: e.target.value },
            })
          }
          fullWidth
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextEditor
          styles={{
            container: { width: "100%", marginTop: "24px" },
            editor: { height: "200px" },
          }}
          value={formik.values.description}
          placeholder="Descrição"
          onChange={(value) => {
            formik.handleChange({
              target: { id: "description", value },
            });
          }}
          error={
            formik?.touched?.description && Boolean(formik?.errors?.description)
          }
          helperText={formik?.errors?.description}
          maxLength={1000}
        />
      </>
    )}
  </FormContainer>
);
