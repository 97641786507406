import { forwardRef, useEffect, useMemo, useState } from "react";

import {
  TextEditorProps as UiTextEditorProps,
  useSyncedRef,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, removeHtmlTags } from "@utils";

import {
  CharCountContainer,
  StyledTextEditor,
  EditorContainer,
} from "./styled";

type TextEditorProps = {
  onBlur?: (
    e: React.FocusEvent<HTMLDivElement>,
    changedValue: string
  ) => void | undefined;
  maxLength?: number;
  error?: boolean;
  helperText?: string;
} & Omit<UiTextEditorProps, "onError" | "errorMessage" | "onBlur">;

export const TextEditor = forwardRef(
  (
    { onBlur, onChange, value, maxLength, ...props }: TextEditorProps,
    forwardRef
  ) => {
    const ref: any = useSyncedRef(forwardRef);

    const [internalValue, setInternalValue] =
      useState<TextEditorProps["value"]>("");

    const textSize = useMemo(() => {
      return removeHtmlTags(internalValue as any).length;
    }, [internalValue]);

    useEffect(() => {
      if (value !== undefined && value !== internalValue) {
        setInternalValue(value);
      }
    }, [value]);

    return (
      <EditorContainer
        onBlur={(e) => {
          onBlur?.(e, internalValue as string);
        }}
      >
        <StyledTextEditor
          ref={ref}
          {...(props as any)}
          value={internalValue}
          onError={props.error}
          errorMessage={props.helperText}
          onChange={(changedValue, delta, source, editor) => {
            const changedText = removeHtmlTags(changedValue);

            const textLength = changedText.length || 0;

            if (maxLength && textLength > maxLength) {
              const _editor = ref?.current?.getEditor();

              const _editorLength = editor.getLength() - 1;

              _editor.deleteText(
                _editorLength - (textLength - maxLength),
                _editorLength + 1
              );

              setInternalValue(_editor.root.innerHTML);

              onChange?.(
                _editor.root.innerHTML,
                _editor.editor.delta,
                source,
                _editor
              );

              return;
            }

            setInternalValue(changedValue);

            onChange?.(changedValue, delta, source, editor);
          }}
        />
        {maxLength && (
          <CharCountContainer>
            <StyledText
              variant="caption"
              setColor="neutral50"
              children={`Máximo ${maxLength} caracteres`}
              style={{ fontWeight: 600 }}
            />

            <StyledText
              variant="caption"
              setColor="neutral50"
              children={`${textSize || 0}/${maxLength}`}
              style={{ fontWeight: 600 }}
            />
          </CharCountContainer>
        )}
      </EditorContainer>
    );
  }
);

TextEditor.displayName = "TextEditor";
