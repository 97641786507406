import styled from "styled-components";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const StyledSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  height: "300px",
  width: "100%",
}))`
  border-radius: 12px;
`;

export { StyledSkeleton };
