import { Menu } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Maincontainer = styled.div``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const Line = styled.div<{ marginBottom?: string; marginTop?: string }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div<{ setColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;

  border-radius: 30px;
  padding: 8px;
  box-shadow: 0 2px 3px ${({ theme }) => theme.colors.neutral[80]};
  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "success90":
        return theme.colors.feedback.success[90];
      case "info90":
        return theme.colors.feedback.info[90];
      case "error90":
        return theme.colors.feedback.error[90];
      case "tertiary90":
        return theme.colors.tertiary[90];
      case "secondary95":
        return theme.colors.secondary[95];
      default:
        return "";
    }
  }};
`;

export const StyledMenu = styled(Menu)`
  div.menu-base-container-custom-theme {
    display: flex;
  }

  .MuiMenu-list {
    display: flex;
    
    .Mui-selected {
      background-color: transparent; 
    }
  }
  .MuiMenuItem-root {
    padding 0px 8px;
  }
`;

export const AnswerArea = styled.div`
  width: 100%;
  display: flex;
`;

export const MenuContainer = styled.div`
  div.menu-base-container-custom-theme {
    display: flex;
  }
`;
