import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PageTitle = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 34px;
`;

export const Counter = styled.div`
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.neutral[90]};
  padding: 2px 12px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-left: 10px;
`;
