import { useMemo } from "react";
import { useNavigate } from "react-router";
import {
  PillButton,
  PageContainer,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, track } from "@utils";
import { routes } from "@routes";

import { SentRequest } from "./components/Tabs/SentRequests";
import { ReceivedRequest } from "./components/Tabs/ReceivedRequests";
import { Received } from "./components/Tabs/Received";
import { BigNumbers } from "./components/BigNumbers";
import { People } from "./components/Tabs/People";
import { Sent } from "./components/Tabs/Sent";

import { Header } from "./styled";
import { trpc } from "@api/client";

export const PageManageFeedbacks = () => {
  const navigate = useNavigate();

  const tabItens = useMemo(
    () => [
      { label: "Recebidos", component: <Received /> },
      { label: "Enviados", component: <Sent /> },
      { label: "Solicitações recebidas", component: <ReceivedRequest /> },
      { label: "Solicitações enviadas", component: <SentRequest /> },
      { label: "Pessoas", component: <People /> },
    ],
    []
  );

  const { data, isLoading } =
    trpc.performance.feedback.getManagerInsights.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    });

  return (
    <PageContainer>
      <Header>
        <div>
          <StyledTitle
            variant="headline6"
            children="Gerenciar feedbacks"
            setColor="neutral20"
          />
          <StyledText
            variant="body3"
            setColor="neutral40"
            children="Envie, solicite e receba feedbacks valiosos para o seu crescimento pessoal e profissional de forma fácil e construtiva."
          />
        </div>
        <PillButton
          size="medium"
          variant="default"
          type="secondary"
          icon="IconSettings"
          onClick={() => {
            track({
              name: "performance_feedbacks_settings_clicked",
            });
            navigate(routes.PageFeedbackSettings);
          }}
        />
      </Header>
      <BigNumbers data={data} isLoading={isLoading} />
      <Tab tabItens={tabItens} />
    </PageContainer>
  );
};
