import { WarnText, InfoText } from "./styled";

interface UnfilledFieldProps {
  value: string;
}
export const UnfilledField = ({ value }: UnfilledFieldProps) => {
  return value ? (
    <InfoText variant="body3" tag="span">
      {value}
    </InfoText>
  ) : (
    <WarnText variant="body3">Não preenchido</WarnText>
  );
};
