import styled from "styled-components";

import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { Avatar } from "@mui/material";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0px;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  width: 60px;
  height: 60px;
`;

export const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 40px;
  width: 100%;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTag = styled(Tag)`
  pointer-events: none;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 12px;
`;
