import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

interface IFinishEvaluationModal extends CommonModalProps {
  disabled?: boolean;
}

export const FinishEvaluationModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  disabled,
}: IFinishEvaluationModal) => {
  return (
    <ConfirmationModal
      open={open}
      showWarning
      secondaryVariant="default"
      icon="IconAlertCircle"
      title="Tem certeza que deseja enviar a avaliação"
      subTitle="Você ainda poderá realizar modificações na avaliação enquanto o prazo de resposta não expirar."
      confirm={{
        title: "Enviar avaliação",
        icon: "IconArrowRight",
        iconColor: "neutral100",
        disabled: disabled || isLoading,
      }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      surveyId="modal_finish_evaluation_survey"
    />
  );
};
