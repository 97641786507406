import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;

  padding: 40px 24px;
  gap: 24px;

  width: 100%;
  max-width: 311px;

  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

export { CardContainer, ContentContainer, TextContainer, ButtonsContainer };
