import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormikProps } from "formik";

import {
  Accordion,
  Button,
  DatePicker,
  PillButton,
  Skeleton,
  TextField,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { cloneDeep } from "lodash-es";
import { routes } from "@routes";
import { trpc } from "@api/client";

import { StyledIcon, StyledText, StyledTitle, track } from "@utils";
import {
  Container,
  FlexRow,
  LeftColumn,
  Line,
  RightColumn,
  Span,
  StyledSelect,
  StyledPLine,
} from "./styled";

type ActionsProps = {
  topic: string;
  type: string;
  developmentAction: string;
  initialDate: Date;
  finalDate: Date;
  successIndicators: string;
  itemsToDevelop: string;
  _id?: string;
  index?: string;
};

type SecondStepProps = {
  formik: FormikProps<any>;
  fetching?: boolean;
  disabled?: boolean;
};

export const SecondStep = ({ formik, fetching, disabled }: SecondStepProps) => {
  const navigate = useNavigate();

  const { employeeId, origin = "", idpId = "" } = useParams();

  useEffect(() => {
    formik.validateForm().then((errors) => {
      if (idpId) return;
      if (!errors.basicInfo) return;

      if (Object.keys(errors.basicInfo).length !== 0) {
        const ownerUrl = employeeId ? `/to/${employeeId}` : "";

        navigate(
          routes.PageCreateIDP.replace(":origin", origin).replace(
            ":step",
            "1"
          ) + ownerUrl
        );
      }
    });
  }, []);

  const { data: criterials, isFetching: isLoadingCriterials } =
    trpc.performance.evaluation.getCriterialsByEvaluationProcedure.useQuery(
      {
        evaluationId: formik.values.basicInfo?.evaluationId,
      },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!formik.values.basicInfo?.evaluationId,
      }
    );

  const actionsRender = useMemo(() => {
    return formik?.values?.actions?.map((action: ActionsProps, index) => {
      const isEvaluationIdp = formik.values.basicInfo.type === "evaluation";

      const criterialsName =
        isEvaluationIdp && action.topic
          ? (
              criterials?.find((c) => c._id === action.topic) || {
                name: "",
              }
            ).name
          : "";

      const name = (isEvaluationIdp ? criterialsName : action?.topic) || "Ação";

      return (
        <Accordion
          key={action?._id || action?.index}
          defaultExpanded={index == 0}
          actions={[
            {
              onClick: () => {
                console.log("");
              },
              children: (
                <PillButton
                  icon="IconTrash"
                  size="small"
                  variant="default"
                  type="secondary"
                  onClick={() => {
                    const clonedActions = cloneDeep(formik.values.actions);

                    clonedActions.splice(index, 1);

                    formik.handleChange({
                      target: { id: "actions", value: clonedActions },
                    });
                  }}
                />
              ),
            },
          ]}
          customHeader={
            <StyledTitle
              variant="body2"
              style={{ textTransform: "capitalize", maxWidth: "" }}
            >
              <StyledPLine numberOfLines={2}>{name}</StyledPLine>
            </StyledTitle>
          }
          variant="default"
        >
          <Container>
            <FlexRow>
              <StyledTitle
                setColor="neutral30"
                variant="body2"
                children={"Informações principais"}
              />
              <StyledText variant="body3" setColor="neutral30">
                <Span>*</Span> campo obrigatório
              </StyledText>
            </FlexRow>
            <FlexRow>
              {!!isEvaluationIdp &&
                (isLoadingCriterials ? (
                  <Skeleton width={"100%"} height={60} variant="rounded" />
                ) : (
                  <StyledSelect
                    label={
                      <StyledText variant="body3" setColor="neutral30">
                        Critérios Avaliativos
                        <Span>*</Span>
                      </StyledText>
                    }
                    options={criterials?.map((criterial) => ({
                      label: criterial.name,
                      value: criterial._id,
                    }))}
                    value={action?.topic}
                    searchable={true}
                    fullWidth
                    onSelectChange={(_, option) => {
                      const clonedActions = cloneDeep(formik.values.actions);

                      clonedActions[index].topic = option?.value;

                      formik.handleChange({
                        target: { id: "actions", value: clonedActions },
                      });
                    }}
                    error={
                      formik.touched.actions?.[index]?.topic &&
                      Boolean(formik?.errors?.actions?.[index]?.topic)
                    }
                    helperText={
                      formik.touched.actions?.[index]?.topic &&
                      formik?.errors?.actions?.[index]?.topic
                    }
                  />
                ))}
              {!isEvaluationIdp && (
                <div style={{ width: "100%" }}>
                  <TextField
                    label={
                      <StyledText variant="body3" setColor="neutral30">
                        Tema <Span>*</Span>
                      </StyledText>
                    }
                    fullWidth
                    value={action?.topic}
                    onChange={({ target }) => {
                      const clonedActions = cloneDeep(formik.values.actions);

                      clonedActions[index].topic = target?.value;

                      formik.handleChange({
                        target: { id: "actions", value: clonedActions },
                      });
                    }}
                    error={
                      formik.touched.actions?.[index]?.topic &&
                      Boolean(formik?.errors?.actions?.[index]?.topic)
                    }
                    helperText={
                      formik.touched.actions?.[index]?.topic &&
                      formik?.errors?.actions?.[index]?.topic
                    }
                  />
                </div>
              )}
              <StyledSelect
                label={
                  <StyledText variant="body3" setColor="neutral30">
                    Tipo de ação <Span>*</Span>
                  </StyledText>
                }
                options={[
                  { label: "Prática (aprender fazendo)", value: "practical" },
                  {
                    label: "Social (conversar, mentorias, trocas)",
                    value: "social",
                  },
                  {
                    label: "Teórica (curso, treinamento)",
                    value: "teorical",
                  },
                ]}
                value={action?.type}
                searchable={true}
                fullWidth
                onSelectChange={(_, option) => {
                  if (employeeId) {
                    track({
                      name:
                        option?.value === "practical"
                          ? "performance_myidp_createnewplan_configurations_actiontype_practical_selected"
                          : option?.value === "social"
                          ? "performance_myidp_createnewplan_configurations_actiontype_social_selected"
                          : "performance_myidp_createnewplan_configurations_actiontype_theoretical_selected",
                    });
                  } else {
                    track({
                      name:
                        option?.value === "practical"
                          ? "performance_idp_createactionplan_configurations_actiontype_practical_selected"
                          : option?.value === "social"
                          ? "performance_idp_createactionplan_configurations_actiontype_social_selected"
                          : "performance_idp_createactionplan_configurations_actiontype_theoretical_selected",
                    });
                  }
                  const clonedActions = cloneDeep(formik.values.actions);

                  clonedActions[index].type = option?.value;

                  formik.handleChange({
                    target: { id: "actions", value: clonedActions },
                  });
                }}
                error={
                  formik.touched.actions?.[index]?.type &&
                  Boolean(formik?.errors?.actions?.[index]?.type)
                }
                helperText={
                  formik.touched.actions?.[index]?.type &&
                  formik?.errors?.actions?.[index]?.type
                }
              />
            </FlexRow>
            <div>
              <TextField
                label={
                  <StyledText variant="body3" setColor="neutral30">
                    Ação de desenvolvimento <Span>*</Span>
                  </StyledText>
                }
                rows={3}
                multiline={true}
                fullWidth
                value={action?.developmentAction}
                onChange={({ target }) => {
                  const clonedActions = cloneDeep(formik.values.actions);

                  clonedActions[index].developmentAction = target?.value;

                  formik.handleChange({
                    target: { id: "actions", value: clonedActions },
                  });
                }}
                error={
                  formik.touched.actions?.[index]?.developmentAction &&
                  Boolean(formik?.errors?.actions?.[index]?.developmentAction)
                }
                helperText={
                  formik.touched.actions?.[index]?.developmentAction &&
                  formik?.errors?.actions?.[index]?.developmentAction
                }
              />
            </div>
            <Line />
            <StyledTitle
              setColor="neutral30"
              variant="body2"
              children={"Prazo"}
            />
            <FlexRow>
              <DatePicker
                name={"initialDate"}
                label={"Data Inicial *"}
                fromDate={dayjs()}
                toDate={action.finalDate}
                value={action.initialDate}
                onDateChange={(value) => {
                  const date =
                    value && dayjs(value).isValid() ? dayjs(value) : null;

                  const parsedValue = dayjs(value).isValid()
                    ? date?.toISOString()
                    : null;

                  const clonedActions = cloneDeep(formik.values.actions);
                  clonedActions[index].initialDate = parsedValue;
                  formik.handleChange({
                    target: { id: "actions", value: clonedActions },
                  });
                }}
                error={
                  formik.touched.actions?.[index]?.initialDate &&
                  Boolean(formik?.errors?.actions?.[index]?.initialDate)
                }
                helperText={
                  formik.touched.actions?.[index]?.initialDate &&
                  formik?.errors?.actions?.[index]?.initialDate?.toString()
                }
                fullWidth
              />
              <DatePicker
                name={"finalDate"}
                label={"Data Final *"}
                fromDate={action.initialDate || dayjs()}
                value={action?.finalDate}
                onDateChange={(value) => {
                  const date =
                    value && dayjs(value).isValid() ? dayjs(value) : null;

                  const parsedValue = dayjs(value).isValid()
                    ? date?.toISOString()
                    : null;

                  const clonedActions = cloneDeep(formik.values.actions);
                  clonedActions[index].finalDate = parsedValue;
                  formik.handleChange({
                    target: { id: "actions", value: clonedActions },
                  });
                }}
                error={
                  formik.touched.actions?.[index]?.finalDate &&
                  Boolean(formik?.errors?.actions?.[index]?.finalDate)
                }
                helperText={
                  formik.touched.actions?.[index]?.finalDate &&
                  formik?.errors?.actions?.[index]?.finalDate?.toString()
                }
                fullWidth
              />
            </FlexRow>

            <Line />

            <StyledTitle
              setColor="neutral30"
              variant="body2"
              children={"Informações complementares"}
            />

            <div>
              <TextField
                label={
                  <StyledText variant="body3" setColor="neutral30">
                    Indicadores de sucesso
                  </StyledText>
                }
                rows={3}
                multiline={true}
                fullWidth
                value={action?.successIndicators}
                onChange={({ target }) => {
                  const clonedActions = cloneDeep(formik.values.actions);

                  clonedActions[index].successIndicators = target?.value;

                  formik.handleChange({
                    target: { id: "actions", value: clonedActions },
                  });
                }}
              />
            </div>
            <div>
              <TextField
                label={
                  <StyledText variant="body3" setColor="neutral30">
                    Pontos a desenvolver
                  </StyledText>
                }
                value={action?.itemsToDevelop}
                rows={3}
                multiline={true}
                fullWidth
                onChange={({ target }) => {
                  const clonedActions = cloneDeep(formik.values.actions);

                  clonedActions[index].itemsToDevelop = target?.value;

                  formik.handleChange({
                    target: { id: "actions", value: clonedActions },
                  });
                }}
              />
            </div>
          </Container>
        </Accordion>
      );
    });
  }, [formik, criterials, isLoadingCriterials]);

  return (
    <div style={{ display: "flex" }}>
      <LeftColumn>
        <StyledTitle
          variant="body1"
          children="Configurações"
          setColor="secondary50"
        />
        <StyledText
          variant="body3"
          children="Personalize as ações do plano de desenvolvimento."
          setColor="neutral50"
        />
      </LeftColumn>
      <RightColumn>
        <Button
          size="large"
          variant="secondary"
          style={{ width: "100%" }}
          disabled={fetching || disabled}
          onClick={() => {
            track({
              name: employeeId
                ? "performance_myidp_createnewplan_configurations_addnewaction_clicked"
                : "performance_idp_createactionplan_configurations_addnewaction_clicked",
            });
            const newActions = [
              {
                index: new Date().toISOString(),
                topic: "",
                type: "",
                developmentAction: "",
                initialDate: "",
                finalDate: "",
                successIndicators: "",
                itemsToDevelop: "",
                status: "created",
              },
              ...formik?.values?.actions,
            ];
            formik.handleChange({
              target: { id: "actions", value: newActions },
            });
          }}
        >
          <StyledIcon name="IconPlus" setColor="primary" />
          Adicionar Ação
        </Button>
        {fetching ? (
          <>
            <Skeleton width={"100%"} height={106} variant="rounded" />
          </>
        ) : (
          actionsRender
        )}
      </RightColumn>
    </div>
  );
};
