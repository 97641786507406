import styled from "styled-components";

export const DataGridContainer = styled.div`
  .data-grid-pagination-container {
    margin: 16px 0px 0px 0px !important;
  }

  .data-grid-custom-select div.MuiSelect-select {
    padding-right: 36px !important;
  }

  .select-icon-custom svg {
    top: calc(50% - 0.7em);
    right: 5px;
  }
`;
