import { PillButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTitle, StyledText } from "@utils";

import { HeaderContainer, TitleContainer } from "../styled";

interface HeaderProps {
  title: string;
  subTitle: string;
  onClose: React.EventHandler<any> | undefined;
}

export const Header = ({ title, subTitle, onClose }: HeaderProps) => {
  return (
    <HeaderContainer>
      <TitleContainer>
        <StyledTitle variant="headline6">{title}</StyledTitle>
        <StyledText variant="body3" setColor="neutral50">
          {subTitle}
        </StyledText>
      </TitleContainer>
      <div style={{ marginTop: "-20px" }}>
        <PillButton
          variant="default"
          size="small"
          type="secondary"
          icon="IconX"
          onClick={onClose}
        />
      </div>
    </HeaderContainer>
  );
};
