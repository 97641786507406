import { FormikProps } from "formik";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { Table } from "./Table";

import type { SendFeedbackFormProps } from "@pages/Feedback/PageSendFeedback/types";

import { CardContainer, ErrorContainer } from "./styled";

type CriterialsProps = {
  formik: FormikProps<SendFeedbackFormProps>;
  evaluation: {
    _id: string;
    name: string;
  };
  isLoading: boolean;
};

export const Criterials = ({
  formik,
  evaluation,
  isLoading,
}: CriterialsProps) => {
  return (
    <CardContainer
      error={
        !!formik.touched.evaluationCriterials &&
        Boolean(formik.errors.evaluationCriterials)
      }
    >
      <Tag
        variant={"primary"}
        children={"Obrigatório"}
        style={{ marginBottom: "16px" }}
      />
      <StyledTitle
        setColor="neutral30"
        variant="body2"
        children={"Critérios Avaliativos"}
        style={{ marginBottom: "4px" }}
      />
      <StyledText
        setColor="neutral50"
        variant="body4"
        children={
          "Personalize o feedback incluindo critérios avaliativos da avaliação escolhida."
        }
        style={{ marginBottom: "24px" }}
      />

      <Table
        data={formik.values.evaluationCriterials || []}
        evaluation={evaluation}
        onCriterialsChanged={(values) =>
          formik.handleChange({
            target: {
              id: "evaluationCriterials",
              value: values,
            },
          })
        }
        loading={isLoading}
      />
      {formik.touched.evaluationCriterials &&
        formik.errors.evaluationCriterials === "no_competence" && (
          <ErrorContainer>
            <StyledIcon
              name="IconInfoCircle"
              setColor="error50"
              fill="none"
              size={18}
            />
            <StyledText variant="caption" setColor="neutral50">
              Selecione ao menos um critério avaliativo.
            </StyledText>
          </ErrorContainer>
        )}
      {formik.touched.evaluationCriterials &&
        Array.isArray(formik?.errors?.evaluationCriterials) &&
        formik?.errors?.evaluationCriterials.length && (
          <ErrorContainer>
            <StyledIcon
              name="IconInfoCircle"
              setColor="error50"
              fill="none"
              size={18}
            />
            <StyledText variant="caption" setColor="neutral50">
              Todos os critérios avaliativos selecionados precisam ser
              avaliados.
            </StyledText>
          </ErrorContainer>
        )}
    </CardContainer>
  );
};
