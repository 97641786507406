import * as yup from "yup";

import { removeHtmlTags } from "@utils";

export type EditCriteriaFormProps = {
  name: string;
  description: string;
};

export const validationSchema = yup.object({
  name: yup.string().required("Este campo deve ser preenchido"),
  description: yup
    .string()
    .test("empty text editor", "Este campo deve ser preenchido", (val) =>
      Boolean(removeHtmlTags(val).trim().length)
    )
    .required("Este campo deve ser preenchido"),
});
