import styled from "styled-components";

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: 40px;
  padding-left: 24px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 40px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 14px;
`;

export const Columns = styled.div`
  display: flex;
`;
export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  width: 100%;
  max-width: 312px;
`;
