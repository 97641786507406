import { cloneDeep } from "lodash-es";

import type { SectionType } from "../types";
import type { Criteria } from "server/src/services/criteria/types";

type getSectionByIndexType = (
  sections: SectionType[],
  selectedSectionIndex: number
) => SectionType | undefined;

const getSectionByIndex: getSectionByIndexType = (
  sections = [],
  selectedSectionIndex
) => {
  const section = sections?.[selectedSectionIndex];

  return section;
};

const getEvaluationCriterials = (sections: SectionType[]) => {
  const clonedSections = cloneDeep(sections);

  const evaluationCriterials = clonedSections.reduce((acc: string[], obj) => {
    const criterials = (obj?.criterials || []).map((c) => c._id);
    return [...acc, ...criterials];
  }, []);

  return [...new Set(evaluationCriterials)];
};

const findSectionName = (sections: SectionType[], newName: string) => {
  const found = sections.find((section) => section.name === newName);

  return found;
};

const generateSectionTitle = (
  sections: SectionType[],
  newTitle: string
): string => {
  const found = sections.find((section) => section.name === newTitle);

  if (found) return generateSectionTitle(sections, `[Cópia] ${newTitle}`);

  return newTitle;
};

interface addSectionProps {
  sections: SectionType[];
  newSection: { name: string; description?: string };
}

const addSection = ({ sections, newSection }: addSectionProps) => {
  const clonedSections = cloneDeep(sections);

  const found = findSectionName(sections, newSection.name);

  if (found) return clonedSections;

  clonedSections.push({
    _id: undefined,
    ...newSection,
    order: clonedSections.length + 1,
    criterials: [],
  });

  return clonedSections;
};

interface addCriteriaBySectionProps {
  sections: SectionType[];
  selectedSectionIndex: number;
  criterials: Criteria[];
}

const addCriteriaBySection = ({
  sections,
  selectedSectionIndex,
  criterials,
}: addCriteriaBySectionProps) => {
  const section = getSectionByIndex(sections, selectedSectionIndex);

  const clonedSections = cloneDeep(sections);

  if (!section) return clonedSections;

  const evaluationCriterials = getEvaluationCriterials(sections);

  const criterialsToAdd = criterials.filter(
    (criterial) => !evaluationCriterials.includes(criterial._id)
  );

  const sectionCriterials = section.criterials || [];

  sectionCriterials.push(...(criterialsToAdd as any));

  clonedSections[selectedSectionIndex].criterials = sectionCriterials.map(
    (criteria, index) => ({ ...criteria, order: index + 1 })
  );

  return clonedSections;
};

interface renameSectionProps {
  sections: SectionType[];
  selectedSectionIndex: number;
  updatedSection: {
    name: string;
    description?: string;
  };
}

const renameSection = ({
  sections,
  selectedSectionIndex,
  updatedSection,
}: renameSectionProps) => {
  const section = getSectionByIndex(sections, selectedSectionIndex);

  const clonedSections = cloneDeep(sections);

  if (!section) return clonedSections;

  const found = findSectionName(clonedSections, updatedSection.name);
  const isSameName = section.name === updatedSection.name;

  if (found && !isSameName) return clonedSections;

  clonedSections[selectedSectionIndex].name = updatedSection.name;
  clonedSections[selectedSectionIndex].description = updatedSection.description;

  return clonedSections;
};

interface duplicateSectionProps {
  sections: SectionType[];
  selectedSectionIndex: number;
}

const duplicateSection = ({
  sections,
  selectedSectionIndex,
}: duplicateSectionProps) => {
  const section = getSectionByIndex(sections, selectedSectionIndex);

  const clonedSections = cloneDeep(sections);

  if (!section) return clonedSections;

  const name = generateSectionTitle(clonedSections, `[Cópia] ${section.name}`);

  clonedSections.push({
    _id: undefined,
    name,
    description: section.description,
    order: clonedSections.length + 1,
    criterials: [],
  });

  return clonedSections;
};

interface removeSectionProps {
  sections: SectionType[];
  selectedSectionIndex: number;
}

const removeSection = ({
  sections,
  selectedSectionIndex,
}: removeSectionProps) => {
  const section = getSectionByIndex(sections, selectedSectionIndex);

  const clonedSections = cloneDeep(sections);

  if (!section) return clonedSections;

  clonedSections.splice(selectedSectionIndex, 1);

  return clonedSections.map((section, index) => ({
    ...section,
    order: index + 1,
  }));
};

interface removeCriteriaBySectionProps {
  sections: SectionType[];
  selectedSectionIndex: number;
  criteriaId: string;
}

const removeCriteriaBySection = ({
  sections,
  selectedSectionIndex,
  criteriaId,
}: removeCriteriaBySectionProps) => {
  const section = getSectionByIndex(sections, selectedSectionIndex);

  const clonedSections = cloneDeep(sections);

  if (!section) return clonedSections;

  const sectionCriterials = section.criterials || [];

  const criteriaIndex = sectionCriterials.findIndex(
    (criteria) => criteria._id === criteriaId
  );

  if (criteriaIndex === -1) return clonedSections;

  sectionCriterials.splice(criteriaIndex, 1);

  clonedSections[selectedSectionIndex].criterials = sectionCriterials.map(
    (criteria, index) => ({ ...criteria, order: index + 1 })
  );

  return clonedSections;
};

export {
  addSection,
  addCriteriaBySection,
  duplicateSection,
  renameSection,
  removeSection,
  removeCriteriaBySection,
  getEvaluationCriterials,
};
