import styled, { css } from "styled-components";

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  ${FlexColumn};
  gap: 32px;
`;

const ItemContainer = styled.div`
  ${FlexColumn};
  gap: 16px;
`;

export { Container, ItemContainer };
