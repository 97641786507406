import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 24px;
  padding: 40px 0px;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
