import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const SelectArea = styled.div`
  width: 100%;
  max-width: 395px;

  @media screen and (max-width: 1200px) {
    max-width: none;
  }
`;

export const InfoGrid = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.secondary[80]};
  background: ${({ theme }) => theme.colors.secondary[99]};
  padding: 16px 32px 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-bottom: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const PeopleArea = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Typography)`
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-right: 16px;
`;

export const ArrowIcon = styled(Icons)`
  margin: 0 12px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const GridItem = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme }) => theme.colors.neutral[100]};
  padding: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
`;

export const GridIcon = styled.div`
  padding: 16px;
  border-radius: 200px;
  background: #ffe0ef;
  svg {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

export const GridTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const GridDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  span {
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral[50]};
    margin-left: 8px;
  }
`;
