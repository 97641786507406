import { SearchField } from "../SearchField";
import { TableFilters } from "../TableFilters";

import { PaginationTableProps } from "./types";

export const PaginationTable = ({
  loading,
  loadingFilters,
  data = [],
  filters,
  pagination,
  isFiltered,
  searchPlaceholder,
  tableGridRender,
  refetch,
  onSearch,
  onFiltersChanged,
  onPaginationChanged,
}: PaginationTableProps) => {
  return (
    <div>
      {onSearch && (
        <SearchField
          disabled={loading}
          onChange={onSearch}
          placeholder={searchPlaceholder}
          style={{ marginBottom: "32px" }}
        />
      )}

      {filters?.[0] && (
        <TableFilters
          disabled={loading || loadingFilters}
          filters={filters}
          onFilter={onFiltersChanged}
        />
      )}

      {tableGridRender({
        data,
        loading,
        pagination,
        isFiltered,
        onPaginationChanged,
        refetch,
      })}
    </div>
  );
};
