import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  PillButton,
  Tooltip,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { StyledText, pageSizeOptions, renderHtmlText } from "@utils";

import {
  DataGridContainer,
  CellArea,
  TooltipText,
  CellAreaAnswer,
  FlexRow,
} from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

import { useTheme } from "styled-components";

export const TableGrid = ({
  data,
  rawData,
  errorCriterial,
  loading,
}: TableGridCommonProps & {
  errorCriterial: { _id: string }[] | undefined;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { participantId = "", cycleId = "" } = useParams();

  const columns = useMemo(
    () => [
      {
        Header: "Critério",
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description = "" } = original;
          return (
            <CellArea>
              <TooltipText variant="body3">
                {name}
                <Tooltip
                  title={renderHtmlText(description)}
                  style={{ marginLeft: "4px", cursor: "pointer" }}
                >
                  <div>
                    <Icons name="IconInfoCircle" fill="transparent" size={16} />
                  </div>
                </Tooltip>
              </TooltipText>
              <StyledText variant="body4">
                {renderHtmlText(description)}
              </StyledText>
            </CellArea>
          );
        },
      },
      {
        Header: "Resposta",
        accessor: "answer",
        disableSortBy: true,
        Cell: ({ row }) => {
          const answer = row?.original?.answer || "-";
          const _id = row?.original?._id;

          const hasNoAnswer = !!errorCriterial?.find((e) => e._id === _id);

          return (
            <CellAreaAnswer>
              <FlexRow>
                <StyledText variant="body3" children={answer} />
                {hasNoAnswer && (
                  <Icons
                    color={theme.colors.feedback.error[50]}
                    name="IconInfoCircle"
                    size={18}
                  />
                )}
              </FlexRow>
            </CellAreaAnswer>
          );
        },
      },
      {
        Header: "Ações",
        sticky: "right",
        disableSortBy: true,
        accessor: "action",
        Cell: ({ row }) => {
          const sectionIndex = row?.original?.sectionIndex || 1;

          return (
            <PillButton
              onClick={() =>
                navigate(
                  routes.PageViewEvaluation(
                    cycleId,
                    participantId,
                    "evaluation",
                    sectionIndex
                  )
                )
              }
              size="small"
              variant="default"
              type="secondary"
              icon="IconPencil"
            />
          );
        },
      },
    ],
    [data, participantId, errorCriterial]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum critério foi encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhuma critério foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </DataGridContainer>
  );
};
