import { Carousel, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { Card, CircleContainer, SkeletonContainer } from "./styled";

export const BigNumbers = ({ data, isLoading }) => {
  return isLoading ? (
    <SkeletonContainer>
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
    </SkeletonContainer>
  ) : (
    <div style={{ marginBottom: "10px" }}>
      <Carousel
        slides={[
          <Card key={0}>
            <CircleContainer setColor="secondary95">
              <StyledIcon
                name="IconPlane"
                fill="none"
                setColor="secondary50"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.total?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Todos os planos"}
            />
          </Card>,
          <Card key={1}>
            <CircleContainer setColor="neutral90">
              <StyledIcon
                name="IconHistory"
                fill="none"
                setColor="neutral40"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.created?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Não iniciados"}
            />
          </Card>,
          <Card key={2}>
            <CircleContainer setColor="info90">
              <StyledIcon
                name="IconLoader"
                fill="none"
                setColor="info40"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.progressing?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Em andamento"}
            />
          </Card>,
          <Card key={3}>
            <CircleContainer setColor="error90">
              <StyledIcon
                name="IconAlertTriangle"
                fill="none"
                setColor="error40"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.delayed?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Atrasados"}
            />
          </Card>,
          <Card key={4}>
            <CircleContainer setColor="success90">
              <StyledIcon
                name="IconCheck"
                fill="none"
                setColor="success40"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.finished?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Concluídos"}
            />
          </Card>,
        ]}
        gap={24}
        arrows="bottom"
        alignSlides="center"
      />
    </div>
  );
};
