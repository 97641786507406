import { useMemo } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import { RenderNameField } from "@components/TableNameField";
import { OverflowCheck } from "@components/OverflowCheck";
import { StyledText, pageSizeOptions } from "@utils";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
}: TableGridProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Solicitado por",

        accessor: "requestedBy",

        Cell: ({ row }) =>
          RenderNameField(row?.original?.requestedBy?.name || ""),
      },
      {
        Header: "Solicitado a",
        accessor: "from",
        Cell: ({ row }) => RenderNameField(row?.original?.from?.name || ""),
      },
      {
        Header: "Feedback para",
        accessor: "to",
        Cell: ({ row }) => RenderNameField(row?.original?.to?.name || ""),
      },
      {
        Header: "Solicitação",
        accessor: "message",
        Cell: ({ value }) => {
          return (
            <StyledText
              variant="body3"
              style={{ fontWeight: 600 }}
              children={OverflowCheck({
                text: value?.replace(/<(?:.|\n)*?>|&nbsp;/gm, " ") || "",
              })}
            />
          );
        },
      },
      {
        Header: "Criado em",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const isValid = dayjs(value).isValid();
          return isValid ? dayjs(value).format("DD/MM/YYYY") : "-";
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Ainda não existem solicitações de feedback disponíveis."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhuma avaliação foi encontrada!",
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};
