import { useMemo, useState } from "react";
import { cloneDeep } from "lodash";

import {
  DataGrid,
  Skeleton,
  PillButton,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";

import { OverflowCheck } from "@components/OverflowCheck";

import { AddCompanyValuesModal } from "@components/Modals";

import {
  StyledIcon,
  StyledText,
  StyledTitle,
  pageSizeOptions,
  track,
} from "@utils";

interface TableGridProps {
  data: any[];
  pageSize: number;
  loading: boolean;
  onValuesChanged: (values: any[]) => void;
  refetch?: () => void;
}

export const TableGrid = ({
  data,
  pageSize,
  loading,
  onValuesChanged,
}: TableGridProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Valor",
        accessor: "name",
        Cell: (e) => {
          return (
            <>
              <StyledTitle variant="body3" children={e?.row?.original?.name} />
              <StyledText
                variant="body3"
                children={OverflowCheck({
                  text: e?.row?.original?.description,
                  width: "400px",
                })}
              />
            </>
          );
        },
      },
      {
        Header: "Ação",
        accessor: "action",
        sticky: "right",
        Cell: (info) => {
          return (
            <PillButton
              size="medium"
              variant="default"
              type="secondary"
              icon="IconTrash"
              onClick={() => {
                const selectedValues = cloneDeep(data);
                const filteredValues = selectedValues.filter(
                  (value) => value._id !== info.row.original._id
                );

                onValuesChanged(filteredValues);
              }}
            />
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  return (
    <>
      {!!data.length && (
        <Button
          size={"medium"}
          variant="secondary"
          onClick={() => {
            track({
              name: "performance_myfeedbacks_requestfeedback_addvalues_clicked",
            });
            setIsOpen(true);
          }}
          style={{ width: "100%", marginBottom: "24px" }}
        >
          Adicionar Valores <StyledIcon name="IconPlus" fill="none" />
        </Button>
      )}

      <DataGrid
        hasPagination={false}
        columns={data.length ? columns : []}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum valor encontrado",
          children: (
            <Button
              size={"large"}
              variant="primary"
              onClick={() => {
                track({
                  name: "performance_myfeedbacks_requestfeedback_addvalues_clicked",
                });
                setIsOpen(true);
              }}
            >
              Adicionar Valores <StyledIcon name="IconPlus" fill="none" />
            </Button>
          ),
        }}
        pageSizeOptions={pageSizeOptions}
      />
      <AddCompanyValuesModal
        open={isOpen}
        selectedValues={data}
        onValuesChanged={onValuesChanged}
        type={"request"}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
