import { trpc } from "@api/client";

import { Table } from "./Table";

import { PageContainer } from "./styled";

interface ReceivedRequestProps {
  employeeId: string;
}

export const ReceivedRequest = ({ employeeId }: ReceivedRequestProps) => {
  const { data, isFetching } =
    trpc.performance.feedback.getReceivedRequestsByEmployee.useQuery(
      { employeeId },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!employeeId,
      }
    );

  return (
    <PageContainer>
      <Table
        loading={isFetching}
        data={data?.receivedFeedbackRequests || []}
        filters={data?.filter || []}
      />
    </PageContainer>
  );
};
