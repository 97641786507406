import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  Icons,
  Typography,
  Menu,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { IdpDrawer } from "@components/Drawers";
import { DeleteIDPModal } from "@components/Modals";

import { ProgressBar } from "@components/ProgressBar";
import { TableTag } from "@components/TableTag";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { StyledIcon, StyledText, dispatchToast, pageSizeOptions } from "@utils";

import type { TableGridCommonProps } from "@components/Table/types";

import {
  CellArea,
  DataGridContainer,
  DeleteOption,
  Option,
  TagArea,
} from "./styled";

export const TableGrid = ({ data, rawData, loading }: TableGridCommonProps) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();

  const [deleteIdp, setDeleteIdp] = useState({ idpId: "", isOpen: false });
  const [openDrawer, setOpenDrawer] = useState({ open: false, _id: "" });

  const { mutate: duplicateIdp } =
    trpc.performance.idp.duplicateIdp.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Plano duplicado com sucesso.",
        });

        utils.performance.idp.getEmployeeIdpsByCycle.invalidate();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao duplicar, tente novamente em breve.",
        });
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: "Plano de ação",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name = "-" } = original;
          return (
            <CellArea>
              <Typography variant="body3">{name}</Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Tipo de plano",
        disableSortBy: true,
        accessor: "planType",
        Cell: ({ row: { original } }) => {
          const { type = "-" } = original;
          return (
            <CellArea>
              <Typography variant="body3">
                {type === "independent"
                  ? "Independente"
                  : "Atrelado à avaliação"}
              </Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "status",
        Cell: ({ row: { original } }) => {
          const { status } = original;

          const labels = {
            finished: { label: "Concluído", color: "green" },
            progressing: { label: "Em andamento", color: "blue" },
            created: { label: "Não iniciado", color: "gray" },
            delayed: { label: "Atrasado", color: "yellow" },
          };

          return (
            <TagArea>
              <TableTag
                label={labels[status]?.label}
                variant={labels[status]?.color}
                hasDot={true}
              />
            </TagArea>
          );
        },
      },
      {
        Header: "Progresso",
        disableSortBy: true,
        accessor: "progress",
        Cell: ({ row: { original } }) => {
          const { progress = 0 } = original;
          return (
            <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
              <ProgressBar progress={progress} />
            </div>
          );
        },
      },
      {
        Header: "Qtd. de ações",
        disableSortBy: true,
        accessor: "actions",
        Cell: ({ row: { original } }) => {
          const { actions = [] } = original;
          return (
            <CellArea>
              <Typography variant="body3">{actions?.length}</Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Criado por",
        disableSortBy: true,
        accessor: "createdBy",
        Cell: ({ row: { original } }) => {
          const { createdBy } = original;
          return (
            <CellArea>
              <Typography variant="body3">{createdBy?.name}</Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { _id } = original;
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () => {
                    setOpenDrawer({ open: true, _id });
                  },
                  children: (
                    <Option>
                      <Icons
                        name="IconDeviceDesktopAnalytics"
                        fill="transparent"
                      />
                      <StyledText
                        variant="body3"
                        style={{ fontWeight: 600 }}
                        setColor="neutral40"
                      >
                        Ver detalhes do plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    navigate(
                      routes.PageEditIDP.replace(":origin", "manage-idps")
                        .replace(":step", "1")
                        .replace(":idpId", _id)
                    );
                  },
                  children: (
                    <Option>
                      <Icons name="IconPencil" fill="transparent" />
                      <StyledText
                        variant="body3"
                        style={{ fontWeight: 600 }}
                        setColor="neutral40"
                      >
                        Editar plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => duplicateIdp({ _id }),

                  children: (
                    <Option>
                      <StyledIcon name="IconCopy" fill="transparent" />
                      <StyledText
                        variant="body3"
                        style={{ fontWeight: 600 }}
                        setColor="neutral40"
                      >
                        Duplicar plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => setDeleteIdp({ idpId: _id, isOpen: true }),
                  children: (
                    <DeleteOption>
                      <StyledIcon
                        name="IconTrash"
                        fill="transparent"
                        setColor="error50"
                      />
                      <StyledText
                        variant="body3"
                        style={{ fontWeight: 600 }}
                        setColor="error50"
                      >
                        Excluir plano de ação
                      </StyledText>
                    </DeleteOption>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                variant="default"
                size="small"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum PDI foi encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhum PDI foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
      <DeleteIDPModal
        open={deleteIdp.isOpen}
        idpId={deleteIdp.idpId}
        refetch={() =>
          utils.performance.idp.getEmployeeIdpsByCycle.invalidate()
        }
        onClose={() => setDeleteIdp({ idpId: "", isOpen: false })}
      />
      <IdpDrawer
        isOpen={openDrawer?.open}
        onClose={() => setOpenDrawer({ open: false, _id: "" })}
        dataId={openDrawer?._id ?? ""}
        origin="manage-idps"
      />
    </DataGridContainer>
  );
};
