import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { Table } from "@components/Table";

import { TableGrid } from "./TableGrid";

import { Counter, PageTitle } from "./styled";

import type { Filter } from "@components/PaginationTable/types";

import { RouterOutput } from "@api/client";

type FeedbackRouteTypes = RouterOutput["performance"]["feedback"];

interface FeedbackSentProps {
  sentFeedbacks?: FeedbackRouteTypes["getEmployeeSentFeedbacksByCycleId"];
  loading?: boolean;
}

export const FeedbackSent = ({ sentFeedbacks, loading }: FeedbackSentProps) => {
  return (
    <div>
      {loading ? (
        <Skeleton width={"100%"} height={"488px"} />
      ) : (
        <>
          <PageTitle variant="headline8">
            Feedbacks enviados
            <Counter>{sentFeedbacks?.feedbacks?.length}</Counter>
          </PageTitle>

          <Table
            data={sentFeedbacks?.feedbacks || []}
            filters={(sentFeedbacks?.filter || []) as Filter[]}
            loading={loading || false}
            searchable={true}
            searchableField="to.name"
            searchPlaceholder={"Buscar por feedbacks"}
            tableGridRender={(props) => <TableGrid {...props} />}
          />
        </>
      )}
    </div>
  );
};
