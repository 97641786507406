import { trpc } from "@api/client";
import { useParams } from "react-router-dom";

import { Table } from "./Table";

import { PageContainer } from "./styled";

export const IDP = () => {
  const { _id = "" } = useParams();

  const { data, isLoading, refetch } =
    trpc.performance.idp.getIdpsByEmployee.useQuery(
      {
        employeeId: _id,
      },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!_id,
      }
    );

  return (
    <PageContainer>
      <Table
        loading={isLoading}
        data={data?.employeeIdps || []}
        filters={data?.filter || []}
        refetch={() => refetch()}
      />
    </PageContainer>
  );
};
