import styled, { css } from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  gap: 28px;

  width: 100%;
`;

export const TabButton = styled.button<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;

  width: 100%;

  border-color: ${({ theme }) => theme.colors.neutral[70]};
  border-width: 1px;
  border-style: solid;
  color: ${({ theme }) => theme.colors.neutral[50]};
  svg {
    fill: transparent;
    stroke: ${({ theme }) => theme.colors.neutral[50]};
  }

  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme }) => theme.colors.secondary[95]};
          border-color: ${({ theme }) => theme.colors.secondary[70]};
          border-width: 1px;
          border-style: solid;
          color: ${({ theme }) => theme.colors.secondary[50]};
          svg {
            fill: transparent;
            stroke: ${({ theme }) => theme.colors.secondary[50]};
          }
        `
      : null}
`;
