import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardContainer = styled.div`
  padding: 40px;
  max-width: 871px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 32px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const OptionCard = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  padding: 8px 8px 8px 20px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;
