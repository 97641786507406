import { trpc } from "@api/client";

import { dispatchToast, StyledText } from "@utils";

import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

interface IDeleteCompanyValueModalProps extends CommonModalProps {
  companyValueId: string;
  refetch?: () => void;
}

type DeleteCompanyValueModalProps = Omit<
  IDeleteCompanyValueModalProps,
  "onConfirm"
>;

export const DeleteCompanyValueModal = ({
  open,
  companyValueId,
  onClose,
  refetch,
}: DeleteCompanyValueModalProps) => {
  const { mutate, isLoading } =
    trpc.performance.feedback.deleteCompanyValue.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Valor deletado com sucesso.",
        });

        refetch?.();
        setTimeout(() => onClose?.(new Event("click")), 1000);
      },
      onError: () =>
        dispatchToast({
          type: "error",
          content: "Erro ao deletar o valor, tente novamente em breve.",
        }),
    });

  return (
    <ConfirmationModal
      open={open}
      showWarning
      icon="IconAlertCircle"
      title="Tem certeza que deseja excluir o valor empresarial?"
      subTitle="As informações contidas neste valor serão excluídas."
      subTitleStyle={{ marginBottom: "0" }}
      children={
        <StyledText
          variant="body3"
          children="(Esta ação não afetará feedbacks enviados anteriormente)"
          setColor="neutral50"
          style={{ textAlign: "center", marginBottom: "14px" }}
        />
      }
      confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
      onClose={onClose}
      onConfirm={() => {
        mutate({ companyValueId: companyValueId });
      }}
      isLoading={isLoading}
    />
  );
};
