import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  cursor: pointer;

  max-width: 311px;
  min-height: 282px;
  width: 100%;

  border-radius: 8px;
  padding: 24px;
  user-select: none;

  background-color: ${({ theme }) => theme.colors.secondary[99]};
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.15s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary[95]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary[95]};
  }
`;
