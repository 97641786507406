import { StyledText, cpfMask } from "@utils";

import { AvatarContainer, NameContainer } from "./styled";

const avatarMaker = (name: string) => {
  if (!name) return "";
  const matches = name.split(" ");

  if (matches.length > 1) {
    const firstName = matches.shift();

    const firstLetter = firstName?.slice(0, 1);
    const secondLetter = matches?.pop()?.slice(0, 1);

    return `${firstLetter}${secondLetter}`.toLocaleUpperCase();
  } else {
    return matches?.pop()?.slice(0, 2).toLocaleUpperCase();
  }
};

export const RenderNameField = (name: string, documentNumber = "") => (
  <NameContainer>
    <AvatarContainer>{avatarMaker(name)}</AvatarContainer>
    <div>
      <StyledText variant="body3" children={name} setColor="neutral40" />
      {!!documentNumber && (
        <StyledText
          variant="body3"
          children={`CPF: ${cpfMask(documentNumber)}`}
          setColor="neutral50"
        />
      )}
    </div>
  </NameContainer>
);
