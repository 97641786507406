import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { StyledIcon, StyledText, dispatchToast } from "@utils";

import { AnalysisTab } from "./components/AnalysisTab";
import { AnswersTab } from "./components/AnswersTab";

import {
  LeftContainer,
  MainPage,
  RightContainer,
  SelectButton,
  SelectCard,
  SelectContainer,
  StyledSelect,
} from "./styled";

interface IPerformanceEvaluationsProps {
  evaluated?: { _id: string; name: string; email?: string };
}

export const PerformanceEvaluations = ({
  evaluated,
}: IPerformanceEvaluationsProps) => {
  const { _id = "" } = useParams();

  const location = useLocation();

  const { state }: any = location;

  const [selectedEvaluationId, setSelectedEvaluationId] = useState<string>("");

  const [tab, setTab] = useState<"gradeAnalysis" | "answers">("gradeAnalysis");

  const { data: evaluationsList, isFetching: loadingEvaluationsList } =
    trpc.performance.evaluation.getAnsweredEvaluationsNameByEvaluated.useQuery(
      {
        evaluatedId: _id,
      },
      {
        onError: () =>
          dispatchToast({
            type: "error",
            content:
              "Erro ao carregar a lista de avaliações. Tente novamente em breve.",
          }),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!_id,
      }
    );

  useEffect(() => {
    if (!state?.evaluationId) return;

    if (!evaluationsList) return;

    const evaluationExists = evaluationsList.find(
      (e) => e.value === state?.evaluationId
    );

    if (!evaluationExists) return;

    setSelectedEvaluationId(state?.evaluationId);
  }, [evaluationsList]);

  return (
    <MainPage>
      <SelectCard>
        <LeftContainer>
          <StyledText
            variant="body3"
            children="Selecionar avaliações"
            setColor="neutral50"
            style={{ maxWidth: 80 }}
          />
          {!loadingEvaluationsList ? (
            <StyledSelect
              label={"Selecionar avaliação"}
              value={selectedEvaluationId}
              noOptionsText={"Nenhuma avaliação respondida"}
              onSelectChange={(_, option) =>
                setSelectedEvaluationId(option?.value)
              }
              searchable={true}
              options={evaluationsList || []}
              fullWidth
            />
          ) : (
            <Skeleton
              width={"100%"}
              height={60}
              style={{ maxWidth: "298px" }}
              variant="rounded"
            />
          )}
        </LeftContainer>

        <RightContainer>
          <SelectContainer>
            <SelectButton
              onClick={() => setTab("gradeAnalysis")}
              selected={tab === "gradeAnalysis"}
            >
              <StyledIcon
                name="IconChartBar"
                size={14}
                setColor={tab === "gradeAnalysis" ? "secondary50" : "neutral40"}
              />
              <StyledText
                variant="body3"
                children="Análise de notas"
                setColor={tab === "gradeAnalysis" ? "secondary50" : "neutral40"}
                style={{ width: 124 }}
              />
            </SelectButton>
            <SelectButton
              onClick={() => setTab("answers")}
              selected={tab === "answers"}
            >
              <StyledIcon
                name="IconNotes"
                size={14}
                setColor={tab === "answers" ? "secondary50" : "neutral40"}
              />
              <StyledText
                variant="body3"
                children="Respostas"
                setColor={tab === "answers" ? "secondary50" : "neutral40"}
              />
            </SelectButton>
          </SelectContainer>
        </RightContainer>
      </SelectCard>

      {tab === "gradeAnalysis" && (
        <AnalysisTab evaluatedId={_id} evaluationId={selectedEvaluationId} />
      )}

      {tab === "answers" && (
        <AnswersTab evaluationId={selectedEvaluationId} evaluated={evaluated} />
      )}
    </MainPage>
  );
};
