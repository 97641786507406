import { Avatar, Drawer } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div``;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 600px;
  }
`;

export const Header = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

export const Body = styled.div`
  padding: 32px 40px;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
  width: 57px;
  height: 57px;
  font-size: 32px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NameContainer = styled.div``;

export const Span = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const Tag = styled.div<{ setColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 14px;
  padding: 4px 12px;
  white-space: nowrap;
  color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[10];
      case "yellow":
        return theme.colors.feedback.error[10];
      case "gray":
        return theme.colors.neutral[10];
      case "blue":
        return theme.colors.feedback.info[10];
      default:
        return "";
    }
  }};

  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[90];
      case "yellow":
        return theme.colors.feedback.error[90];
      case "gray":
        return theme.colors.neutral[90];
      case "blue":
        return theme.colors.feedback.info[90];
      default:
        return "";
    }
  }};
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
