import styled from "styled-components";

import { IconButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const StyledSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  height: "185px",
  width: "100%",
}))`
  border-radius: 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRowContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const TitleTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitlePeopleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const TitlePeopleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const EmployeeInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DescriptionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
`;

const DescriptionItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledDescriptionIcon = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      padding: 3px !important;
      border-color: ${({ theme }) => theme.colors.secondary[95]};
      pointer-events: none;
      svg {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const DescriptionDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary[90]};
`;

export {
  StyledSkeleton,
  FlexColumn,
  TitleRowContainer,
  TitleTextsContainer,
  TitlePeopleContainer,
  TitlePeopleItem,
  EmployeeInfoContainer,
  DescriptionRowContainer,
  DescriptionItem,
  StyledDescriptionIcon,
  DescriptionDot,
};
