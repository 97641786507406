import { useNavigate } from "react-router-dom";

import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { TableTag } from "@components/TableTag";

import { routes } from "@routes";

import { HeaderArea, StyledBreadcrumbs, Title, TitleArea } from "./styled";

interface HeaderProps {
  evaluationName?: string;
  evaluationStatus?: string;
}

export const Header = ({
  evaluationName = "",
  evaluationStatus = "",
}: HeaderProps) => {
  const navigate = useNavigate();

  const breadCrumbs = [
    <LinkButton
      variant="neutral"
      style={{ alignSelf: "flex-start" }}
      onClick={() => navigate(routes.PageMyCycles())}
    >
      Meus Ciclos
    </LinkButton>,
    <LinkButton style={{ alignSelf: "flex-start" }} variant="neutral">
      Detalhe da avaliação
    </LinkButton>,
  ];

  const evaluationTags = {
    available: { label: "Ativa", variant: "green" },
    scheduled: { label: "Agendada", variant: "blue" },
    expired: { label: "Encerrada", variant: "gray" },
  };

  return (
    <div>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
      />
      <HeaderArea>
        <TitleArea>
          <Title variant="headline6">
            {evaluationName}
            {evaluationStatus && (
              <TableTag
                label={evaluationTags[evaluationStatus]?.label}
                variant={evaluationTags[evaluationStatus]?.variant}
                hasDot={true}
              />
            )}
          </Title>
        </TitleArea>
      </HeaderArea>
    </div>
  );
};
