import { useCallback } from "react";

import { LinkButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Container,
  ContentWrapper,
  TextWrapper,
  StyledText,
  StyledProgressText,
  StyledRefreshIcon,
  StyledCheckIcon,
} from "./styled";

export interface FollowUpProgressProps {
  questionsLabel?: "Perguntas respondidas" | "Critérios respondidos";
  loading?: boolean;
  evaluationType?: "led" | "lead" | "self" | "byLeader" | "byLed";
  steps: {
    total: number;
    actualStep: number;
  };
  questions: {
    answered: number;
    total: number;
    currentGrade: number;
  };
  progressButton?: {
    disabled?: boolean;
    loading?: boolean;
    hasChanges?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  };
}

export const FollowUpProgress = ({
  questionsLabel = "Perguntas respondidas",
  loading,
  steps,
  questions,
  progressButton,
  evaluationType,
}: FollowUpProgressProps) => {
  const { total, actualStep } = steps;
  const { total: totalQuestions, answered, currentGrade } = questions;

  const renderProgressSave = useCallback(() => {
    const { disabled, loading, hasChanges, onClick } = progressButton || {};

    if (loading)
      return (
        <StyledProgressText variant="caption">
          <StyledRefreshIcon name="IconRefresh" size={16} /> Salvando progresso
        </StyledProgressText>
      );

    if (hasChanges)
      return (
        <LinkButton
          variant="neutral"
          disabled={disabled}
          style={{
            fontSize: "12px",
            lineHeight: "18px",
            alignSelf: "flex-start",
          }}
          onClick={onClick}
        >
          Salvar progresso
        </LinkButton>
      );

    return (
      <StyledProgressText variant="caption">
        <StyledCheckIcon name="IconCheck" size={16} /> Progresso salvo
      </StyledProgressText>
    );
  }, [progressButton]);

  const formatNumber = useCallback((value: number) => {
    if (value <= 0) return 0;
    return value;
  }, []);

  return (
    <Container>
      <ContentWrapper>
        {loading ? (
          <Skeleton width={"250px"} />
        ) : (
          <TextWrapper>
            <StyledText variant="body4">
              Seção:{" "}
              <b>
                {formatNumber(actualStep)}/{formatNumber(total)}
              </b>
            </StyledText>
            <StyledText variant="body4">•</StyledText>
            <StyledText variant="body4">
              {questionsLabel}:{" "}
              <b>
                {formatNumber(answered)}/{formatNumber(totalQuestions)}
              </b>
            </StyledText>
            {["led", "byLeader"].includes(evaluationType || "") && (
              <>
                <StyledText variant="body4">•</StyledText>
                <StyledText variant="body4">
                  Média atual: {currentGrade}
                </StyledText>
              </>
            )}
          </TextWrapper>
        )}

        {progressButton && !loading && renderProgressSave()}
      </ContentWrapper>
    </Container>
  );
};
