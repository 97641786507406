import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { TableGrid } from "./TableGrid";

import { Table } from "@components/Table";

import { Counter, PageTitle } from "./styled";

import type { Filter } from "@components/PaginationTable/types";

import { RouterOutput } from "@api/client";

type FeedbackRouteTypes = RouterOutput["performance"]["feedback"];

interface FeedbackReceivedProps {
  receivedFeedbacks?: FeedbackRouteTypes["getEmployeeReceivedFeedbacksByCycleId"];
  loading?: boolean;
}

export const FeedbackReceived = ({
  receivedFeedbacks,
  loading,
}: FeedbackReceivedProps) => {
  return (
    <div>
      {loading ? (
        <Skeleton width={"100%"} height={"488px"} />
      ) : (
        <>
          <PageTitle variant="headline8">
            Feedbacks recebidos
            <Counter>{receivedFeedbacks?.feedbacks?.length}</Counter>
          </PageTitle>

          <Table
            data={receivedFeedbacks?.feedbacks || []}
            filters={(receivedFeedbacks?.filter || []) as Filter[]}
            loading={loading || false}
            searchable={true}
            searchableField="from.name"
            searchPlaceholder={"Buscar por feedbacks"}
            tableGridRender={(props) => <TableGrid {...props} />}
          />
        </>
      )}
    </div>
  );
};
