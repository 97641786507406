import { useNavigate } from "react-router-dom";

import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { dispatchToast, getAccessTokenSync, track } from "@utils";

import { HeaderArea, StyledText, StyledTitle } from "./styled";

export const Header = () => {
  const navigate = useNavigate();

  const { employeeId = "" } = getAccessTokenSync();

  return (
    <>
      <HeaderArea>
        <div style={{ padding: "40px 0px" }}>
          <StyledTitle variant="headline6" setColor="neutral20">
            PDI
          </StyledTitle>
          <StyledText variant="body3" setColor="neutral50">
            Crie planos de ação e acompanhe seu desempenho profissional.
          </StyledText>
        </div>
        <div>
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              track({
                name: "performance_myidp_createnewplan_clicked",
              });
              if (!employeeId) {
                dispatchToast({
                  type: "error",
                  content:
                    "Ops! , não foi possível encontrar os dados do usuário, favor tentar novamente mais tarde!",
                });

                return;
              }

              navigate(
                routes.PageCreateIDP.replace(":origin", "my-idps").replace(
                  ":step",
                  "1"
                ) + `/to/${employeeId}`
              );
            }}
          >
            Criar novo plano
            <Icons name="IconPlus" />
          </Button>
        </div>
      </HeaderArea>
    </>
  );
};
