import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

interface CloseEvaluationModalProps extends CommonModalProps {
  daysToExpire: number;
}

export const CloseEvaluationModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  daysToExpire = 1,
}: CloseEvaluationModalProps) => {
  if (daysToExpire < 0) return <></>;

  return (
    <ConfirmationModal
      open={open}
      showWarning
      secondaryVariant="error"
      icon="IconAlertCircle"
      title="Tem certeza que deseja encerrar a avaliação previamente?"
      subTitle={
        <>
          {daysToExpire === 0 ? (
            <b>Sua avaliação encerra hoje.</b>
          ) : (
            <>
              Ainda {daysToExpire > 1 ? "faltam" : "falta"}{" "}
              <b>
                {daysToExpire} {daysToExpire > 1 ? "dias" : "dia"}
              </b>{" "}
              para o <b>encerramento</b> estipulado na criação do ciclo.
            </>
          )}
          <br />
          <br />
          <b>Nenhum dos participantes</b> poderá mais <b>responder</b> a
          avaliação após essa ação.
        </>
      }
      confirm={{
        title: "Confirmar encerramento",
      }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};
