import { useMemo } from "react";
import { PageContainer, Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";

import { BigNumbers } from "./components/BigNumbers";
import { Header } from "./components/Header";
import { TabArea } from "./styled";
import { IDP } from "./Tabs/IDP";
import { People } from "./Tabs/People";
import { dispatchToast } from "@utils";

export const PageMyIDPs = () => {
  const { data, isFetching } =
    trpc.performance.idp.getEmployeeIdpInsights.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar os detalhes dos PDIs. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  const { data: employeeHierarchy } =
    trpc.employee.getEmployeeHierarchy.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar as informações do usuário. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  const tabItens = useMemo(() => {
    const items = [
      {
        label: "Plano de ação",
        component: <IDP />,
      },
    ];

    if (employeeHierarchy?.isLeader)
      items.push({ label: "Meus liderados", component: <People /> });

    return items;
  }, [employeeHierarchy]);

  return (
    <PageContainer>
      <Header />
      <BigNumbers data={data} isLoading={isFetching} />
      <TabArea>
        <Tab defaultTab={0} tabItens={tabItens} />
      </TabArea>
    </PageContainer>
  );
};
