import { FormikProps } from "formik";
import { TextField, SelectField } from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";
import { FormCard } from "@components/Cards";

import { RequiredAsterisk, StyledText } from "@utils";

import type { BasicInfoFormProps, DisabledEditType } from "../../../types";

import { FormSkeleton, FormContainer, CharCountContainer } from "./styled";

interface IBasicInfoFormProps {
  formik: FormikProps<BasicInfoFormProps>;
  isLoading?: boolean;
  loadingCycles?: boolean;
  cycleNames: { name: string; _id: string }[];
  disabledEdit?: DisabledEditType;
}

export const BasicInfoForm = ({
  formik,
  isLoading,
  loadingCycles,
  cycleNames,
  disabledEdit,
}: IBasicInfoFormProps) => {
  if (isLoading) return <FormSkeleton />;

  return (
    <FormContainer>
      <FormCard
        title="Informações privadas"
        description="As informações ficarão visíveis apenas para o RH na listagem de avaliações na tabela. Você pode adicionar dados (departamento, cargo) no título para facilitar a organização e identificação das avaliações existentes."
        mandatory
      >
        <div style={{ width: "100%" }}>
          <TextField
            label={
              <StyledText variant="body3" setColor="neutral30" fontWeight={600}>
                Nome da avaliação
                <RequiredAsterisk />
              </StyledText>
            }
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={formik.values.privateName}
            onChange={({ target }) => {
              formik.handleChange({
                target: { id: "privateName", value: target?.value },
              });
            }}
            error={
              formik.touched.privateName && Boolean(formik.errors.privateName)
            }
            helperText={formik.touched.privateName && formik.errors.privateName}
            disabled={disabledEdit?.all}
          />
          <CharCountContainer>
            <StyledText
              variant="caption"
              setColor="neutral50"
              children={`${formik.values.privateName?.length || 0}/50`}
              style={{ fontWeight: 600 }}
            />
          </CharCountContainer>
        </div>

        <div style={{ width: "100%" }}>
          <TextField
            label="Descrição"
            id={"description"}
            name={"description"}
            multiline={true}
            rows={3}
            inputProps={{ maxLength: 1000 }}
            value={formik.values.privateDescription}
            onChange={({ target }) => {
              formik.handleChange({
                target: { id: "privateDescription", value: target?.value },
              });
            }}
            fullWidth
            error={
              formik.touched.privateDescription &&
              Boolean(formik.errors.privateDescription)
            }
            helperText={
              formik.touched.privateDescription &&
              formik.errors.privateDescription
            }
            disabled={disabledEdit?.all}
          />
        </div>

        <SelectField
          label={
            <StyledText variant="body3" setColor="neutral30" fontWeight={600}>
              Ciclo
              <RequiredAsterisk />
            </StyledText>
          }
          disabled={disabledEdit?.partial || disabledEdit?.all || loadingCycles}
          searchable={true}
          value={formik.values.cycleId}
          onSelectChange={(_, option) =>
            formik.handleChange({
              target: { id: "cycleId", value: option?.value },
            })
          }
          options={(cycleNames || []).map((cycle) => {
            return { label: cycle.name, value: cycle._id };
          })}
          error={formik.touched.cycleId && Boolean(formik.errors.cycleId)}
          helperText={formik.touched.cycleId && formik.errors.cycleId}
          fullWidth
        />
      </FormCard>

      <FormCard
        title="Informações públicas"
        description="Escreva as instruções para os participantes que realizarão a avaliação de acordo com suas necessidades e objetivos. As informações ficarão visíveis para os colaboradores ao realizarem a avaliação."
        mandatory
      >
        <div style={{ width: "100%" }}>
          <TextField
            label={
              <StyledText variant="body3" setColor="neutral30" fontWeight={600}>
                Nome da avaliação
                <RequiredAsterisk />
              </StyledText>
            }
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={formik.values.name}
            onChange={({ target }) => {
              formik.handleChange({
                target: { id: "name", value: target?.value },
              });
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={disabledEdit?.all}
          />
          <CharCountContainer>
            <StyledText
              variant="caption"
              setColor="neutral50"
              children={`${formik.values.name?.length || 0}/50`}
              style={{ fontWeight: 600 }}
            />
          </CharCountContainer>
        </div>

        <TextEditor
          styles={{
            container: { width: "100%" },
            editor: { height: "200px" },
          }}
          value={formik.values.description}
          id="description"
          placeholder={`Descrição e instruções da avaliação*`}
          onChange={(value) => {
            formik.handleChange({
              target: { id: "description", value },
            });
          }}
          maxLength={3000}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={
            (formik.touched.description && formik?.errors?.description) ||
            undefined
          }
          readOnly={disabledEdit?.all}
        />
      </FormCard>
    </FormContainer>
  );
};
