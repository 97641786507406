import { useMemo, useState } from "react";

import { MenuItem } from "@mui/material";

import {
  Button,
  DataGrid,
  dayjs,
  Dot,
  Pagination,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { TableTag } from "@components/TableTag";

import { EvaluationOptions } from "./EvaluationOptions";

import { pageSizeOptions, renderHtmlText, StyledText } from "@utils";

import {
  CellArea,
  ItensPerPageArea,
  PaginationArea,
  PaginationContainer,
  StyledSelectField,
  StyledTypography,
} from "./styled";

import type { TableGridCommonProps } from "@components/PaginationTable/types";

export const TableGrid = ({
  data,
  pagination,
  loading,
  refetch,
  onPaginationChanged,
}: TableGridCommonProps) => {
  const [handleId, setHandleId] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Informações privadas",
        disableSortBy: true,
        accessor: "privateName",
        Cell: ({ row: { original } }) => {
          const { privateName, privateDescription = "" } = original;
          return (
            <CellArea>
              <StyledText variant="body3">{privateName}</StyledText>
              <Typography variant="body4">
                {renderHtmlText(privateDescription)}
              </Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Informações públicas",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description } = original;
          return (
            <CellArea>
              <StyledText variant="body3">{name}</StyledText>
              <Typography variant="body4">
                {renderHtmlText(description)}
              </Typography>
            </CellArea>
          );
        },
      },
      {
        Header: "Status",
        accessor: "evaluationStatus",
        disableSortBy: true,

        Cell: ({ value }) => {
          const info = {
            active: { label: "Avaliação ativa", variant: "success" },
            scheduled: { label: "Avaliação agendada", variant: "info" },
            draft: { label: "Avaliação em rascunho", variant: "error" },
            finished: {
              label: "Avaliação encerrada",
              variant: "gray",
            },
            in_calibration: {
              label: "Em calibração",
              variant: "gray",
            },
            calibration_finished: {
              label: "Calibração encerrada",
              variant: "gray",
            },
          };

          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Tag variant={info?.[value]?.variant || "gray"}>
                <Dot variant={info?.[value]?.variant} />
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: 600,
                    width: "max-content",
                  }}
                >
                  {info?.[value]?.label || " - "}
                </Typography>
              </Tag>
            </div>
          );
        },
      },
      // {
      //   Header: "Andamento das respostas",
      //   accessor: "progress",
      //   Cell: ({ row }) => (
      //     <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
      //       <ProgressBar progress={row?.original?.progress} />
      //     </div>
      //   ),
      // },
      {
        Header: "Tipo de avaliação",
        accessor: "type",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { types } = original;
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              {types.map(({ type }, index) => {
                const labels = {
                  byLeader: "Líder",
                  byLed: "Liderado",
                  self: "Auto...",
                };

                return (
                  <TableTag key={index} label={labels[type]} variant="gray" />
                );
              })}
            </div>
          );
        },
      },
      {
        Header: "Início",
        disableSortBy: true,
        accessor: "initialDate",
        Cell: ({ row: { original } }) => {
          const { startDate } = original;
          return (
            <StyledText variant="body3">
              {startDate && dayjs(startDate).isValid()
                ? dayjs(startDate).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        Header: "Encerramento",
        disableSortBy: true,
        accessor: "finalDate",
        Cell: ({ row: { original } }) => {
          const { endDate } = original;
          return (
            <StyledText variant="body3">
              {endDate && dayjs(endDate).isValid()
                ? dayjs(endDate).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        Header: "Criado em",
        disableSortBy: true,
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          const { createdAt } = original;
          return (
            <StyledText variant="body3">
              {createdAt && dayjs(createdAt).isValid()
                ? dayjs(createdAt).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const status = row.original.status;
          const type = row.original.type || [];

          const hasLeadType = type.includes("lead");

          const readyToCalibration = status === "finished" && hasLeadType;
          const inCalibration = status === "in_calibration";

          const hidden = !readyToCalibration && !inCalibration;

          const daysToExpire = dayjs(row?.original?.endDate).diff(
            dayjs(),
            "day"
          );

          return (
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {!hidden && (
                <Button
                  size="small"
                  variant="primary"
                  children="Calibrar"
                  style={{ marginTop: "2.5px" }}
                  onClick={() => {
                    const evaluationId = row?.original?.evaluationId;
                    if (inCalibration) {
                      console.log("inCalibration");
                    }
                    if (readyToCalibration) {
                      setHandleId(evaluationId);
                      console.log("readyToCalibration", handleId);
                    }
                  }}
                />
              )}
              <EvaluationOptions
                status={row?.original?.evaluationStatus}
                evaluationId={row?.original?._id || ""}
                refetch={() => refetch?.()}
                daysToExpire={daysToExpire}
              />
            </div>
          );
        },
      },
    ],
    [data]
  );

  const { page, pageSize, totalCount } = pagination;

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          children: (
            <div>
              Seu líder ainda não lhe enviou feedback sobre esse ciclo de
              desempenho.
            </div>
          ),
        }}
        pageSizeOptions={pageSizeOptions}
        customPagination={({ setPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 10}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                  onPaginationChanged(page, newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <MenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </MenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > totalCount ? totalCount : page * pageSize}
                &nbsp;de&nbsp;{totalCount || 0}&nbsp;
                {totalCount === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                onChange={(_, value) => {
                  onPaginationChanged(value, pageSize);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
    </>
  );
};
