import { CSSProperties } from "react";

import { NavigateOptions } from "react-router-dom";

import { Header, Footer, FooterProps } from "./components";

import {
  Container,
  ContentWrapper,
  PageContainer,
  ProgressBarContainer,
} from "./styled";

import {
  FollowUpProgressProps,
  FollowUpProgress,
} from "@components/FollowUpProgress";

export interface RoutesProps {
  route?: string;
  label: string;
  options?: NavigateOptions;
}

interface StepperProps {
  steps: string[];
  activeStep: number;
}

type PageTemplateProps = {
  children: React.ReactNode;
  routes: RoutesProps[];
  footer: FooterProps;
  stepper?: StepperProps;
  hasHeader?: boolean;
  followUpProgress?: FollowUpProgressProps;
  customHeader?: React.ReactNode;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  backButton?: boolean;
  progressPercentage?: number;
};

export const PageTemplate = ({
  children,
  routes,
  stepper,
  footer,
  hasHeader = true,
  progressPercentage,
  customHeader,
  containerStyle = {},
  contentStyle = {},
  backButton = true,
  followUpProgress,
}: PageTemplateProps) => {
  return (
    <Container style={containerStyle}>
      {hasHeader ? (
        <Header routes={routes} stepper={stepper} backButton={backButton} />
      ) : (
        customHeader || <></>
      )}
      {progressPercentage && (
        <ProgressBarContainer progressValue={progressPercentage} />
      )}
      {followUpProgress && <FollowUpProgress {...followUpProgress} />}
      <ContentWrapper>
        <PageContainer style={contentStyle}>{children}</PageContainer>
      </ContentWrapper>
      <Footer
        cancelProps={footer.cancelProps}
        goBackProps={footer.goBackProps}
        confirmProps={footer.confirmProps}
        skipProps={footer.skipProps}
        draftProps={footer.draftProps}
      />
    </Container>
  );
};
