import { CardContainer } from "./styled";

import { ShapeIcon, IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTitle } from "@utils";

interface IBlankCardProps {
  icon: IconTypes;
  title: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const BlankCard = ({ icon, title, onClick }: IBlankCardProps) => {
  return (
    <CardContainer onClick={onClick}>
      <ShapeIcon name={icon} variant="default" size={48} stroke="default" />
      <StyledTitle
        variant="body2"
        setColor="secondary50"
        style={{ maxWidth: 164, fontWeight: 600 }}
      >
        {title}
      </StyledTitle>
    </CardContainer>
  );
};
