import { useState, useMemo } from "react";
import { cloneDeep } from "lodash";

import { NotificationCard } from "@components/Cards";

import { TableSearch } from "./TableSearch";
import { TableGrid } from "./TableGrid";

import { TableProps } from "./types";

export const Table = ({
  loading,
  data = [],
  selectedValues,
  onValuesSelected,
  evaluationName,
}: TableProps) => {
  const [search, setSearch] = useState<string>("");
  const filteredData = useMemo(() => {
    if (!search) return data;
    let dataToFilter = cloneDeep(data);
    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const byName = item?.name?.toLowerCase().includes(search.toLowerCase());
        return byName;
      });
    }

    return dataToFilter;
  }, [data, search]);

  return (
    <div style={{ paddingLeft: "40px" }}>
      {data.length !== 0 && (
        <TableSearch
          disabled={!Boolean((data || [])?.length)}
          onChange={setSearch}
        />
      )}
      <div style={{ marginBottom: "40px" }}>
        <NotificationCard
          type={"info"}
          variant={"outlined"}
          iconName={"IconAlertCircle"}
          title={`Critérios relacionados a avaliação “${evaluationName}”.`}
        />
      </div>
      <TableGrid
        data={filteredData || []}
        loading={loading}
        onValuesSelected={onValuesSelected}
        selectedValues={selectedValues}
      />
    </div>
  );
};
