import { useMemo } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  Menu,
  PillButton,
  dayjs,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import { OverflowCheck } from "@components/OverflowCheck";

import { Option } from "./styled";
import { StyledText, pageSizeOptions } from "@utils";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  refetch?: () => void;
  onEdit: (e: any) => void;
  onDelete: (e: any) => void;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
  onEdit,
  onDelete,
}: TableGridProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Valor empresarial",
        accessor: "name",
      },
      {
        Header: "Descrição",
        accessor: "description",

        Cell: ({ row }) => (
          <>
            {OverflowCheck({ text: row.original.description, width: "400px" })}
          </>
        ),
      },
      {
        Header: "Criado em",
        accessor: "createdAt",
        Cell: ({ row }) => (
          <>
            {dayjs(row.original.createdAt).isValid()
              ? dayjs(row.original.createdAt).format("DD/MM/YY")
              : "Não preenchido"}
          </>
        ),
      },

      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        Cell: ({ row }) => {
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () => {
                    onEdit({
                      open: true,
                      companyValueId: row.original._id,
                      params: {
                        name: row?.original?.name,
                        description: row?.original?.description,
                      },
                    });
                  },
                  children: (
                    <Option>
                      <Icons name="IconPencil" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Editar valor
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    onDelete({
                      open: true,
                      companyValueId: row.original._id,
                    });
                  },
                  children: (
                    <Option>
                      <Icons name="IconTrash" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Deletar valor
                      </StyledText>
                    </Option>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                size="small"
                variant="default"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={"Criar novo valor"}
        description={"Ainda não existem valores empresariais cadastrados."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhum valor empresarial foi encontrado.",
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};
