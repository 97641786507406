import { useCallback } from "react";
import { FormikProps } from "formik";
import { cloneDeep } from "lodash-es";

import { track } from "@utils";

import {
  StyledBoxAdornment,
  CommentariesContainer,
  StyledCheckBox,
} from "./styled";

import { ConfigurationsFormProps } from "../../../../types";

interface EvaluationSettingsProps {
  formik: FormikProps<ConfigurationsFormProps>;
  disabledEdit: boolean;
}

export const EvaluationSettings = ({
  formik,
  disabledEdit,
}: EvaluationSettingsProps) => {
  const handleCheckboxChange = useCallback(
    (
      types: ConfigurationsFormProps["preferences"]["commentaries"],
      type: ConfigurationsFormProps["preferences"]["commentaries"][0],
      checked: boolean
    ) => {
      let clonedTypes = cloneDeep(types || []);

      if (checked) clonedTypes.push(type);
      else clonedTypes = clonedTypes.filter((types) => types !== type);

      return [...new Set(clonedTypes)];
    },
    []
  );

  return (
    <CommentariesContainer>
      <StyledBoxAdornment
        size={20}
        title={"Incluir campo de comentário opcional em cada critério"}
        description=""
        disabled={disabledEdit}
        leftAdornment={
          <StyledCheckBox
            size="small"
            checked={formik.values.preferences?.commentaries?.includes(
              "criterial"
            )}
            onChange={(_, checked) => {
              if (checked) {
                track({
                  name: "people_strategic_hr_performance_company_evaluations_createevaluation_evaluationform_commentoncriteria_selected",
                });
              }

              const commentariesUpdated = handleCheckboxChange(
                formik.values.preferences?.commentaries || [],
                "criterial",
                checked
              );

              formik.handleChange({
                target: {
                  id: "preferences.commentaries",
                  value: commentariesUpdated,
                },
              });
            }}
            disabled={disabledEdit}
          />
        }
      />

      <StyledBoxAdornment
        size={20}
        title={"Incluir campo de comentário opcional ao final da avaliação"}
        description=""
        disabled={disabledEdit}
        leftAdornment={
          <StyledCheckBox
            size="small"
            checked={formik.values.preferences?.commentaries?.includes(
              "general"
            )}
            onChange={(_, checked) => {
              if (checked) {
                track({
                  name: "people_strategic_hr_performance_company_evaluations_createevaluation_evaluationform_generalcomment_selected",
                });
              }

              const commentariesUpdated = handleCheckboxChange(
                formik.values.preferences?.commentaries || [],
                "general",
                checked
              );

              formik.handleChange({
                target: {
                  id: "preferences.commentaries",
                  value: commentariesUpdated,
                },
              });
            }}
            disabled={disabledEdit}
          />
        }
      />
    </CommentariesContainer>
  );
};
