import { useMemo } from "react";
import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle } from "@utils";

import {
  MainContainer,
  DateContainer,
  Card,
  CardContent,
  StyledAvatar,
  CardsContainer,
} from "./styled";

type Insights = {
  type: "self" | "byLeader" | "byLed";
  grade?: number;
  finishedAt?: Date | string;
  average?: number;
};

interface IBigNumbersProps {
  data?: Insights;
}

export const BigNumbers = ({ data }: IBigNumbersProps) => {
  const renderFinalDate = useMemo(() => {
    const date = data?.finishedAt;
    if (!date || !dayjs(date).isValid()) return <> - </>;
    const formatedDate = dayjs(date).format("DD/MM/YY");
    const formatedHour = dayjs(date).format("HH:mm");

    return (
      <DateContainer>
        <StyledTitle
          variant="body1"
          children={formatedDate}
          setColor="neutral40"
        />
        <StyledText
          variant="body2"
          children={formatedHour}
          setColor="neutral50"
        />
      </DateContainer>
    );
  }, [data]);

  return (
    <MainContainer>
      <CardsContainer>
        <Card>
          <StyledAvatar
            children={<Icons name="IconReportAnalytics" size={24} />}
          />
          <CardContent>
            <StyledText
              variant="body3"
              children="Nota do avaliador selecionado"
              setColor="neutral40"
            />
            <StyledTitle
              setColor="neutral40"
              variant="body1"
              children={data?.grade?.toString() || " - "}
              style={{ fontWeight: 700 }}
            />
          </CardContent>
        </Card>
        <Card>
          <StyledAvatar
            children={<Icons name="IconReportAnalytics" size={24} />}
          />

          <CardContent>
            <StyledText
              variant="body3"
              children="Nota final (nota do líder)"
              setColor="neutral40"
            />
            <StyledTitle
              setColor="neutral40"
              variant="body1"
              children={data?.average?.toString() || " - "}
              style={{ fontWeight: 700 }}
            />
          </CardContent>
        </Card>
        <Card>
          <StyledAvatar children={<Icons name="IconCalendar" size={24} />} />

          <CardContent>
            <StyledText
              variant="body3"
              children="Data de conclusão"
              setColor="neutral40"
            />
            {renderFinalDate}
          </CardContent>
        </Card>
      </CardsContainer>
    </MainContainer>
  );
};
