import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CriterialsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const CriterialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CriterialTextsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

const CriterialTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  height: "300px",
  width: "100%",
}))`
  border-radius: 12px;
`;

export {
  TitleContainer,
  CriterialsContainer,
  CriterialContainer,
  CriterialTextsContainer,
  CriterialTitleContainer,
  StyledSkeleton,
};
