import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { routes } from "@routes";

import { StyledText, StyledTitle } from "@utils";
import { useNavigate } from "react-router-dom";

import {
  BannerArea,
  ContentArea,
  IconArea,
  ItemArea,
  TitleArea,
  CardsArea,
} from "./styled";

const ItemCard = ({
  name,
  icon,
  description,
}: {
  name: string;
  icon: "IconBrush" | "IconStar" | "IconFileDescription";
  description: string;
}) => (
  <ItemArea>
    <IconArea>
      <Icons name={icon} size={24} />
    </IconArea>
    <div>
      <StyledTitle variant="headline9" weight={700} setColor="secondary50">
        {name}
      </StyledTitle>
      <StyledText variant="body4" weight={600} setColor="neutral40">
        {description}
      </StyledText>
    </div>
  </ItemArea>
);

export const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <TitleArea>
      <StyledTitle
        variant="headline8"
        weight={700}
        setColor="secondary50"
        style={{ marginBottom: 8 }}
      >
        Conheça os novos ciclos de desempenho!
      </StyledTitle>
      <StyledText
        variant="body3"
        weight={600}
        setColor="neutral50"
        style={{ marginBottom: "24px" }}
      >
        Com os <b>Ciclos de desempenho</b>, você tem mais flexibilidade para
        gerenciar o desempenho da sua empresa. Eleve a performance do seu time a
        um novo nível!
      </StyledText>
      <Grid container spacing={4}>
        <Grid
          item
          md={12}
          lg={5}
          style={{
            width: "100%",
          }}
        >
          <CardsArea>
            <ItemCard
              name="Personalização do ciclo"
              icon="IconBrush"
              description="Escolha as etapas e centralize o acompanhamento de tarefas através do calendário do ciclo."
            />
            <ItemCard
              name="Critérios avaliativos"
              icon="IconStar"
              description="Crie e gerencie os critérios (competências, valores, perguntas, metas, etc...) que serão utilizados nas avaliações, feedbacks e PDI."
            />
            <ItemCard
              name="Múltiplas avaliações no mesmo ciclo"
              icon="IconFileDescription"
              description="Seu ciclo pode conter uma ou mais avaliações, adaptando-se ao contexto da sua empresa e garantindo um acompanhamento mais centralizado."
            />
          </CardsArea>
        </Grid>
        <Grid
          item
          md={12}
          lg={7}
          style={{ width: "100%" }}
          alignSelf={"center"}
        >
          <BannerArea>
            <ContentArea>
              <StyledTitle
                variant="headline7"
                weight={700}
                setColor="secondary50"
              >
                Personalize os ciclos de
                <br />
                performance da sua empresa
                <br />
                de maneira eficiente!
              </StyledTitle>
              <StyledText
                variant="body3"
                weight={400}
                setColor="secondary50"
                style={{ marginTop: "16px", marginBottom: "24px" }}
              >
                Avalie colaboradores, envie feedbacks
                <br />
                construtivos, crie planos de desenvolvimento
                <br />
                personalizados e transforme o potencial da
                <br />
                sua equipe em resultados concretos.
              </StyledText>
              <Button
                variant="primary"
                size="large"
                onClick={() => navigate(routes.PageCreateCycle())}
              >
                Criar seu primeiro ciclo
                <Icons name="IconArrowRight" />
              </Button>
            </ContentArea>
          </BannerArea>
        </Grid>
      </Grid>
    </TitleArea>
  );
};
