import { useEffect, useState } from "react";

import { Header } from "./components/Header";
import { Evaluateds, Evaluators } from "./components/Tabs";

interface IOverviewProps {
  evaluationId: string;
}

export const Overview = ({ evaluationId }: IOverviewProps) => {
  const [tab, setTab] = useState<"evaluateds" | "evaluators">("evaluateds");
  const [search, setSearch] = useState<string | undefined>("");

  useEffect(() => {
    setSearch(undefined);
  }, [tab]);

  return (
    <>
      <Header onSearch={setSearch} selectedTab={setTab} />

      {tab === "evaluateds" && (
        <Evaluateds evaluationId={evaluationId} search={search} />
      )}

      {tab === "evaluators" && (
        <Evaluators evaluationId={evaluationId} search={search} />
      )}
    </>
  );
};
