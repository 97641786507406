import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

export const EvaluationWithSaveModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: CommonModalProps) => {
  return (
    <ConfirmationModal
      open={open}
      showWarning
      variantType="default"
      primaryColor="primary"
      icon="IconInfoCircle"
      title="Deseja sair e salvar o progresso da avaliação?"
      subTitle="O conteúdo será salvo como rascunho e você poderá editar mais tarde."
      cancel={{ title: "Voltar" }}
      confirm={{
        title: "Sair e salvar rascunho",
        icon: "IconArrowRight",
        iconColor: "neutral100",
      }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};
