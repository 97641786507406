import { StyledText, StyledTitle } from "@utils";
import { CompetenceContainer, CompetencesContainer, Tag } from "./styled";

import { Dot } from "@flash-tecnologia/hros-web-ui-v2";

export const Values = ({ values }) => {
  return (
    <>
      <StyledTitle variant="body2" children="Valores" setColor="neutral30" />
      <CompetencesContainer>
        {values.map((value: any, index: number) => {
          const info = {
            doing_well: { label: "Mandando bem", variant: "green" },
            could_improve: { label: "Pode melhorar", variant: "yellow" },
          };

          return (
            <CompetenceContainer
              key={value._id}
              lastChildren={index + 1 === values.length}
            >
              <div style={{ display: "flex" }}>
                <Tag setColor={info[value?.value]?.variant || "gray"}>
                  <Dot variant={info[value?.value]?.variant || "gray"} />
                  <StyledText variant="caption" style={{ fontWeight: 600 }}>
                    {info[value?.value]?.label || ""}
                  </StyledText>
                </Tag>
              </div>

              <StyledTitle variant="body3" children={value?.name} />
              <StyledText variant="body4" children={value?.description} />
            </CompetenceContainer>
          );
        })}
      </CompetencesContainer>
    </>
  );
};
