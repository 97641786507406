import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";

import { OpenRoutesGuard } from "./openRoutesGuard";
import { RoutesGuard } from "./permissionsRoutesGuard";
import { StagingRoutesGuard } from "./stagingRoutesGuard";

import {
  PageCreateEvaluation,
  PageEvaluationDetails,
  PageManageEvaluations,
  PagePersonEvaluationDetails,
  PagePreviewEvaluation,
  PageViewEvaluation,
  PageCreateCycle,
  PageCycleDetail,
  PageManageCycles,
  PageMyCycles,
  PageManageCriterials,
  PagePersonDetails,
  PageUpdateParticipants,
  PageCreateIDP,
  PageFeedbackSettings,
  PageManageFeedbacks,
  PageManageIDP,
  PageMyFeedbacks,
  PageMyIDPs,
  PageRequestFeedback,
  PageSendFeedback,
} from "../pages";

export const routes = {
  //EVALUATION
  PageManageEvaluations: "/performance/evaluations/v2",
  PageManagerEvaluationDetails: (evaluationId: string) =>
    `/performance/evaluations/v2/evaluation-details/${evaluationId}`,
  PageCreateEvaluation: (step: string, id?: string) => {
    const path = `/performance/evaluations/v2/create/${step}`;
    if (!id) return path;
    return path + `/${id}`;
  },
  PageEditEvaluation: (step: string, _id: string) =>
    `/performance/evaluations/v2/edit/${step}/${_id}`,
  PageViewEvaluation: (
    cycleId: string,
    participantId: string,
    type: string,
    step: number | undefined = undefined
  ) => {
    const path = `/performance/my-cycles/${cycleId}/answer/${participantId}/${type}`;
    if (!step) return path;
    return path + `?step=${step}`;
  },
  PagePreviewEvaluation: (evaluationId: string, step: string) =>
    `/performance/evaluations/v2/preview/${evaluationId}/${step}`,

  //CRITERIA
  PageManageCriterials: "/performance/criterials",

  //CYCLES
  PageManageCycles: "/performance/manage-cycles",
  PageMyCycles: (cycleId?: string) => {
    const path = `/performance/my-cycles`;
    if (!cycleId) return path;
    return path + `/${cycleId}`;
  },
  PageCreateCycle: (id?: string) => {
    const path = `/performance/manage-cycles/create`;
    if (!id) return path;
    return path + `/${id}`;
  },
  PageCycleDetail: (id: string) => `/performance/cycle-detail/${id}`,
  PagePersonEvaluationDetails: (_id: string) =>
    `/performance/my-cycles/evaluation-details/${_id}`,

  //FEEDBACK
  PageRequestFeedback:
    "/performance/my-feedbacks/:filterType/request-feedback/:_id",
  PageManagerRequestFeedback:
    "/performance/manage-feedbacks/:filterType/request-feedback/:_id",
  PageManageFeedbacks: "/performance/manage-feedbacks",
  PageMyFeedbacks: "/performance/my-feedbacks",
  PageSendFeedback: "/performance/my-feedbacks/send-feedback",
  PageManagerSendFeedback: "/performance/manage-feedbacks/send-feedback",
  PageFeedbackSettings: "/performance/manage-feedbacks/feedback-settings",

  //IDP
  PageMyIdps: "/performance/my-idps",
  PageManageIDP: "/performance/manage-idps",
  PageCreateIDP: "/performance/:origin/create-idp/:step",
  PageEditIDP: "/performance/:origin/edit-idp/:step/:idpId",

  //CROSS
  PagePersonDetails: (origin: string, employeeId: string) =>
    `/performance/${origin}/person-details/v2/${employeeId}`,
  PageUpdateParticipants: "/performance/update-participants",

  //CROSS-MODULES
  PageEmployees: "/employees",
} as const;

export const slugRoutes = [
  {
    slug: "performance_review_view",
    routes: [
      routes.PageManageEvaluations,
      routes.PageManagerEvaluationDetails(":evaluationId"),
      routes.PageCreateEvaluation(":step"),
      routes.PageCreateEvaluation(":step", ":_id"),
      routes.PageEditEvaluation(":step", ":_id"),
      routes.PagePreviewEvaluation(":evaluationId", ":step"),
      routes.PageCycleDetail(":id"),
    ],
  },
  {
    slug: "performance_view_feedbacks",
    routes: [
      routes.PageManageFeedbacks,
      routes.PageManagerRequestFeedback,
      routes.PageManagerSendFeedback,
      routes.PageManagerSendFeedback + "/:requestId",
      routes.PageManagerSendFeedback + "/edit/:_id",
      routes.PageManagerSendFeedback + "/to/:receiverId",
      routes.PageFeedbackSettings,
    ],
  },
  {
    slug: "performance_view_idps",
    routes: [routes.PageManageIDP],
  },
  {
    slug: "performance_view_criterials",
    routes: [routes.PageManageCriterials],
  },
  {
    slug: "performance_view_cycles",
    routes: [
      routes.PageManageCycles,
      routes.PageCreateCycle(),
      routes.PageCreateCycle(":_id"),
      routes.PageCycleDetail(":id"),
    ],
  },
];

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routes.PageRequestFeedback,
      hideHeader: true,
    },
    {
      path: routes.PageManagerRequestFeedback,
      hideHeader: true,
    },
    { path: routes.PageSendFeedback, hideHeader: true },
    { path: routes.PageSendFeedback + "/:requestId", hideHeader: true },
    { path: routes.PageSendFeedback + "/to/:receiverId", hideHeader: true },
    { path: routes.PageSendFeedback + "/edit/:_id", hideHeader: true },
    { path: routes.PageManagerSendFeedback, hideHeader: true },
    { path: routes.PageManagerSendFeedback + "/:requestId", hideHeader: true },
    {
      path: routes.PageManagerSendFeedback + "/to/:receiverId",
      hideHeader: true,
    },
    { path: routes.PageManagerSendFeedback + "/edit/:_id", hideHeader: true },
    { path: routes.PageCreateIDP, hideHeader: true },
    { path: routes.PageCreateIDP + "/to/:employeeId", hideHeader: true },
    { path: routes.PageEditIDP, hideHeader: true },
    { path: routes.PageCreateEvaluation(":step"), hideHeader: true },
    { path: routes.PageCreateEvaluation(":step", ":id"), hideHeader: true },
    { path: routes.PageEditEvaluation(":step", ":_id"), hideHeader: true },
    {
      path: routes.PageViewEvaluation(":cycleId", ":participantId", ":type"),
      hideHeader: true,
    },
    {
      path: routes.PagePreviewEvaluation(":evaluationId", ":step"),
      hideHeader: true,
    },
    { path: routes.PageCreateCycle(), hideHeader: true },
    { path: routes.PageCreateCycle(":_id"), hideHeader: true },
    { path: routes.PageUpdateParticipants, hideHeader: true },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<OpenRoutesGuard />}>
          <Route path={routes.PageMyFeedbacks} element={<PageMyFeedbacks />} />

          <Route path={routes.PageSendFeedback}>
            <Route index element={<PageSendFeedback />} />
            <Route path=":requestId" element={<PageSendFeedback />} />
            <Route path="to/:receiverId" element={<PageSendFeedback />} />
            <Route path="edit/:_id" element={<PageSendFeedback />} />
          </Route>

          <Route
            path={routes.PageRequestFeedback}
            element={<PageRequestFeedback />}
          />

          <Route path={routes.PageMyIdps} element={<PageMyIDPs />} />

          <Route path={routes.PageCreateIDP}>
            <Route index element={<PageCreateIDP />} />
            <Route path="to/:employeeId" element={<PageCreateIDP />} />
          </Route>

          <Route path={routes.PageEditIDP} element={<PageCreateIDP />} />

          <Route
            path={routes.PagePersonEvaluationDetails(":_id")}
            element={<PagePersonEvaluationDetails />}
          />

          <Route
            path={routes.PageViewEvaluation(
              ":cycleId",
              ":participantId",
              ":type"
            )}
            element={<PageViewEvaluation />}
          />

          <Route path={routes.PageMyCycles()} element={<PageMyCycles />}>
            <Route index element={<PageMyCycles />} />
            <Route path=":cycleId" element={<PageMyCycles />} />
          </Route>

          <Route
            path={routes.PagePersonDetails(":origin", ":_id")}
            element={<PagePersonDetails />}
          />
        </Route>

        <Route element={<RoutesGuard />}>
          <Route
            path={routes.PageManageFeedbacks}
            element={<PageManageFeedbacks />}
          />

          <Route path={routes.PageManageIDP} element={<PageManageIDP />} />

          <Route path={routes.PageManagerSendFeedback}>
            <Route index element={<PageSendFeedback />} />
            <Route path=":requestId" element={<PageSendFeedback />} />
            <Route path="to/:receiverId" element={<PageSendFeedback />} />
            <Route path="edit/:_id" element={<PageSendFeedback />} />
          </Route>

          <Route
            path={routes.PageManagerRequestFeedback}
            element={<PageRequestFeedback />}
          />

          <Route
            path={routes.PageFeedbackSettings}
            element={<PageFeedbackSettings />}
          />

          <Route
            path={routes.PageManageEvaluations}
            element={<PageManageEvaluations />}
          />

          <Route
            path={routes.PageManageCriterials}
            element={<PageManageCriterials />}
          />

          <Route
            path={routes.PageManageCycles}
            element={<PageManageCycles />}
          />

          <Route
            path={routes.PageManagerEvaluationDetails(":evaluationId")}
            element={<PageEvaluationDetails />}
          />

          <Route path={routes.PageCreateEvaluation(":step")}>
            <Route index element={<PageCreateEvaluation />} />
            <Route path=":_id" element={<PageCreateEvaluation />} />
          </Route>

          <Route
            path={routes.PageEditEvaluation(":step", ":_id")}
            element={<PageCreateEvaluation />}
          />

          <Route
            path={routes.PagePreviewEvaluation(":evaluationId", ":step")}
            element={<PagePreviewEvaluation />}
          />

          <Route path={routes.PageCreateCycle()}>
            <Route index element={<PageCreateCycle />} />
            <Route path=":_id" element={<PageCreateCycle />} />
          </Route>

          <Route
            path={routes.PageCycleDetail(":id")}
            element={<PageCycleDetail />}
          />
        </Route>

        <Route element={<StagingRoutesGuard />}>
          <Route
            path={routes.PageUpdateParticipants}
            element={<PageUpdateParticipants />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
