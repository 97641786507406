import { Drawer } from "@mui/material";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 671px;
  }
`;

const Header = styled.div`
  padding: 40px 24px 24px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: scroll;
  overflow-x: hidden;

  padding: 40px;
  gap: 40px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 40px;

  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const Span = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export {
  MainContainer,
  StyledDrawer,
  Header,
  HeaderTitleContainer,
  Body,
  FlexRow,
  HeaderButtons,
  ButtonContainer,
  Span,
  ContentContainer,
};
