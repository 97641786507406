import styled from "styled-components";

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ModelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 24px;
  margin-bottom: 24px;
`;

export { TextContainer, MainContainer, ModelsContainer };
