import { useFormik } from "formik";

import { trpc } from "@api/client";

import { dispatchToast, track } from "@utils";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { CreateCriteriaForm } from "./components/CreateCriteriaForm";

import { ContentContainer, StyledModal } from "./styled";

import { validationSchema, CreateCriteriaFormProps } from "./types";

import type { CommonModalProps } from "@components/Modals/types";

import type { Criteria } from "server/src/services/criteria/types";

interface ICreateCriterialModalProps extends CommonModalProps {
  onCreated?: (criterial: Criteria) => void;
  showWarning?: boolean;
}

type CreateCriterialModalProps = Omit<ICreateCriterialModalProps, "onConfirm">;

export const CreateCriteriaModal = ({
  open,
  showWarning,
  onClose,
  onCreated,
}: CreateCriterialModalProps) => {
  const utils = trpc.useContext();

  const { mutate, isLoading } =
    trpc.performance.criteria.createCriteria.useMutation({
      onSuccess: (criterial) => {
        dispatchToast({
          type: "success",
          content: "Critério avaliativo criado com sucesso!",
        });

        utils.performance.criteria.getAllCriterials.invalidate();

        onCreated?.(criterial as any);

        setTimeout(() => handleClose("close"), 1000);
      },
      onError: (e: any) => {
        const valueExists = e?.data?.error === "CRITERIAL_EXISTS_ERROR";

        let message =
          "Erro ao criar o critério avaliativo, tente novamente em breve.";

        if (valueExists)
          message = "Erro ao criar critério avaliativo, nome já existe!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const formik = useFormik<CreateCriteriaFormProps>({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      track({
        name: "people_strategic_hr_performance_company_ratingcriteria_createcriteria_save_clicked",
      });
      mutate({
        params: {
          name: values.name,
          description: values.description,
          type: "custom",
        },
      });
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleClose = (origin: string) => {
    formik.resetForm();

    if (origin === "cancel") {
      track({
        name: "people_strategic_hr_performance_company_ratingcriteria_createcriteria_cancel_clicked",
      });
    }

    onClose?.(new Event("click"));
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <Header
          title="Critério de avaliação"
          subTitle="Seus critérios podem contemplar competências, valores, perguntas, metas, etc... Será possível configurar as escalas ao criar uma avaliação e todos os critérios poderão ser utilizados em avaliações, feedbacks e PDI."
          onClose={() => handleClose("close")}
        />
      }
      footer={
        <Footer
          submitTitle="Salvar critério"
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={() => handleClose("cancel")}
        />
      }
    >
      <ContentContainer>
        <CreateCriteriaForm formik={formik} showWarning={showWarning} />
      </ContentContainer>
    </StyledModal>
  );
};
