import { useNavigate } from "react-router-dom";
import { Button, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { People } from "./People";
import { routes } from "@routes";

import { StyledIcon, StyledText, StyledTitle, track } from "@utils";
import { Header, LeftContainer, RightContainer } from "./styled";

export const PageManageIDP = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Header>
        <LeftContainer>
          <StyledTitle
            variant="headline6"
            children="PDI"
            setColor="neutral30"
          />
          <StyledText
            variant="body3"
            children="Crie planos de ação e acompanhe seu desempenho profissional."
            setColor="neutral40"
          />
        </LeftContainer>
        <RightContainer>
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              track({
                name: "performance_idp_createnewplan_clicked",
              });
              navigate(
                routes.PageCreateIDP.replace(":origin", "manage-idps").replace(
                  ":step",
                  "1"
                )
              );
            }}
          >
            Criar novo plano
            <StyledIcon name="IconPlus" setColor="neutral100" />
          </Button>
        </RightContainer>
      </Header>
      <People />
    </PageContainer>
  );
};
