import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, track } from "@utils";

import { HeaderArea } from "./styled";

export const Header = ({ handleOpenModal }) => {
  return (
    <HeaderArea>
      <div style={{ padding: "40px 0px" }}>
        <StyledTitle variant="headline6" setColor="neutral20">
          Critérios avaliativos
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral50">
          Crie e gerencie os critérios (<b>competências</b>,{" "}
          <b>valores, perguntas</b>, <b>metas</b>, etc...) que serão utilizados
          nas avaliações de desempenho, feedbacks e PDI.
        </StyledText>
      </div>
      <div style={{ flexShrink: 0 }}>
        <Button
          variant="primary"
          size="large"
          onClick={() => {
            track({
              name: "people_strategic_hr_performance_company_ratingcriteria_createcriteria_clicked",
            });
            handleOpenModal();
          }}
        >
          Criar critério
          <Icons name="IconPlus" />
        </Button>
      </div>
    </HeaderArea>
  );
};
