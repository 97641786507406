import { TableGrid } from "./TableGrid";
import { TableProps } from "./types";

export const Table = ({
  loading,
  onCriterialsChanged,
  evaluation,
  data = [],
}: TableProps) => {
  return (
    <TableGrid
      data={data || []}
      evaluation={evaluation}
      onCriterialsChanged={onCriterialsChanged}
      pageSize={10}
      loading={loading}
    />
  );
};
