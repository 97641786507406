import { useMemo, useState } from "react";
import { FormikProps } from "formik";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { dispatchToast, StyledText, StyledTitle, track } from "@utils";

import { trpc } from "@api/client";

import { TextEditor } from "@components/TextEditor";
import Banner from "@components/Banner";

import { CompanyValues } from "./CompanyValues";
import { Criterials } from "./Criterials";
import { FormLoading } from "./FormLoading";

import { SendFeedbackFormProps, EDITOR_MAX_LENGTH } from "../types";

import {
  CardContainer,
  MainContainer,
  Row,
  SelectContainer,
  Span,
  StyledSelect,
} from "./styled";

interface SolicitationFormProps {
  loading: boolean;
  formik: FormikProps<SendFeedbackFormProps>;
  isFromRequest: boolean;
  isCompanyValuesMandatory: boolean;
  hasReceiver: boolean;
  isEmployee?: boolean;
}

export const SolicitationForm = ({
  loading,
  formik,
  isFromRequest,
  isCompanyValuesMandatory,
  hasReceiver,
  isEmployee,
}: SolicitationFormProps) => {
  const [disabledFields, setDisabledFields] = useState(false);

  const { data: evaluations = [], isFetching: evaluationsLoading } =
    trpc.performance.evaluation.getAnsweredEvaluationsNameByEvaluated.useQuery(
      { evaluatedId: formik.values.to },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!formik.values.to && formik.values.type === "evaluation",
        onSuccess: (data) => {
          if (!data?.length) {
            dispatchToast({
              type: "error",
              content: `Não há nenhuma avaliação associada a essa pessoa`,
            });
          }

          setDisabledFields(!data?.length);
        },
      }
    );

  const { data: baseData, isFetching: baseDataLoading } =
    trpc.performance.feedback.getSendFeedbackBaseData.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar a lista de pessoas. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  const evaluationSelected = useMemo(() => {
    if (!formik.values.evaluationId) return undefined;

    const evaluation = evaluations.find(
      (e) => e.value === formik.values.evaluationId
    );

    if (!evaluation) return undefined;

    return {
      _id: evaluation.value,
      name: evaluation.label,
    };
  }, [formik.values.evaluationId, evaluations]);

  if (loading || baseDataLoading) return <FormLoading />;

  return (
    <MainContainer onSubmit={formik.handleSubmit}>
      <CardContainer>
        <Row>
          <StyledTitle
            setColor="neutral30"
            variant="body2"
            children={"Destinatário"}
          />
          <StyledText variant="body3" setColor="neutral30">
            <Span>*</Span> campo obrigatório
          </StyledText>
        </Row>
        <StyledText
          setColor="neutral50"
          variant="body4"
          children={"Selecione para quem o feedback será enviado."}
        />

        {isFromRequest && (
          <Banner
            type="info"
            icon="IconInfoCircle"
            title="Responder solicitação de feedback"
            subTitle="Escreva feedbacks a partir das solicitações de seus colegas e ajude a promover uma cultura de trocas construtivas, além de contribuir com o desenvolvimento coletivo de uma forma alinhada com os objetivos da empresa."
            hasHideBanner={false}
            style={{ marginTop: "24px" }}
          />
        )}

        <StyledSelect
          label={
            <StyledText variant="body3" setColor="neutral30">
              Para quem será o feedback<Span>*</Span>
            </StyledText>
          }
          disabled={isFromRequest || hasReceiver}
          fullWidth
          searchable={true}
          value={formik.values.to}
          onSelectChange={(_, option) => {
            formik.handleChange({
              target: { id: "to", value: option?.value },
            });

            if (option?.value != formik.values.to) {
              formik.setFieldValue("evaluationCriterials", []);
              formik.setFieldValue("evaluationId", "");
            }
          }}
          options={baseData?.employees || []}
          error={formik.touched.to && Boolean(formik.errors.to)}
          helperText={formik.touched.to && formik.errors.to}
        />

        {isFromRequest && (
          <TextEditor
            styles={{
              container: { width: "100%", marginTop: "24px" },
            }}
            readOnly={true}
            id="request_message"
            value={formik.values.requestMessage}
            placeholder="Mensagem de solicitação*"
          />
        )}
      </CardContainer>

      <CardContainer>
        <Row>
          <StyledTitle
            setColor="neutral30"
            variant="body2"
            children={"Dados do feedback"}
          />

          <StyledText variant="body3" setColor="neutral30">
            <Span>*</Span> campo obrigatório
          </StyledText>
        </Row>
        <StyledText
          setColor="neutral50"
          variant="body4"
          children={"Configure o tipo de feedback que deseja enviar."}
        />
        <SelectContainer>
          <StyledSelect
            label={
              <StyledText variant="body3" setColor="neutral30">
                Tipo de feedback<Span>*</Span>
              </StyledText>
            }
            value={formik?.values?.type || ""}
            disabled={isFromRequest || isEmployee}
            onSelectChange={(_, option) => {
              track({
                name:
                  option?.value === "evaluation"
                    ? "performance_myfeedbacks_sendfeedback_feedbacklinkedtoevaluation_selected"
                    : "performance_myfeedbacks_sendfeedback_spontaneousfeedback_selected",
              });
              formik.handleChange({
                target: { id: "type", value: option.value },
              });

              if (option?.value != formik.values.type) {
                formik.setFieldValue("evaluationCriterials", []);
                formik.setFieldValue("evaluationId", "");
              }
            }}
            options={[
              {
                label: "Feedback espontâneo",
                value: "voluntary",
              },
              {
                label: "Feedback atrelado à avaliação",
                value: "evaluation",
              },
            ]}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
            fullWidth
          />

          {formik.values.type === "evaluation" &&
            (evaluationsLoading ? (
              <Skeleton
                variant="rounded"
                style={{ marginTop: "24px" }}
                width={"100%"}
                height={"60px"}
              />
            ) : (
              <StyledSelect
                label={
                  <StyledText variant="body3" setColor="neutral30">
                    Selecionar avaliação<Span>*</Span>
                  </StyledText>
                }
                value={formik.values.evaluationId || ""}
                searchable
                onSelectChange={(_, option) =>
                  formik.handleChange({
                    target: { id: "evaluationId", value: option?.value },
                  })
                }
                options={evaluations}
                error={
                  formik.touched.evaluationId &&
                  Boolean(formik.errors.evaluationId)
                }
                helperText={
                  formik.touched.evaluationId && formik.errors.evaluationId
                }
                fullWidth
                disabled={isEmployee || disabledFields}
              />
            ))}
        </SelectContainer>
      </CardContainer>

      {evaluationSelected && (
        <Criterials
          formik={formik}
          evaluation={evaluationSelected}
          isLoading={loading}
        />
      )}

      {!!baseData?.companyValuesExists && (
        <CompanyValues
          formik={formik}
          isMandatory={isCompanyValuesMandatory}
          isLoading={baseDataLoading}
        />
      )}

      <CardContainer>
        <Row>
          <StyledTitle
            setColor="neutral30"
            variant="body2"
            children={"Conteúdo do feedback"}
          />
          <StyledText variant="body3" setColor="neutral30">
            <Span>*</Span> campo obrigatório
          </StyledText>
        </Row>
        <StyledText
          setColor="neutral50"
          variant="body4"
          children={
            "Escreva o feedback de forma construtiva e de acordo com os parâmetros configurados anteriormente."
          }
          style={{ marginBottom: "24px" }}
        />
        <TextEditor
          styles={{ container: { width: "100%" } }}
          value={formik.values.message}
          id="message"
          placeholder="Feedback*"
          onChange={(value) => {
            formik.handleChange({
              target: { id: "message", value },
            });
          }}
          maxLength={EDITOR_MAX_LENGTH}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={
            (formik.touched.message && formik?.errors?.message) || undefined
          }
        />
      </CardContainer>
    </MainContainer>
  );
};
