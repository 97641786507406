import { useState, useMemo } from "react";
import { Grid } from "@mui/material";
import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { EvaluationTab } from "./components/EvaluationTab";

import { StyledText, StyledPLine } from "@utils";

import { MainContainer, TabButton } from "./styled";

import { AnswersProps } from "./types";

export const Answers = ({ sections, scale }: AnswersProps) => {
  const [tab, setTab] = useState<number>(1);

  const selectedSection = useMemo(() => {
    const foundSection = (sections || []).find((s) => s.order == tab);

    if (!foundSection) return <></>;

    return (
      <EvaluationTab
        id={foundSection._id}
        name={foundSection.name}
        description={foundSection?.description || ""}
        questions={foundSection.criterials}
        scales={scale || []}
      />
    );
  }, [tab, sections, scale]);

  if (!sections?.length || !scale?.length)
    return (
      <EmptyState
        description={"Nenhuma avaliador selecionado"}
        buttonProps={{ hidden: true, size: "small" }}
        buttonText=""
      />
    );

  return (
    <MainContainer>
      <Grid container spacing={3}>
        <Grid item sm={12} md={3} lg={2} width={"100%"} marginBottom={"24px"}>
          {(sections || []).map(({ name, _id, order }) => (
            <TabButton
              key={_id}
              active={tab === order}
              onClick={() => setTab(order)}
              style={{ marginBottom: "16px" }}
            >
              <StyledPLine numberOfLines={1}>
                <StyledText
                  variant="body4"
                  style={{ fontWeight: "700", textAlign: "left" }}
                >
                  {name}
                </StyledText>
              </StyledPLine>
            </TabButton>
          ))}
        </Grid>

        <Grid item sm={12} md={9} lg={10} width={"100%"}>
          {selectedSection}
        </Grid>
      </Grid>
    </MainContainer>
  );
};
