import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import { Grid } from "@mui/material";

import * as yup from "yup";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";

import {
  EvaluationWithSaveModal,
  EvaluationWithoutSaveModal,
} from "@components/Modals";

import { ConfigurationsForm } from "./components";

import { routes } from "@routes";

import { trpc } from "@api/client";

import { StyledTitle, StyledText, track, getObjDiffs } from "@utils";

import { Header, LeftContainer } from "../../Steps/styled";

import type {
  BasicStepProps,
  ConfigurationsFormProps,
} from "../../Steps/types";

const validationSchema = yup.object({
  types: yup
    .array()
    .of(yup.string().oneOf(["byLeader", "byLed", "self"]).required())
    .min(1),
  scale: yup
    .array(
      yup.object({
        order: yup.number().required(),
        value: yup.number().required(),
        title: yup.string().required("Este campo deve ser preenchido"),
        subtitle: yup.string().notRequired(),
      })
    )
    .min(3),
  preferences: yup.object({
    commentaries: yup
      .array()
      .of(yup.string().oneOf(["criterial", "general", "section"])),
  }),
});

export const ConfigurationStep = ({
  isEdit,
  disabledEdit,
  isLoading,
  error,
  steps,
  breadcrumbs,
  onSubmit,
  onNavigateByStep,
  evaluation,
  isUpdating,
}: BasicStepProps) => {
  const navigate = useNavigate();
  const disabled = disabledEdit.partial || disabledEdit.all;

  const [exitAndSaveModal, setExitAndSaveModal] = useState(false);
  const [withoutSaveModal, setWithoutSaveModal] = useState(false);

  const utils = trpc.useContext();

  const defaultScale = [
    {
      order: 1,
      title: "Necessita melhora",
      subtitle: "O profissional não atende ao critério requerido.",
      value: 1,
    },
    {
      order: 2,
      title: "Atinge parcialmente",
      subtitle:
        "Atende parcialmente ao critério, oscilando no desempenho e estando em processo de desenvolvimento.",
      value: 2,
    },
    {
      order: 3,
      title: "Atinge o esperado",
      subtitle: "O profissional demonstra pleno atendimento do critério.",
      value: 3,
    },
    {
      order: 4,
      title: "Excede o esperado",
      subtitle:
        "Apresenta o comportamento além do esperado para seu nível de complexidade.",
      value: 4,
    },
    {
      order: 5,
      title: "Excede muito o esperado",
      subtitle:
        "Apresenta comportamento muito além do esperado para seu nível de complexidade.",
      value: 5,
    },
  ];

  const formik = useFormik<ConfigurationsFormProps>({
    initialValues: {
      types: ["byLeader"],
      scale: defaultScale,
      preferences: {
        commentaries: [],
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const hasChanges = formHasChanges(values);

      if (hasChanges) return onSubmit(values, "evaluateds");

      onNavigateByStep("evaluateds");
    },
  });

  const formHasChanges = useCallback(
    (formikValues: ConfigurationsFormProps) => {
      const valuesToTest = {
        types: formikValues.types,
        order: formikValues.scale.map((scale) => scale.order),
        title: formikValues.scale.map((scale) => scale.title),
        subtitle: formikValues.scale.map((scale) => scale.subtitle),
        value: formikValues.scale.map((scale) => scale.value),
        commentaries: formikValues.preferences.commentaries,
      };

      const baseValues = {
        types: (evaluation?.types || []).map((type) => type.type),
        order: (evaluation?.scale || []).map((scale) => scale.order),
        title: (evaluation?.scale || []).map((scale) => scale.title),
        subtitle: (evaluation?.scale || []).map((scale) => scale.subtitle),
        value: (evaluation?.scale || []).map((scale) => scale.value),
        commentaries: evaluation?.preferences?.commentaries || [],
      };

      return getObjDiffs(valuesToTest, baseValues);
    },
    [evaluation]
  );

  useEffect(() => {
    if (!evaluation) return;

    const evaluationScale = evaluation.scale || [];

    const evaluationTypes = (evaluation.types || []).map((type) => type.type);

    const defaultTypes = formik.initialValues.types;

    const hasCalibrationStep = !!evaluation?.cycle?.steps?.find(
      (step) => step.type === "calibration"
    );

    const draftWithoutLeader =
      evaluationTypes.length &&
      hasCalibrationStep &&
      evaluation.status === "draft" &&
      !evaluationTypes.includes("byLeader");

    if (draftWithoutLeader) evaluationTypes.push("byLeader");

    formik.setValues({
      types: evaluationTypes.length ? evaluationTypes : defaultTypes,
      scale: evaluationScale.length ? evaluationScale : formik.values.scale,
      preferences: evaluation.preferences || formik.values.preferences,
    });
  }, [evaluation]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 2,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModal(true),
        },
        ...(evaluation?.evaluationStatus === "draft" && {
          draftProps: {
            disabled: isLoading || isUpdating || error,
            title: "Sair e salvar rascunho",
            callback: () => setExitAndSaveModal(true),
          },
        }),
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => onNavigateByStep("basic-info"),
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          disabled: error || isLoading,
          loading: isUpdating,
          callback: () => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_settings_continue_clicked",
            });
            formik.handleSubmit();
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid item sm={12} md={12} lg={3} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Configurações"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Configure o tipo de avaliação, prazos do ciclo completo e escala."
              }
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={12} lg={9} style={{ width: "100%" }}>
          <ConfigurationsForm
            formik={formik}
            isLoading={isLoading}
            cycle={evaluation?.cycle}
            disabledEdit={disabled}
            isDraft={evaluation?.status === "draft"}
          />
        </Grid>
      </Grid>

      <EvaluationWithoutSaveModal
        open={withoutSaveModal}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() => {
          utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
          navigate(routes.PageManageEvaluations);
        }}
      />

      <EvaluationWithSaveModal
        open={exitAndSaveModal}
        isLoading={isUpdating}
        onConfirm={async () => {
          const errors = await formik.validateForm();

          const values = formik.values;

          Object.keys(errors).map((key) => {
            if (values.hasOwnProperty(key)) delete values[key];
          });

          const hasChanges = formHasChanges(values);

          if (hasChanges) return onSubmit(values, "manageEvaluations");

          onNavigateByStep("manageEvaluations");
        }}
        onClose={() => setExitAndSaveModal(false)}
      />
    </PageTemplate>
  );
};
