import { useMemo } from "react";

import { DataGrid, PillButton } from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";

import { StyledIcon, StyledText, pageSizeOptions } from "@utils";

import { PeopleOptions } from "./PeopleOptions";

import {
  IconContainer,
  ProgressBar,
  ProgressBarContainer,
  StatusRow,
  DataGridContainer,
} from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

export const TableGrid = ({ data, loading }: TableGridCommonProps) => {
  const evaluatedColumn = ({ depth, value }: any) => {
    if (!depth && value?.total !== undefined)
      return (
        <ProgressBarContainer>
          <ProgressBar totalDone={value?.percentage ?? 0}>
            <span />
          </ProgressBar>
          <StyledText
            variant="body3"
            setColor="secondary50"
            children={`${value?.answered}/${value?.total}`}
          />
        </ProgressBarContainer>
      );

    if (depth && value?.name)
      return (
        <ProfileTable
          name={value?.name}
          email={
            <StatusRow>
              <StyledIcon
                name={value?.answered ? "IconCheck" : "IconAlertTriangle"}
                setColor={value?.answered ? "success40" : "error40"}
                size={14}
              />
              <StyledText
                variant="body4"
                setColor="neutral60"
                children={value?.answered ? "Concluído" : "Pendente"}
              />
            </StatusRow>
          }
        />
      );

    return <></>;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Avaliador",
        accessor: "name",
        Cell: ({ row, value }: any) => {
          return !row.depth && <ProfileTable name={value} />;
        },
      },
      {
        Header: "Líder avalia liderado",
        accessor: "byLeader",
        Cell: ({ row }: any) =>
          evaluatedColumn({
            depth: row.depth,
            value: row.original.byLeader,
          }),
      },
      {
        Header: "Liderado avalia líder",
        accessor: "byLed",
        Cell: ({ row }: any) =>
          evaluatedColumn({
            depth: row.depth,
            value: row.original.byLed,
          }),
      },
      {
        Header: "Participante se autoavalia",
        accessor: "self",
        Cell: ({ row }: any) =>
          evaluatedColumn({ depth: row.depth, value: row.original.self }),
      },

      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const byLed = row.original.byLed;

          const byLeader = row.original.byLeader;

          const self = row.original.self;

          const allowExport =
            byLed?.percentage > 0 ||
            byLeader?.percentage > 0 ||
            self?.percentage > 0;

          return row?.depth === 0 ? (
            <IconContainer>
              <PeopleOptions
                _id={row?.original?._id}
                canExportReport={allowExport}
              />
              {row?.canExpand ? (
                <span {...row.getToggleRowExpandedProps()}>
                  {row?.isExpanded ? (
                    <PillButton
                      size="small"
                      variant="default"
                      type="secondary"
                      icon="IconChevronUp"
                    />
                  ) : (
                    <PillButton
                      size="small"
                      variant="default"
                      type="secondary"
                      icon="IconChevronDown"
                    />
                  )}
                </span>
              ) : (
                <PillButton
                  size="small"
                  variant="default"
                  type="secondary"
                  icon="IconChevronDown"
                  disabled={true}
                />
              )}
            </IconContainer>
          ) : null;
        },
      },
    ],
    [data]
  );
  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        expandSubRows={true}
        loading={loading}
        emptyState={{
          isFiltered: true,
          message: "Nenhum avaliador foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </DataGridContainer>
  );
};
