import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { PaginationTable } from "@components/PaginationTable";
import { ConfirmationModal } from "@components/Modals";

import { Header, TableGrid } from "./components";

import { dispatchToast, track } from "@utils";

import { routes } from "@routes";

import { trpc } from "@api/client";

import type { PaginationProps } from "@components/PaginationTable/types";

export const PageManageEvaluations = () => {
  const navigate = useNavigate();

  const [createCycleModalOpen, setCreateCycleModalOpen] = useState(false);

  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    pageSize: 10,
    totalCount: 0,
  });

  const [search, setSearch] = useState<string>("");

  const [filtersInfo, setFiltersInfo] = useState<{
    cycle?: string[];
    status?: (
      | "draft"
      | "scheduled"
      | "active"
      | "disabled"
      | "finished"
      | "in_calibration"
      | "calibration_finished"
    )[];
    types?: ("self" | "byLeader" | "byLed")[];
    startDate?: { startDate: string; endDate?: string };
    endDate?: { startDate: string; endDate?: string };
    createdAt?: { startDate: string; endDate?: string };
  }>();

  const { data, isFetching, refetch } =
    trpc.performance.evaluation.getAllEvaluationsPaginated.useQuery(
      {
        ...pagination,
        search,
        sortBy: "createdAt",
        filters: filtersInfo,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar as avaliações, tente novamente em breve!",
          });
        },
        onSuccess: (result) => {
          setPagination((prev) => {
            return { ...prev, totalCount: result?.total || 0 };
          });
        },
      }
    );

  trpc.performance.cycle.getLastCycle.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false,

    enabled: data?.total === 0,
    onSuccess: (lastCycle) => setCreateCycleModalOpen(!lastCycle),
  });

  const { data: filters, isFetching: loadingFilters } =
    trpc.performance.evaluation.getAllEvaluationsFilters.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Erro ao buscar os filtros das avaliações, tente novamente em breve!",
        });
      },
    });

  useEffect(() => {
    track({ name: "people_strategic_hr_performance_company_view_evaluations" });
  }, []);

  useEffect(() => {
    if (!data) return;

    if (data.total === pagination.totalCount) return;

    setPagination({
      page: data.page,
      pageSize: data.pageSize,
      totalCount: data.total,
    });
  }, [data?.total]);

  return (
    <PageContainer>
      <Header />
      <PaginationTable
        loading={isFetching}
        loadingFilters={loadingFilters}
        data={data?.items || []}
        pagination={pagination}
        filters={filters || []}
        isFiltered={!!search || !!filtersInfo}
        searchPlaceholder="Buscar por avaliações"
        tableGridRender={(props) => (
          <TableGrid
            {...props}
            onCreateClick={() =>
              navigate(routes.PageCreateEvaluation("models"))
            }
          />
        )}
        onSearch={setSearch}
        onPaginationChanged={(page, pageSize) => {
          setPagination((prev) => ({ ...prev, page, pageSize }));
        }}
        onFiltersChanged={({ cycle, status, type, startDate, endDate }) => {
          setFiltersInfo({
            cycle: cycle,
            status: status,
            types: type,
            startDate: startDate?.[0],
            endDate: endDate?.[0],
          });
        }}
        refetch={refetch}
      />

      <ConfirmationModal
        open={createCycleModalOpen}
        title={"Crie um ciclo de desempenho para realizar avaliações"}
        showWarning
        primaryColor={"secondary40"}
        icon="IconAlertCircle"
        variantType="default"
        subTitle={
          "É necessário criar ao menos um ciclo de desempenho antes de iniciar a configuração de avaliações"
        }
        confirm={{
          title: "Criar ciclo",
          icon: "IconArrowRight",
          iconColor: "neutral90",
        }}
        cancel={{ title: "Agora não" }}
        onConfirm={() => navigate(routes.PageCreateCycle())}
        onClose={() => setCreateCycleModalOpen(false)}
      />
    </PageContainer>
  );
};
