import { trpc } from "@api/client";
import { useParams } from "react-router-dom";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { BigNumbers } from "./components/BigNumbers";
import { IDP } from "./components/IDP";

import { TabArea } from "./styled";

export const Idps = () => {
  const { _id = "" } = useParams();

  const { data, isFetching } =
    trpc.performance.idp.getIdpInsightsByEmployee.useQuery(
      {
        employeeId: _id,
      },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        enabled: !!_id,
      }
    );

  return (
    <PageContainer>
      <BigNumbers data={data} isLoading={isFetching} />
      <TabArea>
        <IDP />
      </TabArea>
    </PageContainer>
  );
};
