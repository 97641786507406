import styled, { css } from "styled-components";

import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { Avatar } from "@mui/material";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0px;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
  width: 60px;
  height: 60px;
`;

export const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 40px;
`;

export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTag = styled(Tag)`
  pointer-events: none;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 12px;
`;

export const TabButton = styled.button<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;

  max-width: 200px;
  width: 100%;

  border-color: ${({ theme }) => theme.colors.neutral[70]};
  border-width: 1px;
  border-style: solid;
  color: ${({ theme }) => theme.colors.neutral[50]};
  svg {
    fill: transparent;
    stroke: ${({ theme }) => theme.colors.neutral[50]};
  }

  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme }) => theme.colors.secondary[95]};
          border-color: ${({ theme }) => theme.colors.secondary[70]};
          border-width: 1px;
          border-style: solid;
          color: ${({ theme }) => theme.colors.secondary[50]};
          svg {
            fill: transparent;
            stroke: ${({ theme }) => theme.colors.secondary[50]};
          }
        `
      : null}
`;

export const StyledPLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};

  -webkit-box-orient: vertical;

  word-break: keep-all;
`;
