import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      color: ${({ theme }) => theme.colors.feedback.info[40]} !important;

      &::before {
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.feedback.info[40]} !important;
      }
    }
  }
`;
