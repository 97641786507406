import { useEffect, useMemo, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import * as yup from "yup";

import { PageTemplate } from "@components/PageTemplate";
import { SolicitationForm } from "./SolicitationForm";

import { ConfirmationModal } from "@components/Modals";

import { trpc } from "@api/client";
import { routes } from "@routes";

import {
  StyledIcon,
  StyledText,
  StyledTitle,
  dispatchToast,
  track,
  removeHtmlTags,
} from "@utils";

import { EDITOR_MAX_LENGTH } from "./types";

import { LeftContainer, MainContainer } from "./styled";

const validationSchema = yup.object({
  to: yup.string().required("Este campo deve ser preenchido"),
  from: yup.string().required("Este campo deve ser preenchido"),
  message: yup
    .string()
    .required("Este campo deve ser preenchido")
    .test(
      "len",
      `O texto deve ter até ${EDITOR_MAX_LENGTH} caracteres`,
      (val) => removeHtmlTags(val).length <= EDITOR_MAX_LENGTH
    ),
  companyValues: yup.array().notRequired(),
});

export const PageRequestFeedback = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading: baseDataLoading } =
    trpc.performance.feedback.getSendFeedbackRequestBaseData.useQuery(
      undefined,
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar a lista de pessoas. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const { mutate, isLoading } =
    trpc.performance.feedback.createFeedbackRequest.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: "Solicitação enviada com sucesso.",
          type: "success",
        });
        setTimeout(
          () =>
            navigate(
              isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
            ),
          1000
        );
      },
      onError: () => {
        dispatchToast({
          content: "Erro ao enviar a solicitação. Tente novamente em berve.",
          type: "error",
        });
      },
    });

  const isManager = useMemo(
    () => !!pathname.match("/manage-feedbacks"),
    [pathname]
  );

  const routesBread = [
    {
      label: isManager ? "Gerenciar Feedbacks" : "Meus Feedbacks",
      route: isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks,
    },
    {
      label: "Solicitar feedback",
    },
  ];

  const formik = useFormik<any>({
    initialValues: {
      to: "",
      from: "",
      message: "",
      companyValues: [],
    },
    validationSchema: validationSchema,
    onSubmit: async ({ to, from, message, companyValues }) => {
      let requiredValues = { to, from, message } as {
        to: string;
        from: string;
        message: string;
        companyValues?: any[];
      };

      if (companyValues.length)
        requiredValues = { ...requiredValues, companyValues };

      mutate({
        params: requiredValues,
      });
    },
  });

  useEffect(() => {
    formik.handleChange({
      target: { id: params?.filterType, value: params._id },
    });
  }, [params]);

  const redirectToHome = useMemo(
    () => params?.filterType !== "to" && params?.filterType !== "from",
    [params]
  );

  return redirectToHome ? (
    <Navigate to={"/home"} />
  ) : (
    <PageTemplate
      routes={routesBread}
      footer={{
        cancelProps: {
          disabled: false,
          title: "Sair",
          callback: () => {
            track({
              name: "performance_myfeedbacks_requestfeedback_exit_clicked",
            });
            setIsOpen(true);
          },
        },
        confirmProps: {
          title: (
            <>
              Solicitar feedback
              <StyledIcon name="IconCheck" fill="transparent" />
            </>
          ),
          width: "250px",
          loading: isLoading,
          callback: () => {
            track({
              name: "performance_myfeedbacks_requestfeedback_requestfeedback_clicked",
            });
            formik.handleSubmit();
          },
        },
      }}
    >
      <MainContainer>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={"Solicitar Feedback"}
        />
      </MainContainer>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid item sm={12} md={5} lg={4} paddingRight={"24px"}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Dados da solicitação"}
              style={{ paddingBottom: "16px" }}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "É possível solicitar que um colaborador envie um feedback e determinar quais aspectos serão avaliados"
              }
              style={{ paddingBottom: "16px" }}
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={7} lg={8}>
          <SolicitationForm
            formik={formik}
            options={data || {}}
            isLoading={baseDataLoading}
            params={params}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        open={isOpen}
        showWarning
        secondaryVariant="default"
        icon="IconAlertCircle"
        title="Deseja sair sem enviar a solicitação de feedback?"
        subTitle="Você perderá todas as configurações da solicitação."
        cancel={{ title: "Voltar" }}
        confirm={{
          title: "Sair sem salvar",
          icon: "IconArrowRight",
          iconColor: "neutral100",
        }}
        onClose={() => setIsOpen(false)}
        onConfirm={() => navigate(routes.PageMyFeedbacks)}
      />
    </PageTemplate>
  );
};
