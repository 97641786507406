import { useState, useMemo } from "react";
import { cloneDeep } from "lodash-es";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { Table } from "@components/Table";

import { BigNumbers } from "./components/BigNumbers";
import { TableGrid } from "./components/TableGrid";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

export interface IEvaluatedsProps {
  evaluationId: string;
  search?: string;
}

export const Evaluateds = ({ evaluationId, search }: IEvaluatedsProps) => {
  const [answeredFilter, setAnsweredFilter] = useState<boolean | undefined>();

  const { data, isFetching } =
    trpc.performance.evaluation.getDetailsOverviewEvaluateds.useQuery(
      { evaluationId: evaluationId },
      {
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Não foi possível buscar a lista de avaliações do ciclo. Tente novamente em breve.",
          });
        },
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!evaluationId,
      }
    );

  const filteredData = useMemo(() => {
    const notFiltered =
      !data?.length && !search && answeredFilter === undefined;

    if (notFiltered) return data;

    let dataToFilter = cloneDeep(data || []);

    if (search) {
      const lowerCaseSearch = search.toLowerCase();

      dataToFilter = dataToFilter.filter((item) =>
        item.name.toLowerCase().includes(lowerCaseSearch)
      );
    }

    if (answeredFilter !== undefined) {
      dataToFilter = dataToFilter.filter(
        (item) => item.isAlreadyEvaluated === answeredFilter
      );
    }

    return dataToFilter;
  }, [data, search, answeredFilter]);

  if (isFetching) return <Skeleton variant="rounded" height={"400px"} />;

  return (
    <>
      <BigNumbers
        evaluationId={evaluationId}
        onFilterStatus={setAnsweredFilter}
      />

      <Table
        loading={isFetching}
        data={filteredData || []}
        tableGridRender={(props) => <TableGrid {...props} />}
      />
    </>
  );
};
