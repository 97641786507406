import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";

import { Skeleton, EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { Overview } from "./components/Overview";
import { Details } from "./components/Details";
import { Results } from "./components/Results";

import type { Evaluation } from "server/src/services/evaluation/types";

import { StyledTab } from "./styled";

export interface TabsHandleProps {
  setActiveTab: (tab: number) => void;
}
interface ITabsProps {
  selectedEvaluation?: Evaluation;
  isLoading?: boolean;
}

const TabsSkeleton = () => {
  return (
    <>
      <Skeleton width={300} height={50} style={{ marginBottom: "18px" }} />
      <Skeleton width={"100%"} height={450} />
    </>
  );
};

export const Tabs = forwardRef<TabsHandleProps, ITabsProps>(
  ({ selectedEvaluation, isLoading }: ITabsProps, ref) => {
    const { state } = useLocation();

    const { activeTab: stateActiveTab } = (state as any) || {};

    const [activeTab, setActiveTab] = useState(0);

    useImperativeHandle(
      ref,
      () => ({
        setActiveTab: (tab) => tab !== activeTab && setActiveTab(tab),
      }),
      [activeTab]
    );

    useEffect(() => {
      if (stateActiveTab === undefined) return;

      setActiveTab(stateActiveTab);
    }, [stateActiveTab]);

    if (isLoading) return <TabsSkeleton />;

    if (!selectedEvaluation)
      return (
        <EmptyState
          buttonProps={{ size: "small", hidden: true }}
          buttonText=""
          description="Nenhuma avaliação encontrada."
        />
      );

    return (
      <StyledTab
        onTabChanged={setActiveTab}
        selected={activeTab}
        tabItens={[
          {
            label: "Visão Geral",
            component: <Overview evaluationId={selectedEvaluation._id} />,
          },
          {
            label: "Resultados",
            component: <Results evaluation={selectedEvaluation} />,
          },
          {
            label: "Detalhes",
            component: <Details selectedEvaluation={selectedEvaluation} />,
          },
        ]}
      />
    );
  }
);
