import { FormikProps } from "formik";
import { useTheme } from "styled-components";
import { TextField } from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";
import Banner from "@components/Banner";

import { StyledText, StyledTitle } from "@utils";

import { Row, Span, FormContainer } from "../styled";

import type { CreateCriteriaFormProps } from "../types";

interface ICreateCriteriaFormProps {
  formik: FormikProps<CreateCriteriaFormProps>;
  showWarning?: boolean;
}

export const CreateCriteriaForm = ({
  formik,
  showWarning,
}: ICreateCriteriaFormProps) => {
  const theme = useTheme();

  return (
    <FormContainer>
      <Row>
        <StyledTitle
          setColor="neutral30"
          variant="headline9"
          children={"Nome e descrição"}
          style={{ fontWeight: 700 }}
        />
        <StyledText variant="body3" setColor="neutral30">
          <Span>*</Span> campo obrigatório
        </StyledText>
      </Row>

      {showWarning && (
        <Banner
          type="info"
          icon="IconInfoCircle"
          title="Ao criar um novo critério dentro do formulário, ele será salvo no banco de critérios e poderá ser utilizado novamente."
          hasHideBanner={true}
          style={{ marginBottom: "24px" }}
        />
      )}

      <TextField
        label={
          <StyledText variant="body3" setColor="neutral30">
            Nome do critério
            <span style={{ color: theme.colors.feedback.error[40] }}>*</span>
          </StyledText>
        }
        value={formik.values.name}
        onChange={(e) =>
          formik.handleChange({
            target: { id: "name", value: e.target.value },
          })
        }
        fullWidth
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextEditor
        styles={{
          container: { width: "100%", marginTop: "24px" },
          editor: { height: "200px" },
        }}
        placeholder="Descrição*"
        value={formik.values.description}
        onChange={(value) => {
          formik.handleChange({
            target: { id: "description", value },
          });
        }}
        error={
          formik?.touched?.description && Boolean(formik?.errors?.description)
        }
        helperText={formik?.errors?.description}
        maxLength={1000}
      />
    </FormContainer>
  );
};
