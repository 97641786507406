import { FormikProps } from "formik";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { Table } from "./Table";

import type { SendFeedbackFormProps } from "@pages/Feedback/PageSendFeedback/types";

import { CardContainer, ErrorContainer } from "./styled";

interface CompanyValuesProps {
  formik: FormikProps<SendFeedbackFormProps>;
  isMandatory: boolean;
  isLoading: boolean;
}

export const CompanyValues = ({
  formik,
  isMandatory,
  isLoading,
}: CompanyValuesProps) => {
  return (
    <CardContainer
      error={
        !!formik.touched.companyValues && Boolean(formik.errors.companyValues)
      }
    >
      <Tag
        variant={isMandatory ? "primary" : "gray"}
        children={isMandatory ? "Obrigatório" : "Não obrigatório"}
        style={{ marginBottom: "16px" }}
      />
      <StyledTitle
        setColor="neutral30"
        variant="body2"
        children={"Valores da empresa"}
        style={{ marginBottom: "4px" }}
      />
      <StyledText
        setColor="neutral50"
        variant="body4"
        children={
          "Insira valores empresariais que orientarão a realização dos feedbacks."
        }
        style={{ marginBottom: "24px" }}
      />

      <Table
        data={formik.values.companyValues || []}
        editable={!isMandatory}
        onValuesChanged={(values) =>
          formik.handleChange({
            target: {
              id: "companyValues",
              value: values,
            },
          })
        }
        loading={isLoading}
      />
      {formik.touched.companyValues &&
        Array.isArray(formik?.errors?.companyValues) &&
        formik?.errors?.companyValues.length && (
          <ErrorContainer>
            <StyledIcon
              name="IconInfoCircle"
              setColor="error50"
              fill="none"
              size={18}
            />
            <StyledText variant="caption" setColor="neutral50">
              Todos os valores selecionados precisam ser avaliados.
            </StyledText>
          </ErrorContainer>
        )}
    </CardContainer>
  );
};
