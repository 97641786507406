import { useEffect, useMemo, useState } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  Checkbox,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";
import { StyledIcon, StyledText, pageSizeOptions } from "@utils";
import type { TableGridCommonProps } from "@components/Table/types";

import { AnswerStatus } from "./styled";

interface TableGridProps extends TableGridCommonProps {
  onIdsSelected: (ids: string[]) => void;
}

export const TableGrid = ({
  data,
  rawData,
  loading,
  onIdsSelected,
}: TableGridProps) => {
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    const allChecked = data.every((item) =>
      selectedItems.includes(item?.led?._id)
    );
    setAllChecked(allChecked);
  }, [data, selectedItems]);

  useEffect(() => onIdsSelected(selectedItems), [selectedItems]);

  const columns = useMemo(
    () => [
      {
        accessor: "checkBoxColumn",
        disableSortBy: true,
        sticky: "unset",

        Header: () => {
          return (
            <Checkbox
              checked={allChecked}
              onChange={(_, value) => {
                const idsToChange = data?.map((item) => item.led._id);
                if (value) {
                  setSelectedItems((items) => [
                    ...items,
                    ...idsToChange.filter(
                      (item) => items.includes(item) === false
                    ),
                  ]);
                } else {
                  setSelectedItems((items) =>
                    items.filter((item) => idsToChange.includes(item) === false)
                  );
                }
              }}
            />
          );
        },
        Cell: ({ row }) => {
          const _id = row?.original?.led?._id;
          const checked = selectedItems.some((id) => id === _id);

          return (
            <Checkbox
              checked={checked}
              onChange={() => {
                setSelectedItems((item) => {
                  if (checked) {
                    return item.filter((id) => id !== _id);
                  }
                  return [...item, _id];
                });
              }}
            />
          );
        },
      },
      {
        Header: "Liderado",
        accessor: "led",
        sticky: "unset",

        Cell: ({ value }: any) => {
          return (
            <ProfileTable
              name={value?.name || " - "}
              email={value?.email || " - "}
            />
          );
        },
      },
      {
        Header: "Líder anterior",
        accessor: "lastLeader",
        sticky: "unset",

        Cell: ({ value }: any) => {
          return (
            <ProfileTable
              name={value?.name || " - "}
              email={value?.email || " - "}
            />
          );
        },
      },
      {
        Header: "Líder atual",
        accessor: "currentLeader",
        sticky: "unset",
        Cell: ({ value }: any) => {
          return (
            <ProfileTable
              name={value?.name || " - "}
              email={value?.email || " - "}
            />
          );
        },
      },
      {
        Header: "Avaliação",
        accessor: "evaluation",
        sticky: "unset",

        Cell: ({ value }: any) => {
          return (
            <>
              <StyledText
                variant="body3"
                setColor="neutral40"
                children={value.name || " - "}
              />
              {value.status !== "answered" && (
                <AnswerStatus>
                  <StyledIcon name="IconCheck" setColor="success40" />
                  <StyledText
                    variant="body4"
                    setColor="neutral40"
                    children={"Respondida pelo líder anterior"}
                  />
                </AnswerStatus>
              )}
            </>
          );
        },
      },
    ],
    [allChecked, data, selectedItems]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum participante precisa ser atualizado no momento."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(10) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhum participante foi encontrado!",
      }}
      pageSizeOptions={pageSizeOptions}
    />
  );
};
