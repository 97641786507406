import { useMemo } from "react";
import { FormikProps } from "formik";

import { EvaluationTypes } from "./components/EvaluationsTypes";
import { EvaluationsScale } from "./components/EvaluationsScale";
import { EvaluationsChronogram } from "./components/EvaluationsChronogram";
import { EvaluationSettings } from "./components/EvaluationsSettings";

import { FormSkeleton, FormContainer, StyledAccordion } from "./styled";

import type { ConfigurationsFormProps } from "../../../types";
import type { Cycle } from "server/src/services/cycle/types";

interface IConfigurationFormProps {
  formik: FormikProps<ConfigurationsFormProps>;
  cycle?: Cycle;
  isLoading?: boolean;
  disabledEdit: boolean;
  isDraft?: boolean;
}

export const ConfigurationsForm = ({
  formik,
  cycle,
  isLoading,
  disabledEdit,
  isDraft,
}: IConfigurationFormProps) => {
  const evaluationTypesConfig = useMemo(() => {
    return {
      error: formik.touched.types && Boolean(formik.errors.types),
      isFilled: !!(formik.values.types || []).length,
    };
  }, [formik.touched.types, formik.errors.types, formik.values.types]);

  const scalesConfig = useMemo(() => {
    const scale = formik.values.scale || [];

    const notFilled = scale.some((s) => !s.title);

    return {
      error: formik.touched.scale && Boolean(formik.errors.scale),
      isFilled: !notFilled && scale.length >= 3,
    };
  }, [formik.touched.scale, formik.errors.scale, formik.values.scale]);

  const hasCalibrationStep = !!cycle?.steps?.find(
    (step) => step.type === "calibration"
  );

  if (isLoading) return <FormSkeleton />;

  return (
    <FormContainer>
      <StyledAccordion
        error={evaluationTypesConfig.error}
        defaultExpanded={true}
        variant="default"
        tagName={evaluationTypesConfig.isFilled ? "Completo" : "Obrigatório"}
        tagVariant={evaluationTypesConfig.isFilled ? "success" : "error"}
        title="Tipo de avaliação"
        description="Selecione quais modalidades de avaliação serão respondidas. O conteúdo da avaliação será o mesmo para todos os tipos selecionados."
        children={
          <EvaluationTypes
            formik={formik}
            disabledEdit={disabledEdit}
            hasCalibrationStep={hasCalibrationStep}
            isDraft={isDraft}
          />
        }
      />

      <StyledAccordion
        defaultExpanded={true}
        variant="default"
        tagName="Completo"
        tagVariant="success"
        title="Cronograma"
        description="Confira as datas de início e encerramento do ciclo configuradas previamente. Para editá-las, basta acessar os detalhes do ciclo."
        children={<EvaluationsChronogram cycle={cycle} />}
      />

      <StyledAccordion
        error={scalesConfig.error}
        defaultExpanded={true}
        variant="default"
        tagName={scalesConfig.isFilled ? "Completo" : "Obrigatório"}
        tagVariant={scalesConfig.isFilled ? "success" : "error"}
        title="Escala"
        description="As configurações da escala serão aplicadas para todas as questões da avaliação. A escala pode conter entre 3 e 5 itens."
        children={
          <EvaluationsScale formik={formik} disabledEdit={disabledEdit} />
        }
      />

      <StyledAccordion
        defaultExpanded={true}
        variant="default"
        tagName={"Opcional"}
        tagVariant={"pink"}
        title="Preferências da avaliação"
        description="Determine se campos de comentários opcionais serão incluídos na avaliação."
        children={
          <EvaluationSettings formik={formik} disabledEdit={disabledEdit} />
        }
      />
    </FormContainer>
  );
};
