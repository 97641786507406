import { ReactNode } from "react";
import { useTheme } from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

interface DataItemProps {
  title?: string;
  description?: string | ReactNode;
  content?: ReactNode;
}

export const DataItem = ({ title, description, content }: DataItemProps) => {
  const theme = useTheme();

  const renderDescription = (description: string | ReactNode) => {
    const isString = typeof description === "string";

    if (isString)
      return (
        <Typography
          variant="body3"
          style={{
            fontSize: 16,
            color: theme.colors.neutral[40],
            fontWeight: 600,
          }}
          children={description}
        />
      );
    return description;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      {title && (
        <Typography
          variant="caption"
          variantColor={theme.colors.neutral[50]}
          style={{ fontWeight: 700 }}
        >
          {title}
        </Typography>
      )}
      {description && renderDescription(description)}
      {content}
    </div>
  );
};
