import styled from "styled-components";

export const CompetencesContainer = styled.div``;

export const CompetenceContainer = styled.div<{ lastChildren?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 28px 8px;
  border-bottom: ${({ theme, lastChildren }) =>
    !lastChildren && `1px solid ${theme.colors.neutral[90]}`};
`;

export const Tag = styled.div<{ setColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 14px;
  padding: 4px 12px;
  white-space: nowrap;
  color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[10];
      case "yellow":
        return theme.colors.feedback.error[10];
      case "gray":
        return theme.colors.neutral[10];

      default:
        return "";
    }
  }};

  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[90];
      case "yellow":
        return theme.colors.feedback.error[90];
      case "gray":
        return theme.colors.neutral[90];

      default:
        return "";
    }
  }};
`;
