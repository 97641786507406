import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { FormikProps } from "formik";

import { StyledText, StyledTitle } from "@utils";

import { Row, Span, FormContainer } from "../styled";

import type { CompanyValueFormProps } from "../types";

interface ICompanyValueFormProps {
  formik: FormikProps<CompanyValueFormProps>;
}

export const CompanyValueForm = ({ formik }: ICompanyValueFormProps) => (
  <FormContainer>
    <Row>
      <StyledTitle
        setColor="neutral30"
        variant="headline9"
        children={"Nome e descrição"}
        style={{ fontWeight: 700 }}
      />
      <StyledText variant="body3" setColor="neutral30">
        <Span>*</Span> campo obrigatório
      </StyledText>
    </Row>
    <TextField
      label={
        <div>
          Nome do valor<Span>*</Span>
        </div>
      }
      value={formik.values.name}
      onChange={(e) =>
        formik.handleChange({
          target: { id: "name", value: e.target.value },
        })
      }
      fullWidth
      error={formik.touched.name && Boolean(formik.errors.name)}
      helperText={formik.touched.name && formik.errors.name}
    />
    <TextField
      label={
        <div>
          Descrição<Span>*</Span>
        </div>
      }
      multiline={true}
      rows={4}
      fullWidth
      style={{ marginTop: "24px" }}
      value={formik.values.description}
      onChange={(e) =>
        formik.handleChange({
          target: { id: "description", value: e.target.value },
        })
      }
      error={formik.touched.description && Boolean(formik.errors.description)}
      helperText={formik.touched.description && formik.errors.description}
    />
  </FormContainer>
);
