import { Carousel, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { Card, CircleContainer, SkeletonContainer } from "./styled";

export const BigNumbers = ({ data, isLoading }) => {
  return isLoading ? (
    <SkeletonContainer>
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
      <Skeleton variant="rounded" width={266} height={166} />
    </SkeletonContainer>
  ) : (
    <div style={{ marginBottom: "10px" }}>
      <Carousel
        slides={[
          <Card key={data?.total}>
            <CircleContainer>
              <StyledIcon
                name="IconMailbox"
                fill="none"
                setColor="secondary50"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.total?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Todos os feedbacks"}
            />
          </Card>,
          <Card key={data?.feedbacksReceived}>
            <CircleContainer>
              <StyledIcon
                name="IconMailOpened"
                fill="none"
                setColor="secondary50"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.feedbacksReceived?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Recebidos"}
            />
          </Card>,
          <Card key={data?.feedbacksSent}>
            <CircleContainer>
              <StyledIcon
                name="IconMailForward"
                fill="none"
                setColor="secondary50"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.feedbacksSent?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Enviados"}
            />
          </Card>,
          <Card key={data?.requestsReceived}>
            <CircleContainer>
              <StyledIcon
                name="IconSignLeft"
                fill="none"
                setColor="secondary50"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.requestsReceived?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Solicitações recebidas"}
            />
          </Card>,
          <Card key={data?.requestsSent || ""}>
            <CircleContainer>
              <StyledIcon
                name="IconSignRight"
                fill="none"
                setColor="secondary50"
                size={32}
              />
            </CircleContainer>
            <StyledTitle
              variant="headline5"
              setColor="neutral40"
              children={data?.requestsSent?.toString() || "-"}
            />
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Solicitações enviadas"}
            />
          </Card>,
        ]}
        gap={24}
        arrows="bottom"
        alignSlides="center"
      />
    </div>
  );
};
