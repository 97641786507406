import {
  ShapeIconOptions,
  LinkButtonProps,
  ButtonProps,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { ModalProps as ModalPropsMui } from "@mui/material";

import {
  StyledModal,
  Container,
  IconContainer,
  ContentArea,
  Footer as StyledFooter,
  StyledButton,
} from "./styled";

export type ModalProps = ModalPropsMui & {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
};

const Modal = ({
  open,
  onClose,
  className,
  children,
  header,
  footer,
  icon,
  style,
  ...rest
}: ModalProps) => {
  return (
    <StyledModal
      className={className}
      open={open}
      onClose={onClose}
      style={style}
      {...rest}
    >
      <Container className="modal-container">
        {icon && (
          <IconContainer className="modal-icon-container">{icon}</IconContainer>
        )}
        {header && <>{header}</>}
        <ContentArea className="modal-content-area">{children}</ContentArea>
        {footer && <>{footer}</>}
      </Container>
    </StyledModal>
  );
};

const Footer = ({ children }: { children: React.ReactNode }) => {
  return <StyledFooter>{children}</StyledFooter>;
};

const DefaultFooter = ({
  variantType,
  isLoading,
  cancelButton,
  confirmButton,
  secondaryVariant,
}: {
  variantType: ShapeIconOptions;
  isLoading?: boolean;
  secondaryVariant?: ShapeIconOptions;
  cancelButton: {
    title: string;
    variant?: LinkButtonProps["variant"];
    onClick: React.EventHandler<any> | undefined;
    hide?: boolean;
  };
  confirmButton: {
    variant?: ButtonProps["variant"];
    content: string | React.ReactNode;
    onClick: React.EventHandler<any> | undefined;
    disabled?: boolean;
  };
}) => {
  return (
    <StyledFooter disableCancel={!cancelButton?.hide}>
      {!cancelButton?.hide && (
        <LinkButton
          variant={cancelButton?.variant || "neutral"}
          style={{ alignSelf: "center" }}
          children={cancelButton?.title}
          onClick={cancelButton?.onClick}
          disabled={isLoading}
        />
      )}
      <StyledButton
        size="large"
        variant={confirmButton?.variant || "primary"}
        variantType={secondaryVariant || (variantType as any)}
        onClick={confirmButton.onClick}
        loading={isLoading}
        disabled={confirmButton.disabled}
      >
        {confirmButton.content}
      </StyledButton>
    </StyledFooter>
  );
};

export { Modal, Footer, DefaultFooter };
