import { Button, PillButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle, track } from "@utils";

import { trpc } from "@api/client";

import {
  Body,
  FlexRow,
  Header,
  HeaderTitleContainer,
  MainContainer,
  StyledDrawer,
  HeaderButtons,
  ButtonContainer,
  ContentContainer,
  Span,
} from "./styled";

interface IModelDetailsDrawerProps {
  isOpen: boolean;
  modelId: string;
  onClose: () => void;
  onModelSelected: (modelId: string) => void;
}

export const ModelDetailsDrawer = ({
  isOpen,
  modelId = "",
  onClose,
  onModelSelected,
}: IModelDetailsDrawerProps) => {
  const { data, isLoading } =
    trpc.performance.evaluation.getEvaluationModelById.useQuery(
      {
        _id: modelId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!modelId,
      }
    );

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          {isLoading ? (
            <>
              <Skeleton
                width={80}
                style={{ marginBottom: 6 }}
                variant="rounded"
              />
              <Skeleton width={190} variant="rounded" />
            </>
          ) : (
            <FlexRow>
              <HeaderTitleContainer>
                <StyledTitle
                  variant="headline9"
                  children={"Sobre o modelo"}
                  setColor="neutral30"
                />
                <StyledTitle
                  variant="headline7"
                  children={data?.name || " - "}
                  setColor="neutral10"
                />
              </HeaderTitleContainer>
              <HeaderButtons>
                <div style={{ marginTop: "-16px" }}>
                  <PillButton
                    variant="default"
                    size="small"
                    type="secondary"
                    icon="IconX"
                    onClick={onClose}
                  />
                </div>
              </HeaderButtons>
            </FlexRow>
          )}
        </Header>

        <Body>
          {isLoading ? (
            <>
              <div>
                <Skeleton
                  width={50}
                  style={{ marginBottom: 8 }}
                  variant="rounded"
                />
                <Skeleton width={100} variant="rounded" />
              </div>
              <div>
                <Skeleton
                  width={150}
                  style={{ marginBottom: 8 }}
                  variant="rounded"
                />
                <Skeleton
                  width={"100%"}
                  height={"50px"}
                  variant="rounded"
                  style={{ marginBottom: 8 }}
                />
                <Skeleton
                  width={"100%"}
                  height={"130px"}
                  variant="rounded"
                  style={{ marginBottom: 8 }}
                />

                <Skeleton width={"100%"} variant="rounded" height={"70px"} />
              </div>
            </>
          ) : (
            <>
              <ContentContainer>
                <StyledTitle
                  variant="headline9"
                  children={"Público"}
                  setColor="neutral30"
                />
                <StyledText
                  variant="body4"
                  children={data?.target || " - "}
                  setColor="neutral30"
                />
              </ContentContainer>

              <ContentContainer>
                <StyledTitle
                  variant="headline9"
                  children={"Recomendação de aplicação"}
                  setColor="neutral30"
                />

                {data?.aboutModel.map((item) => {
                  switch (item.section) {
                    case "top":
                      return (
                        <StyledText
                          key={item.section}
                          variant="body4"
                          setColor="neutral30"
                        >
                          O modelo de avaliação <Span children={data?.name} /> é
                          indicado para {item.text}
                        </StyledText>
                      );
                    case "center":
                      return (
                        <StyledText
                          key={item.section}
                          variant="body4"
                          setColor="neutral30"
                          children={item.text}
                        />
                      );
                    case "bottom":
                      return (
                        <StyledText
                          key={item.section}
                          variant="body4"
                          setColor="neutral30"
                          children={`Importante: ${item.text}`}
                        />
                      );
                    default:
                      <div key={item.section}></div>;
                      break;
                  }
                })}
              </ContentContainer>
            </>
          )}
        </Body>

        <ButtonContainer>
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              if (data?.name === "Habilidades em foco") {
                track({
                  name: "people_strategic_hr_performance_company_evaluations_createevaluation_model_skillsinfocus_details_use_clicked",
                });
              } else {
                track({
                  name: "people_strategic_hr_performance_company_evaluations_createevaluation_model_technologytalents_details_use_clicked",
                });
              }

              onModelSelected(modelId);
            }}
          >
            Usar modelo
            <StyledIcon name="IconArrowRight" setColor="neutral100" />
          </Button>
        </ButtonContainer>
      </MainContainer>
    </StyledDrawer>
  );
};
