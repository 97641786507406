import { DataCard } from "@components/Cards";

import { CardTable } from "../CardTable";

import { renderHtmlText } from "@utils";

import type { SectionType } from "../../../types";

import { CardsContainer } from "./styled";

interface EvalFormTabProps {
  data: SectionType[];
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
}
export const EvalFormTab = ({
  data,
  onNavigate,
  disabled,
}: EvalFormTabProps) => {
  return (
    <CardsContainer>
      <DataCard
        title="Revisar perguntas"
        subTitle="Confira as perguntas que serão enviadas nessa pesquisa."
        editable={!disabled}
        onEditClick={onNavigate}
        children={
          <CardTable
            leftColumnTitle="Critério"
            rightColumnTitle="Descrição"
            sections={data?.map((item) => ({
              name: item.name,
              description: item.description || "",
              data: (item?.criterials || []).map((criterial) => ({
                order: criterial.order,
                title: criterial.name || "",
                subtitle: renderHtmlText(criterial.description || ""),
              })),
            }))}
          />
        }
      />
    </CardsContainer>
  );
};
