import styled from "styled-components";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      max-height: 90vh;
      width: 90%;
      max-width: 978px !important;
    }

    .modal-content-area {
      padding: 40px 112px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterContainer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`;

const HeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 40px 24px 40px 112px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 540px;
`;

export const StyledTab = styled(Tab)`
  &.MuiTabs-root {
    margin-bottom: 32px !important;
  }
`;

export { StyledModal, Flex, FooterContainer, HeaderContainer, TitleContainer };
