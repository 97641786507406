import { useEffect, useState } from "react";
import { PillButton, Radio } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, StyledTitle, dispatchToast, track } from "@utils";
import {
  ButtonsContainer,
  CardContainer,
  Header,
  OptionCard,
  OptionsContainer,
  PageContainer,
} from "./styled";
import { trpc } from "@api/client";

export const OtherSettings = () => {
  const [isEditing, setIsEditing] = useState(false);

  const { data, refetch } =
    trpc.performance.feedback.getFeedbackPrivacy.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
    });
  const { mutate, isLoading } =
    trpc.performance.feedback.updateCompanyFeedbackPrivacy.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: "Configuração alterada com sucesso.",
          type: "success",
        });
        setIsEditing(false);
        refetch();
      },
      onError: () => {
        dispatchToast({
          content:
            "Não foi possível atualizar a configuração, tente novamente em breve.",
          type: "error",
        });
      },
    });

  const [feedbackPrivacy, setFeedbackPrivacy] = useState<any>("self");
  useEffect(() => {
    data && setFeedbackPrivacy(data);
  }, [data]);
  return (
    <PageContainer>
      <StyledText
        variant="body3"
        setColor="neutral40"
        children="Personalize as configurações de envio e visualização de feedbacks."
      />
      <CardContainer>
        <Header>
          <div>
            <StyledTitle
              variant="body2"
              setColor="neutral30"
              children="Visualização de feedback para líderes"
            />
            <StyledText
              variant="body4"
              setColor="neutral40"
              children="Configure como os líderes terão acesso aos feedbacks de seus liderados."
            />
          </div>
          {isEditing ? (
            <ButtonsContainer>
              <PillButton
                size="small"
                variant="default"
                type="secondary"
                icon="IconCheck"
                onClick={() => {
                  track({
                    name: "performance_feedbacks_settings_othersettings_leaderspermission_complete_clicked",
                  });
                  mutate({ feedbackPrivacy: feedbackPrivacy });
                }}
                loading={isLoading}
              />
              <PillButton
                size="small"
                variant="default"
                type="secondary"
                icon="IconX"
                onClick={() => {
                  setIsEditing(false);
                }}
                loading={isLoading}
              />
            </ButtonsContainer>
          ) : (
            <PillButton
              size="small"
              variant="default"
              type="secondary"
              icon="IconPencil"
              onClick={() => {
                track({
                  name: "performance_feedbacks_settings_othersettings_edit_clicked",
                });
                setIsEditing(true);
              }}
            />
          )}
        </Header>
        {isEditing ? (
          <OptionsContainer>
            <OptionCard>
              <Radio
                checked={feedbackPrivacy === "leader"}
                onChange={({ target }) => {
                  if (target.checked) {
                    track({
                      name: "performance_feedbacks_settings_othersettings_leaderspermission_leadershaveallaccess_selected",
                    });
                  }
                  setFeedbackPrivacy("leader");
                }}
              />
              <StyledText
                variant="body3"
                setColor="neutral40"
                children="Líderes têm acesso a todos os feedbacks enviados e recebidos por seus liderados"
              />
            </OptionCard>
            <OptionCard>
              <Radio
                checked={feedbackPrivacy === "self" || !feedbackPrivacy}
                onChange={({ target }) => {
                  if (target.checked) {
                    track({
                      name: "performance_feedbacks_settings_othersettings_leaderspermission_leadershaverestrictedaccess_selected",
                    });
                  }
                  setFeedbackPrivacy("self");
                }}
              />
              <StyledText
                variant="body3"
                setColor="neutral40"
                children="Líderes têm acesso somente aos feedbacks que seus liderados escolheram compartilhar"
              />
            </OptionCard>
          </OptionsContainer>
        ) : (
          <StyledTitle
            variant="body3"
            setColor="neutral30"
            children={
              feedbackPrivacy === "leader"
                ? "Líderes têm acesso a todos os feedbacks enviados e recebidos por seus liderados"
                : "Líderes têm acesso somente aos feedbacks que seus liderados escolheram compartilhar"
            }
          />
        )}
      </CardContainer>
    </PageContainer>
  );
};
