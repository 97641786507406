import styled, { keyframes } from "styled-components";

import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral100};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral90};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs}`};
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-self: center;
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 600;
`;

const StyledProgressText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 600;
`;

const rotate = keyframes`  
 from{
    -webkit-transform: rotate(360deg);
 }
 
 to{
    -webkit-transform: rotate(0deg);
 }
`;

const StyledRefreshIcon = styled(Icons)`
  animation: ${rotate} 2s linear infinite;
`;

const StyledCheckIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.success[40]};
`;

export {
  Container,
  ContentWrapper,
  TextWrapper,
  StyledText,
  StyledProgressText,
  StyledRefreshIcon,
  StyledCheckIcon,
};
