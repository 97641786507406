import {
  DataGrid,
  Skeleton,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CalibrationDrawer,
  CalibrationDetailsDrawer,
} from "@components/Drawers";

import { pageSizeOptions } from "@utils";

import { DataGridContainer } from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

import {
  EvaluationGrades,
  EvaluationStatus,
} from "server/src/services/evaluation/types";

import { useEvaluationDetails } from "./hooks";

interface ITableGridProps extends TableGridCommonProps {
  evaluation: {
    _id: string;
    name: string;
    status?: EvaluationStatus;
    types: EvaluationGrades["type"][];
  };
}

export const TableGrid = ({
  data,
  rawData,
  loading,
  evaluation,
}: ITableGridProps) => {
  const {
    columns,
    tableData,
    openCalibrationDrawer,
    openHistoryDrawer,
    onSubmit,
    onCloseHistory,
    onCloseCalibration,
  } = useEvaluationDetails({
    evaluation,
    data,
  });

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum resultado foi encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={tableData || []}
        expandSubRows
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhuma resultado foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />

      <CalibrationDrawer
        isOpen={openCalibrationDrawer.open}
        onClose={onCloseCalibration}
        data={openCalibrationDrawer.data}
        onSubmit={onSubmit}
      />

      <CalibrationDetailsDrawer
        isOpen={openHistoryDrawer.open}
        onClose={onCloseHistory}
        data={openHistoryDrawer.data}
      />
    </DataGridContainer>
  );
};
