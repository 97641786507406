import { Breadcrumbs, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin: 40px 0px 20px 0px;
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-top: 38px;
`;

export const Title = styled(Typography)`
  display: flex;
  color: ${({ theme }) => theme.colors.neutral[20]};
  align-items: center;
  gap: 8px;
  button {
    align-self: center;
  }
`;
