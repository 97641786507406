import { useMemo } from "react";
import { FormikProps } from "formik";
import { cloneDeep } from "lodash-es";

import { SectionsOptions } from "../../components/SectionsOptions";

import { SectionItem } from "./components/SectionItem";
import { DraggableCriterials } from "./components/DraggableCriterials";
import { CriterialsOptions } from "./components/CriterialsOptions";

import { dispatchToast, getIn } from "@utils";

import {
  addCriteriaBySection,
  removeCriteriaBySection,
  getEvaluationCriterials,
} from "../../utils";

import { FormSkeleton, FormContainer } from "./styled";

import type { SectionsFormProps } from "../../../types";
import type { onOptionsClickedType } from "../../types";

interface ISectionsFormProps {
  formik: FormikProps<SectionsFormProps>;
  selectedSectionIndex: number;
  isLoading?: boolean;
  disabledEdit: boolean;
  onOptionsClicked: onOptionsClickedType;
  hasByLeader: boolean;
}

export const SectionsForm = ({
  formik,
  selectedSectionIndex,
  disabledEdit,
  isLoading,
  onOptionsClicked,
  hasByLeader,
}: ISectionsFormProps) => {
  const section = formik.values.sections?.[selectedSectionIndex];

  const questionsLength = section?.criterials?.length || 0;

  const hasSectionError = useMemo(() => {
    const sectionErrors = Array.isArray(getIn(formik.errors, "sections"))
      ? (formik.errors.sections as any)
      : undefined;
    const touchedSections = formik.touched.sections || [];

    const sectionError = !!Object.keys(
      getIn(sectionErrors, `[${selectedSectionIndex}]`) || {}
    ).length;

    const sectionTouched = !!Object.keys(
      touchedSections?.[selectedSectionIndex] || {}
    ).length;

    return sectionTouched && sectionError;
  }, [formik.errors.sections, formik.touched.sections, selectedSectionIndex]);

  if (isLoading) return <FormSkeleton />;

  return (
    <FormContainer>
      {section && (
        <SectionItem
          error={hasSectionError}
          title={section.name || "Seção sem título"}
          description={section.description || "Descrição (opcional)"}
          tagName={`${questionsLength} pergunta${
            questionsLength !== 1 ? "s" : ""
          }`}
          tagVariant={"gray"}
          actionsElement={
            <SectionsOptions
              type="buttons"
              section={section}
              selectedSectionIndex={selectedSectionIndex}
              sectionTitles={(formik.values.sections || []).map(
                (section) => section.name
              )}
              sectionsLimit={
                (formik.values.sections || []).length <= (hasByLeader ? 2 : 1)
              }
              onOptionsClicked={onOptionsClicked}
              disabledEdit={disabledEdit}
              hasByLeader={hasByLeader}
            />
          }
          children={
            <>
              <DraggableCriterials
                criterials={section.criterials || []}
                onReordered={(items) => {
                  const sections = cloneDeep(formik.values.sections || []);

                  sections[selectedSectionIndex].criterials = items;

                  formik.handleChange({
                    target: { id: "sections", value: sections },
                  });
                }}
                onDeletedCriteria={(criteriaId) => {
                  const updatedSections = removeCriteriaBySection({
                    sections: formik.values.sections,
                    selectedSectionIndex,
                    criteriaId,
                  });

                  formik.handleChange({
                    target: { id: "sections", value: updatedSections },
                  });

                  dispatchToast({
                    type: "success",
                    content:
                      "Critério removido do formulário de avaliação com sucesso.",
                  });
                }}
                disabledEdit={disabledEdit}
              />

              <CriterialsOptions
                hasCriterials={!!section.criterials?.length}
                evaluationCriterials={getEvaluationCriterials(
                  formik.values.sections
                )}
                onAddedCriteria={(criterials) => {
                  const updatedSections = addCriteriaBySection({
                    sections: formik.values.sections,
                    selectedSectionIndex,
                    criterials,
                  });

                  formik.handleChange({
                    target: { id: "sections", value: updatedSections },
                  });

                  dispatchToast({
                    type: "success",
                    content:
                      "Critério(s) adicionado(s) ao formulário de avaliação com sucesso.",
                  });
                }}
                disabledEdit={disabledEdit}
              />
            </>
          }
        />
      )}
    </FormContainer>
  );
};
