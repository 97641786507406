import { useMemo } from "react";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

import { Timeline } from "@components/Timeline";

import { StyledTitle } from "@utils";

import { Cycle } from "server/src/services/cycle/types";

import { MainContainer, PreviewContainer } from "./styled";

type StepShape = { [key: string]: { label: string; icon: IconTypes } };

const stepShape: StepShape = {
  evaluation: {
    label: "Avaliações",
    icon: "IconFileDescription",
  },
  calibration: { label: "Calibração", icon: "IconBrandSpeedtest" },
  feedback: { label: "Feedback", icon: "IconMailOpened" },
  idp: { label: "PDI", icon: "IconPlaneTilt" },
};

interface IEvaluationsChronogramProps {
  cycle?: Cycle;
}

export const EvaluationsChronogram = ({
  cycle,
}: IEvaluationsChronogramProps) => {
  const cycleSteps = useMemo(() => {
    return (cycle?.steps || []).map((step) => {
      const item = stepShape[step?.type] || {};

      return {
        label: item.label || "",
        icon: item.icon || "",
        start: step.startDate || "",
        end: step.endDate || "",
      };
    });
  }, [cycle]);

  return (
    <MainContainer style={{ gap: "24px" }}>
      <PreviewContainer>
        <StyledTitle
          variant="headline9"
          children={"Pré-visualização do cronograma"}
          setColor={"neutral30"}
        />
        <Timeline items={cycleSteps} />
      </PreviewContainer>
    </MainContainer>
  );
};
