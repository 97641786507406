import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const InfoText = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const WarnText = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  margin-bottom: 16px;
`;
