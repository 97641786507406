import { useNavigate } from "react-router-dom";
import { routes } from "@routes";
import { useMemo } from "react";

import { Button, Menu } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledText,
  StyledTitle,
  StyledIcon,
  dispatchToast,
  getAccessTokenSync,
  track,
} from "@utils";

import { MainContainer, Option } from "./styled";

export const Header = () => {
  const navigate = useNavigate();

  const { employeeId = "" } = getAccessTokenSync();

  const options = useMemo(
    () => [
      {
        onClick: () => {
          track({
            name: "performance_myfeedbacks_sendfeedback_selected",
          });
          navigate(routes.PageSendFeedback);
        },
        children: (
          <Option>
            <StyledIcon name="IconMailbox" fill="transparent" />
            <StyledText variant="body3">Enviar Feedback</StyledText>
          </Option>
        ),
      },
      {
        onClick: () => {
          track({
            name: "performance_myfeedbacks_requestfeedback_selected",
          });
          if (!employeeId) {
            dispatchToast({
              type: "error",
              content:
                "Por favor, efetue o logout e faça login novamente para continuar. Obrigado!",
            });

            return;
          }

          navigate(
            routes.PageRequestFeedback.replace(":filterType", "to").replace(
              ":_id",
              employeeId
            )
          );
        },
        children: (
          <Option>
            <StyledIcon name="IconMailForward" fill="transparent" />
            <StyledText variant="body3">Solicitar Feedback</StyledText>
          </Option>
        ),
      },
    ],
    [employeeId]
  );

  return (
    <>
      <MainContainer>
        <div>
          <StyledTitle
            variant="headline6"
            children="Meus feedbacks"
            setColor="neutral20"
          />
          <StyledText
            variant="body3"
            setColor="neutral40"
            children="Envie, solicite e receba feedbacks valiosos para o seu crescimento pessoal e profissional de forma fácil e construtiva."
          />
        </div>
        <Menu
          type={"select"}
          options={options}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              track({
                name: "performance_myfeedbacks_createnew_clicked",
              });
            }}
          >
            Criar Novo <StyledIcon name="IconPlus" size={24} />
          </Button>
        </Menu>
      </MainContainer>
    </>
  );
};
