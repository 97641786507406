import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Dot,
  PillButton,
  Menu,
  Tab,
  Skeleton,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";
import { routes } from "@routes";

import { StyledTitle, StyledText } from "@utils";

import { MoreInfo } from "./Tabs/MoreInfo";
import { Actions } from "./Tabs/Actions";

import { DeleteIDPModal } from "@components/Modals";

import {
  Body,
  FlexRow,
  Header,
  MainContainer,
  FlexContainer,
  StyledDrawer,
  Column,
  Tag,
  HeaderButtons,
  ProgressArea,
  ProgressWrapper,
  ProgressBar,
  Title,
  PlanDescription,
  PlanGoal,
  Option,
  DeleteOption,
} from "./styled";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dataId?: string;
  refetch?: () => void;
  origin: "my-idps" | "manage-idps";
};
export const IdpDrawer = ({ isOpen, onClose, dataId = "", origin }: Props) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [deleteIdp, setDeleteIdp] = useState({ idpId: "", isOpen: false });

  const { data, isLoading } = trpc.performance.idp.getIdpByIdFull.useQuery(
    {
      _id: dataId,
    },
    {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      enabled: isOpen && !!dataId,
    }
  );

  const renderTag = (status: string) => {
    switch (status) {
      case "finished":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"green"}>
              <Dot variant={"success"} />
              <StyledTitle variant="caption">Concluído</StyledTitle>
            </Tag>
          </div>
        );
      case "progressing":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"blue"}>
              <Dot variant={"blue"} />
              <StyledTitle variant="caption">Em andamento</StyledTitle>
            </Tag>
          </div>
        );
      case "created":
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"gray"}>
              <Dot variant={"gray"} />
              <StyledTitle variant="caption">Não iniciado</StyledTitle>
            </Tag>
          </div>
        );
      case "delayed":
      default:
        return (
          <div style={{ display: "flex" }}>
            <Tag setColor={"yellow"}>
              <Dot variant={"yellow"} />
              <StyledTitle variant="caption">Atrasado</StyledTitle>
            </Tag>
          </div>
        );
    }
  };

  const tabItems = useMemo(() => {
    const items = [
      {
        label: "Ações de desempenho",
        component: <Actions data={data} origin={origin} />,
      },
      {
        label: "Mais informações",
        component: <MoreInfo data={data} />,
      },
    ];

    return items;
  }, [data]);

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          {isLoading ? (
            <Skeleton variant="rectangular" height={"500px"} />
          ) : (
            <>
              <FlexRow>
                <StyledTitle variant="body1" children={"Plano de ação"} />
                <HeaderButtons>
                  <Menu
                    type={"select"}
                    options={[
                      {
                        onClick: () => {
                          navigate(
                            routes.PageEditIDP.replace(":origin", origin)
                              .replace(":step", "1")
                              .replace(":idpId", dataId)
                          );
                        },
                        children: (
                          <Option>
                            <Icons name="IconPencil" fill="transparent" />
                            <StyledText
                              variant="body3"
                              style={{ fontWeight: 600 }}
                            >
                              Editar plano de ação
                            </StyledText>
                          </Option>
                        ),
                      },
                      // {
                      //   onClick: () => {
                      //     console.log("ll");
                      //   },
                      //   children: (
                      //     <Option>
                      //       <Icons name="IconDownload" fill="transparent" />
                      //       <StyledText
                      //         variant="body3"
                      //         style={{ fontWeight: 600 }}
                      //       >
                      //         Exportar relatório
                      //       </StyledText>
                      //     </Option>
                      //   ),
                      // },
                      {
                        onClick: () => {
                          setDeleteIdp({ idpId: dataId, isOpen: true });
                        },
                        children: (
                          <DeleteOption>
                            <Icons name="IconTrash" fill="transparent" />
                            <StyledText
                              variant="body3"
                              style={{ fontWeight: 600 }}
                            >
                              Excluir plano de ação
                            </StyledText>
                          </DeleteOption>
                        ),
                      },
                    ]}
                    disableAutoFocusItem={true}
                    disabled={isLoading}
                    anchorOrigin={{ vertical: "center", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <PillButton
                      variant="default"
                      size="small"
                      type="secondary"
                      icon="IconDotsVertical"
                    />
                  </Menu>

                  <PillButton
                    variant="default"
                    size="small"
                    type="secondary"
                    icon="IconX"
                    onClick={onClose}
                  />
                </HeaderButtons>
              </FlexRow>

              <FlexContainer>
                <Column>
                  <Title variant="body4" children={"Pessoa"} />
                  <Tag setColor={"secondary"}>
                    <StyledTitle variant="caption">
                      {data?.employee?.name}
                    </StyledTitle>
                  </Tag>
                </Column>
              </FlexContainer>
              <FlexContainer style={{ marginTop: "22px" }}>
                <Column style={{ marginRight: "40px" }}>
                  <Title variant="body4" children={"Plano"} />
                  <PlanDescription
                    variant="headline8"
                    style={{ marginBottom: "4px" }}
                  >
                    {data?.name}
                  </PlanDescription>
                </Column>
                <Column>
                  <Title variant="body4" children={"Status"} />
                  {renderTag(data?.status ?? "")}
                </Column>
              </FlexContainer>
              <FlexContainer style={{ marginTop: "22px" }}>
                <Column style={{ marginRight: "40px" }}>
                  <Title variant="body4" children={"Objetivo do plano"} />
                  <PlanGoal variant="body3" style={{ marginBottom: "4px" }}>
                    {data?.goal}
                  </PlanGoal>
                </Column>
              </FlexContainer>
              <FlexContainer style={{ marginTop: "22px" }}>
                <Column style={{ marginRight: "40px", width: "100%" }}>
                  <Title variant="body4" children={"Progresso do plano"} />
                  <ProgressArea>
                    <ProgressWrapper>
                      <ProgressBar totalDone={data?.progress ?? 0}>
                        <span />
                      </ProgressBar>
                    </ProgressWrapper>
                    <div>{data?.progress}%</div>
                  </ProgressArea>
                </Column>
              </FlexContainer>
            </>
          )}
        </Header>
        <Body>
          {isLoading ? (
            <Skeleton variant="rectangular" height={"500px"} />
          ) : (
            <Tab tabItens={tabItems} />
          )}
        </Body>
      </MainContainer>
      <DeleteIDPModal
        open={deleteIdp.isOpen}
        idpId={deleteIdp.idpId}
        refetch={() => {
          utils.performance.idp.getEmployeeIdps.invalidate();
          utils.performance.idp.getIdpsByEmployee.invalidate();
          utils.performance.idp.getEmployeeIdpsByCycle.invalidate();

          onClose();
        }}
        onClose={() => setDeleteIdp({ idpId: "", isOpen: false })}
      />
    </StyledDrawer>
  );
};
