import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Maincontainer = styled.div``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const Line = styled.div<{ marginBottom?: string; marginTop?: string }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FeedBackText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
