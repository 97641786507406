import { useMemo } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";

import { pageSizeOptions } from "@utils";

import { ProfileTable } from "@components/ProfileTable";

import type { TableGridCommonProps } from "@components/Table/types";

import { DataGridContainer } from "./styled";

export const TableGrid = ({
  data,
  rawData,
  isFiltered,
  loading,
}: TableGridCommonProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Avaliador",
        accessor: "evaluator.name",
        disableSortBy: true,
        Cell: ({ value }) => {
          return <ProfileTable name={value} />;
        },
      },
      {
        Header: "Avaliado",
        accessor: "evaluated.name",
        disableSortBy: true,
        Cell: ({ value }) => {
          return <ProfileTable name={value} />;
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhum avaliado encontrado!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: isFiltered,
          message: "Nenhum avaliado encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </DataGridContainer>
  );
};
