import { useFormik } from "formik";

import { trpc } from "@api/client";
import { dispatchToast } from "@utils";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { EditCriteriaForm } from "./components/EditCriteriaForm";

import { ContentContainer, StyledModal } from "./styled";

import { validationSchema, EditCriteriaFormProps } from "./types";

import type { CommonModalProps } from "@components/Modals/types";

interface IEditCriteriaModalProps extends CommonModalProps {
  _id: string;
  refetch: () => any;
}

type EditCriterialModalProps = Omit<IEditCriteriaModalProps, "onConfirm">;

export const EditCriteriaModal = ({
  _id,
  open,
  onClose,
  refetch,
}: EditCriterialModalProps) => {
  const { isFetching: loadingData } =
    trpc.performance.criteria.getCriteriaById.useQuery(
      { params: { _id } },
      {
        enabled: !!_id,
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          formik.setValues({
            name: data?.name,
            description: data?.description,
          });
        },
        onError: () => {
          const message =
            "Erro ao buscar o critério avaliativo, tente novamente em breve!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );

  const { mutate, isLoading: isUpdating } =
    trpc.performance.criteria.updateCriteria.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Critério avaliativo editado com sucesso!",
        });

        refetch();
        handleClose();
      },
      onError: (e: any) => {
        const valueExists = e?.data?.error === "CRITERIAL_EXISTS_ERROR";

        let message =
          "Erro ao editar o critério avaliativo, tente novamente em breve.";

        if (valueExists)
          message = "Erro ao editar critério avaliativo, nome já existe!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const formik = useFormik<EditCriteriaFormProps>({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate({
        params: {
          _id: _id,
          name: values.name,
          description: values.description,
        },
      });
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleClose = () => {
    formik.resetForm();
    onClose?.(new Event("click"));
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <Header
          title="Critério de avaliação"
          subTitle="Adicione o nome e descrição do critério."
          onClose={handleClose}
        />
      }
      footer={
        <Footer
          submitTitle="Salvar critério"
          isLoading={isUpdating}
          disabled={loadingData}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      }
    >
      <ContentContainer>
        <EditCriteriaForm formik={formik} loadingData={loadingData} />
      </ContentContainer>
    </StyledModal>
  );
};
