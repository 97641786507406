import { useMemo } from "react";
import { useNavigate, NavigateOptions } from "react-router-dom";

import {
  PillButton,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  Container,
  LeftContainer,
  StyledBreadcrumbs,
  StyledStepper,
} from "./styled";

interface RoutesProps {
  route?: string;
  label: string;
  options?: NavigateOptions;
}

interface StepperProps {
  steps: string[];
  activeStep: number;
}

interface HeaderProps {
  backButton?: boolean;
  routes: RoutesProps[];
  stepper?: StepperProps;
}

export const Header = ({ routes, stepper, backButton = true }: HeaderProps) => {
  const navigate = useNavigate();

  const breadCrumbs = useMemo(
    () =>
      routes.map(({ label, route, options }) => {
        return (
          <LinkButton
            key={label}
            style={{ alignSelf: "flex-start" }}
            variant="neutral"
            onClick={() => route && navigate(route, options)}
          >
            {label}
          </LinkButton>
        );
      }),
    [routes]
  );

  return (
    <Container>
      <LeftContainer>
        {backButton && (
          <PillButton
            variant="default"
            size="small"
            type="secondary"
            icon="IconArrowLeft"
            onClick={() => navigate(-1)}
          />
        )}

        <StyledBreadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={breadCrumbs}
        />
      </LeftContainer>
      <StyledStepper
        steps={stepper?.steps || []}
        activeStep={stepper?.activeStep}
      />
    </Container>
  );
};
