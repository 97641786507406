import { StyledTitle, StyledText, renderHtmlText } from "@utils";

import {
  SectionContainer,
  SectionTitle,
  LeftColumn,
  OrderContainer,
  RightColumn,
  TableLine,
  MainContainer,
} from "./styled";

interface ICardTableProps {
  rightColumnTitle: string;
  leftColumnTitle: string;
  sections: {
    name?: string;
    description?: string;
    data: { name: string; description: string; order: number }[];
  }[];
}

export const CardTable = ({
  leftColumnTitle,
  rightColumnTitle,
  sections,
}: ICardTableProps) => {
  if (!sections?.length) return <></>;

  const renderTableBody = (data: ICardTableProps["sections"][0]["data"]) => {
    if (!data.length) return <></>;

    return data.map((value, index) => {
      const lastChildren = data.length - 1 === index;

      return (
        <TableLine hasLine={!lastChildren} key={value.name + index}>
          <LeftColumn>
            {value?.order !== undefined && (
              <OrderContainer children={value.order + 1} />
            )}

            {value?.name && (
              <StyledTitle
                variant="body3"
                children={value.name}
                setColor="neutral40"
              />
            )}
          </LeftColumn>
          <RightColumn>
            {value?.description && (
              <StyledTitle
                variant="body3"
                children={renderHtmlText(value.description)}
                setColor="neutral40"
              />
            )}
          </RightColumn>
        </TableLine>
      );
    });
  };

  return (
    <MainContainer>
      {sections.map((section, index) => {
        return (
          <SectionContainer key={index}>
            <SectionTitle>
              {section.name && (
                <StyledTitle
                  variant="headline8"
                  children={section.name || " - "}
                  setColor="neutral30"
                />
              )}

              {section.description && (
                <StyledText
                  variant="body4"
                  children={renderHtmlText(section.description) || " - "}
                  setColor="neutral50"
                />
              )}
            </SectionTitle>

            {section.data?.[0] && (
              <div>
                <TableLine style={{ padding: "0px" }}>
                  <LeftColumn
                    children={
                      <StyledTitle
                        variant="headline9"
                        children={leftColumnTitle}
                        setColor="neutral30"
                      />
                    }
                  />
                  <RightColumn
                    children={
                      <StyledTitle
                        variant="headline9"
                        children={rightColumnTitle}
                        setColor="neutral30"
                      />
                    }
                  />
                </TableLine>

                {renderTableBody(section.data || [])}
              </div>
            )}
          </SectionContainer>
        );
      })}
    </MainContainer>
  );
};
