import styled from "styled-components";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const FormSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  height: "500px",
  width: "100%",
}))`
  border-radius: 12px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const CharCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px 16px;
`;

export { FormSkeleton, FormContainer, CharCountContainer };
