import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { IconTypes, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { Timeline, TimelineProps } from "@components/Timeline";
import { Banner, Header, Tabs } from "./components";

import { dispatchToast } from "@utils";

import { TimelineArea } from "./styled";

export const PageCycleDetail = () => {
  const { id = "" } = useParams();

  const { data: cycle, isFetching: isLoading } =
    trpc.performance.cycle.getCycleById.useQuery(
      { cycleId: id },
      {
        enabled: !!id,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: (e: any) => {
          const cycleNotExists = e?.data?.error === "CYCLE_NOT_EXISTS_ERROR";

          const message = cycleNotExists
            ? "Erro ao buscar ciclo, ciclo não foi encontrado!"
            : "Erro ao buscar ciclo, tente novamente em breve!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );

  const { data: settingsData, isFetching: isLoadingSettings = true } =
    trpc.performance.cycle.getCycleSetup.useQuery(
      { cycleId: id },
      {
        enabled: !!id,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

  const timelineItems = useMemo(() => {
    if (!cycle?.steps?.length) return [];

    const label = {
      evaluation: { label: "Avaliações", icon: "IconFileDescription" },
      calibration: { label: "Calibração", icon: "IconBrandSpeedtest" },
      feedback: { label: "Feedback", icon: "IconMail" },
      idp: { label: "PDI", icon: "IconPlaneTilt" },
    } as { [key: string]: { label: string; icon: IconTypes } };

    const mappedData: TimelineProps["items"] = cycle.steps.map((step) => ({
      label: label[step?.type]?.label,
      icon: label[step?.type]?.icon,
      start: step?.startDate,
      end: step?.endDate,
    }));

    return mappedData;
  }, [cycle]);

  return (
    <PageContainer style={{ paddingTop: "40px" }}>
      <Header
        _id={id}
        name={cycle?.name}
        description={cycle?.description}
        cycleStatus={cycle?.cycleStatus}
        isLoading={isLoading}
      />

      <Banner data={settingsData} isLoading={isLoadingSettings} />

      {!!timelineItems.length && !isLoading && (
        <TimelineArea>
          <Timeline items={timelineItems || []} />
        </TimelineArea>
      )}

      <Tabs cycle={cycle} />
    </PageContainer>
  );
};
