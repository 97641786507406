import { Dot, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledIcon } from "@utils";

export const FeedbackTag = (evaluation) => {
  switch (evaluation) {
    case "doing_well":
      return (
        <Tag variant={"green"} style={{ height: "26px", width: "165px" }}>
          <Dot variant={"green"} />
          Mandando bem
          <span>
            <StyledIcon name="IconChevronDown" fill="none" size={16} />
          </span>
        </Tag>
      );
    case "could_improve":
      return (
        <Tag variant={"error"} style={{ height: "26px", width: "160px" }}>
          <Dot variant={"error"} />
          Pode melhorar
          <span>
            <StyledIcon name="IconChevronDown" fill="none" size={16} />
          </span>
        </Tag>
      );
    case "":
    default:
      return (
        <Tag variant={"gray"} style={{ height: "26px" }}>
          <Dot variant={"gray"} />
          Selecionar
          <span>
            <StyledIcon name="IconChevronDown" fill="none" size={16} />
          </span>
        </Tag>
      );
  }
};
