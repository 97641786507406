import { ConfirmationModal } from "@components/Modals";

import type { CommonModalProps } from "@components/Modals/types";

export const UnfinishEvaluationModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: CommonModalProps) => {
  return (
    <ConfirmationModal
      open={open}
      showWarning
      secondaryVariant="default"
      icon="IconAlertCircle"
      title="Você não terminou de responder todas as questões"
      subTitle="É necessário responder todas questões para enviar a avaliação."
      confirm={{
        title: "Completar avaliação",
        variant: "secondary",
      }}
      cancel={{ hide: true }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};
