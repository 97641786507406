import styled from "styled-components";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const FormSkeleton = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  height: "400px",
  width: "100%",
}))`
  border-radius: 12px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export { FormSkeleton, FormContainer };
