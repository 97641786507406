import styled from "styled-components";

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 32px;
  width: 100%;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  flex-wrap: wrap;
`;

export { Container };
