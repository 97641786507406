import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TimelineArea = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 32px;
  border-radius: 12px;
  margin-bottom: 32px;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 32px 16px;
  }
`;

export const TimelineTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const TimelineTitleArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TimelineInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ActionButton = styled(Button)`
  width: 144px;
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DeleteOption = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const DeleteWarning = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;
