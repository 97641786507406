import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CriteriasList = styled.ol`
  list-style-type: decimal;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const CriteriaWrapper = styled.div`
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const CriteriaItem = styled.li`
  list-style-position: inside;
  line-height: ${({ theme }) => theme.spacings.xs};
  font-size: ${({ theme }) => theme.spacings.xs1};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral30};
  &::marker {
    margin-right: ${({ theme }) => theme.spacings.xs};
  }
`;

export const Description = styled.div`
  > * {
    color: ${({ theme }) => theme.colors.neutral30};
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    list-style-position: inside;
  }
`;

export const EllipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
