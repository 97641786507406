import { Modal } from "@components/Modal";

import styled from "styled-components";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      width: 100%;
      max-width: 645px !important;
      max-height: 95vh;
    }

    .modal-content-area {
      padding: 40px 112px;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export { StyledModal, ContentContainer, IconContainer, ConfirmationContainer };
