import { ReactNode, useState } from "react";

import {
  TextField,
  ShapeIconProps,
  ShapeIconOptions,
  ShapeIcon,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle } from "@utils";

import { DefaultFooter } from "@components/Modal";

import type { CommonModalProps } from "@components/Modals/types";

import {
  StyledModal,
  ContentContainer,
  IconContainer,
  ConfirmationContainer,
} from "./styled";

interface WordsConfirmationModalProps extends CommonModalProps {
  wordToConfirm: string;
  title: string;
  subTitle?: string;
  customSubTitle?: ReactNode;
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  cancel?: { title: string; hide?: boolean };
  confirm: { title: string; icon?: ShapeIconProps["name"]; iconColor?: string };
  icon?: ShapeIconProps["name"];
  showWarning?: boolean;
  variantType?: ShapeIconOptions;
  primaryColor?: string;
  secondaryVariant?: ShapeIconOptions;
}

export const WordsConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  wordToConfirm,
  variantType = "error",
  title,
  subTitle,
  customSubTitle,
  titleStyle,
  subTitleStyle,
  cancel = { title: "Cancelar", hide: false },
  confirm,
  icon,
  showWarning,
  primaryColor = "error40",
  secondaryVariant = "default",
}: WordsConfirmationModalProps) => {
  const [enableConfirm, setEnableConfirm] = useState(false);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      footer={
        <DefaultFooter
          variantType={variantType}
          isLoading={isLoading}
          secondaryVariant={secondaryVariant}
          cancelButton={{
            title: cancel.title,
            onClick: onClose,
            hide: cancel.hide,
          }}
          confirmButton={{
            disabled: !enableConfirm,
            content: (
              <>
                {confirm.title}
                {confirm.icon && (
                  <StyledIcon
                    setColor={confirm.iconColor}
                    name={confirm.icon}
                    fill="none"
                    size={40}
                  />
                )}
              </>
            ),
            onClick: onConfirm,
          }}
        />
      }
    >
      <ContentContainer>
        {icon && (
          <IconContainer>
            <ShapeIcon
              variant={variantType}
              name={icon}
              size={64}
              stroke={variantType}
            />
          </IconContainer>
        )}

        {showWarning && (
          <StyledTitle
            variant="body3"
            children="Atenção!"
            setColor={primaryColor}
            style={{ textAlign: "center" }}
          />
        )}

        <StyledTitle
          variant="headline6"
          children={title}
          setColor="neutral20"
          style={{
            textAlign: "center",
            marginBottom: "16px",
            ...(titleStyle || {}),
          }}
        />

        {subTitle && (
          <StyledText
            variant="body3"
            children={subTitle}
            setColor="neutral50"
            style={{
              textAlign: "center",
              marginBottom: "14px",
              ...(subTitleStyle || {}),
            }}
          />
        )}

        {!subTitle && customSubTitle && customSubTitle}

        <ConfirmationContainer>
          <StyledTitle
            style={{
              marginBottom: "16px",
            }}
            variant="body3"
            children={`Digite ${wordToConfirm} e confirme`}
            setColor="neutral20"
          />
          <TextField
            style={{ textAlign: "left", width: "100%" }}
            label={`Digite ${wordToConfirm}`}
            inputProps={{ maxLength: wordToConfirm.length }}
            onChange={({ target }) => {
              setEnableConfirm(target.value === wordToConfirm);
            }}
          />
        </ConfirmationContainer>
      </ContentContainer>
    </StyledModal>
  );
};
