import { useEffect } from "react";
import { useFormik } from "formik";

import { trpc } from "@api/client";

import { Header, Footer, CompanyValueForm } from "./components";

import { dispatchToast } from "@utils";

import { CompanyValueFormProps, validationSchema } from "./types";

import type { CommonModalProps } from "@components/Modals/types";

import { StyledModal, ContentContainer } from "./styled";

type CompanyValue = {
  name: string;
  description: string;
};

interface IEditCompanyValueModalProps extends CommonModalProps {
  companyValueId: string;
  data: CompanyValue;
  refetch?: () => void;
}

type EditCompanyValueModalProps = Omit<
  IEditCompanyValueModalProps,
  "onConfirm"
>;

export const EditCompanyValueModal = ({
  open,
  companyValueId,
  data,
  onClose,
  refetch,
}: EditCompanyValueModalProps) => {
  const { mutate, isLoading } =
    trpc.performance.feedback.updateCompanyValue.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Valor editado com sucesso.",
        });

        refetch?.();
        onClose?.(new Event("click"));
      },
      onError: (e: any) => {
        const valueExists = e?.data?.error === "COMPANY_VALUE_EXISTS_ERROR";

        let message = "Erro ao editar o valor, tente novamente em breve.";

        if (valueExists) message = "Erro ao editar valor, nome já existe!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const formik = useFormik<CompanyValueFormProps>({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate({ companyValueId: companyValueId, params: values });
    },
  });

  useEffect(() => {
    if (!data) return;

    formik.setValues({ name: data?.name, description: data?.description });
  }, [data]);

  useEffect(() => {
    !open && formik.resetForm();
  }, [open]);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <Header
          title="Edite o valor empresarial"
          subTitle="Edite os valores empresariais que orientarão a realização dos feedbacks."
          onClose={onClose}
        />
      }
      footer={
        <Footer
          submitTitle="Editar valor"
          isLoading={isLoading}
          onSubmit={() => formik.handleSubmit()}
          onClose={onClose}
        />
      }
    >
      <ContentContainer>
        <CompanyValueForm formik={formik} />
      </ContentContainer>
    </StyledModal>
  );
};
